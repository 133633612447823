import React from "react";
import {
  Text,
  HStack,
  Button,
  Center,
  Icon,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { useJobAPI } from "../endpoints/jobs";
import { useFileAPI } from "../endpoints/files";
import { useAPIRequest } from "../hooks/useAPI";
import { handleFileUpload } from "../util/handleFileUpload";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Colors } from "../theme/colors";
import { isSubmissionElementEditable } from "../util/isSubmissionElementEditable";
import { useAppSelector } from "src/store";
import {
  lookupSubmissionElementById,
  selectSubmissionId,
  selectSubmissionMeta,
  selectUserRoleOnSubmission,
} from "src/store/selectors/submission.selectors";
import { SubmissionElementEditViewProps } from "src/types/submission.types";
import { BiasAnalysisSelectQuestion } from "./BiasAnalysisSelectQuestion";
import { BiasAnalysisDemographicTable } from "./BiasAnalysisDemographicTable";
import { BiasAnalysisControlGroupSelect } from "./BiasAnalysisControlGroupSelect";
import { SubmissionViewHeader } from "./SubmissionViewHeader";
import { useElementContext } from "src/hooks/useElementContext";
import { ELEMENT_LEFT_PADDING, BORDER_WIDTH } from "./DocumentSectionWrapper";
import { LEFT_MARGIN } from "./SubmissionEditorElements";

interface TestParams {
  prediction_label: string;
  true_label: string;
  demographic_dict: any;
  output_type: string;
  supervised: boolean;
}

export const BiasAnalysisSubmissionView = ({
  id,
}: SubmissionElementEditViewProps) => {
  const sendAPIRequest = useAPIRequest();
  const element = useAppSelector(lookupSubmissionElementById(id));
  const [isLoading, setIsLoading] = React.useState(false);
  const [fileId, setFileId] = React.useState<string | null>(null);
  const [fileURL, setFileURL] = React.useState<string | null>(null);
  const [testResults, setTestResults] = React.useState<string | null>(null);
  const [progress, setProgress] = React.useState(0);
  const { element: elementState } = useElementContext();
  const elementLevel = elementState ? elementState.indentationLevel - 1 : 1;
  const marginLeft =
    elementLevel * -LEFT_MARGIN +
    elementLevel * -ELEMENT_LEFT_PADDING +
    elementLevel * -BORDER_WIDTH -
    68;

  if (element && element.type !== "biasAnalysis") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const [uploadedFiles, setUploadedFiles] = React.useState(
    element?.content.uploadedFiles
  );
  const globalUserId = useAppSelector(
    (state) => state.user.userContext?.user._id
  );
  const meta = useAppSelector(selectSubmissionMeta);
  const [headers, setHeaders] = React.useState<string[]>([]);
  const role = useAppSelector((state) =>
    selectUserRoleOnSubmission(state, globalUserId)
  );

  const [selectedValues, setSelectedValues] = React.useState<TestParams>({
    prediction_label: "",
    true_label: "",
    demographic_dict: {
      Race: {
        labels: [],
        control_group: "",
      },
      Gender: {
        labels: [],
        control_group: "",
      },
      Age: {
        labels: [],
        control_group: "",
      },
      Disability: {
        labels: [],
        control_group: "",
      },
    },
    output_type: "continuous",
    supervised: true,
  });

  const { postJob, getJobById } = useJobAPI();
  const { postFiles } = useFileAPI();
  const isEditable = isSubmissionElementEditable(element?.content, role, meta);

  const submissionId = useAppSelector(selectSubmissionId);

  React.useEffect(() => {
    const run = async () => {
      try {
        const submission = await sendAPIRequest(`submissions/${submissionId}`);

        if (!submission.jobs || submission.jobs.length === 0) return;

        const mostRecentJob = submission.jobs[submission.jobs.length - 1];
        const outputFileURL = mostRecentJob.outputFile;

        const headersResponse = (await sendAPIRequest(
          `file/${mostRecentJob.inputFileId}/headers`,
          "GET"
        )) as any;

        const res = await fetch(outputFileURL);
        const htmlContent = await res.text();

        setHeaders(headersResponse.headers as string[]);
        setSelectedValues(mostRecentJob.parameters);
        setTestResults(htmlContent);
      } catch (e) {
        return;
      }
    };

    run();
  }, [submissionId, sendAPIRequest]);

  // Update nested properties of selectedValues
  const handleUpdateSelectedValues = (
    field: string,
    value: string | boolean
  ) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleDemographicChange = (
    category: string,
    label: string,
    categoryLabels: string[]
  ) => {
    const updatedDemographicValues = {
      ...selectedValues.demographic_dict,
      [category]: {
        ...selectedValues.demographic_dict[category],
        labels: categoryLabels,
      },
    };

    handleUpdateSelectedValues("demographic_dict", updatedDemographicValues);
  };

  const handleUpload = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!submissionId) {
        throw new Error("Submission ID is not set");
      }

      if (
        !element?.content.allowMultiple &&
        uploadedFiles &&
        uploadedFiles.length > 0
      ) {
        setUploadedFiles([]);
      }

      const [fileId, url] = await handleFileUpload(
        event,
        submissionId,
        id,
        postFiles
      );
      setFileId(fileId);
      setFileURL(url);
      setUploadedFiles((prev: any) => [...prev, { fileId, url }]);
      try {
        const headersResponse = await sendAPIRequest(
          `file/${fileId}/headers`,
          "GET"
        );
        setHeaders(headersResponse.headers as string[]);
      } catch (error) {
        console.error("Error retrieving headers", error);
      }
    },
    [
      id,
      postFiles,
      sendAPIRequest,
      submissionId,
      element?.content.allowMultiple,
      uploadedFiles,
    ]
  );

  const handleControlGroupUpdate = (category: string, controlGroup: string) => {
    const updatedDemographicValues = {
      ...selectedValues.demographic_dict,
      [category]: {
        ...selectedValues.demographic_dict[category],
        control_group: controlGroup,
      },
    };

    handleUpdateSelectedValues("demographic_dict", updatedDemographicValues);
  };

  const handleRunAnalysis = React.useCallback(async () => {
    try {
      setIsLoading(true);
      // API request to POST /jobs
      const jobResponse = await postJob({
        fileId: fileId!,
        testParams: selectedValues,
      });
      const { jobId } = jobResponse;

      // Start progress bar
      const interval = setInterval(() => {
        setProgress((prev) => {
          const newProgress = prev + 5;
          if (newProgress >= 100) {
            clearInterval(interval);
          }
          return newProgress;
        });
      }, 1000);

      // Wait for 20 seconds before making the next API call
      setTimeout(async () => {
        // API request to GET /jobs/:id
        const jobResult = await getJobById(jobId!);
        const { outputFileURL } = jobResult;

        // Download and display HTML content
        const res = await fetch(outputFileURL);
        const htmlContent = await res.text();

        setTestResults(htmlContent);
        setIsLoading(false);
        setProgress(0);
      }, 20000);
    } catch (error) {
      setIsLoading(false);
      console.error("Error running bias analysis", error);
    }
  }, [fileId, getJobById, postJob, selectedValues]);

  if (!element) {
    return null;
  }

  return (
    <div style={{ paddingBottom: 5 }}>
      <SubmissionViewHeader
        titleDataTestId={`bias-analysis-title-text-${element?.pathIndex.join("-")}`}
        rules={element.rules}
        title={element.content.title}
        titleNumber={element.titleNumber ?? ""}
        role={role}
        adjudicatorNotes={element.content.adjudicatorNotes}
        surveyTakerNotes={element.content.surveyTakerNotes}
        audience={element.content.audience}
        questionPlaceholder="Enter the bias analysis instructions"
        question={element.content.question}
      />

      <HStack gap={0}>
        <input
          data-test-id={`bias-file-upload-${element.pathIndex.join("-")}`}
          style={{ ...styles.input }}
          id={`bias-file-upload-${id}`}
          type="file"
          accept=".csv"
          onChange={handleUpload}
        />
        <label htmlFor={!isEditable ? "" : `bias-file-upload-${id}`}>
          <div
            style={{
              ...styles.label,
              marginBottom: 8,
              marginRight: 8,
              backgroundColor: !isEditable
                ? Colors.gray[300]
                : Colors.brightblue[500],
            }}
          >
            <Icon as={FileUploadOutlinedIcon} />
            <Text>Choose a file</Text>
          </div>
        </label>

        <VStack
          data-test-id={`uploaded-file-names-${element.pathIndex.join("-")}`}
        >
          {uploadedFiles &&
            uploadedFiles.map((file: any, idx: number) => (
              <Text key={idx}>{file.url} </Text>
            ))}
        </VStack>
      </HStack>
      {headers.length > 0 && (
        <VStack
          data-test-id={`bias-analysis-input-${element.pathIndex.join("-")}`}
          align="stretch"
          spacing={4}
        >
          <BiasAnalysisSelectQuestion
            disabled={!isEditable}
            question="Select the type of model output:"
            options={[
              ["Continuous Outcomes (e.g., rankings)", "continuous"],
              ["Discrete Outcomes (e.g., binary predictions)", "binary"],
            ]}
            value={selectedValues.output_type}
            onChange={(value) =>
              handleUpdateSelectedValues("output_type", value)
            }
          />
          <BiasAnalysisSelectQuestion
            disabled={!isEditable}
            question="Variable for model output:"
            options={headers.map((header) => [header, header])}
            value={selectedValues.prediction_label}
            onChange={(value) =>
              handleUpdateSelectedValues("prediction_label", value)
            }
          />
          <BiasAnalysisSelectQuestion
            disabled={!isEditable}
            question="Variable for truth:"
            options={headers.map((header) => [header, header])}
            value={selectedValues.true_label}
            onChange={(value) =>
              handleUpdateSelectedValues("true_label", value)
            }
          />
          <BiasAnalysisDemographicTable
            disabled={!isEditable}
            headers={headers}
            demographicValues={selectedValues.demographic_dict}
            handleDemographicChange={handleDemographicChange}
          />
          <BiasAnalysisControlGroupSelect
            disabled={!isEditable}
            demographicValues={selectedValues.demographic_dict}
            handleControlGroupChange={handleControlGroupUpdate}
          />
        </VStack>
      )}
      {(fileURL || headers.length > 0) && (
        <Button
          isLoading={isLoading}
          colorScheme="blue"
          onClick={handleRunAnalysis}
          disabled={isLoading || !isEditable}
          isDisabled={isLoading || !isEditable}
        >
          Run Bias Analysis
        </Button>
      )}
      {progress !== 0 && progress < 100 && (
        <Center>
          <progress value={progress} max="100"></progress>
        </Center>
      )}
      {testResults && !isLoading && (
        <Flex
          direction="column"
          style={{ justifyContent: "flex-start" }}
          overflow={"visible"}
          width={"800px"}
        >
          <div
            style={{
              padding: 15,
              marginLeft: marginLeft,

              width: "100%",
              borderLeft: `1px solid ${Colors.gray[600]}`,
              borderRight: `1px solid ${Colors.gray[600]}`,
              fontSize: "14px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
            }}
            dangerouslySetInnerHTML={{ __html: testResults }}
          />
        </Flex>
      )}
    </div>
  );
};

const styles = {
  input: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    zIndex: -1,
  },
  label: {
    width: "150px",
    padding: 5,
    borderRadius: "2px",
    fontSize: "12px",
    boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",
    color: "white",
    cursor: "pointer",
    justifyContent: "center",
    display: "flex",
  },
};
