import { nanoid } from "nanoid";

import { ROLE_1 } from "../roles";
import {
  ApprovedElement,
  AttestationElement,
  BiasAnalysisElement,
  DeniedElement,
  FileUploadElement,
  MultiSelectElement,
  SectionElement,
  SingleSelectElement,
  TextBlockElement,
  TextResponseElement,
} from "src/types/form.types";
import { AttestationInstance } from "src/types";
import { Delta } from "./quill.utils";

export const getElementId = () => {
  return nanoid(16);
};

export const getEmptyApproved = (parentElementId: string): ApprovedElement => {
  return {
    id: getElementId(),
    parentElementId,
    type: "approved",
    rules: [],
    titleNumber: "",
    updatedAt: null,
    children: [],
    content: {
      title: "Approved",
      description: new Delta(),
      audience: "all",
      surveyTakerNotes: new Delta(),
      adjudicatorNotes: new Delta(),
    },
    pathIndex: [],
    titleNumberPathIndex: [],
  };
};

export const getDefaultAttestationInstance = (): AttestationInstance => {
  return {
    person: null,
    title: "",
    organization: "",
    signed: false,
    signedTimestamp: "",
    signature: "",
    date: "",
  };
};

export const getEmptyAttestation = (
  parentElementId: string
): AttestationElement => {
  return {
    id: getElementId(),
    parentElementId,
    type: "attestation",
    rules: [],
    titleNumber: "",
    updatedAt: null,
    content: {
      audience: ROLE_1,
      title: "",
      question: new Delta(),
      attestationType: "eSignature",
      collectTitle: false,
      collectOrganization: false,
      requiredNumber: 1,
      attestations: [getDefaultAttestationInstance()],
      surveyTakerNotes: new Delta(),
      adjudicatorNotes: new Delta(),
    },
    pathIndex: [],
    titleNumberPathIndex: [],
    children: [],
  };
};

export const getEmptyTextResponse = (
  parentElementId: string
): TextResponseElement => {
  return {
    id: getElementId(),
    parentElementId,
    type: "textResponse",
    rules: [],
    titleNumber: "",
    updatedAt: null,
    content: {
      audience: ROLE_1,
      title: "",
      textType: "rich",
      surveyTakerNotes: new Delta(),
      adjudicatorNotes: new Delta(),
      question: new Delta(),
      lengthLimit: 512,
      response: new Delta(),
    },
    pathIndex: [],
    titleNumberPathIndex: [],
    children: [],
  };
};

export const getEmptyBiasElement = (
  parentElementId: string
): BiasAnalysisElement => {
  return {
    id: getElementId(),
    parentElementId,
    type: "biasAnalysis",
    titleNumber: "",
    rules: [],
    updatedAt: null,
    content: {
      title: "",
      question: new Delta(),
      allowMultiple: false,
      adjudicatorNotes: new Delta(),
      surveyTakerNotes: new Delta(),
      uploadedFiles: [],
      audience: ROLE_1,
      restrictDeletion: true,
      items: [
        {
          label: "pass",
          selected: true,
        },
        {
          label: "fail",
          selected: false,
        },
      ],
    },
    pathIndex: [],
    titleNumberPathIndex: [],
    children: [],
  };
};

export const getEmptyDenied = (parentElementId: string): DeniedElement => {
  return {
    id: getElementId(),
    parentElementId,
    type: "denied",
    rules: [],
    titleNumber: "",
    updatedAt: null,
    content: {
      title: "Denied",
      surveyTakerNotes: new Delta(),
      adjudicatorNotes: new Delta(),
      description: new Delta(),
      audience: "all",
    },
    pathIndex: [],
    titleNumberPathIndex: [],
    children: [],
  };
};

export const getEmptyFileUpload = (
  parentElementId: string
): FileUploadElement => {
  return {
    id: getElementId(),
    parentElementId,
    type: "fileUpload",
    rules: [],
    titleNumber: "",
    updatedAt: null,
    content: {
      title: "",
      question: new Delta(),
      allowMultiple: false,
      adjudicatorNotes: new Delta(),
      surveyTakerNotes: new Delta(),
      uploadedFiles: [],
      audience: ROLE_1,
      restrictDeletion: true,
    },
    pathIndex: [],
    titleNumberPathIndex: [],
    children: [],
  };
};

export const getEmptyMultiSelect = (
  parentElementId: string
): MultiSelectElement => {
  return {
    parentElementId,
    id: getElementId(),
    type: "multiSelect",
    rules: [],
    titleNumber: "",
    updatedAt: null,
    content: {
      audience: ROLE_1,
      title: "",
      surveyTakerNotes: new Delta(),
      adjudicatorNotes: new Delta(),
      question: new Delta(),
      items: [{ label: "Option 1", selected: false }],
      other: {
        allowed: false,
        label: "Other",
        selected: false,
      },
      none: {
        allowed: false,
        label: "None",
        selected: false,
      },
      all: {
        allowed: false,
        label: "All",
        selected: false,
      },
    },
    pathIndex: [],
    titleNumberPathIndex: [],
    children: [],
  };
};

export const getEmptySection = (
  parentElementId: string | null
): SectionElement => {
  return {
    id: getElementId(),
    parentElementId,
    type: "section",
    titleNumber: "",
    updatedAt: null,
    children: [],
    rules: [],
    content: { title: "", audience: ROLE_1 },
    pathIndex: [],
    titleNumberPathIndex: [],
  };
};

export const getEmptySingleSelect = (
  parentElementId: string
): SingleSelectElement => {
  return {
    id: getElementId(),
    parentElementId,
    type: "singleSelect",
    rules: [],
    titleNumber: "",
    updatedAt: null,
    content: {
      audience: ROLE_1,
      title: "",
      surveyTakerNotes: new Delta(),
      adjudicatorNotes: new Delta(),
      question: new Delta(),
      other: {
        allowed: false,
        label: "Other",
        selected: false,
      },
      none: {
        allowed: false,
        label: "None",
        selected: false,
      },
      items: [{ label: "Option 1", selected: false, allowed: true }],
    },
    pathIndex: [],
    titleNumberPathIndex: [],
    children: [],
  };
};

export const getEmptyTextBlock = (
  parentElementId: string
): TextBlockElement => {
  return {
    id: getElementId(),
    parentElementId,
    type: "textBlock",
    titleNumber: "",
    rules: [],
    updatedAt: null,
    content: {
      value: new Delta(),
      audience: ROLE_1,
    },
    pathIndex: [],
    titleNumberPathIndex: [],
    children: [],
  };
};
