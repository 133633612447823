import { createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import {
  newConversation,
  resolveConversation,
  updateConversation,
} from "../slices/comments.slice";
import { formsApi } from "src/services/forms.service";
import { submissionsApi } from "src/services/submission.service";

export const commentsMiddleware = createListenerMiddleware();

let lastNewConversationRequestAbort: () => void;

commentsMiddleware.startListening({
  actionCreator: newConversation,
  effect: async (action, listener) => {
    if (lastNewConversationRequestAbort) {
      lastNewConversationRequestAbort();
    }

    const state = listener.getState() as RootState;
    const formState = state.form;
    const submissionState = state.submission;
    const commentsState = state.comments;

    if (formState.status === "success") {
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElements.initiate({
          formId: formState.form._id,
          formElements: formState.elements.map(
            (element) => formState.elementsById[element.id]
          ),
          commentThreadsDictionary: commentsState.commentThreadsDictionary,
        })
      );

      lastNewConversationRequestAbort = abort;
    } else if (submissionState.status === "success") {
      const { abort } = listener.dispatch(
        submissionsApi.endpoints.updateSubmissionComments.initiate({
          submissionId: submissionState.submission._id,
          commentThreadsDictionary: commentsState.commentThreadsDictionary,
        })
      );

      lastNewConversationRequestAbort = abort;
    } else {
      throw new Error(
        "Form or submission not loaded trying to add conversation"
      );
    }
  },
});

let lastConversationUpdateRequestAbort: () => void;

commentsMiddleware.startListening({
  actionCreator: updateConversation,
  effect: async (action, listener) => {
    if (lastConversationUpdateRequestAbort) {
      lastConversationUpdateRequestAbort();
    }

    const state = listener.getState() as RootState;
    const formState = state.form;
    const submissionState = state.submission;
    const commentsState = state.comments;

    if (formState.status === "success") {
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElements.initiate({
          formId: formState.form._id,
          formElements: formState.elements.map(
            (element) => formState.elementsById[element.id]
          ),
          commentThreadsDictionary: commentsState.commentThreadsDictionary,
        })
      );

      lastConversationUpdateRequestAbort = abort;
    } else if (submissionState.status === "success") {
      const { abort } = listener.dispatch(
        submissionsApi.endpoints.updateSubmissionComments.initiate({
          submissionId: submissionState.submission._id,
          commentThreadsDictionary: commentsState.commentThreadsDictionary,
        })
      );

      lastConversationUpdateRequestAbort = abort;
    } else {
      throw new Error(
        "Form or submission not loaded trying to update conversation"
      );
    }
  },
});

commentsMiddleware.startListening({
  actionCreator: resolveConversation,
  effect: async (action, listener) => {
    const state = listener.getState() as RootState;
    const formState = state.form;
    const submissionState = state.submission;
    const commentsState = state.comments;

    if (formState.status === "success") {
      listener.dispatch(
        formsApi.endpoints.updateFormElements.initiate({
          formId: formState.form._id,
          formElements: formState.elements.map(
            (element) => formState.elementsById[element.id]
          ),
          commentThreadsDictionary: {
            ...commentsState.commentThreadsDictionary,
          },
        })
      );
    } else if (submissionState.status === "success") {
      listener.dispatch(
        submissionsApi.endpoints.updateSubmissionComments.initiate({
          submissionId: submissionState.submission._id,
          commentThreadsDictionary: {
            ...commentsState.commentThreadsDictionary,
          },
        })
      );
    } else {
      throw new Error(
        "Form or submission not loaded trying to resolve conversation"
      );
    }
  },
});
