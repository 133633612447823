import React from "react";
import { AudienceDisplay } from "./AudienceSelection";
import { useAppSelector } from "src/store";
import { lookupSubmissionElementById } from "src/store/selectors/submission.selectors";
import { SubmissionElementEditViewProps } from "src/types/submission.types";
import { RichTextReadOnly } from "./RichTextReadOnly";

export const TextBlockSubmissionView = ({
  id,
}: SubmissionElementEditViewProps) => {
  const element = useAppSelector(lookupSubmissionElementById(id));

  if (element && element.type !== "textBlock") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const displayParagraph = React.useCallback(() => {
    if (element?.content.value) {
      return true;
    }
    return false;
  }, [element]);

  if (!element) {
    return null;
  }

  return (
    <div>
      <div
        className="edit-text-block"
        style={{
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <AudienceDisplay
          audience={element.content.audience}
          buildView={false}
          staticContent={true}
        />
      </div>
      {displayParagraph() ? (
        <RichTextReadOnly value={element.content.value} />
      ) : null}
    </div>
  );
};
