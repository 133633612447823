import { RequireAuth } from "./components/RequireAuth";
import { ActiveOrganization } from "./components/ActiveOrganization";
import { DashboardPage } from "./pages/DashboardPage";
import { FormBuilderRoutePage } from "./pages/FormBuilderRoutePage";
import { FormsRoutePage } from "./pages/FormsRoutePage";
import { LoginPage } from "./pages/LoginPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { OrganizationRoutePage } from "./pages/OrganizationRoutePage";
import { PostLoginPage } from "./pages/PostLoginPage";
import { SubmissionEditorRoutePage } from "./pages/SubmissionEditorRoutePage";
import { SubmissionsPage } from "./pages/SubmissionsPage";
import { UserProfilePage } from "./pages/UserProfilePage";
import { CreateSubmissionRoutePage } from "./pages/CreateSubmissionRoutePage";
import { QuillTranslator } from "./components/QuillTranslator";

export const routes = {
  Home: {
    path: "/",
    Page: (
      <RequireAuth>
        <ActiveOrganization>
          <DashboardPage />
        </ActiveOrganization>
      </RequireAuth>
    ),
  },
  Forms: {
    path: "/forms",
    Page: (
      <RequireAuth>
        <ActiveOrganization>
          <FormsRoutePage />
        </ActiveOrganization>
      </RequireAuth>
    ),
  },
  FormBuilder: {
    path: "/form-builder/:id",
    Page: (
      <RequireAuth>
        <QuillTranslator>
          <ActiveOrganization>
            <FormBuilderRoutePage />
          </ActiveOrganization>
        </QuillTranslator>
      </RequireAuth>
    ),
  },
  Submissions: {
    path: "/submissions",
    Page: (
      <RequireAuth>
        <QuillTranslator>
          <ActiveOrganization>
            <SubmissionsPage />
          </ActiveOrganization>
        </QuillTranslator>
      </RequireAuth>
    ),
  },
  SubmissionEditor: {
    path: "/submission-editor/:id",
    Page: <SubmissionEditorRoutePage />,
  },
  CreateSubmission: {
    path: "/create-submission/:formId",
    page: <CreateSubmissionRoutePage />,
  },
  Login: {
    path: "/login",
    Page: <LoginPage />,
  },
  PostLogin: {
    path: "/post-login",
    Page: <PostLoginPage />,
  },
  UserProfile: {
    path: "/user-profile",
    Page: (
      <RequireAuth>
        <ActiveOrganization>
          <UserProfilePage />
        </ActiveOrganization>
      </RequireAuth>
    ),
  },
  Organization: {
    path: "/organization",
    Page: (
      <RequireAuth>
        <ActiveOrganization>
          <OrganizationRoutePage />
        </ActiveOrganization>
      </RequireAuth>
    ),
  },
  NotFound: {
    path: "/*",
    Page: <NotFoundPage />,
  },
};
