import { PathIndex } from "../../types";
import {
  EnrichedSubmission,
  SubmissionElement,
  SubmissionUser,
} from "src/types/submission.types";
import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { submissionsApi } from "src/services/submission.service";
import {
  getSubmissionElementsById,
  transformSubmissionElementsToElementState,
} from "src/util/submission.utils";
import { elementsByType } from "src/util/elements-by-type.utils";

export interface InitSubmissionState {
  status: "init";
}

export interface ErrorSubmissionState {
  status: "error";
  message: string;
}

export interface SubmissionElementState {
  id: string;
  indentationLevel: number;
  pathIndex: PathIndex;
  titleNumberPathIndex: PathIndex;
  type: keyof typeof elementsByType;
  children: SubmissionElementState[];
  parentElementId: string | null;
}

export interface LoadedSubmissionState {
  status: "success";
  submission: EnrichedSubmission;
  submissionId: string;
  elements: SubmissionElementState[];
  elementsById: { [elementId: string]: SubmissionElement };
  meta: EnrichedSubmission["meta"];
  selectedElementId: string | null;

  editingElementId: string | null;

  isPrivilegeConfigModalOpen: boolean;
  displayPrivilegeBanner: boolean;
  displayPrivilegeConfirmation: boolean;
}

export interface SavingSubmissionState
  extends Omit<LoadedSubmissionState, "status"> {
  status: "saving";
}

export type SubmissionState =
  | InitSubmissionState
  | ErrorSubmissionState
  | LoadedSubmissionState
  | SavingSubmissionState;

const initialState: SubmissionState = {
  status: "init",
};

export const submissionSlice = createSlice<
  SubmissionState,
  SliceCaseReducers<SubmissionState>,
  string,
  SliceSelectors<SubmissionState>,
  string
>({
  name: "submission",
  initialState,
  reducers: {
    setDisplayPrivilegeBanner: (state, action: PayloadAction<boolean>) => {
      if (state.status === "success" || state.status === "saving") {
        state.displayPrivilegeBanner = action.payload;
      }
    },

    setDisplayPrivilegeConfirmation: (
      state,
      action: PayloadAction<boolean>
    ) => {
      if (state.status === "success" || state.status === "saving") {
        state.displayPrivilegeConfirmation = action.payload;
      }
    },

    updateSubmissionTitle: (state, action: PayloadAction<string>) => {
      if (state.status === "success" || state.status === "saving") {
        const title = action.payload;
        state.meta.title = title;
      }
    },

    setSubmissionEditingElementId: (state, action: PayloadAction<string>) => {
      if (state.status === "success" || state.status === "saving") {
        state.editingElementId = action.payload;
      }
    },

    updateSubmissionElement: (
      state,
      action: PayloadAction<{ elementValue: any; elementId: string }>
    ) => {
      if (state.status === "success" || state.status === "saving") {
        const { elementValue, elementId } = action.payload;
        const element = state.elementsById[elementId];

        // type guard since elementValue is not on all element types
        const updatedElementValue =
          element.type === "textResponse" ||
          element.type === "singleSelect" ||
          element.type === "multiSelect" ||
          element.type === "attestation" ||
          element.type === "biasAnalysis" ||
          element.type === "fileUpload"
            ? { elementValue }
            : {};

        state.elementsById[elementId] = {
          ...state.elementsById[elementId],
          ...updatedElementValue,
        };
      }
    },

    updateSubmissionUsers: (
      state,
      action: PayloadAction<{ documentUsers: SubmissionUser[] }>
    ) => {
      if (state.status === "success" || state.status === "saving") {
        const { documentUsers } = action.payload;
        state.submission.users = documentUsers;
      }
    },

    deleteSubmissionUser: (state, action: PayloadAction<string>) => {
      if (state.status === "success" || state.status === "saving") {
        state.submission.users = state.submission.users.filter(
          (user) => user.userId !== action.payload
        );
      }
    },
  },

  extraReducers(builder) {
    builder.addMatcher(
      submissionsApi.endpoints.getSubmissionById.matchFulfilled,
      (state, action) => {
        const submission = action.payload;
        return {
          status: "success",
          submission,
          submissionId: submission._id,
          elements: transformSubmissionElementsToElementState(
            submission.elements
          ),
          elementsById: getSubmissionElementsById(submission.elements),
          meta: submission.meta,
          selectedElementId: null,

          editingElementId: null,

          isPrivilegeConfigModalOpen: false,
          displayPrivilegeConfig: false,
          displayPrivilegeBanner: submission.meta.privileged,
          displayPrivilegeConfirmation: submission.meta.privileged,
        };
      }
    );
    builder.addMatcher(
      submissionsApi.endpoints.updateSubmissionElements.matchFulfilled,
      (state, action) => {
        const submission = action.payload;

        if (state.status !== "success" && state.status !== "saving") {
          throw new Error("Invalid state");
        }

        return {
          ...state,
          editingElementId: null,
          elements: transformSubmissionElementsToElementState(
            submission.elements
          ),
          elementsById: getSubmissionElementsById(submission.elements),
        };
      }
    );
  },
});

export const {
  toggleIsRulesDrawerOpen,
  setDisplayPrivilegeBanner,
  setDisplayPrivilegeConfirmation,
  setSubmissionEditingElementId,
  updateSubmissionElement,
  updateSubmissionTitle,
  updateSubmissionUsers,
  deleteSubmissionUser,
} = submissionSlice.actions;
