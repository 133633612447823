import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export const selectOrganizationMembershipState = (state: RootState) => {
  return state.organizationMembership;
};

export const selectOrganizationMemberInfo = createSelector(
  selectOrganizationMembershipState,
  (organizationState) => {
    return organizationState.userInfo;
  }
);

export const selectOrganizationMembershipEdited = createSelector(
  selectOrganizationMembershipState,
  (organizationState) => {
    return organizationState.edited;
  }
);

export const selectOrganizationMembershipExpiration = createSelector(
  selectOrganizationMembershipState,
  (organizationState) => {
    return organizationState.expiration;
  }
);

export const selectOrganizationMembershipEmailValid = createSelector(
  selectOrganizationMembershipState,
  (organizationState) => {
    return organizationState.emailValid;
  }
);

export const selectOrganizationMembershipShowCustom = createSelector(
  selectOrganizationMembershipState,
  (organizationState) => {
    return organizationState.showCustom;
  }
);

export const selectCurrentOrgMembership = createSelector(
  selectOrganizationMembershipState,
  (organizationState) => {
    return organizationState.userInfo.currentOrgMembership;
  }
);

export const selectOrgRoleName = createSelector(
  selectOrganizationMembershipState,
  (organizationState) => {
    return organizationState.roleName;
  }
);
