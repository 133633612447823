import {
  Alert,
  Button,
  HStack,
  ListItem,
  ModalBody,
  ModalFooter,
  Switch,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useUpdateFormPrivilegeWarningMutation } from "src/services/forms.service";

interface FormPrivilegeDisabledBodyProps {
  formId: string;
  currentDocumentPrivilegeWarningsIgnored: boolean;
  onClose: () => void;
  setCurrentDocumentIgnorePrivilegeWarnings: (x: boolean) => void;
  savePrivilegeState: (x: boolean) => Promise<boolean>;
}

export const FormPrivilegeDisabledBody = ({
  formId,
  currentDocumentPrivilegeWarningsIgnored,
  onClose,
  setCurrentDocumentIgnorePrivilegeWarnings,
  savePrivilegeState,
}: FormPrivilegeDisabledBodyProps) => {
  const [updateFormPrivilegeWarning] = useUpdateFormPrivilegeWarningMutation();

  const [enablingPrivilege, setEnablingPrivilege] = React.useState(false);

  const enablePrivilege = React.useCallback(() => {
    setEnablingPrivilege(!enablingPrivilege);
  }, [enablingPrivilege]);

  const dismissPrivilegeWarning = React.useCallback(async () => {
    try {
      const response = await updateFormPrivilegeWarning({
        formId,
        privilegeWarningDismissed: true,
      }).unwrap();
      if (response.message) {
        setCurrentDocumentIgnorePrivilegeWarnings(true);
      } else {
        // TODO error modal
      }
      onClose();
    } catch (e) {
      // TODO error modal
      onClose();
    }
  }, [
    formId,
    onClose,
    setCurrentDocumentIgnorePrivilegeWarnings,
    updateFormPrivilegeWarning,
  ]);

  const setAndSavePrivilegeassertion = React.useCallback(async () => {
    await savePrivilegeState(true);
    onClose();
  }, [onClose, savePrivilegeState]);

  return (
    <>
      <ModalBody bg="white">
        <VStack spacing="12px" mt="8px">
          <Alert
            status={
              currentDocumentPrivilegeWarningsIgnored ? "warning" : "error"
            }
            variant="left-accent"
          >
            Legal Privilege Not Asserted.
          </Alert>
          <HStack w="full">
            <Switch
              data-test-id="assert-legal-privilege-switch-button"
              size="sm"
              isChecked={enablingPrivilege}
              onChange={enablePrivilege}
              colorScheme={"brightblue"}
            />
            <Text mt="20px" mb="20px">
              Assert Legal Privilege
            </Text>
          </HStack>
          {enablingPrivilege ? (
            <>
              <Text w="full">When asserting privilege...</Text>
              <UnorderedList w="full" ml="160px" mr="100px">
                <ListItem>
                  Form and submission collaborators must be approved by a person
                  with the Manage Legal Privilege permission.
                </ListItem>
                <ListItem>
                  All collaborators must agree to your communications and
                  confidentiality statement.
                </ListItem>
                <ListItem>
                  A privilege assertion banner will be displayed.
                </ListItem>
              </UnorderedList>
            </>
          ) : (
            <></>
          )}
        </VStack>
      </ModalBody>

      {enablingPrivilege ? (
        <ModalFooter bg="white">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            data-test-id="save-assert-legal-privilege-button"
            onClick={setAndSavePrivilegeassertion}
          >
            Save Assertion
          </Button>
        </ModalFooter>
      ) : (
        <ModalFooter bg="white">
          {!currentDocumentPrivilegeWarningsIgnored ? (
            <Button variant="tertiary" onClick={dismissPrivilegeWarning}>
              Dismiss Warning
            </Button>
          ) : (
            <></>
          )}
          <Button variant="secondary" onClick={onClose}>
            Acknowledge
          </Button>
        </ModalFooter>
      )}
    </>
  );
};
