import { RootState } from "src/store";
import { submissionsApi } from "src/services/submission.service";
import {
  ListenerEffect,
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
} from "@reduxjs/toolkit";

let lastDeleteSubmissionUserRequestAbort: () => void;

export const handleDeleteSubmissionUser: ListenerEffect<
  PayloadAction<string>,
  unknown,
  ThunkDispatch<unknown, unknown, UnknownAction>,
  unknown
> = async (action, listener) => {
  if (lastDeleteSubmissionUserRequestAbort) {
    lastDeleteSubmissionUserRequestAbort();
  }

  const state = listener.getState() as RootState;
  const submissionState = state.submission;

  if (submissionState.status !== "success") {
    throw new Error(
      "Attempting to update a submission when submission is not set"
    );
  }

  const { abort } = listener.dispatch(
    submissionsApi.endpoints.deleteSubmissionUser.initiate({
      submissionId: submissionState.submissionId,
      userId: action.payload,
    })
  );

  lastDeleteSubmissionUserRequestAbort = abort;
};
