import { Spacer, SpacerSizes } from "../components/Spacer";
import { Flex, Text, Icon, HStack } from "@chakra-ui/react";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useAppSelector } from "src/store";
import { lookupSubmissionElementById } from "src/store/selectors/submission.selectors";
import { SubmissionElementEditViewProps } from "src/types/submission.types";
import { RichTextReadOnly } from "./RichTextReadOnly";

export const DeniedSubmissionView = ({
  id,
}: SubmissionElementEditViewProps) => {
  const element = useAppSelector(lookupSubmissionElementById(id));

  if (element && element.type !== "denied") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  if (!element) {
    return null;
  }
  return (
    <Flex
      alignContent="flex-start"
      w={"100%"}
      borderLeftColor={"red.500"}
      borderLeftWidth={7}
      borderRadius={2}
      flexDirection="column"
      paddingLeft={5}
    >
      <Flex flexDirection="row" alignItems={"flex-end"}>
        <HStack w={"96%"} justifyContent={"space-between"}>
          <HStack gap={0}>
            <Icon as={RemoveCircleOutlineOutlinedIcon} />
            <Spacer x={SpacerSizes.sm} />

            <Text variant="bold">{element.content.title}</Text>
          </HStack>
        </HStack>
      </Flex>
      <RichTextReadOnly value={element.content.description} />
    </Flex>
  );
};
