import {
  HStack,
  Flex,
  Icon,
  Table,
  Text,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Menu,
  MenuDivider,
  MenuItem,
  MenuButton,
  IconButton,
  MenuList,
  Spacer,
  TableContainer,
  Tooltip,
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";

interface SubmissionTableProps {
  dataTestId?: string;
  headers: string[];
  rowsData: (string | ReactNode)[][];
  rowCallbacks: {
    finalize: any;
    unfinalize: any;
    sharing: any;
    sharingApproval: any;
    delete: any;
  };
  rowsAllowedActions: any[];
  rowsAttentionItems: any[];
  rowClickHandlers: (() => void)[];
  finalizePanel: boolean;
}

export const SubmissionsTable = ({
  dataTestId,
  headers,
  rowsData,
  rowCallbacks,
  rowsAllowedActions,
  rowsAttentionItems,
  rowClickHandlers,
  finalizePanel,
}: SubmissionTableProps) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [hoverRowIndex, setHoverRowIndex] = useState<number | null>(null);
  const tooltipOpenDelay = 1000;

  const handleRowClick = (index: number) => {
    if (selectedRowIndex !== index) {
      setSelectedRowIndex(index);
    }
  };

  const handleRowMouseEnter = (index: number) => {
    if (hoverRowIndex !== index) {
      setHoverRowIndex(index);
    }
  };

  const handleRowMouseLeave = (index: number) => {
    if (hoverRowIndex === index) {
      setHoverRowIndex(null);
    }
  };

  const SubmissionsMenuList = ({ index }: { index: number }) => {
    const rowAllowedActions = rowsAllowedActions[index];
    const rowAttentionItems = rowsAttentionItems[index];

    const displayPrivilegeApproval =
      rowAllowedActions.privilegeApprovals &&
      rowAttentionItems.privilegeApprovalsNeeded;
    const hIconSize = 5;
    const hIconColor = "gray.700";

    return (
      <Flex width="100%">
        <Spacer />
        {hoverRowIndex === index ? (
          <HStack spacing="12px">
            {displayPrivilegeApproval ? (
              <Tooltip
                label="Approve Access Requests"
                aria-label="Approve access requests."
                openDelay={tooltipOpenDelay}
              >
                <Icon
                  data-test-id="approve-access-request-submission-icon-btn"
                  as={AdminPanelSettingsOutlinedIcon}
                  boxSize={hIconSize}
                  color={hIconColor}
                  onClick={(event) => {
                    rowCallbacks.sharingApproval(index);
                    event.stopPropagation();
                  }}
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {rowAllowedActions.open ? (
              <Tooltip
                label="Open in Submisson Editor"
                aria-label="Open the submission in the submission editor."
                openDelay={tooltipOpenDelay}
              >
                <Icon
                  data-test-id="open-submission-icon-btn"
                  as={AssignmentOutlinedIcon}
                  boxSize={hIconSize}
                  color={hIconColor}
                  onClick={(event) => {
                    rowClickHandlers[index]();
                    event.stopPropagation();
                  }}
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {rowAllowedActions.assignSubmitters ||
            rowAllowedActions.assignAdjudicators ? (
              <Tooltip
                label="Sharing Settings"
                aria-label="Open sharing settings."
                openDelay={tooltipOpenDelay}
              >
                <Icon
                  data-test-id="share-settings-submission-icon-btn"
                  as={PersonAddAlt1OutlinedIcon}
                  boxSize={hIconSize}
                  color={hIconColor}
                  onClick={(event) => {
                    rowCallbacks.sharing(index);
                    event.stopPropagation();
                  }}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </HStack>
        ) : (
          <></>
        )}

        <Menu>
          <MenuButton
            data-test-id="submission-menu-btn"
            as={IconButton}
            aria-label="Actions"
            variant="unstyled"
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "auto",
            }}
          >
            <Icon as={MoreVertOutlinedIcon} boxSize={4} />
          </MenuButton>
          <MenuList mt="-10px">
            {displayPrivilegeApproval ? (
              <MenuItem
                key={`menu-priv-${index}`}
                data-test-id="approve-access-request-submission-menu-item"
                onClick={(event) => {
                  rowCallbacks.sharingApproval(index);
                  event.stopPropagation();
                }}
              >
                <HStack>
                  <Icon as={AdminPanelSettingsOutlinedIcon} boxSize={4} />
                  <Text>Approve Access Requests</Text>
                </HStack>
              </MenuItem>
            ) : (
              <></>
            )}
            {rowAllowedActions.open ? (
              <MenuItem
                key={`menu-open-${index}`}
                data-test-id="open-submission-menu-item"
                onClick={(event) => {
                  rowClickHandlers[index]();
                  event.stopPropagation();
                }}
              >
                <HStack>
                  <Icon as={AssignmentOutlinedIcon} boxSize={4} />
                  <Text>Open in Submission Editor</Text>
                </HStack>
              </MenuItem>
            ) : (
              <></>
            )}
            {rowAllowedActions.assignSubmitters ||
            rowAllowedActions.assignAdjudicators ? (
              <MenuItem
                key={`menu-sharing-${index}`}
                data-test-id="share-settings-submission-menu-item"
                onClick={(event) => {
                  rowCallbacks.sharing(index);
                  event.stopPropagation();
                }}
              >
                <HStack>
                  <Icon as={PersonAddAlt1OutlinedIcon} boxSize={4} />
                  <Text>Sharing Settings</Text>
                </HStack>
              </MenuItem>
            ) : (
              <></>
            )}
            {rowAllowedActions.finalize && !finalizePanel ? (
              <>
                <MenuDivider />
                <MenuItem
                  key={`menu-finalize-${index}`}
                  data-test-id="finalize-submission-menu-item"
                  onClick={(event) => {
                    rowCallbacks.finalize(index);
                    event.stopPropagation();
                  }}
                >
                  <HStack>
                    <Icon as={ArchiveOutlinedIcon} boxSize={4} />
                    <Text>Finalize Submission</Text>
                  </HStack>
                </MenuItem>
              </>
            ) : (
              <></>
            )}

            {rowAllowedActions.finalize && finalizePanel ? (
              <>
                <MenuDivider />
                <MenuItem
                  key={`menu-unfinalize-${index}`}
                  data-test-id="unfinalize-submission-menu-item"
                  onClick={(event) => {
                    rowCallbacks.unfinalize(index);
                    event.stopPropagation();
                  }}
                >
                  <HStack>
                    <Icon as={UnarchiveOutlinedIcon} boxSize={4} />
                    <Text>Unfinalize Submission</Text>
                  </HStack>
                </MenuItem>
              </>
            ) : (
              <></>
            )}
            {rowAllowedActions.delete ? (
              <>
                <MenuDivider />
                <MenuItem
                  key={`menu-delete-${index}`}
                  data-test-id="delete-submission-menu-item"
                  onClick={(event) => {
                    rowCallbacks.delete(index);
                    event.stopPropagation();
                  }}
                >
                  <HStack>
                    <Icon as={DeleteOutlineOutlinedIcon} boxSize={4} />
                    <Text>Delete Submission</Text>
                  </HStack>
                </MenuItem>
              </>
            ) : (
              <></>
            )}
          </MenuList>
        </Menu>
      </Flex>
    );
  };

  return (
    <TableContainer>
      <Table data-test-id={dataTestId} colorScheme="gray">
        <colgroup>
          <col span={1} style={{ width: "48px" }} />
          <col span={1} style={{ width: "48px" }} />
          <col span={1} style={{ minWidth: "150px" }} />
          <col span={1} style={{ minWidth: "150px" }} />
          <col span={1} style={{ width: "200px" }} />
          <col span={1} style={{ width: "210px" }} />
        </colgroup>

        <Thead>
          <Tr>
            {headers.map((header, idx) => (
              <Th key={idx}>{header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rowsData.map((rowData, idx) => (
            <Tr
              key={idx}
              onClick={() => handleRowClick(idx)}
              onDoubleClick={rowClickHandlers[idx]}
              onMouseEnter={() => handleRowMouseEnter(idx)}
              onMouseLeave={() => handleRowMouseLeave(idx)}
              style={{}}
            >
              <Td
                key={`${idx}-action`}
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  cursor: "default",
                  padding: "5px 25px",
                }}
              >
                {rowsAllowedActions[idx].privilegeApprovals &&
                rowsAttentionItems[idx].privilegeApprovalsNeeded ? (
                  <Tooltip
                    label="Sharing approvals are awaiting your action."
                    aria-label="Sharing approvals are awaiting your action."
                    openDelay={tooltipOpenDelay}
                  >
                    <Icon as={ErrorOutlineOutlinedIcon} boxSize={5} />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Td>
              {rowData.map((cellData, index) => (
                <Td
                  key={`${idx}-${index}`}
                  style={{
                    backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                    cursor: "default",
                    padding: "5px 25px",
                  }}
                >
                  {cellData}
                </Td>
              ))}
              <Td
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  padding: "5px 25px",
                }}
              >
                <SubmissionsMenuList index={idx} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
