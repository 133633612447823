import { Text, HStack } from "@chakra-ui/react";
import { SubmissionAdmonition } from "./SubmissionAdmonition";
import { Spacer, SpacerSizes } from "./Spacer";
import { AudienceDisplay } from "./AudienceSelection";
import { ServerRule } from "../types/rule.types";
import { RichTextReadOnly } from "./RichTextReadOnly";
import { RichTextDelta } from "src/types/form.types";

interface SubmissionViewHeaderProps {
  titleDataTestId?: string;
  titleNumber: string;
  title: string;
  role: string;
  adjudicatorNotes: RichTextDelta;
  surveyTakerNotes: RichTextDelta;
  audience: string;
  question: RichTextDelta;
  questionPlaceholder: string;
  rules: ServerRule[];
}

export const SubmissionViewHeader = ({
  titleDataTestId,
  titleNumber,
  title,
  adjudicatorNotes,
  surveyTakerNotes,
  role,
  audience,
  question,
  questionPlaceholder,
}: SubmissionViewHeaderProps) => {
  return (
    <>
      <HStack justifyContent={"space-between"} gap={1}>
        <HStack>
          <Text>{titleNumber}</Text>
          <Text
            data-test-id={titleDataTestId}
            id={"title-input"}
            variant="bold"
          >
            {title}
          </Text>
        </HStack>
        <HStack>
          <AudienceDisplay audience={audience} buildView={false} />
        </HStack>
      </HStack>
      <Spacer y={SpacerSizes.sm} />
      <SubmissionAdmonition
        buildView={false}
        adjudicatorNotes={adjudicatorNotes}
        submitterNotes={surveyTakerNotes}
        role={role}
      />

      {question.ops && question.ops.length > 0 ? (
        <>
          <Spacer y={SpacerSizes.sm} />
          {/* <RichText
            className={nanoid()}
            placeholder={questionPlaceholder}
            size="fit"
            value={internalQuestion}
            readOnly={true}
            showToolbar={false}
            borderColor="border-none"
          /> */}
          <RichTextReadOnly
            placeholder={questionPlaceholder}
            value={question}
          />
        </>
      ) : null}
    </>
  );
};
