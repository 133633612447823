import { SubmissionMeta } from "src/types/submission.types";

export const isSubmissionElementEditable = (
  element: any,
  role: string,
  meta?: SubmissionMeta
) => {
  const roleMatchesAudience =
    role === element?.audience || element.audience === "all";
  const unfinalized = meta ? !meta.finalized : true;

  return roleMatchesAudience && unfinalized;
};
