import { useState } from "react";
import {
  Heading,
  HStack,
  Text,
  Card,
  CardBody,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Link,
} from "@chakra-ui/react";
import { createIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { AuthenticatedPage } from "../components/AuthenticatedPage";
import { routes } from "src/routes";

const Circle = createIcon({
  displayName: "Circle",
  viewBox: "0 0 200 200",
  path: (
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  ),
});

export const ToDoSection = ({ data }: { data: any[] }) => {
  const navigate = useNavigate();

  return (
    <Card variant="outline">
      <CardBody>
        <TableContainer>
          <Table variant="simple">
            <Tbody>
              {data.map((item, index: number) => (
                <Tr key={`${item.title}-${index}`}>
                  <Td width={"10%"}>
                    <Circle boxSize={4} color="brand.500" />
                  </Td>
                  <Td>
                    <Link
                      onClick={() => navigate(`/document-view?id=${item.id}`)}
                    >
                      {item.title}
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

const EmptyToDoSection = () => {
  return (
    <Card variant="outline">
      <CardBody>
        <HStack>
          <CheckCircleIcon w="24px" h="24px" color="brand.500" />
          <Text>{`You're all caught up.`}</Text>
        </HStack>
      </CardBody>
    </Card>
  );
};

export const DashboardPage = () => {
  const [mockToDo, setMockToDo] = useState([]);
  const navigate = useNavigate();

  navigate(routes.Forms.path, { replace: true });

  return (
    <AuthenticatedPage title="Inbox">
      <div style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "5%" }}>
        <Heading variant="h2">Todo</Heading>
        <Spacer y={SpacerSizes.sm} />
        {mockToDo.length ? (
          <ToDoSection data={mockToDo} />
        ) : (
          <EmptyToDoSection />
        )}
      </div>
    </AuthenticatedPage>
  );
};
