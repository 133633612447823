import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import {
  findElementById,
  recursiveUpdateTitleNumbers,
  recursiveUpdateStateTitleNumbers,
} from "src/util/form.utils";
import { FormElementState, FormState } from "../slices/form.slice";

export const dedentElementReducer: CaseReducer<
  FormState,
  PayloadAction<{
    elementState: FormElementState;
    parentElementId: string;
  }>
> = (state, action) => {
  if (state.status === "success") {
    let { elementState } = action.payload;
    const { parentElementId } = action.payload;
    const parentElement = state.elementsById[parentElementId];
    const parentElementState = findElementById(
      parentElementId,
      state.elements
    )!;

    // calculate new element path index
    const targetIndex = parentElement.children.findIndex(
      (child) => child.id === elementState.id
    );
    const lastNumberOfNewPath: number =
      parentElement.pathIndex[parentElement.pathIndex.length - 1] + 1;
    const newPathIndex = [
      ...parentElement.pathIndex.slice(0, -1),
      lastNumberOfNewPath,
    ];

    // remove element from parent element children
    state.elementsById[parentElement.id] = {
      ...parentElement,
      children: parentElement.children.filter((e) => e.id !== elementState.id),
    };
    parentElementState.children.splice(targetIndex, 1);

    // insert element into grandparent element children
    const grandParentElement = parentElementState.parentElementId
      ? findElementById(parentElementState.parentElementId, state.elements)
      : null;
    const grandParentElementState = grandParentElement
      ? findElementById(grandParentElement.id, state.elements)
      : null;

    elementState = {
      ...elementState,
      pathIndex: newPathIndex,
      parentElementId: grandParentElement ? grandParentElement.id : null,
      indentationLevel: elementState.indentationLevel - 1,
    };
    grandParentElementState
      ? grandParentElementState.children.splice(
          lastNumberOfNewPath,
          0,
          elementState
        )
      : state.elements.splice(lastNumberOfNewPath, 0, elementState);

    // update titleNumbers and path Indexes
    recursiveUpdateStateTitleNumbers(state.elements, null, state);
    recursiveUpdateTitleNumbers(state.elements, state);
  }
};
