import {
  CommentThread,
  Comment,
  FormUser,
  MentionableUser,
  CommentThreadDictionary,
  UserQuery,
} from "src/types";
import { ROLE_1, ROLE_2 } from "src/roles";
import _ from "lodash";

export const findUserIdsToAdd = (
  mentionedOrAssigned: string[],
  currentMembers: FormUser[],
  mentionableMembers: MentionableUser[]
) => {
  const formMembersIds = currentMembers.map((member) => member.userId);

  const userIdsToAddToForm = [];

  for (const user of mentionedOrAssigned) {
    // find entry with matching email or text in mentionableMembers
    const userInfo = mentionableMembers.find(
      (member: MentionableUser) =>
        member.text === user || member.data.email === user
    );

    // if user is not in formMembers, add them
    if (userInfo && !formMembersIds.includes(userInfo.key)) {
      userIdsToAddToForm.push(userInfo.key);
    }
  }

  return userIdsToAddToForm;
};

export const filterCommentThreadsDictionary = (
  commentThreadsDictionary: CommentThreadDictionary,
  secondaryCommentFilter: string,
  primaryCommentFilter: string,
  userRole: string,
  userInfo: UserQuery
): CommentThreadDictionary => {
  let filteredDictionary: CommentThreadDictionary = {};
  if (commentThreadsDictionary) {
    for (const [key, value] of Object.entries(commentThreadsDictionary)) {
      if (secondaryCommentFilter === "open") {
        const filteredThread = value?.filter(
          (commentThread) => !commentThread.resolved
        );
        filteredDictionary[key] = filteredThread;
      } else if (secondaryCommentFilter === "resolved") {
        const filteredThread = value?.filter(
          (commentThread) => commentThread.resolved
        );
        filteredDictionary[key] = filteredThread;
      } else {
        filteredDictionary[key] = value;
      }
    }

    // Filter by primary filter
    if (primaryCommentFilter === "conversations assigned to you") {
      const filteredEntries = Object.keys(filteredDictionary).reduce(
        (acc: CommentThreadDictionary, key: string) => {
          const threads = filteredDictionary[key];
          const assignedThreads = threads.filter((thread: CommentThread) => {
            return thread.comments.some(
              (comment: Comment) =>
                comment.assignedTo?.includes(userInfo.user.email) ||
                comment.assignedTo?.includes(
                  userInfo.user.firstName
                    ? userInfo.user.firstName?.concat(
                        " ",
                        userInfo.user.lastName ?? ""
                      )
                    : ""
                )
            );
          });

          if (assignedThreads.length > 0) {
            acc[key] = assignedThreads;
          }

          return acc;
        },
        {} as CommentThreadDictionary
      );

      filteredDictionary = filteredEntries;
    } else if (primaryCommentFilter === "your conversations") {
      const filteredEntries = Object.keys(filteredDictionary).reduce(
        (acc: CommentThreadDictionary, key: string) => {
          const threads = filteredDictionary[key];
          const userThreads = threads.filter((thread: CommentThread) => {
            return thread.comments.some(
              (comment: Comment) =>
                comment.user === userInfo.user._id ||
                comment.assignedTo?.includes(userInfo.user.email) ||
                comment.mentioned?.includes(userInfo.user.email)
            );
          });

          if (userThreads.length > 0) {
            acc[key] = userThreads;
          }

          return acc;
        },
        {} as CommentThreadDictionary
      );

      filteredDictionary = filteredEntries;
    }
    // filter out adjudicator threads if role is submitter
    if (userRole === ROLE_1) {
      filteredDictionary = _.mapValues(
        filteredDictionary,
        (threads: CommentThread[]) => {
          return threads.filter((thread) => thread.type !== ROLE_2);
        }
      );
    }
  }

  return filteredDictionary;
};
