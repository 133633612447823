const INIT = "init";
const SUCCESS = "success";
const ERROR = "error";

export type STATUS = typeof INIT | typeof SUCCESS | typeof ERROR;

export enum SaveStatus {
  unsavedChanges,
  inProgress,
  saved,
}
