import React from "react";
import {
  Button,
  Text,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { LuminosModalHeader } from "./LuminosModalHeader";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import RichText from ".././RichText";
import { useAppSelector } from "../../store";
import { Spacer, SpacerSizes } from "../Spacer";
import { useOrganizationAPI } from "../../endpoints/_index";
import { useCreateSubmissionMutation } from "src/services/submission.service";

export const CreateSubmissionModal = ({
  isOpen,
  onClose,
  formName,
  formId,
  becomeAdjudicator,
}: {
  isOpen: boolean;
  onClose: () => void;
  formName: string;
  formId: string;
  becomeAdjudicator: boolean;
}) => {
  const globalUserInfo = useAppSelector((state) => state.user.userContext);
  const organizationId = useAppSelector(
    (state) => state.user.currentOrganizationId
  );
  const userId = globalUserInfo?.user?._id;
  const userEmail = globalUserInfo?.user?.email;
  const mentionableMembers = useAppSelector(
    (state) => state.organization.mentionableMembers
  );
  const [validInput, setValidInput] = React.useState(false);
  const [emailAddresses, setEmailAddresses] = React.useState(
    becomeAdjudicator ? null : [userEmail]
  );
  const navigate = useNavigate();
  const { inviteNewUser, refreshOrganizationMembers } = useOrganizationAPI();
  const [createSubmission] = useCreateSubmissionMutation();

  function wrappedAssignIcon(): React.ReactElement {
    return <Icon as={AssignmentIndOutlinedIcon} ml="4px"></Icon>;
  }

  React.useEffect(() => {
    if (emailAddresses !== null && emailAddresses.length > 0) {
      setValidInput(true);
    } else {
      setValidInput(false);
    }
  }, [emailAddresses]);

  React.useEffect(() => {
    setEmailAddresses(becomeAdjudicator ? null : [userEmail]);
  }, [becomeAdjudicator, userId, userEmail, organizationId]);

  const handleOnChange = (event: any) => {
    const mentionedUsers = event[0].children?.filter(
      (textNode: any) => textNode.type == "@"
    );
    if (mentionedUsers) {
      setEmailAddresses(mentionedUsers.map((textNode: any) => textNode.value));
    }
  };

  async function handleCreateSubmission() {
    const knownEmails = mentionableMembers.map(
      (member: any) => member.data.email
    );
    const knownNames = mentionableMembers.map((member: any) => member.text);
    const knownUsers = knownEmails.concat(knownNames);
    const knownUserIds = mentionableMembers
      .filter(
        (member: any) =>
          emailAddresses?.includes(member.data.email) ||
          emailAddresses?.includes(member.text)
      )
      .map((member: any) => member.key);
    const newEmails = emailAddresses?.filter((email: string | undefined) => {
      const isInKnownUsers = knownUsers.includes(email?.trim());
      const isUserEmail = email === userEmail;
      return !isUserEmail && !isInKnownUsers;
    });

    let userIds = [...knownUserIds];
    if (becomeAdjudicator) {
      userIds = userIds.filter((id: string) => id !== userId);
    }

    if (newEmails) {
      const newUserIds = await Promise.all(
        newEmails.map(async (address: string | undefined) => {
          const userInfo = {
            email: address?.trim(),
            orgMembership: {
              membershipType: "guest",
              permissions: {
                inviteUser: true,
              },
            },
          };
          const res: any = await inviteNewUser(userInfo);
          if (res.userId) {
            return res.userId;
          }
          return null;
        })
      );

      userIds.push(...newUserIds);
      refreshOrganizationMembers();
    }

    const newSubmission = await createSubmission({
      formId: formId,
      submitters: userIds,
    }).unwrap();
    navigate(`/submission-editor/${newSubmission.id}`);
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth="500px">
          <LuminosModalHeader
            title={"Create Submission from Form"}
            closeButton={false}
            iconWrapper={wrappedAssignIcon()}
          />
          <ModalBody bg="white">
            <Wrap>
              <WrapItem>
                <Text>{`To create a submission of "${formName}", type "@" to select who will be assigned as submitter(s).`}</Text>
              </WrapItem>
            </Wrap>
            <Spacer y={SpacerSizes.md} />
            <RichText
              showToolbar={false}
              placeholder="type @ to mention users"
              size="sm"
              onChange={(e) => handleOnChange(e)}
              includeMentions={true}
            />
            {becomeAdjudicator ? (
              <Wrap>
                <WrapItem>
                  <Text fontSize="12px">{`As an owner of this form, you'll automatically be added to the submission as an reviewer. You'll be able to modify who has reviewer access once the submission is created.`}</Text>{" "}
                </WrapItem>
              </Wrap>
            ) : (
              <></>
            )}
          </ModalBody>

          <ModalFooter bg="white">
            <Button variant="secondary" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button isDisabled={!validInput} onClick={handleCreateSubmission}>
              Create Submission
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
