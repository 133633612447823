import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";

export interface SelectedElementState {
  selectedElementId: string | null;
}

const initialState: SelectedElementState = {
  selectedElementId: null,
};

export const selectedElementSlice = createSlice<
  SelectedElementState,
  SliceCaseReducers<SelectedElementState>,
  string,
  SliceSelectors<SelectedElementState>,
  string
>({
  name: "selected-element",
  initialState,
  reducers: {
    setSelectedElementId: (state, action: PayloadAction<string | null>) => {
      state.selectedElementId = action.payload;
    },
  },
});

export const { setSelectedElementId } = selectedElementSlice.actions;
