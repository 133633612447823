import { ROLE_2, ROLE_1 } from "../roles";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { SurveyTakerNotes } from "./SurveyTakerNotes";
import React from "react";
import { RichTextDelta } from "src/types/form.types";

interface SubmissionAdmonitionProps {
  submitterNotes: RichTextDelta;
  adjudicatorNotes: RichTextDelta;
  role?: string;
  buildView: boolean;
}

export const SubmissionAdmonition = ({
  submitterNotes,
  adjudicatorNotes,
  role,
  buildView,
}: SubmissionAdmonitionProps) => {
  const adjudicatorNotesHasContent = React.useMemo(() => {
    return adjudicatorNotes.ops && adjudicatorNotes.ops.length > 0;
  }, [adjudicatorNotes]);
  const submitterNotesHasContent = React.useMemo(() => {
    return submitterNotes.ops && submitterNotes.ops.length > 0;
  }, [submitterNotes]);

  return (
    <div>
      {adjudicatorNotesHasContent && (role === ROLE_2 || buildView) ? (
        <div>
          <SurveyTakerNotes text={adjudicatorNotes} />
          <Spacer y={SpacerSizes.xs} />
        </div>
      ) : null}

      {submitterNotesHasContent && (role === ROLE_1 || buildView) ? (
        <div>
          <SurveyTakerNotes text={submitterNotes} />
          <Spacer y={SpacerSizes.xs} />
        </div>
      ) : null}
    </div>
  );
};
