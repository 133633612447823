import React from "react";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

import { useAppDispatch, useAppSelector } from "../store";
import { selectSubmissionMeta } from "../store/selectors/submission.selectors";
import { updateSubmissionTitle } from "../store/slices/submission.slice";

import { PageInfo } from "./TopNavBar/PageInfo";
import { DocumentTitleInput } from "./DocumentTitleInput";

export const SubmissionEditorTitle = () => {
  const dispatch = useAppDispatch();
  const submissionMeta = useAppSelector(selectSubmissionMeta);

  const handleSubmissionTitleChange = React.useCallback(
    (value: string) => {
      dispatch(updateSubmissionTitle(value));
    },
    [dispatch]
  );
  return (
    <PageInfo>
      <AssignmentOutlinedIcon sx={{ color: "white" }} />
      {submissionMeta?.title !== undefined ? (
        <DocumentTitleInput
          dataTestId="submission-title-input"
          value={submissionMeta.title}
          onChange={handleSubmissionTitleChange}
        />
      ) : null}
    </PageInfo>
  );
};
