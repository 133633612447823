import { RootState } from "src/store";
import { submissionsApi } from "src/services/submission.service";
import {
  ListenerEffect,
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
} from "@reduxjs/toolkit";
let lastUpdateSubmissionPrivilegeBannerRequestAbort: () => void;

export const handleDisplayPrivilegeBanner: ListenerEffect<
  PayloadAction<boolean>,
  unknown,
  ThunkDispatch<unknown, unknown, UnknownAction>,
  unknown
> = async (action, listener) => {
  if (lastUpdateSubmissionPrivilegeBannerRequestAbort) {
    lastUpdateSubmissionPrivilegeBannerRequestAbort();
  }
  const state = listener.getState() as RootState;
  const submissionState = state.submission;

  if (
    submissionState.status !== "success" &&
    submissionState.status !== "saving"
  ) {
    throw new Error(
      "Attempting to update a submission when submission is not set"
    );
  }

  const { submissionId } = submissionState;
  const { abort } = listener.dispatch(
    submissionsApi.endpoints.updateSubmissionPrivilege.initiate({
      submissionId,
      privileged: action.payload,
    })
  );
  lastUpdateSubmissionPrivilegeBannerRequestAbort = abort;
};
