import {
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Wrap,
  WrapItem,
  Text,
} from "@chakra-ui/react";
import { useAppSelector } from "src/store";
import { UserQuery, MembershipDetail } from "src/types";
import { selectOrgRoleName } from "src/store/selectors/organization-membership.selectors";

export const OrganizationModalNewUserRole = ({
  userInfo,
  membershipToEdit,
  handleRelationshipChangetoMember,
  handleRelationshipChangetoGuest,
  changeRoleTemplateWrapper,
  organizationName,
}: {
  userInfo: UserQuery;
  membershipToEdit: MembershipDetail | null;
  handleRelationshipChangetoMember: () => void;
  handleRelationshipChangetoGuest: () => void;
  changeRoleTemplateWrapper: (newRole: string) => void;
  organizationName: string;
}) => {
  const orgRoleTemplateName = useAppSelector(selectOrgRoleName);
  return (
    <>
      <Text>Relationship & Role</Text>
      <HStack ml="20px" mt="6px" spacing="0px">
        <Wrap spacing="2px">
          <WrapItem>
            {membershipToEdit ? (
              <Center h="100%" fontSize="sm">
                {`${userInfo.user.email} is`}
              </Center>
            ) : (
              <Center h="100%" fontSize="sm">
                The new user is
              </Center>
            )}
          </WrapItem>
          <WrapItem>
            <Menu>
              <WrapItem></WrapItem>
              <MenuButton
                data-test-id="relationship-dropdown-button"
                border="1px"
                borderRadius="3px"
                pt="2px"
                pb="2px"
                pl="4px"
                pr="4px"
                borderColor="gray.500"
                fontSize="sm"
              >
                {userInfo.currentOrgMembership.membershipType == "guest"
                  ? "external to"
                  : "a member of"}
              </MenuButton>
              <MenuList>
                <MenuItem
                  fontSize="xs"
                  onClick={handleRelationshipChangetoMember}
                >
                  a member of
                </MenuItem>
                <MenuItem
                  fontSize="xs"
                  onClick={handleRelationshipChangetoGuest}
                >
                  external to
                </MenuItem>
              </MenuList>
            </Menu>
          </WrapItem>
          <WrapItem>
            <Center h="100%" fontSize="sm">
              {organizationName}
            </Center>
          </WrapItem>
          <WrapItem>
            <Center h="100%" fontSize="sm" ml="1px">
              and has
            </Center>
          </WrapItem>
          <WrapItem>
            <Menu>
              <WrapItem></WrapItem>
              <MenuButton
                data-test-id="role-dropdown-button"
                border="1px"
                borderRadius="3px"
                pt="2px"
                pb="2px"
                pl="4px"
                pr="4px"
                borderColor="gray.500"
                fontSize="sm"
              >
                {orgRoleTemplateName}
              </MenuButton>
              <MenuList>
                <MenuItem
                  fontSize="xs"
                  onClick={() => {
                    changeRoleTemplateWrapper("Standard Guest");
                  }}
                >
                  Standard Guest
                </MenuItem>
                <MenuItem
                  fontSize="xs"
                  onClick={() => {
                    changeRoleTemplateWrapper("Standard Member");
                  }}
                >
                  Standard Member
                </MenuItem>
                <MenuItem
                  fontSize="xs"
                  onClick={() => {
                    changeRoleTemplateWrapper("Governor");
                  }}
                >
                  Governor
                </MenuItem>
                <MenuItem
                  data-test-id="custom-role"
                  fontSize="xs"
                  onClick={() => {
                    changeRoleTemplateWrapper("Custom");
                  }}
                >
                  Custom
                </MenuItem>
              </MenuList>
            </Menu>
          </WrapItem>
          <WrapItem>permissions.</WrapItem>
        </Wrap>
      </HStack>
    </>
  );
};
