import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateField } from "@mui/x-date-pickers/DateField";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Colors } from "src/theme/colors";
import {
  Text,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Fade,
  VStack,
} from "@chakra-ui/react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { MembershipDetail } from "src/types";
import { useAppDispatch, useAppSelector } from "src/store";
import { selectOrganizationMembershipExpiration } from "src/store/selectors/organization-membership.selectors";
import {
  setExpirationDate,
  setExpirationLocale,
  setExpirationNowVsScheduled,
  setExpirationTime,
} from "src/store/slices/organization-membership.slice";
import { setExpirationEnabled } from "src/store/slices/organization-membership.slice";
import moment from "moment";
import { SpacerSizes, Spacer } from "../Spacer";

export const OrganizationModalUserExpiration = ({
  membershipToEdit,
}: {
  membershipToEdit: MembershipDetail | null;
}) => {
  const mTheme = createTheme({});
  const expiration = useAppSelector(selectOrganizationMembershipExpiration);
  const dispatch = useAppDispatch();

  const setFormatSensical = React.useCallback(() => {
    dispatch(setExpirationLocale("zh-cn"));
  }, [dispatch]);

  const setFormatUS = React.useCallback(() => {
    dispatch(setExpirationLocale("en-us"));
  }, [dispatch]);

  const setExpireNowVsScheduled = React.useCallback(
    (nowVsScheduled: string) => {
      dispatch(setExpirationNowVsScheduled(nowVsScheduled));
    },
    [dispatch]
  );

  const handleExpirationTimeChange = React.useCallback(
    (time: moment.Moment | null) => {
      dispatch(setExpirationTime({ e: time?.toISOString() }));
    },
    [dispatch]
  );

  const handleExpirationDateChange = React.useCallback(
    (date: moment.Moment | null) => {
      dispatch(setExpirationDate({ e: date?.toISOString() }));
    },
    [dispatch]
  );

  const updateEnableExpiration = React.useCallback(
    (enabled: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setExpirationEnabled({ enabled: enabled.target.checked }));
    },
    [dispatch]
  );

  return (
    <>
      <Text>Expiration</Text>
      <FormControlLabel
        label={<Text fontSize={"small"}>Set user expiration</Text>}
        control={
          <Checkbox
            style={{ padding: 3 }}
            size="small"
            sx={{
              "&.Mui-checked": {
                color: Colors.brightblue[600],
              },
            }}
            onChange={updateEnableExpiration}
            checked={expiration.expirationEnabled}
          />
        }
      />
      {membershipToEdit !== null && expiration.expirationEnabled ? (
        <Fade in={membershipToEdit !== null}>
          <HStack ml={42}>
            <Text>Expire</Text>
            <Menu>
              <MenuButton
                border="1px"
                borderRadius="3px"
                pt="2px"
                pb="2px"
                pl="4px"
                pr="4px"
                borderColor="gray.500"
                fontSize="sm"
              >
                {expiration.nowVsScheduled}
              </MenuButton>
              <MenuList zIndex={100}>
                <MenuItem
                  fontSize="xs"
                  onClick={() => {
                    setExpireNowVsScheduled("immediately");
                  }}
                >
                  immediately
                </MenuItem>
                <MenuItem
                  fontSize="xs"
                  onClick={() => {
                    setExpireNowVsScheduled("at a scheduled date and time");
                  }}
                >
                  at a scheduled date and time
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Fade>
      ) : null}
      <Spacer y={SpacerSizes.sm} />
      <Fade
        in={
          (expiration.expirationEnabled &&
            expiration.expirationDateTime !== null &&
            membershipToEdit === null) ||
          (expiration.expirationEnabled &&
            expiration.expirationDateTime !== null &&
            membershipToEdit !== null &&
            expiration.nowVsScheduled === "at a scheduled date and time")
        }
      >
        <HStack ml={42} align="flex-start">
          <VStack>
            <ThemeProvider theme={mTheme}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={expiration.expirationLocale}
              >
                <DateField
                  size="small"
                  label="Date"
                  value={moment(expiration.expirationDateTime)}
                  onChange={handleExpirationDateChange}
                />
                <TimeField
                  size="small"
                  label="Time"
                  value={moment(expiration.expirationDateTime)}
                  onChange={handleExpirationTimeChange}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </VStack>
          <Menu>
            <MenuButton>
              <Icon color="gray.600" as={SettingsOutlinedIcon}></Icon>
            </MenuButton>
            <MenuList>
              <MenuItem fontSize="xs" onClick={setFormatSensical}>
                YYYY-MM-DD 24hr
              </MenuItem>
              <MenuItem fontSize="xs" onClick={setFormatUS}>
                DD/MM/YYYY AM/PM
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Fade>
      <Spacer />
    </>
  );
};
