import { VStack, Checkbox } from "@chakra-ui/react";
import { useAppSelector } from "src/store";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { FormElementBuildViewProps } from "src/types/form.types";
import { PreviewHeader } from "./PreviewHeader";
import { Typography } from "@mui/material";

export const MultiSelectPreview = ({ id }: FormElementBuildViewProps) => {
  const element = useAppSelector(lookupFormElementById(id));

  if (element && element.type !== "multiSelect") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  if (!element) {
    return null;
  }

  return (
    <div style={{ width: "100%" }}>
      <PreviewHeader
        title={element.content.title}
        titleNumber={element.titleNumber ?? ""}
        adjudicatorNotes={element.content.adjudicatorNotes}
        surveyTakerNotes={element.content.surveyTakerNotes}
        audience={element.content.audience}
        questionPlaceholder="Enter the question"
        question={element.content.question}
        rules={element.rules}
      />
      <VStack
        display="flex"
        gap={1}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
      >
        {element.content.items.map((item, i) => (
          <Checkbox
            isFocusable={false}
            colorScheme="brightblue"
            key={i}
            isChecked={false}
            value={item.label}
            alignItems="flex-start"
          >
            <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
              {item.label}
            </Typography>
          </Checkbox>
        ))}
        {element.content.other.allowed && (
          <Checkbox
            isFocusable={false}
            colorScheme="brightblue"
            isChecked={false}
            value={element.content.other.label}
            alignItems="flex-start"
          >
            <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
              {element.content.other.label}
            </Typography>
          </Checkbox>
        )}
        {element.content.none.allowed && (
          <Checkbox
            isFocusable={false}
            colorScheme="brightblue"
            isChecked={false}
            value={element.content.none.label}
            alignItems="flex-start"
          >
            <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
              {element.content.none.label}
            </Typography>
          </Checkbox>
        )}
        {element.content.all.allowed && (
          <Checkbox
            isFocusable={false}
            colorScheme="brightblue"
            isChecked={false}
            value={element.content.all.label}
            alignItems="flex-start"
          >
            <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
              {element.content.all.label}
            </Typography>
          </Checkbox>
        )}
      </VStack>
    </div>
  );
};
