import { useNavigate, useParams } from "react-router-dom";
import { routes } from "src/routes";
import { CreateSubmissionDataPage } from "./CreateSubmissionDataPage";

export const CreateSubmissionRoutePage = () => {
  const navigate = useNavigate();
  const formId = useParams<{ formId: string }>().formId;

  if (!formId) {
    navigate(routes.Forms.path);
    return null;
  }

  return <CreateSubmissionDataPage id={formId} />;
};
