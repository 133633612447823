import { PageInfo } from "./TopNavBar/PageInfo";
import {
  HStack,
  Icon,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import ConstructionOutlinedIcon from "@mui/icons-material/Construction";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DocumentTitleInput } from "./DocumentTitleInput";
import { FormDescriptionModal } from "./Modal/FormDescriptionModal";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  selectFormId,
  selectFormMeta,
} from "src/store/selectors/form.selectors";
import React from "react";
import { updateFormTitle } from "src/store/slices/form.slice";

export const FormBuilderTitle = () => {
  const formId = useAppSelector(selectFormId);
  const formMeta = useAppSelector(selectFormMeta);
  const dispatch = useAppDispatch();
  const {
    isOpen: descriptionModalIsOpen,
    onOpen: openDescriptionModal,
    onClose: closeDescriptionModal,
  } = useDisclosure();

  const handleFormTitleChange = React.useCallback(
    (value: string) => {
      dispatch(updateFormTitle(value));
    },
    [dispatch]
  );

  return (
    <PageInfo
      menuList={
        <HStack w="full">
          <HStack mr="auto" color="white.500" pl="40px">
            <MenuList color="gray.600">
              <MenuItem onClick={openDescriptionModal}>
                <HStack>
                  <Icon as={InfoOutlinedIcon} boxSize=".75em" mr="4px"></Icon>
                  <Text>Form Description</Text>
                </HStack>
              </MenuItem>
            </MenuList>
          </HStack>
        </HStack>
      }
    >
      <Icon as={ConstructionOutlinedIcon} color="white" />
      {formMeta?.title !== undefined && (
        <DocumentTitleInput
          dataTestId="form-title-input"
          value={formMeta.title}
          // needs to be passed as a prop instead of being directly called in child b/c submission editor also uses AuthWidthInput
          onChange={handleFormTitleChange}
        />
      )}

      {descriptionModalIsOpen ? (
        <FormDescriptionModal
          isOpen={descriptionModalIsOpen}
          onClose={closeDescriptionModal}
          formId={formId || ""}
          initialFormDescription={formMeta?.description}
        />
      ) : null}
    </PageInfo>
  );
};
