import { MentionableUser, PathIndex } from "src/types";
import { FormElement } from "../types/form.types";
import { FormElementState, SavingFormState } from "src/store/slices/form.slice";
import { romanize } from "./romanize.utils";
import { LoadedFormState } from "src/store/slices/form.slice";

export const elementTypesWithoutTitleNumbers = [
  "textBlock",
  "approved",
  "denied",
];

export const staticElementTypes = [
  "section",
  "textBlock",
  "approved",
  "denied",
];

export const transformFormElementsToElementState = (
  elements: FormElement[],
  activeFormElements?: FormElement[],
  parentElementId?: string
): FormElementState[] => {
  const allFormElements = activeFormElements ?? elements;
  return elements.map((e) => {
    const pathIndex = getPathIndexForElementId({
      targetElementId: e.id,
      elements: allFormElements,
    });
    return {
      id: e.id,
      parentElementId: parentElementId || null,
      indentationLevel: e.pathIndex.length,
      pathIndex: e.pathIndex || pathIndex || [],
      titleNumberPathIndex: e.titleNumberPathIndex || [],
      titleNumber: e.titleNumber || "",
      type: e.type,
      children: transformFormElementsToElementState(
        e.children || [],
        allFormElements,
        e.id
      ),
    };
  });
};

interface SetTitleNumberPathIndexParams {
  elements: FormElementState[];
  parentTitleNumberPathIndex: PathIndex;
}

/**
 * Set `titleNumberPathIndex` property on the
 * elements passed in. This function does not update
 * the children of the elements.
 *
 * `textBlock`, `approved`, and `denied` element types do not get
 * the titleNumberPathIndex set because these elements do not display
 * a title number.
 * @param config.elements - The elements to loop through and update
 * @param config.parentTitleNumberPathIndex - the parent's titleNumberPathIndex
 */
export const setTitleNumberPathIndex = ({
  elements,
  parentTitleNumberPathIndex,
}: SetTitleNumberPathIndexParams): FormElementState[] => {
  let titleNumberIndex = 0;
  return elements.map((e) => {
    if (elementTypesWithoutTitleNumbers.includes(e.type)) {
      return { ...e };
    } else {
      const updatedElement = {
        ...e,
        titleNumberPathIndex: [...parentTitleNumberPathIndex, titleNumberIndex],
      };
      ++titleNumberIndex;
      return updatedElement;
    }
  });
};

export const getTitleNumber = (path: PathIndex): string => {
  let titleNumber = "";
  path.forEach((levelIndex, nestingLevel) => {
    const levelIndexNumber = levelIndex + 1;

    titleNumber += [
      levelIndexNumber,
      String.fromCharCode(96 + levelIndexNumber),
      romanize(levelIndexNumber),
      levelIndexNumber,
    ][nestingLevel];

    titleNumber += ".";
  });

  return titleNumber;
};

interface GetPathIndexForElementIdParams {
  targetElementId: string;
  elements: FormElement[];
  currentPathIndex?: PathIndex;
}

export const getPathIndexForElementId = ({
  targetElementId,
  elements,
  currentPathIndex = [],
}: GetPathIndexForElementIdParams): PathIndex | null => {
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const pathIndex = [...currentPathIndex, i];
    if (element.id === targetElementId) {
      return pathIndex;
    }

    const childPathIndex = getPathIndexForElementId({
      targetElementId,
      elements: element.children || [],
      currentPathIndex: pathIndex,
    });

    if (childPathIndex) {
      return [...childPathIndex];
    }
  }
  return null;
};

export const assignPathIdsAndSectionNumbers = (
  elements: FormElement[],
  currentPath: PathIndex = []
): FormElement[] => {
  return elements.map((element, index) => {
    const pathIndex = [...currentPath, index];
    const titleNumber = ["textBlock", "approved", "denied"].includes(
      element.type
    )
      ? undefined
      : getTitleNumber(pathIndex);

    return {
      ...element,
      pathIndex,
      titleNumber,
      children: assignPathIdsAndSectionNumbers(element.children, pathIndex),
    };
  });
};

export const getFormElementsById = (
  elements: FormElement[],
  parentElementId?: string
) => {
  let elementsById: { [key: string]: FormElement } = {};

  elements.forEach((element) => {
    if (!element.id) {
      throw new Error("Form Element does not have ID set");
    }

    if (element.id in elementsById) {
      throw new Error("Form Element is already in elementsById");
    }

    elementsById[element.id] = {
      ...element,
      parentElementId: parentElementId || null,
    };

    const childrenElementsById = element?.children
      ? getFormElementsById(element.children, element.id)
      : null;

    elementsById = {
      ...elementsById,
      ...childrenElementsById,
    };
  });

  return elementsById;
};

export const getElementPathIndexes = (
  elementIds: string[],
  formElements: FormElement[] | FormElementState[] | undefined
): PathIndex[] => {
  const findPathIndex = (
    id: string,
    elements: FormElement[] | FormElementState[],
    currentPath: PathIndex = []
  ): PathIndex | null => {
    for (let i = 0; i < elements?.length; i++) {
      const element = elements[i];

      if (element.id === id) {
        return [...currentPath, i];
      }

      if (element.children) {
        const childPath = findPathIndex(id, element.children, [
          ...currentPath,
          i,
        ]);

        if (childPath) {
          return childPath;
        }
      }
    }

    return null;
  };

  if (!formElements) {
    return [];
  }

  return elementIds.map((id) => findPathIndex(id, formElements) || []);
};

export const getIndentationLevelBak = (
  elementId: string,
  formElements: FormElement[]
): number => {
  return getElementPathIndexes([elementId], formElements)[0].length;
};

export const getElementAndParent = (
  pathIndex: PathIndex,
  currentElements: FormElementState[]
): [FormElementState | undefined, FormElementState | undefined] => {
  let parentElement: FormElementState | undefined;
  let targetElement: FormElementState | undefined;

  for (let i = 0; i < pathIndex.length; i++) {
    const index = pathIndex[i];

    if (index < 0 || index >= currentElements.length) {
      return [undefined, undefined];
    }

    parentElement = targetElement;
    targetElement = currentElements[index];
    currentElements = targetElement.children || [];
  }

  return [targetElement, parentElement];
};

export const getIndentationLevel = (
  elementId: string,
  elements: FormElement[] | undefined
): number => {
  if (!elements) return 0;
  const element = findElementById(elementId, elements);
  if (!element) return 0;
  return element.pathIndex?.length ?? 0;
};

interface ElementWithId<E extends ElementWithId<E>> {
  id: string;
  children: E[];
}

export const findElementById = <E extends ElementWithId<E>>(
  elementId: string,
  elements: E[]
): E | null => {
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    if (element.id === elementId) {
      return element;
    } else if (element.children) {
      const foundElement = findElementById<E>(elementId, element.children);
      if (foundElement) {
        return foundElement;
      }
    }
  }
  return null;
};

export const getSiblingAbove = (
  pathIndex: PathIndex,
  currentElements: FormElementState[] | undefined
) => {
  if (!currentElements) return null;
  if (pathIndex.length === 0) return null;

  const targetIndex = pathIndex[pathIndex.length - 1];
  const parentPathIndex = pathIndex.slice(0, -1);

  const parentElements =
    parentPathIndex.length > 0
      ? getElementAndParent(parentPathIndex, currentElements)[0]?.children
      : currentElements;

  return parentElements && targetIndex > 0
    ? parentElements[targetIndex - 1]
    : null;
};

export const getSiblingBelow = (
  pathIndex: PathIndex,
  currentElements: FormElementState[] | undefined
) => {
  if (!currentElements) return null;
  if (pathIndex.length === 0) return null;

  const targetIndex = pathIndex[pathIndex.length - 1];
  const parentPathIndex = pathIndex.slice(0, -1);

  const parentElements =
    parentPathIndex.length > 0
      ? getElementAndParent(parentPathIndex, currentElements)[0]?.children
      : currentElements;

  return parentElements && targetIndex < parentElements.length - 1
    ? parentElements[targetIndex + 1]
    : null;
};

export const getEmailsNewToOrganization = (
  mentionableMembers: MentionableUser[],
  emailAddresses?: (string | undefined)[]
) => {
  const knownEmails = mentionableMembers.map(
    (member: MentionableUser) => member.data.email
  );
  const knownNames = mentionableMembers.map(
    (member: MentionableUser) => member.text
  );
  const knownUsers = knownEmails.concat(knownNames);

  const newEmails = emailAddresses?.filter(
    (email: string | undefined) => !knownUsers.includes(email?.trim() ?? "")
  );

  return newEmails;
};

interface GetElementsToBeUpdatedParams {
  localElementsById: { [key: string]: FormElement };
  serverElements: FormElement[];
  editingElementId: string | null;
}

export const getElementsToBeUpdatedById = ({
  localElementsById,
  serverElements,
  editingElementId,
}: GetElementsToBeUpdatedParams): { [key: string]: FormElement } => {
  let elementsToBeUpdatedById: { [key: string]: FormElement } = {};

  serverElements.forEach((e) => {
    if (e.id !== editingElementId) {
      const localElement = localElementsById[e.id];

      if (!localElement) {
        return;
      }

      if (localElement.updatedAt && e.updatedAt) {
        if (Date.parse(e.updatedAt) > Date.parse(localElement.updatedAt)) {
          elementsToBeUpdatedById[e.id] = e;
        }
      }

      const childrenElementsToBeUpdatedById = getElementsToBeUpdatedById({
        localElementsById,
        serverElements: e.children,
        editingElementId,
      });

      elementsToBeUpdatedById = {
        ...childrenElementsToBeUpdatedById,
        ...elementsToBeUpdatedById,
      };
    }
  });

  return elementsToBeUpdatedById;
};

export const getLengthOfTreeFromElement = (
  element: FormElement,
  length: number
) => {
  element.children.forEach((child) => {
    return 1 + getLengthOfTreeFromElement(child, length + 1);
  });
  return length;
};

/**
 * Returns the height of the tree starting from the
 * element passed in.
 *
 * @param config.startingElement - The element to calculate the height from
 */
export function findRelativeTreeHeight(
  startingElement: FormElement | undefined
): number {
  let depth = 0;
  if (startingElement?.children) {
    startingElement.children.forEach((s: FormElement) => {
      const tempDepth = findRelativeTreeHeight(s);
      if (tempDepth > depth) depth = tempDepth;
    });
  }
  return depth + 1;
}

export const recursiveUpdateStateTitleNumbers = (
  elements: FormElementState[],
  parentElementState: FormElementState | null,
  state: LoadedFormState | SavingFormState
): FormElementState[] => {
  let updatedElements = setTitleNumberPathIndex({
    elements,
    parentTitleNumberPathIndex: parentElementState
      ? parentElementState.titleNumberPathIndex
      : [],
  });

  updatedElements = updatedElements.map((element: FormElementState) => {
    const updatedPathIndex = getElementPathIndexes(
      [element.id],
      state.elements
    )[0];
    if (!updatedPathIndex) {
      return element;
    }
    return {
      ...element,
      pathIndex: updatedPathIndex,
      titleNumber: getTitleNumber(element.titleNumberPathIndex),
      indentationLevel: updatedPathIndex.length,
    };
  });

  if (parentElementState) {
    parentElementState.children = updatedElements;
  } else {
    state.elements = updatedElements;
  }

  updatedElements.forEach((element: FormElementState) => {
    if (element.children.length > 0) {
      return recursiveUpdateStateTitleNumbers(element.children, element, state);
    }
  });

  return updatedElements;
};

export const recursiveUpdateTitleNumbers = (
  elements: FormElementState[],
  state: LoadedFormState | SavingFormState
): { [key: string]: FormElement } => {
  elements.forEach((element) => {
    if (element.children.length > 0) {
      return recursiveUpdateTitleNumbers(element.children, state);
    }
  });

  elements.forEach((element) => {
    const updatedElementState = findElementById(element.id, state.elements);
    const updatedPathIndex = getElementPathIndexes(
      [element.id],
      state.elements
    )[0];
    if (!updatedElementState) {
      return;
    }
    state.elementsById[element.id] = {
      ...state.elementsById[element.id],
      titleNumberPathIndex: updatedElementState.titleNumberPathIndex,
      parentElementId: updatedElementState.parentElementId,
      pathIndex: updatedPathIndex,
      titleNumber: getTitleNumber(updatedElementState.titleNumberPathIndex),
      children: element.children.map((child) => state.elementsById[child.id]),
    };
  });

  return state.elementsById;
};
