import React from "react";
import { Input } from "@chakra-ui/react";
import { SaveStatus } from "src/constants/status.constants";

interface AutoWidthInputProps {
  value: string;
  onChange?: (e: string) => void;
  placeholder?: string;
  setSaving?: (x: SaveStatus) => void;
  dataTestId?: string;
}

export const AutoWidthInput = ({
  value,
  onChange,
  placeholder = "Untitled Form",
  setSaving,
  dataTestId,
}: AutoWidthInputProps) => {
  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (setSaving) {
        setSaving(SaveStatus.unsavedChanges);
      }
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange, setSaving]
  );

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Input
        data-test-id={dataTestId}
        value={value}
        onChange={handleInputChange}
        style={{
          fontWeight: "bold",
          width: "100%",
        }}
        variant="unstyled"
        placeholder={placeholder}
      />
    </div>
  );
};

export default AutoWidthInput;
