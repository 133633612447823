import React from "react";
import { Box, Center, HStack, VStack, Text } from "@chakra-ui/react";
import { Typography } from "@mui/material";

export const TOPNAV_Z_INDEX = 100;
export const HEADER_HEIGHT = "43px";

interface LuminosNavBarProps {
  title?: string;
  leftAlignedContent?: React.ReactNode;
  rightAlignedContent?: React.ReactNode;
  menuContent?: React.ReactNode;
}

export const LuminosNavBar = ({
  title,
  leftAlignedContent,
  rightAlignedContent,
  menuContent,
}: LuminosNavBarProps) => {
  return (
    <VStack
      zIndex={TOPNAV_Z_INDEX}
      w="full"
      pos={"fixed"}
      h={HEADER_HEIGHT}
      spacing="0px"
      className="navbar"
    >
      <HStack
        className="full-12"
        w="full"
        h="40px"
        bg="gray.900"
        pl="12px"
        pr="12px"
      >
        <Center>
          <img style={{ width: 100, height: "auto" }} src="/Logo.svg" />
        </Center>

        <HStack w="full">
          <HStack mr="auto" color="white.500" pl="40px">
            {title ? (
              <Typography
                data-test-id="nav-bar-title-text"
                className="l-nav-bar-text"
                color={"white"}
                fontWeight={"bold"}
              >
                {title}
              </Typography>
            ) : null}
            <div style={{ width: "100%" }}>{leftAlignedContent}</div>
          </HStack>
          <HStack>
            {rightAlignedContent}
            {menuContent}
          </HStack>
        </HStack>
      </HStack>
      <Box w="full" h="1px" bg="brand.900" />
      <Box w="full" h="2px" bg="gray.900" />
    </VStack>
  );
};
