import { GridItem } from "@chakra-ui/react";
import { LuminosNavBar } from "./LuminosNavBar";
import React from "react";
import { BasePage } from "./BasePage";

interface UnauthenticatedPageProps {
  title?: string;
}

export const UnauthenticatedPage = ({
  title,
  children,
}: React.PropsWithChildren<UnauthenticatedPageProps>) => {
  return (
    <BasePage>
      <LuminosNavBar title={title} />
      <GridItem as="main">{children}</GridItem>
    </BasePage>
  );
};
