import {
  HStack,
  Flex,
  Icon,
  Table,
  Text,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Menu,
  MenuDivider,
  MenuItem,
  MenuButton,
  IconButton,
  MenuList,
  Spacer,
  TableContainer,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import { now } from "moment";

export interface FormDataRow {
  title: string;
  description: string;
  updatedAt: Date | null;
}

interface FormsTableProps {
  dataTestId?: string;
  headers: string[];
  rowsData: FormDataRow[];
  rowCallbacks: {
    copy: any;
    archive: any;
    unarchive: any;
    sharing: any;
    submission: any;
    sharingApproval: any;
  };
  rowsAllowedActions: any[];
  rowsAttentionItems: any[];
  rowClickHandlers: (() => void)[];
  archivePanel: boolean;
}

export const FormsTable = ({
  dataTestId,
  headers,
  rowsData,
  rowCallbacks,
  rowsAllowedActions,
  rowsAttentionItems,
  rowClickHandlers,
  archivePanel,
}: FormsTableProps) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [hoverRowIndex, setHoverRowIndex] = useState<number | null>(null);
  const tooltipOpenDelay = 1000;

  const handleRowClick = (index: number) => {
    if (selectedRowIndex !== index) {
      setSelectedRowIndex(index);
    }
  };

  const handleRowMouseEnter = (index: number) => {
    if (hoverRowIndex !== index) {
      setHoverRowIndex(index);
    }
  };

  const handleRowMouseLeave = (index: number) => {
    if (hoverRowIndex === index) {
      setHoverRowIndex(null);
    }
  };

  const FormsMenuList = ({ index }: { index: number }) => {
    const rowAllowedActions = rowsAllowedActions[index];
    const rowAttentionItems = rowsAttentionItems[index];
    const openAllowed = rowAllowedActions.edit || rowAllowedActions.comment;
    const displayPrivilegeApproval =
      rowAllowedActions.privilegeApprovals &&
      rowAttentionItems.privilegeApprovalsNeeded;
    const hIconSize = 5;
    const hIconColor = "gray.700";

    return (
      <Flex width="100%">
        <Spacer />
        {hoverRowIndex === index ? (
          <HStack spacing="12px">
            {displayPrivilegeApproval ? (
              <Tooltip
                label="Approve Sharing Requests"
                aria-label="Approve sharing requests."
                openDelay={tooltipOpenDelay}
              >
                <Icon
                  as={AdminPanelSettingsOutlinedIcon}
                  boxSize={hIconSize}
                  color={hIconColor}
                  onClick={(event) => {
                    rowCallbacks.sharingApproval(index);
                    event.stopPropagation();
                  }}
                />
              </Tooltip>
            ) : null}
            {rowAllowedActions.submit ? (
              <Tooltip
                label="Submit Form or Assign Submission"
                aria-label="Submit form or assign submission."
                openDelay={tooltipOpenDelay}
              >
                <div>
                  <Icon
                    data-test-id="create-submission-from-form-icon-btn"
                    as={AssignmentIndOutlinedIcon}
                    boxSize={hIconSize}
                    color={hIconColor}
                    onClick={(event) => {
                      rowCallbacks.submission(index);
                      event.stopPropagation();
                    }}
                  />
                </div>
              </Tooltip>
            ) : null}
            {openAllowed ? (
              <Tooltip
                label="Open in Form Builder"
                aria-label="Open the form in the form builder."
                openDelay={tooltipOpenDelay}
              >
                <div>
                  <Icon
                    data-test-id="open-form-icon-btn"
                    as={ConstructionOutlinedIcon}
                    boxSize={hIconSize}
                    color={hIconColor}
                    onClick={(event) => {
                      rowClickHandlers[index]();
                      event.stopPropagation();
                    }}
                  />
                </div>
              </Tooltip>
            ) : null}
            {rowAllowedActions.share ? (
              <Tooltip
                label="Sharing Settings"
                aria-label="Open sharing settings."
                openDelay={tooltipOpenDelay}
              >
                <Icon
                  as={PersonAddAlt1OutlinedIcon}
                  boxSize={hIconSize}
                  color={hIconColor}
                  onClick={(event) => {
                    rowCallbacks.sharing(index);
                    event.stopPropagation();
                  }}
                />
              </Tooltip>
            ) : null}
          </HStack>
        ) : null}

        <Menu>
          <MenuButton
            data-test-id="form-menu-btn"
            as={IconButton}
            aria-label="Actions"
            variant="unstyled"
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "auto",
            }}
          >
            <Icon as={MoreVertOutlinedIcon} boxSize={4} />
          </MenuButton>
          <MenuList data-test-id="menu-list" mt="-10px">
            {displayPrivilegeApproval ? (
              <MenuItem
                key={`menu-priv-${index}`}
                data-test-id="copy-menu-item"
                onClick={(event) => {
                  rowCallbacks.sharingApproval(index);
                  event.stopPropagation();
                }}
              >
                <HStack>
                  <Icon as={AdminPanelSettingsOutlinedIcon} boxSize={4} />
                  <Text>Approve Sharing Requests</Text>
                </HStack>
              </MenuItem>
            ) : null}

            {rowAllowedActions.submit ? (
              <MenuItem
                key={`menu-submit-${index}`}
                data-test-id="create-submission-from-form-menu-item"
                onClick={(event) => {
                  rowCallbacks.submission(index);
                  event.stopPropagation();
                }}
              >
                <HStack>
                  <Icon as={AssignmentIndOutlinedIcon} boxSize={4} />
                  <Text>Submit Form or Assign Submission</Text>
                </HStack>
              </MenuItem>
            ) : null}
            {openAllowed ? (
              <MenuItem
                key={`menu-open-${index}`}
                data-test-id="open-form-menu-item"
                onClick={(event) => {
                  rowClickHandlers[index]();
                  event.stopPropagation();
                }}
              >
                <HStack>
                  <Icon as={ConstructionOutlinedIcon} boxSize={4} />
                  <Text>Open in Form Builder</Text>
                </HStack>
              </MenuItem>
            ) : null}
            {rowAllowedActions.share ? (
              <MenuItem
                key={`menu-sharing-${index}`}
                data-test-id="share-menu-item"
                onClick={(event) => {
                  rowCallbacks.sharing(index);
                  event.stopPropagation();
                }}
              >
                <HStack>
                  <Icon as={PersonAddAlt1OutlinedIcon} boxSize={4} />
                  <Text>Sharing Settings</Text>
                </HStack>
              </MenuItem>
            ) : null}
            {rowAllowedActions.copy ? (
              <>
                <MenuDivider />
                <MenuItem
                  key={`menu-copy-${index}`}
                  data-test-id="copy-menu-item"
                  onClick={(event) => {
                    rowCallbacks.copy(index);
                    event.stopPropagation();
                  }}
                >
                  <HStack>
                    <Icon as={ContentCopyOutlinedIcon} boxSize={4} />
                    <Text>Copy Form</Text>
                  </HStack>
                </MenuItem>
              </>
            ) : null}

            {rowAllowedActions.archive && !archivePanel ? (
              <>
                <MenuDivider />
                <MenuItem
                  key={`menu-archive-${index}`}
                  data-test-id="archive-menu-item"
                  onClick={(event) => {
                    rowCallbacks.archive(index);
                    event.stopPropagation();
                  }}
                >
                  <HStack>
                    <Icon as={ArchiveOutlinedIcon} boxSize={4} />
                    <Text>Archive Form</Text>
                  </HStack>
                </MenuItem>
              </>
            ) : null}

            {rowAllowedActions.archive && archivePanel ? (
              <>
                <MenuDivider />
                <MenuItem
                  key={`menu-unarchive-${index}`}
                  data-test-id="unarchive-menu-item"
                  onClick={(event) => {
                    rowCallbacks.unarchive(index);
                    event.stopPropagation();
                  }}
                >
                  <HStack>
                    <Icon as={UnarchiveOutlinedIcon} boxSize={4} />
                    <Text>Unarchive Form</Text>
                  </HStack>
                </MenuItem>
              </>
            ) : null}
          </MenuList>
        </Menu>
      </Flex>
    );
  };

  return (
    <TableContainer>
      <Table data-test-id={dataTestId} colorScheme="gray">
        <colgroup>
          <col span={1} style={{ width: "48px" }} />
          <col span={1} style={{ minWidth: "200px" }} />
          <col span={1} style={{ minWidth: "300px" }} />
          <col span={1} style={{ width: "200px" }} />
          <col span={1} style={{ width: "210px" }} />
        </colgroup>

        <Thead>
          <Tr>
            {headers.map((header, idx) => (
              <Th key={idx}>{header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rowsData.map((rowData, idx) => (
            <Tr
              key={idx}
              onClick={() => handleRowClick(idx)}
              onDoubleClick={rowClickHandlers[idx]}
              onMouseEnter={() => handleRowMouseEnter(idx)}
              onMouseLeave={() => handleRowMouseLeave(idx)}
              style={{}}
            >
              <Td
                key={`${idx}-action`}
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  cursor: "default",
                  padding: "5px 25px",
                }}
              >
                {rowsAllowedActions[idx].privilegeApprovals &&
                rowsAttentionItems[idx].privilegeApprovalsNeeded ? (
                  <Tooltip
                    label="Sharing approvals are awaiting your action."
                    aria-label="Sharing approvals are awaiting your action."
                    openDelay={tooltipOpenDelay}
                  >
                    <Icon as={ErrorOutlineOutlinedIcon} boxSize={5} />
                  </Tooltip>
                ) : null}
              </Td>
              <Td
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  cursor: "default",
                  padding: "5px 25px",
                }}
              >
                <Text>{rowData.title}</Text>
              </Td>
              <Td
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  cursor: "default",
                  padding: "5px 25px",
                }}
              >
                <Text>{rowData.description}</Text>
              </Td>
              <Td
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  cursor: "default",
                  padding: "5px 25px",
                }}
              >
                <VStack
                  alignItems={"flex-start"}
                  key={`form-timestamp-${idx}`}
                  spacing="0px"
                >
                  <Text fontSize="12px">
                    {new Date(rowData.updatedAt ?? now()).toDateString()}
                  </Text>
                  <Text fontSize="12px">
                    {new Date(rowData.updatedAt ?? now()).toLocaleTimeString()}
                  </Text>
                </VStack>
              </Td>
              <Td
                style={{
                  backgroundColor: selectedRowIndex === idx ? "#eaeef2" : "",
                  padding: "5px 25px",
                }}
              >
                <FormsMenuList index={idx} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
