import React from "react";
import { Text, HStack, Input } from "@chakra-ui/react";
import { FormElementBuildViewProps } from "src/types/form.types";
import { useAppDispatch, useAppSelector } from "src/store";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { updateFormElement } from "src/store/slices/form.slice";

export const SectionBuildView = ({
  children,
  id,
}: React.PropsWithChildren<FormElementBuildViewProps>) => {
  const element = useAppSelector(lookupFormElementById(id));
  const dispatch = useAppDispatch();

  if (element && element.type !== "section") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const dataTestIdPostfix = React.useMemo(() => {
    return `${element?.pathIndex?.join("-")}`;
  }, [element]);

  const handleTitleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (element) {
        dispatch(
          updateFormElement({
            ...element,
            content: {
              ...element.content,
              title: e.target.value,
            },
          })
        );
      }
    },
    [dispatch, element]
  );

  return (
    <>
      <HStack data-test-id={`section-header-${dataTestIdPostfix}`} gap={1}>
        <Text data-test-id={`section-title-number-${dataTestIdPostfix}`}>
          {element?.titleNumber}
        </Text>
        <Input
          data-test-id={`section-title-input-${dataTestIdPostfix}`}
          variant="unstyled"
          style={{
            fontWeight: "bold",
          }}
          type="text"
          placeholder={"Untitled Section"}
          value={element?.content.title}
          onChange={handleTitleChange}
        />
      </HStack>
      {children}
    </>
  );
};
