import React from "react";
import { useElementContext } from "src/hooks/useElementContext";
import { useAppDispatch, useAppSelector } from "src/store";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { selectSelectedElement } from "src/store/selectors/selected-element.selectors";
import { updateFormElement } from "src/store/slices/form.slice";
import { FormElementBuildViewProps, RichTextDelta } from "src/types/form.types";
import { Spacer, SpacerSizes } from "./Spacer";
import { AdvancedSectionWrapper } from "./AdvancedSectionWrapper";
import { AdvancedNotes } from "./AdvancedNotes";
import { TextResponsePreview } from "./TextResponsePreview";
import { BuildViewHeader } from "./BuildViewHeader";
import { RichTextEditor } from "./RichTextEditor";

export const TextResponseBuildView = ({
  id,
  children,
}: React.PropsWithChildren<FormElementBuildViewProps>) => {
  const dispatch = useAppDispatch();
  const selectedElementId = useAppSelector(selectSelectedElement);
  const { element: elementState } = useElementContext();
  const element = useAppSelector(lookupFormElementById(id));
  if (element && element.type !== "textResponse") {
    throw new Error(
      `Expected element type to be textResponse but got: ${element.type}`
    );
  }

  const isSelectedElement = selectedElementId === id;

  const handleContentFieldChange = React.useCallback(
    (field: "question" | "adjudicatorNotes" | "surveyTakerNotes") => {
      return (value: RichTextDelta) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: { ops: value.ops },
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  const handleHeaderChange = React.useCallback(
    (field: "title" | "audience") => {
      return (value: string) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: value,
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  if (!element) {
    return null;
  }

  return isSelectedElement ? (
    <div
      className="my-response-class"
      style={{ marginLeft: `${elementState?.indentationLevel || 0 - 1 * 20}` }}
    >
      <BuildViewHeader
        titleDataTestId={`text-response-title-input-${element.pathIndex.join("-")}`}
        id={id}
        questionPrompt="Question"
        title={element.content.title}
        titlePlaceholder="Untitled Text Response"
        titleNumber={element.titleNumber ?? ""}
        audience={element.content.audience}
        onTitleChange={handleHeaderChange("title")}
        onAudienceChange={handleHeaderChange("audience")}
        rules={element?.rules || []}
      />
      <RichTextEditor
        placeholder="Enter the question"
        value={element.content.question}
        onChange={handleContentFieldChange("question")}
      />
      <Spacer y={SpacerSizes.sm} />
      <AdvancedSectionWrapper>
        <div style={{ width: "100%" }}>
          <AdvancedNotes
            surveyTakerNotes={element.content.surveyTakerNotes}
            adjudicatorNotes={element.content.adjudicatorNotes}
            updateReviewerNotes={handleContentFieldChange("adjudicatorNotes")}
            updateSubmitterNotes={handleContentFieldChange("surveyTakerNotes")}
          />
        </div>
      </AdvancedSectionWrapper>
      {children}
    </div>
  ) : (
    <>
      <TextResponsePreview id={id} />
      {children}
    </>
  );
};
