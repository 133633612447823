/**
 * Enables support for autocompleting @mentions.
 */
import {
  createPluginFactory,
  removeNodes,
  getPluginType,
  PlateEditor,
  Value,
  TNode,
  HandlerReturnType,
} from "@udecode/plate-common";
import { withMention } from "./with-mention";
import { mentionOnKeyDownHandler } from "./mention-on-keydown-handler";
import { findMentionInput } from "./find-mention-input";

export const ELEMENT_MENTION = "mention";
export const ELEMENT_MENTION_INPUT = "mention_input";

export type KeyboardEventHandler = (
  event: React.KeyboardEvent
) => HandlerReturnType;

export const isNodeMentionInput = <V extends Value>(
  editor: PlateEditor<V>,
  node: TNode
): boolean => {
  return node.type === getPluginType(editor, ELEMENT_MENTION_INPUT);
};

export const isSelectionInMentionInput = <V extends Value>(
  editor: PlateEditor<V>
) => findMentionInput(editor) !== undefined;

export const createMentionPlugin = createPluginFactory({
  key: ELEMENT_MENTION,
  isElement: true,
  isInline: true,
  isVoid: true,
  isMarkableVoid: true,
  handlers: {
    onKeyDown: mentionOnKeyDownHandler({ query: isSelectionInMentionInput }),
    onBlur: (editor) => () => {
      removeNodes(editor, {
        match: (n) => n.type === ELEMENT_MENTION_INPUT,
        at: [],
      });
    },
  },
  withOverrides: withMention,
  options: {
    trigger: "@",
    triggerPreviousCharPattern: /^\s?$/,
    createMentionNode: (item: any) => ({ value: item.text }),
  },
  plugins: [
    {
      key: ELEMENT_MENTION_INPUT,
      isElement: true,
      isInline: true,
    },
  ],
  then: (editor, { key }) => ({
    options: {
      id: key,
    },
  }),
});
