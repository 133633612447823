import { Text, HStack } from "@chakra-ui/react";
import { useAppSelector } from "src/store";
import { SubmissionElementEditViewProps } from "src/types/submission.types";
import { lookupSubmissionElementById } from "src/store/selectors/submission.selectors";
import React from "react";

export const SectionSubmissionView = ({
  id,
  children,
}: SubmissionElementEditViewProps) => {
  const element = useAppSelector(lookupSubmissionElementById(id));
  const dataTestIdPostfix = React.useMemo(() => {
    return `${element?.pathIndex?.join("-")}`;
  }, [element]);

  if (element && element.type !== "section") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  return (
    <>
      <HStack
        data-test-id={`section-header-${dataTestIdPostfix}`}
        justifyContent={"space-between"}
        gap={1}
      >
        <HStack>
          <Text data-test-id={`section-title-number-${dataTestIdPostfix}`}>
            {element?.titleNumber}
          </Text>
          <Text
            data-test-id={`section-title-text-${dataTestIdPostfix}`}
            variant="bold"
          >
            {element?.content.title}
          </Text>
        </HStack>
      </HStack>
      {children}
    </>
  );
};
