import { OrganizationPermissions } from "src/types/organization.types";
import { orgRoleTemplateNames } from "src/components/Modal/OrganizationMembershipModal";
import { UserQuery, MembershipDetail } from "src/types";

export const determinePermissionsFromOrgRole = (
  role: string,
  userInfo: UserQuery,
  isMember: boolean
): OrganizationPermissions => {
  if (role == orgRoleTemplateNames.governor) {
    const orgPermissions: OrganizationPermissions = {
      manageOrgPermissions: true,
      manageOrgSettings: true,
      inviteUser: true,
      manageLegalPrivilege: true,
      createOrCopyForms: true,
      seeAllMembers: true,
      seeAllGuests: true,
    };
    return orgPermissions;
  } else if (role == orgRoleTemplateNames.standardMember) {
    const orgPermissions: OrganizationPermissions = {
      manageOrgPermissions: false,
      manageOrgSettings: false,
      inviteUser: true,
      manageLegalPrivilege: false,
      createOrCopyForms: true,
      seeAllMembers: true,
      seeAllGuests: true,
    };

    return orgPermissions;
  } else if (role == orgRoleTemplateNames.standardGuest) {
    const orgPermissions: OrganizationPermissions = {
      manageOrgPermissions: false,
      manageOrgSettings: false,
      inviteUser: true,
      manageLegalPrivilege: false,
      createOrCopyForms: true,
      seeAllMembers: false || isMember,
      seeAllGuests: false,
    };
    return orgPermissions;
  } else {
    const orgPermissions: OrganizationPermissions = {
      manageOrgPermissions:
        userInfo.currentOrgMembership.permissions.manageOrgPermissions,
      manageOrgSettings:
        userInfo.currentOrgMembership.permissions.manageOrgSettings,
      inviteUser: userInfo.currentOrgMembership.permissions.inviteUser,
      manageLegalPrivilege:
        userInfo.currentOrgMembership.permissions.manageLegalPrivilege,
      createOrCopyForms:
        userInfo.currentOrgMembership.permissions.createOrCopyForms,
      seeAllMembers:
        userInfo.currentOrgMembership.permissions.seeAllMembers || isMember,
      seeAllGuests: userInfo.currentOrgMembership.permissions.seeAllGuests,
    };

    return orgPermissions;
  }
};

export const determineOrgRoleTemplate = (membership: MembershipDetail) => {
  if (
    membership.permissions.manageOrgPermissions === true &&
    membership.permissions.manageOrgSettings === true &&
    membership.permissions.inviteUser === true &&
    membership.permissions.manageLegalPrivilege === true &&
    membership.permissions.createOrCopyForms === true &&
    membership.permissions.seeAllMembers === true &&
    membership.permissions.seeAllGuests === true
  ) {
    return orgRoleTemplateNames.governor;
  } else if (
    membership.permissions.manageOrgPermissions === false &&
    membership.permissions.manageOrgSettings === false &&
    membership.permissions.inviteUser === true &&
    membership.permissions.manageLegalPrivilege === false &&
    membership.permissions.createOrCopyForms === false &&
    membership.permissions.seeAllMembers === false &&
    membership.permissions.seeAllGuests === false
  ) {
    return orgRoleTemplateNames.standardGuest;
  } else if (
    membership.permissions.manageOrgPermissions === false &&
    membership.permissions.manageOrgSettings === false &&
    membership.permissions.inviteUser === true &&
    membership.permissions.manageLegalPrivilege === false &&
    membership.permissions.createOrCopyForms === true &&
    membership.permissions.seeAllMembers === true &&
    membership.permissions.seeAllGuests === false
  ) {
    return orgRoleTemplateNames.standardMember;
  }
  return orgRoleTemplateNames.custom;
};
