import { ComponentStyleConfig } from "@chakra-ui/react";

export const ButtonStyle: ComponentStyleConfig = {
  baseStyle: {
    width: "175px",
    margin: 1,
    borderRadius: "2px",
    boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",
  },
  variants: {
    secondary: {
      boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",
      borderWidth: "2px",
      borderColor: "brightblue.500",
      color: "brightblue.500",
      backgroundColor: "transparent",
      _hover: {
        borderColor: "brightblue.600",
        color: "brightblue.600",
      },
    },

    tertiary: {
      boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",
      borderWidth: "2px",
      borderColor: "gray.500",
      color: "gray.800",
      backgroundColor: "transparent",
      textAlign: "right",
      _hover: {
        borderColor: "brightblue.300",
      },
    },

    disabled: {
      boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",
      borderWidth: "2px",
      borderColor: "gray.500",
      color: "gray.800",
      backgroundColor: "gray.100",
      textAlign: "right",
    },

    gray_dropdown: {
      boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",
      border: "1px",
      borderColor: "gray.600",
      color: "gray.900",
      backgroundColor: "transparent",
      textAlign: "left",
      padding: "16px",
      fontWeight: "normal",
      borderRadius: "6px",
      _hover: {
        borderColor: "brightblue.300",
      },
      margin: 0,
    },

    solid: {
      boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",

      bg: "brightblue.500",
      color: "white",
      _hover: {
        bg: "brightblue.600",
      },
    },

    small_primary: {
      size: "sm",
      width: "90px",
      margin: 1,
      borderRadius: "2px",
      fontSize: "12px",
      boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",
      bg: "brightblue.500",
      color: "white",
      _hover: {
        bg: "brightblue.600",
      },
    },

    topnavIcon: {
      width: "0px",
    },

    circularProgress: {
      boxShadow: "1px 1px 1px rgba(128,128,128,0.69)",
      border: "1px",
      borderColor: "gray.600",
      color: "gray.800",
      backgroundColor: "transparent",
      textAlign: "left",
      w: "24px",
      fontWeight: "normal",
      borderRadius: "6px",
      _hover: {
        borderColor: "brightblue.300",
      },
      _active: {
        bg: "gray.300",
      },
      margin: 0,
    },
  },
  defaultProps: {
    size: "md",
    variant: "solid",
  },
};
