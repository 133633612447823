import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { MembershipDetail } from "../../../types";
import { Center, Checkbox, Icon, Text } from "@chakra-ui/react";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export default function MembersTableBody({
  membershipData,
  setMembershipToEdit,
  openAddUserModal,
}: {
  membershipData: MembershipDetail[];
  setMembershipToEdit: Dispatch<SetStateAction<MembershipDetail | undefined>>;
  openAddUserModal: () => void;
}) {
  const [rowsToRender, setRowsToRender] = useState<ReactNode[]>([]);
  useEffect(() => {
    const memberRows: ReactNode[] = [];
    let i = 0;
    memberRows.length = 0;

    const tdFontSize = "12";
    const cellTextColor = "gray.700";
    const tdStyleEven = {
      border: "1px solid var(--chakra-colors-gray-600)",
      padding: "8px",
      background: "white",
    };

    const tdStyleOdd = {
      border: "1px solid var(--chakra-colors-gray-600)",
      padding: "8px",
      background: "var(--chakra-colors-gray-200)",
    };

    function RenderBoolean({ val }: { val: boolean }) {
      if (val) {
        return <Checkbox isDisabled defaultChecked />;
      }
      return <Checkbox isDisabled />;
    }

    function renderBoolean(val: boolean) {
      if (val) {
        return <Checkbox isDisabled defaultChecked />;
      }
      return <Checkbox isDisabled />;
    }

    for (const membership of membershipData) {
      memberRows.push(
        <tr key={membership.userId}>
          <td>
            <Center color={cellTextColor}>
              <Icon
                as={EditOutlinedIcon}
                w="16px"
                h="16px"
                color="brightblue.500"
                onClick={() => {
                  setMembershipToEdit(membership as MembershipDetail);
                  openAddUserModal();
                }}
              />
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <Text fontSize={tdFontSize}>
                {(membership as any).user!.firstName}{" "}
                {(membership as any).user!.lastName}
              </Text>
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <Text fontSize={tdFontSize}>
                {(membership as any).user!.email}
              </Text>
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <Text fontSize={tdFontSize}>
                {(membership as any).membershipType}
              </Text>
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <Text fontSize={tdFontSize}>
                {(membership as any).user!.accountStatus}
              </Text>
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              {membership.membershipExpiration ? (
                <Text fontSize={tdFontSize}>
                  {moment((membership as any).membershipExpiration)
                    .local()
                    .format("ddd YYYY-MM-DD HH:mm:ss")}
                </Text>
              ) : (
                <></>
              )}
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <RenderBoolean
                val={membership.permissions.manageOrgPermissions}
              ></RenderBoolean>
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <RenderBoolean
                val={membership.permissions.manageOrgSettings}
              ></RenderBoolean>
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <RenderBoolean
                val={membership.permissions.inviteUser}
              ></RenderBoolean>
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <RenderBoolean
                val={membership.permissions.createOrCopyForms}
              ></RenderBoolean>
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <RenderBoolean
                val={membership.permissions.manageLegalPrivilege}
              ></RenderBoolean>
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <RenderBoolean
                val={membership.permissions.seeAllMembers}
              ></RenderBoolean>
            </Center>
          </td>
          <td style={i % 2 ? tdStyleOdd : tdStyleEven}>
            <Center color={cellTextColor}>
              <RenderBoolean
                val={membership.permissions.seeAllGuests}
              ></RenderBoolean>
            </Center>
          </td>
        </tr>
      );
      i++;
    }
    setRowsToRender(memberRows);
  }, [membershipData]);

  return <tbody>{rowsToRender}</tbody>;
}
