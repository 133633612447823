import React from "react";
import {
  Checkbox,
  Heading,
  VStack,
  Input,
  Text,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { UnauthenticatedPage } from "../components/UnauthenticatedPage";
import { useAuthenticateMutation } from "../services/authenticate.service";
import { useAppSelector } from "../store";
import { routes } from "src/routes";

interface LoginState {
  emailAddress: string;
  emailIsValid: boolean;
  saveUsername: boolean;
}

export const LoginPage = () => {
  const navigate = useNavigate();
  const [authenticate, { isLoading }] = useAuthenticateMutation();
  const session = useAppSelector((state) => state.auth.session);
  const [loginState, setLoginState] = React.useState<LoginState>({
    emailAddress: "",
    emailIsValid: false,
    saveUsername: false,
  });

  React.useEffect(() => {
    const emailAddress = localStorage.getItem("loginEmail");
    if (emailAddress) {
      setLoginState((prevState) => ({
        ...prevState,
        emailAddress,
        emailIsValid: true,
        saveUsername: true,
      }));
    }
  }, []);

  const handleSubmit = React.useCallback(
    async (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (loginState.saveUsername) {
        localStorage.setItem("loginEmail", loginState.emailAddress);
      }

      try {
        const resp = await authenticate({
          email: loginState.emailAddress,
        }).unwrap();
        navigate("/post-login", { state: resp });
      } catch (e) {
        navigate("/post-login", { state: (e as any).data });
      }
    },
    [authenticate, loginState, navigate]
  );

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let emailIsValid = false;
      const emailAddress = event.target.value;
      const emailParts = emailAddress.split("@");

      if (
        emailParts.length == 2 &&
        emailParts[0].length > 0 &&
        emailParts[1].length > 0
      ) {
        emailIsValid = true;
      }
      setLoginState((prevState) => ({
        ...prevState,
        emailAddress,
        emailIsValid,
      }));
    },
    []
  );

  const handleSaveUsernameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoginState((prevState) => ({
        ...prevState,
        saveUsername: event.target.checked,
      }));
    },
    []
  );

  if (session) {
    return <Navigate to={routes.Forms.path} replace />;
  }

  return (
    <UnauthenticatedPage>
      <div
        style={{
          marginTop: "10%",
          paddingLeft: "25%",
          paddingRight: "25%",
        }}
      >
        <Heading>Welcome</Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing="0px" mt="16px">
            <Flex w="100%">
              <Spacer mr="auto" />
              <Text fontSize="12px" textAlign="right" ml="auto">
                You&apos;ll be added to our waiting list if you don&apos;t have
                an account.
              </Text>
            </Flex>
            <Input
              data-test-id="email-input"
              placeholder="Enter your email address to login."
              size="md"
              w="100%"
              onChange={handleEmailChange}
              value={loginState.emailAddress}
            />
            <Flex w="100%">
              <p>
                By logging in, you acknowledge that you have read and agree to
                the{" "}
                <a
                  href="https://luminos.ai/software-terms-conditions"
                  style={{ color: "blue" }}
                >
                  Luminos.AI Terms and Conditions
                </a>
                .
              </p>
              <Spacer mr="auto" />
            </Flex>
            <Flex w="100%" alignItems="start">
              <Button
                data-test-id="login-button"
                type="submit"
                isDisabled={!loginState.emailIsValid || isLoading}
              >
                Login
              </Button>
              <Checkbox
                onChange={handleSaveUsernameChange}
                m="4px"
                isChecked={loginState.saveUsername}
              ></Checkbox>
              <Text fontSize="14px">Remember my email address.</Text>
            </Flex>
          </VStack>
        </form>
      </div>
    </UnauthenticatedPage>
  );
};
