import { IconButton, IconButtonProps } from "@mui/material";

export const RuleIconButton = (props: IconButtonProps) => {
  return (
    <IconButton
      {...props}
      size="small"
      sx={{ color: "#ccc", ":hover": { color: "rgba(0, 0, 0, 0.54)" } }}
    />
  );
};
