import { createSelector } from "@reduxjs/toolkit";
import { RootState, Selector } from "src/store";
import { FormElement } from "src/types/form.types";

export const selectFormState = (state: RootState) => {
  return state.form;
};

export const selectFormStatus = createSelector(selectFormState, (formState) => {
  return formState.status;
});

export const selectFormErrorMessage = createSelector(
  selectFormState,
  (formState) => {
    if (formState.status === "error") {
      return formState.message;
    }
  }
);

export const selectFormId = createSelector(selectFormState, (formState) => {
  if (formState.status === "success" || formState.status === "saving") {
    return formState.formId;
  }
});

export const selectFormMeta = createSelector(selectFormState, (formState) => {
  if (formState.status === "success" || formState.status === "saving") {
    return formState.meta;
  }
});

export const selectFormUsers = createSelector(selectFormState, (formState) => {
  if (formState.status === "success" || formState.status === "saving") {
    return formState.form.users;
  }
});

export const selectFormReadOnly = createSelector(
  selectFormState,
  (formState) => {
    if (formState.status === "success" || formState.status === "saving") {
      return formState.form.readOnly;
    }
  }
);

export const selectIsRulesDrawerOpen = createSelector(
  selectFormState,
  (formState) => {
    if (formState.status === "success" || formState.status === "saving") {
      return formState.isRulesDrawerOpen;
    }
  }
);

export const selectDisplayPrivilegeBanner = createSelector(
  selectFormState,
  (formState) => {
    if (formState.status === "success" || formState.status === "saving") {
      return formState.displayPrivilegeBanner;
    }
  }
);

export const selectDisplayPrivilegeConfirmation = createSelector(
  selectFormState,
  (formState) => {
    if (formState.status === "success" || formState.status === "saving") {
      return formState.displayPrivilegeConfirmation;
    }
  }
);

export const selectFormElements = createSelector(
  selectFormState,
  (formState) => {
    if (formState.status === "success" || formState.status === "saving") {
      return formState.elements;
    }
  }
);

export const selectFormElementsById = createSelector(
  selectFormState,
  (formState) => {
    if (formState.status === "success" || formState.status === "saving") {
      return formState.elementsById;
    }
  }
);

export const lookupFormElementById = (
  elementId: string
): Selector<FormElement | undefined> =>
  createSelector(selectFormState, (formState) => {
    if (formState.status === "success" || formState.status === "saving") {
      return formState.elementsById[elementId];
    }
  });

export const selectForm = createSelector(selectFormState, (formState) => {
  if (formState.status === "success" || formState.status === "saving") {
    return formState.form;
  }
});

export const selectFormsEditingElementId = createSelector(
  selectFormState,
  (formState) => {
    if (formState.status === "success" || formState.status === "saving") {
      return formState.editingElementId;
    }
  }
);
