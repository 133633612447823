import { useNavigate, useParams } from "react-router-dom";
import { SubmissionEditorDataPage } from "src/components/SubmissionEditorDataPage";
import { routes } from "src/routes";

export const SubmissionEditorRoutePage = () => {
  const navigate = useNavigate();
  const submissionId = useParams<{ id: string }>().id;

  if (!submissionId) {
    navigate(routes.Submissions.path);
    return null;
  }

  return <SubmissionEditorDataPage submissionId={submissionId} />;
};
