import { UserQuery } from "../types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface Credentials {
  email: string;
}

export const authenticateApi = createApi({
  reducerPath: "@authenticateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/public`,
  }),
  endpoints: (builder) => ({
    authenticate: builder.mutation<UserQuery, Credentials>({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const { useAuthenticateMutation } = authenticateApi;
