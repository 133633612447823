import React from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  VStack,
  Textarea,
} from "@chakra-ui/react";
import { useAppSelector } from "../../store";
import { useOrganizationAPI } from "../../endpoints/_index";
import { useLazyGetUserQuery } from "../../services/user.service";

export default function SettingsTabPanel() {
  const { currentOrganizationId, userContext } = useAppSelector(
    (state) => state.user
  );
  const [userInfo, setUserInfo] = React.useState(userContext);
  const [saveNeeded, setSaveNeeded] = React.useState(false);
  const { updateOrganization } = useOrganizationAPI();
  const [getUser] = useLazyGetUserQuery();

  React.useEffect(() => {
    setUserInfo(userContext);
    setSaveNeeded(false);
  }, [userContext]);

  const updateOrgName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserInfo((prevUserInfo) => {
        return prevUserInfo && prevUserInfo.currentOrgMembership.organization
          ? {
              ...prevUserInfo,
              currentOrgMembership: {
                ...prevUserInfo.currentOrgMembership,
                organization: {
                  ...prevUserInfo.currentOrgMembership.organization,
                  name: event.target.value,
                },
              },
            }
          : prevUserInfo;
      });
      setSaveNeeded(true);
    },
    []
  );

  const updateOrgDescription = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setUserInfo((prevUserInfo) => {
        return prevUserInfo && prevUserInfo.currentOrgMembership.organization
          ? {
              ...prevUserInfo,
              currentOrgMembership: {
                ...prevUserInfo.currentOrgMembership,
                organization: {
                  ...prevUserInfo.currentOrgMembership.organization,
                  description: event.target.value,
                },
              },
            }
          : prevUserInfo;
      });
      setSaveNeeded(true);
    },
    []
  );

  const resetChanges = React.useCallback(() => {
    setUserInfo(userContext);
    setSaveNeeded(false);
  }, [userContext]);

  const handleSaveChanges = React.useCallback(async () => {
    if (userInfo && userInfo.currentOrgMembership.organization) {
      await updateOrganization({
        name: userInfo.currentOrgMembership.organization.name,
        description: userInfo.currentOrgMembership.organization.description,
      });
      getUser({
        targetOrganizationId: currentOrganizationId,
      });
      setSaveNeeded(false);
    }
  }, [userInfo, currentOrganizationId, updateOrganization, getUser]);

  return (
    <>
      <Flex alignContent="">
        <Box w="800px">
          <Grid
            templateRows="repeat(8, 1fr)"
            templateColumns="repeat(4, 1fr)"
            gap="3"
          >
            <GridItem colSpan={1} rowSpan={1} alignSelf="center">
              <Text align="right">Organization Name</Text>
            </GridItem>
            <GridItem colSpan={2} rowSpan={1}>
              <Input
                data-test-id="org-name-input"
                placeholder="Organization Name"
                size="md"
                value={userInfo?.currentOrgMembership?.organization?.name}
                onChange={updateOrgName}
              ></Input>
            </GridItem>
            <GridItem colSpan={1}></GridItem>
            <GridItem colSpan={1} rowSpan={1} alignSelf="center">
              <Text align="right">Organization Description</Text>
            </GridItem>
            <GridItem colSpan={3} rowSpan={3}>
              <Textarea
                data-test-id="org-description-input"
                placeholder="Organization Description"
                size="md"
                value={
                  userInfo?.currentOrgMembership?.organization?.description
                }
                onChange={updateOrgDescription}
              ></Textarea>
            </GridItem>
          </Grid>
        </Box>
        <VStack ml="20px">
          {saveNeeded ? (
            <>
              <Button
                data-test-id="org-settings-save-btn"
                onClick={handleSaveChanges}
              >
                Save
              </Button>
              <Button
                data-test-id="org-settings-cancel-btn"
                variant="secondary"
                onClick={resetChanges}
              >
                Cancel
              </Button>
            </>
          ) : (
            <></>
          )}
        </VStack>
      </Flex>
    </>
  );
}
