import { Text, Icon } from "@chakra-ui/react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Colors } from "../theme/colors";
import { useAppSelector } from "src/store";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { FormElementBuildViewProps } from "src/types/form.types";
import { PreviewHeader } from "./PreviewHeader";

export const BiasAnalysisPreview = ({ id }: FormElementBuildViewProps) => {
  const element = useAppSelector(lookupFormElementById(id));

  if (element && element.type !== "biasAnalysis") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  if (!element) {
    return null;
  }

  return (
    <div style={{ width: "100%" }}>
      <PreviewHeader
        rules={element.rules}
        title={element.content.title}
        titleNumber={element.titleNumber ?? ""}
        adjudicatorNotes={element.content.adjudicatorNotes}
        surveyTakerNotes={element.content.surveyTakerNotes}
        audience={element.content.audience}
        questionPlaceholder="Enter the bias analysis instructions"
        question={element.content.question}
      />

      <input
        style={{ ...styles.input }}
        id={`bias-file-upload-${id}`}
        type="file"
        accept=".csv"
      />
      <label htmlFor={""}>
        <div
          style={{
            ...styles.label,
          }}
        >
          <Icon as={FileUploadOutlinedIcon} />
          <Text>Choose a file</Text>
        </div>
      </label>
    </div>
  );
};

const styles = {
  input: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    zIndex: -1,
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    width: "150px",
    padding: 5,
    borderRadius: "2px",
    fontSize: "12px",
    boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",
    color: "white",
    cursor: "pointer",
    justifyContent: "center",
    display: "flex",
    backgroundColor: Colors.gray[300],
    marginBottom: 8,
  },
};
