import React from "react";
import { SaveStatus } from "src/constants/status.constants";
import {
  Box,
  InputBase,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

interface DocumentTitleInputProps {
  value: string;
  onChange?: (e: string) => void;
  placeholder?: string;
  setSaving?: (x: SaveStatus) => void;
  dataTestId?: string;
}

export const DocumentTitleInput = ({
  value,
  onChange,
  placeholder = "Untitled Form",
  setSaving,
  dataTestId,
}: DocumentTitleInputProps) => {
  const theme = useTheme();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const textRef = React.useRef<HTMLSpanElement>(null);
  const isBelowMedium = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    if (textRef.current && containerRef.current) {
      const containerWidth = textRef.current.textContent
        ? textRef.current.offsetWidth + 10
        : placeholder.length * 9;
      containerRef.current.style.width = `${containerWidth}px`;
    }
  }, [placeholder.length, value]);

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (setSaving) {
        setSaving(SaveStatus.unsavedChanges);
      }
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange, setSaving]
  );

  return (
    <Box
      ref={containerRef}
      sx={{ flex: "1 0 auto", maxWidth: isBelowMedium ? "100px" : "400px" }}
      title={value}
    >
      <InputBase
        fullWidth
        sx={{
          "& .MuiInputBase-input": {
            color: "white",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        inputProps={{ "data-test-id": dataTestId }}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
      <Typography
        ref={textRef}
        sx={{ position: "absolute", left: "-9999px", visibility: "hidden" }}
      >
        {value}
      </Typography>
    </Box>
  );
};
