import { Plate, TElement } from "@udecode/plate-common";
import { Editor } from "../pages/Elements/plate-ui/editor";
import { FixedToolbar } from "../pages/Elements/plate-ui/fixed-toolbar";
import { FixedToolbarButtons } from "../pages/Elements/plate-ui/fixed-toolbar-buttons";
import { TooltipProvider } from "../pages/Elements/plate-ui/tooltip";
import { plugins } from "./RichTextPlugins";
import { SimpleFixedToolbarButtons } from "../pages/Elements/plate-ui/simple-fixed-toolbar-buttons";
import _ from "lodash";
import { MentionCombobox } from "../pages/Elements/plate-ui/mention-combobox";
import { Collapse } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { RootState, useAppSelector } from "../store";
import { SlateObject, MentionableUser } from "../types";

export const richTextInitialValue = [
  {
    type: "p",
    children: [
      {
        text: "",
      },
    ],
  },
];

export default function RichText({
  simple = false,
  borderColor = "border-gray-300",
  value,
  readOnly = false,
  showToolbar = true,
  onChange,
  placeholder = "Type a paragraph",
  size = "md",
  className = "pt-2",
  selected = true,
  includeMentions = false,
}: {
  simple?: boolean;
  borderColor?: string;
  value?: SlateObject;
  readOnly?: boolean;
  showToolbar?: boolean;
  onChange?: (e: TElement[]) => void;
  placeholder?: string;
  size?: "sm" | "full" | "md" | "fit";
  className?: string;
  selected?: boolean;
  includeMentions?: boolean;
}) {
  const mentionableMembers: MentionableUser[] = useAppSelector(
    (state: RootState) => state.organization.mentionableMembers
  );

  const handleChange = React.useCallback(
    (v: TElement[]) => {
      if (onChange) {
        onChange(v);
      }
    },
    [onChange]
  );

  const plate = useMemo(() => {
    return (
      <Plate<TElement[]>
        id={value}
        onChange={handleChange}
        plugins={plugins(includeMentions)}
        initialValue={_.isEqual(value, {}) ? richTextInitialValue : value}
      >
        {showToolbar ? (
          <Collapse in={selected}>
            <FixedToolbar>
              {simple ? <SimpleFixedToolbarButtons /> : <FixedToolbarButtons />}
            </FixedToolbar>
          </Collapse>
        ) : null}
        <Editor
          id={value}
          name={`editorField-${value}`}
          placeholder={placeholder}
          className={className.concat(" overflow-x-hidden")}
          size={size ?? "fit"}
          readOnly={readOnly}
        />
        <MentionCombobox items={mentionableMembers} pluginKey="@" />
      </Plate>
    );
    // onChange cannot be included without causing rerenders, onChange cannot be wrapper in useCallback in the parent component
  }, [
    readOnly,
    selected,
    showToolbar,
    simple,
    value,
    mentionableMembers,
    placeholder,
    className,
    size,
    includeMentions,
    handleChange,
  ]);

  return (
    <TooltipProvider>
      <div className={`border-[2px] ${borderColor}`}>{plate}</div>
    </TooltipProvider>
  );
}
