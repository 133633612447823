import { ComponentStyleConfig, defineStyle } from "@chakra-ui/react";
export const HeadingStyle: ComponentStyleConfig = defineStyle({
  variants: {
    h2: {
      fontFamily: "Lato Bold",
      size: "xl",
      fontSize: "24px",
    },
  },
});
