import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { LuminosModalHeader } from "./LuminosModalHeader";

export const DestructiveErrorModal = ({
  closeParent,
  closeErrorModalWrapper,
  errorModalIsOpen,
  title,
}: {
  closeParent: () => void;
  closeErrorModalWrapper: () => void;
  errorModalIsOpen: boolean;
  title: string;
}) => {
  return (
    <Modal isOpen={errorModalIsOpen} onClose={closeErrorModalWrapper}>
      <ModalOverlay>
        <ModalContent>
          <LuminosModalHeader title={title} closeButton={false} />
          <ModalBody w="100%" bg="white">
            An error occurred, please try again later.
          </ModalBody>
          <ModalFooter bg="white">
            <Button
              isDisabled={false}
              onClick={() => {
                closeErrorModalWrapper();
                closeParent();
              }}
            >
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
