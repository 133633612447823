import {
  Box,
  Center,
  Text,
  Flex,
  HStack,
  VStack,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  ModalBody,
  Spacer,
  Heading,
} from "@chakra-ui/react";
import { SlateObject } from "../types";
import { SharingModalUserAccessCard } from "./Modal/SharingModalUserAccessCard";
import RichText from "./RichText";
import { DocUser } from "./Modal/SharingModal";
import {
  EnrichedFormContentless,
  EnrichedSubmissionContentless,
  EnrichedForm,
} from "../types";
import { EnrichedSubmission } from "src/types/submission.types";

interface ISelectUserModalContent {
  loadingDocumentInfo: boolean;
  type: "form" | "submission";
  handleOnChange: (e: SlateObject) => void;
  docUsers: Map<string, DocUser> | undefined;
  setDocUsers: (x: Map<string, DocUser>) => void;
  openErrorModal: () => void;
  setSaving: (x: boolean) => void;
  generalAccessRestrictedText: string;
  generalAccessMembersText: string;
  generalAccessMembersAndGuestsText: string;
  generalAccessText: string;
  updateGeneralAccess: (
    x: "restricted" | "members" | "membersAndGuests"
  ) => void;
  generalAccess: "restricted" | "members" | "membersAndGuests";
  discoverable: boolean;
  setDiscoverableWrapper: (x: boolean) => void;
  deleteUser: (userId: string) => void;
  updateDocumentUserRole: (role: string, userId: string) => void;
  document:
    | EnrichedForm
    | EnrichedFormContentless
    | EnrichedSubmission
    | EnrichedSubmissionContentless;
}

export const SharingModalUserSelection = ({
  loadingDocumentInfo,
  type,
  handleOnChange,
  docUsers,
  setDocUsers,
  openErrorModal,
  setSaving,
  generalAccessRestrictedText,
  generalAccessMembersText,
  generalAccessMembersAndGuestsText,
  generalAccessText,
  updateGeneralAccess,
  generalAccess,
  discoverable,
  setDiscoverableWrapper,
  deleteUser,
  updateDocumentUserRole,
  document,
}: ISelectUserModalContent) => {
  return (
    <ModalBody bg="white">
      <HStack w="100%">
        <Heading size="md" w="100%">
          Share &quot;{document?.meta.title}&quot;
        </Heading>
      </HStack>

      {loadingDocumentInfo ? (
        <Text>loading...</Text>
      ) : (
        <>
          <Flex mt="12px" mb="8px">
            <Box w="100%">
              <div style={{ width: "100%" }}>
                <RichText
                  showToolbar={false}
                  placeholder="type @ to add users"
                  size="fit"
                  onChange={(e: SlateObject) => handleOnChange(e)}
                  includeMentions={true}
                />
              </div>
            </Box>
            <Spacer />
            <Box></Box>
          </Flex>
          <>
            <Heading size="sm" w="100%" mt="12px">
              People With Access
            </Heading>
            <>
              {document && docUsers ? (
                <>
                  {[...docUsers.keys()].map((data: string) => {
                    return (
                      <SharingModalUserAccessCard
                        updateDocumentUserRole={updateDocumentUserRole}
                        deleteUser={deleteUser}
                        key={data}
                        userId={data}
                        docUsers={docUsers}
                        docId={document._id}
                        docType={type}
                        doc={document}
                        setDocUsers={setDocUsers}
                        openErrorModal={openErrorModal}
                        setSaving={setSaving}
                      />
                    );
                  })}
                </>
              ) : null}
            </>
            {type === "form" ? (
              <>
                <Heading size="sm" w="100%" mt="24px">
                  General Submission Access
                </Heading>
                <VStack w="100%" spacing="2px">
                  <Flex w="100%">
                    <Menu>
                      <MenuButton
                        border="1px"
                        pr="2px"
                        pl="2px"
                        pt="0px"
                        pb="0px"
                        mr="2px"
                        borderColor="gray.300"
                        borderRadius="3px"
                      >
                        <Center>
                          <Text fontSize="sm">{generalAccessText}</Text>
                        </Center>
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() => updateGeneralAccess("restricted")}
                        >
                          {generalAccessRestrictedText}
                        </MenuItem>
                        <MenuItem
                          onClick={() => updateGeneralAccess("members")}
                        >
                          {generalAccessMembersText}
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            updateGeneralAccess("membersAndGuests")
                          }
                        >
                          {generalAccessMembersAndGuestsText}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    <Center>
                      <Text fontSize="sm">
                        may create submissions of this form.
                      </Text>
                    </Center>
                  </Flex>

                  {generalAccess === "restricted" ? null : (
                    <>
                      <Flex w="100%">
                        <Text fontSize="sm">This form</Text>

                        <Menu>
                          <MenuButton
                            border="1px"
                            pr="2px"
                            pl="2px"
                            pt="0px"
                            pb="0px"
                            ml="2px"
                            mr="2px"
                            borderColor="gray.300"
                            borderRadius="3px"
                          >
                            <Center>
                              <Text fontSize="sm">
                                {discoverable ? "is" : "is not"}
                              </Text>
                            </Center>
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => setDiscoverableWrapper(true)}
                            >
                              is
                            </MenuItem>
                            <MenuItem
                              onClick={() => setDiscoverableWrapper(false)}
                            >
                              is not
                            </MenuItem>
                          </MenuList>
                        </Menu>

                        <Text fontSize="sm">
                          discoverable on the Forms Page for those with only
                          General Submission
                        </Text>
                      </Flex>
                      {!discoverable ? (
                        <Flex w="100%">
                          <Text fontSize="sm">
                            Access. They must have the link to create a
                            Submission from the Form.
                          </Text>
                        </Flex>
                      ) : (
                        <>
                          <Flex w="100%">
                            <Text fontSize="sm">
                              Access. They may create a Submission from this
                              Form from the Forms Page or
                            </Text>
                          </Flex>
                          <Flex w="100%">
                            <Text fontSize="sm"> using the link.</Text>
                          </Flex>
                        </>
                      )}
                    </>
                  )}
                </VStack>
              </>
            ) : null}
          </>
        </>
      )}
    </ModalBody>
  );
};
