import { userApi } from "src/services/user.service";
import { OrganizationPrivilegeSettings, UserQuery } from "../../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserContextState {
  userContext: UserQuery | null;
  currentOrganizationId: string | null;
}

const initialState: UserContextState = {
  userContext: null,
  currentOrganizationId: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserContext: (state, action: PayloadAction<UserQuery>) => {
      state.userContext = action.payload;

      return state;
    },
    setCurrentOrganizationId: (state, action: PayloadAction<string>) => {
      state.currentOrganizationId = action.payload;
    },
    setOrganizationPrivilege: (
      state,
      action: PayloadAction<OrganizationPrivilegeSettings>
    ) => {
      // @TODO understand why/how organization can be undefined
      if (
        state.userContext?.currentOrgMembership?.organization?.privilegeSettings
      ) {
        state.userContext.currentOrgMembership.organization.privilegeSettings =
          action.payload;
      }
    },
    resetUserContextState: (state) => {
      state.userContext = null;
      state.currentOrganizationId = null;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getUser.matchFulfilled,
      (state, action) => {
        state.userContext = action.payload;
      }
    );
  },
});

export const {
  setUserContext,
  setCurrentOrganizationId,
  setOrganizationPrivilege,
  resetUserContextState,
} = userSlice.actions;
