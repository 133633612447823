import Quill, { DeltaStatic } from "quill";
import { Quill as ReactQuill } from "react-quill";

export const Delta = ReactQuill.import("delta");

let quillTranslator: Quill | null = null;

export const initializeQuillTranslator = (element: HTMLDivElement) => {
  quillTranslator = new Quill(element);
  return quillTranslator;
};

const getQuillTranslator = () => {
  return quillTranslator;
};

export const translateDeltaToText = (delta: DeltaStatic) => {
  const quill = getQuillTranslator();
  if (quill) {
    quill.setContents(delta);
    const text = quill.getText();
    quill.setContents(new Delta());
    return text;
  }
};
