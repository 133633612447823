import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import {
  findElementById,
  getElementPathIndexes,
  getSiblingAbove,
  recursiveUpdateStateTitleNumbers,
  recursiveUpdateTitleNumbers,
} from "src/util/form.utils";
import { FormElementState, FormState } from "../slices/form.slice";

export const indentElementReducer: CaseReducer<
  FormState,
  PayloadAction<{
    elementState: FormElementState;
    parentElementId: string | null;
  }>
> = (state, action) => {
  if (state.status === "success") {
    let { elementState } = action.payload;
    const { parentElementId } = action.payload;
    const parentState = findElementById(parentElementId!, state.elements);
    let parentStateArray = parentState?.children ?? state.elements;

    // calculate new element path index
    const targetPathIndex = getElementPathIndexes(
      [elementState.id],
      state.elements
    )[0];
    const siblingState = getSiblingAbove(targetPathIndex, state.elements);
    if (!siblingState) {
      throw new Error("Cannot indent element without sibling above.");
    }
    const targetIndex = parentStateArray.findIndex(
      (child) => child.id === elementState.id
    );
    const newPathIndex = [
      ...siblingState.pathIndex,
      siblingState.children.length,
    ];
    elementState = {
      ...elementState,
      pathIndex: newPathIndex,
      indentationLevel: newPathIndex.length,
    };

    // removes element from original position
    parentStateArray.splice(targetIndex, 1);
    parentElementId
      ? (state.elementsById[parentElementId] = {
          ...state.elementsById[parentElementId!],
          children: state.elementsById[parentElementId].children.filter(
            (e) => e.id !== elementState.id
          ),
        })
      : (state.elements = state.elements.filter(
          (e) => e.id !== elementState.id
        ));

    // insert element into new position
    siblingState.children = [...(siblingState.children || []), elementState];

    parentStateArray = parentStateArray.map((e) => {
      if (e.id === siblingState.id) {
        return siblingState;
      }
      return e;
    });

    recursiveUpdateStateTitleNumbers(state.elements, null, state);
    recursiveUpdateTitleNumbers(state.elements, state);
  }
};
