import { createApi } from "@reduxjs/toolkit/query/react";
import {
  EnrichedSubmissionContentless,
  CommentThreadDictionary,
} from "src/types";
import { booleanToString } from "src/util/boolean.utils";
import {
  EnrichedSubmission,
  SubmissionElement,
  SubmissionUser,
} from "src/types/submission.types";
import { getAuthenticatedBaseQuery } from "src/util/base-query.utils";

export const submissionsApi = createApi({
  reducerPath: "@submissionsApi",
  baseQuery: getAuthenticatedBaseQuery(`${process.env.REACT_APP_API_URL}`),
  endpoints: (builder) => ({
    getSubmissions: builder.query<
      EnrichedSubmissionContentless[],
      { finalized: boolean } | void
    >({
      query: ({ finalized } = { finalized: false }) => {
        const queryParams = new URLSearchParams({
          finalized: booleanToString(finalized),
        });
        return {
          url: `/submissions?${queryParams.toString()}`,
        };
      },
    }),

    getSubmissionById: builder.query<
      EnrichedSubmission,
      { submissionId: string }
    >({
      query: ({ submissionId }) => ({
        url: `/submissions/${submissionId}`,
      }),
    }),

    createSubmission: builder.mutation<
      { id: string },
      { submitters: string[]; formId: string }
    >({
      query: (body) => ({
        url: `/submissions`,
        method: "POST",
        body,
      }),
    }),

    finalizeSubmission: builder.mutation<
      { message: string },
      { submissionId: string }
    >({
      query: ({ submissionId }) => ({
        url: `/submissions/${submissionId}/finalize`,
        method: "PUT",
      }),
    }),

    unfinalizeSubmission: builder.mutation<
      { message: string },
      { submissionId: string }
    >({
      query: ({ submissionId }) => ({
        url: `/submissions/${submissionId}/unfinalize`,
        method: "PUT",
      }),
    }),

    updateSubmissionElements: builder.mutation<
      EnrichedSubmission,
      {
        submissionId: string;
        elements: SubmissionElement[];
      }
    >({
      query: ({ submissionId, elements }) => ({
        url: `/submissions/${submissionId}/elements`,
        method: "PATCH",
        body: {
          elements,
        },
      }),
    }),

    updateSubmissionTitle: builder.mutation<
      { message: string },
      { submissionId: string; title: string }
    >({
      query: ({ submissionId, title }) => ({
        url: `/submissions/${submissionId}/title`,
        method: "PUT",
        body: { title },
      }),
    }),

    updateSubmissionPrivilege: builder.mutation<
      { message: string },
      { submissionId: string; privileged: boolean }
    >({
      query: ({ submissionId, privileged }) => ({
        url: `/submissions/${submissionId}/privileged`,
        method: "PUT",
        body: { privileged },
      }),
    }),

    updateSubmissionPrivilegeWarning: builder.mutation<
      { message: string },
      { submissionId: string; privilegeWarningDismissed: boolean }
    >({
      query: ({ submissionId, privilegeWarningDismissed }) => ({
        url: `/submissions/${submissionId}/privileged-warning-dismissed`,
        method: "PUT",
        body: { privilegeWarningDismissed },
      }),
    }),

    updateSubmissionComments: builder.mutation<
      { message: string },
      {
        submissionId: string;
        commentThreadsDictionary: CommentThreadDictionary;
      }
    >({
      query: ({ submissionId, commentThreadsDictionary }) => ({
        url: `/submissions/${submissionId}/comment-threads`,
        method: "PUT",
        body: { commentThreadsDictionary },
      }),
    }),

    addUsersToSubmission: builder.mutation<
      { message: string },
      {
        submissionId: string;
        users: SubmissionUser[];
        notifyUsers?: boolean;
        sharingMessage?: string;
      }
    >({
      query: ({ submissionId, users, notifyUsers, sharingMessage }) => ({
        url: `/submissions/${submissionId}/users`,
        method: "POST",
        body: { users, notifyUsers, sharingMessage },
      }),
    }),

    updateSubmissionUserApproved: builder.mutation<
      { message: string },
      { submissionId: string; userId: string }
    >({
      query: ({ submissionId, userId }) => ({
        url: `/submissions/${submissionId}/user-approval/${userId}`,
        method: "PUT",
      }),
    }),

    updateSubmissionUserRole: builder.mutation<
      { message: string },
      { submissionId: string; userId: string; role: string }
    >({
      query: ({ submissionId, userId, role }) => ({
        url: `/submissions/${submissionId}/user-role/${userId}`,
        method: "PUT",
        body: { role },
      }),
    }),

    deleteSubmission: builder.mutation<
      { message: string },
      { submissionId: string }
    >({
      query: ({ submissionId }) => ({
        url: `/submissions/${submissionId}`,
        method: "DELETE",
      }),
    }),

    deleteSubmissionUser: builder.mutation<
      { message: string },
      { submissionId: string; userId: string }
    >({
      query: ({ submissionId, userId }) => ({
        url: `/submissions/${submissionId}/user/${userId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetSubmissionsQuery,
  useLazyGetSubmissionsQuery,
  useGetSubmissionByIdQuery,
  useUpdateSubmissionElementsMutation,
  useUpdateSubmissionTitleMutation,
  useUpdateSubmissionPrivilegeMutation,
  useUpdateSubmissionPrivilegeWarningMutation,
  useUpdateSubmissionCommentsMutation,
  useAddUsersToSubmissionMutation,
  useUpdateSubmissionUserApprovedMutation,
  useUpdateSubmissionUserRoleMutation,
  useDeleteSubmissionMutation,
  useDeleteSubmissionUserMutation,
  useCreateSubmissionMutation,
  useFinalizeSubmissionMutation,
  useUnfinalizeSubmissionMutation,
} = submissionsApi;
