import React from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  VStack,
  Textarea,
} from "@chakra-ui/react";
import { OrganizationPrivilegeSettings, UserQuery } from "../../types";
import { store, useAppSelector } from "../../store";
import { useOrganizationAPI } from "../../endpoints/organization";
import { setOrganizationPrivilege } from "../../store/slices/user.slice";
import { useLazyGetUserQuery } from "../../services/user.service";

export default function SettingsTabPanel() {
  const { currentOrganizationId, userContext } = useAppSelector(
    (state) => state.user
  );
  const [userInfo, setUserInfo] = React.useState<UserQuery | null>(userContext);
  const [saveNeeded, setSaveNeeded] = React.useState(false);
  const { updateOrganizationPrivilageSettings } = useOrganizationAPI();
  const [getUser] = useLazyGetUserQuery();

  const handleUpdatePrivilegeBanner = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserInfo((prevUserInfo) => {
        return prevUserInfo && prevUserInfo.currentOrgMembership.organization
          ? {
              ...prevUserInfo,
              currentOrgMembership: {
                ...prevUserInfo.currentOrgMembership,
                organization: {
                  ...prevUserInfo.currentOrgMembership.organization,
                  privilegeSettings: {
                    ...prevUserInfo.currentOrgMembership.organization
                      .privilegeSettings,
                    privilegeBanner: event.target.value,
                  },
                },
              },
            }
          : prevUserInfo;
      });
      setSaveNeeded(true);
    },
    []
  );

  const updatePrivilegeAgreement = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setUserInfo((prevUserInfo) => {
        return prevUserInfo && prevUserInfo.currentOrgMembership.organization
          ? {
              ...prevUserInfo,
              currentOrgMembership: {
                ...prevUserInfo.currentOrgMembership,
                organization: {
                  ...prevUserInfo.currentOrgMembership.organization,
                  privilegeSettings: {
                    ...prevUserInfo.currentOrgMembership.organization
                      .privilegeSettings,
                    privilegeAgreement: event.target.value,
                  },
                },
              },
            }
          : prevUserInfo;
      });
      setSaveNeeded(true);
    },
    []
  );

  const updatePrivilegeTerms = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setUserInfo((preUserInfo) => {
        return preUserInfo && preUserInfo.currentOrgMembership.organization
          ? {
              ...preUserInfo,
              currentOrgMembership: {
                ...preUserInfo.currentOrgMembership,
                organization: {
                  ...preUserInfo.currentOrgMembership.organization,
                  privilegeSettings: {
                    ...preUserInfo.currentOrgMembership.organization
                      .privilegeSettings,
                    privilegeTerms: event.target.value,
                  },
                },
              },
            }
          : preUserInfo;
      });

      setSaveNeeded(true);
    },
    []
  );

  const resetChanges = React.useCallback(() => {
    setUserInfo(userContext);
    setSaveNeeded(false);
  }, [userContext]);

  const handleSaveChanges = async () => {
    if (userInfo && userInfo.currentOrgMembership.organization) {
      const updatedSettings: OrganizationPrivilegeSettings =
        await updateOrganizationPrivilageSettings({
          privilegeBanner:
            userInfo.currentOrgMembership.organization.privilegeSettings
              .privilegeBanner,
          privilegeAgreement:
            userInfo.currentOrgMembership.organization.privilegeSettings
              .privilegeAgreement,
          privilegeTerms:
            userInfo.currentOrgMembership.organization.privilegeSettings
              .privilegeTerms,
        });

      store.dispatch(setOrganizationPrivilege(updatedSettings));
      getUser({
        targetOrganizationId: currentOrganizationId,
      });
      setSaveNeeded(false);
    }
  };

  return (
    <>
      <Flex alignContent="">
        <Box w="800px">
          <Grid
            templateRows="repeat(8, 1fr)"
            templateColumns="repeat(4, 1fr)"
            gap="3"
          >
            <GridItem colSpan={1} rowSpan={1} alignSelf="center">
              <Text align="right">Privilege Banner</Text>
            </GridItem>
            <GridItem colSpan={2} rowSpan={1}>
              <Input
                placeholder="Privileged and Confidential"
                size="md"
                value={
                  userInfo?.currentOrgMembership?.organization
                    ?.privilegeSettings.privilegeBanner
                }
                onChange={handleUpdatePrivilegeBanner}
              ></Input>
            </GridItem>
            <GridItem colSpan={1}></GridItem>
            <GridItem colSpan={1} rowSpan={1} alignSelf="center">
              <Text align="right">Privilege Agreement</Text>
            </GridItem>
            <GridItem colSpan={3} rowSpan={2}>
              <Textarea
                placeholder=""
                size="md"
                value={
                  userInfo?.currentOrgMembership?.organization
                    ?.privilegeSettings.privilegeAgreement
                }
                onChange={updatePrivilegeAgreement}
              ></Textarea>
            </GridItem>
            <GridItem colSpan={1}></GridItem>

            <GridItem colSpan={1} rowSpan={1} alignSelf="center">
              <Text align="right">Privilege Terms</Text>
            </GridItem>
            <GridItem colSpan={3} rowSpan={3}>
              <Textarea
                placeholder=""
                size="md"
                value={
                  userInfo?.currentOrgMembership?.organization
                    ?.privilegeSettings.privilegeTerms
                }
                onChange={updatePrivilegeTerms}
              ></Textarea>
            </GridItem>
          </Grid>
        </Box>
        <VStack ml="20px">
          {saveNeeded ? (
            <>
              <Button onClick={handleSaveChanges}>Save</Button>
              <Button variant="secondary" onClick={resetChanges}>
                Cancel
              </Button>
            </>
          ) : (
            <></>
          )}
        </VStack>
      </Flex>
    </>
  );
}
