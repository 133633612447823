import React from "react";
import { Text, HStack, Tooltip, Icon, Switch, VStack } from "@chakra-ui/react";
import { AdvancedSectionWrapper } from "./AdvancedSectionWrapper";
import { AdvancedNotes } from "./AdvancedNotes";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useAppDispatch, useAppSelector } from "src/store";
import { FormElementBuildViewProps, RichTextDelta } from "src/types/form.types";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { selectSelectedElement } from "src/store/selectors/selected-element.selectors";
import { updateFormElement } from "src/store/slices/form.slice";
import { BiasAnalysisPreview } from "./BiasAnalysisPreview";
import { BuildViewHeader } from "./BuildViewHeader";
import { RichTextEditor } from "./RichTextEditor";

export const BiasAnalysisBuildView = ({ id }: FormElementBuildViewProps) => {
  const element = useAppSelector(lookupFormElementById(id));
  const selectedElementId = useAppSelector(selectSelectedElement);
  const dispatch = useAppDispatch();
  const isSelectedElement = selectedElementId === id;

  if (element && element.type !== "biasAnalysis") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const handleRichTextChange = React.useCallback(
    (field: "question" | "surveyTakerNotes" | "adjudicatorNotes") => {
      return (value: RichTextDelta) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: value,
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  const handleCheckboxChange = React.useCallback(
    (field: "allowMultiple" | "restrictDeletion") => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: e.target.checked,
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  const handleHeaderChange = React.useCallback(
    (field: "title" | "audience") => {
      return (value: string) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: value,
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  if (!element) {
    return null;
  }

  return isSelectedElement ? (
    <div>
      <div style={{ width: "100%" }}>
        <BuildViewHeader
          id={id}
          titleDataTestId={`bias-analysis-title-input-${element.pathIndex.join("-")}`}
          rules={element?.rules ?? []}
          questionPrompt="Question"
          title={element.content.title}
          titlePlaceholder="Untitled Bias Analysis"
          titleNumber={element?.titleNumber ?? ""}
          audience={element.content.audience}
          onAudienceChange={handleHeaderChange("audience")}
          onTitleChange={handleHeaderChange("title")}
        />
        <RichTextEditor
          placeholder="Enter the bias analysis instructions"
          value={element.content.question}
          onChange={handleRichTextChange("question")}
        />
        <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
          <Text>Options</Text>
          <HStack>
            <Switch
              colorScheme="brightblue"
              isChecked={element.content.allowMultiple}
              onChange={handleCheckboxChange("allowMultiple")}
            />
            <Text>Allow multiple files.</Text>
          </HStack>
          <HStack>
            <Switch
              colorScheme="brightblue"
              isChecked={element.content.restrictDeletion}
              onChange={handleCheckboxChange("restrictDeletion")}
            />
            <Text>Only reviewers may delete. </Text>
            <Tooltip
              label={`When selected only a reviewer may delete attachments on the submission. Attachments may not be deleted after a submission is published.`}
            >
              <Icon boxSize="4" as={HelpOutlineIcon} />
            </Tooltip>
          </HStack>
        </VStack>
      </div>
      <AdvancedSectionWrapper>
        <div style={{ width: "100%" }}>
          <AdvancedNotes
            updateReviewerNotes={handleRichTextChange("adjudicatorNotes")}
            updateSubmitterNotes={handleRichTextChange("surveyTakerNotes")}
            surveyTakerNotes={element.content.surveyTakerNotes}
            adjudicatorNotes={element.content.adjudicatorNotes}
          />
        </div>
      </AdvancedSectionWrapper>
    </div>
  ) : (
    <BiasAnalysisPreview id={id} />
  );
};
