export const convertUserToMentionableUser = (obj: any) => {
  const text =
    obj.user?.firstName || obj.user?.lastName
      ? obj.user.firstName.concat(" ", obj.user.lastName)
      : obj.user.email;
  return {
    key: obj.user._id,
    text: text,
    data: {
      email: obj.user.email,
    },
  };
};
