import { useGetSubmissionByIdQuery } from "src/services/submission.service";
import { AuthenticatedPage } from "./AuthenticatedPage";
import { PageLoader } from "src/lib/components/PageLoader";
import { SubmissionEditor } from "./SubmissionEditor";
import { ErrorSnackbar } from "src/lib/components/ErrorSnackbar";
import { Box, Typography } from "@mui/material";
import { selectSubmissionEditingElementId } from "src/store/selectors/submission.selectors";
import { useAppSelector } from "src/store";

interface SubmissionEditorDataPageProps {
  submissionId: string;
}

export const SubmissionEditorDataPage = ({
  submissionId,
}: SubmissionEditorDataPageProps) => {
  const editingElementId = useAppSelector(selectSubmissionEditingElementId);
  const { data, error, isLoading } = useGetSubmissionByIdQuery(
    {
      submissionId,
    },
    {
      pollingInterval: 5000,

      /**
       * We want to skip polling when a user is editing an element.
       * Adding a `editingElementId` is the best way to check if an element is being edited.
       */
      skip: Boolean(editingElementId),
    }
  );

  return (
    <AuthenticatedPage>
      {isLoading && !error ? <PageLoader /> : null}

      {error && !isLoading ? (
        <Box>
          <Typography variant="h3">Document Not Found</Typography>
        </Box>
      ) : null}

      {data && !isLoading && !error ? <SubmissionEditor /> : null}

      <ErrorSnackbar
        message={error ? "Failed to fetch submission" : undefined}
      />
    </AuthenticatedPage>
  );
};
