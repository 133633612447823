import { AuthenticatedPage } from "../components/AuthenticatedPage";
import { useGetFormsQuery } from "src/services/forms.service";
import { Forms } from "./Forms";
import { PageLoader } from "src/lib/components/PageLoader";

export const FormsDataPage = () => {
  const { isLoading: isNonArchivedLoading } = useGetFormsQuery({
    archived: false,
  });
  const { isLoading: isArchivedLoading } = useGetFormsQuery({ archived: true });
  const isLoading = isArchivedLoading || isNonArchivedLoading;

  return (
    <AuthenticatedPage title="Forms">
      {isLoading ? <PageLoader /> : <Forms />}
    </AuthenticatedPage>
  );
};
