import { FormControl, FormLabel, Select } from "@chakra-ui/react";

export interface BiasAnalysisSelectQuestionProps {
  question: string;
  options: any[];
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

export const BiasAnalysisSelectQuestion = ({
  question,
  options,
  value,
  onChange,
  disabled,
}: BiasAnalysisSelectQuestionProps) => {
  return (
    <FormControl mb={4}>
      <FormLabel>{question}</FormLabel>
      <Select
        isDisabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        {options.map((option, idx) => (
          <option key={idx} value={option[1]}>
            {option[0]}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
