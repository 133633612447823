export const getSlugFromOrgId = (orgId: string) => {
  const updatedSlugToOrgMap: { [key: string]: string } = JSON.parse(
    localStorage.getItem("SlugToOrgIdMap") || "{}"
  );
  const invertedOrgToSlugMap: { [key: string]: string } = {};

  for (const [slug, id] of Object.entries(updatedSlugToOrgMap)) {
    invertedOrgToSlugMap[id] = slug;
  }

  return invertedOrgToSlugMap[orgId];
};

export const getSlugMap = () => {
  const slugMap: { [key: string]: string } = JSON.parse(
    localStorage.getItem("SlugToOrgIdMap") || "{}"
  );
  return slugMap;
};
