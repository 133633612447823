import {
  FormControl,
  FormLabel,
  HStack,
  Radio,
  VStack,
} from "@chakra-ui/react";

interface BiasAnalysisControlGroupSelectProps {
  demographicValues: any;
  handleControlGroupChange: (category: string, controlGroup: string) => void;
  disabled: boolean;
}

export const BiasAnalysisControlGroupSelect = ({
  demographicValues,
  handleControlGroupChange,
  disabled,
}: BiasAnalysisControlGroupSelectProps) => {
  return (
    <VStack align="stretch" spacing={4}>
      {Object.entries(demographicValues).map(([category, values]) => {
        if ((values as any).labels.length > 0) {
          return (
            <FormControl isDisabled={disabled} key={category} as="fieldset">
              <FormLabel as="legend">{category} Control Group</FormLabel>
              <HStack>
                {(values as any).labels.map((label: any) => (
                  <Radio
                    key={label}
                    isChecked={(values as any).control_group === label}
                    onChange={() => handleControlGroupChange(category, label)}
                  >
                    {label}
                  </Radio>
                ))}
              </HStack>
            </FormControl>
          );
        }
        return null;
      })}
    </VStack>
  );
};
