import { OrganizationModalPermissionDetail } from "./OrganizationModalPermissionDetail";
import {
  HStack,
  Input,
  Spacer,
  Text,
  Tooltip,
  VStack,
  Stack,
} from "@chakra-ui/react";
import { permissionDetails } from "./OrganizationMembershipModal";
import { useAppSelector } from "src/store";
import { selectOrganizationMemberInfo } from "src/store/selectors/organization-membership.selectors";
import React from "react";

const userInfoFontSize = "sm";
const checkboxTooltipOpenDelay = 800;
const checkboxTooltipCloseDelay = 50;
const checkboxTooltipFontSize = "sm";

export const OrganizationModalInformationInput = ({
  showCustom,
  handleNameChange,
  setEmailAddress,
}: {
  showCustom: boolean;
  handleNameChange: (
    field: "firstName" | "lastName"
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  setEmailAddress: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const userInfo = useAppSelector(selectOrganizationMemberInfo);

  return (
    <HStack align="start">
      <VStack align="left">
        <Text>Basic Information</Text>
        <HStack spacing="2px">
          <Tooltip
            fontSize={checkboxTooltipFontSize}
            openDelay={checkboxTooltipOpenDelay}
            closeDelay={checkboxTooltipCloseDelay}
            label={
              <Stack mt="4px" mb="8px">
                <Text>A valid email address is required.</Text>
              </Stack>
            }
          >
            <Text fontSize={userInfoFontSize}>Email Address:</Text>
          </Tooltip>
          <Text fontSize={userInfoFontSize} textColor="red.500">
            *
          </Text>
        </HStack>
        <Input
          data-test-id="email-input"
          placeholder="New User Email Address"
          size="sm"
          w="280px"
          value={userInfo.user.email}
          onChange={setEmailAddress}
        ></Input>
        <Tooltip
          fontSize={checkboxTooltipFontSize}
          openDelay={checkboxTooltipOpenDelay}
          closeDelay={checkboxTooltipCloseDelay}
          label={
            <Stack mt="4px" mb="8px">
              <Text>
                If known, please provide the user&apos;s first name. The user
                can override this setting
              </Text>
            </Stack>
          }
        >
          <Text fontSize={userInfoFontSize}>First Name:</Text>
        </Tooltip>
        <Input
          placeholder="New User First Name"
          size="sm"
          w="280px"
          value={userInfo.user.firstName}
          onChange={handleNameChange("firstName")}
        ></Input>
        <Tooltip
          fontSize={checkboxTooltipFontSize}
          openDelay={checkboxTooltipOpenDelay}
          closeDelay={checkboxTooltipCloseDelay}
          label={
            <Stack mt="4px" mb="8px">
              <Text>
                If known, please provide the user&apos;s last name. The user can
                override this setting.
              </Text>
            </Stack>
          }
        >
          <Text fontSize={userInfoFontSize}>Last Name:</Text>
        </Tooltip>
        <Input
          placeholder="New User Last Name"
          size="sm"
          w="280px"
          value={userInfo.user.lastName}
          onChange={handleNameChange("lastName")}
        ></Input>
        <Spacer />
      </VStack>
      <Spacer />
      <VStack>
        <VStack spacing={2} align="left">
          <Text>Permission Detail</Text>
          {permissionDetails.map((p, i) => (
            <OrganizationModalPermissionDetail
              showCustom={showCustom}
              permission={p}
              key={i}
            />
          ))}
        </VStack>
      </VStack>
    </HStack>
  );
};
