import { useAPIRequest } from "../hooks/useAPI";

export const useJobAPI = () => {
  const sendAPIRequest = useAPIRequest();
  const postJob = async ({
    fileId,
    testParams,
  }: {
    fileId: string;
    testParams: any;
  }) => {
    return await sendAPIRequest(`jobs`, "POST", { fileId, testParams });
  };

  const getJobById = async (jobId: string) => {
    return await sendAPIRequest(`jobs/${jobId}`);
  };

  return {
    postJob,
    getJobById,
  };
};
