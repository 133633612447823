import {
  Flex,
  Text,
  HStack,
  Input,
  VStack,
  Checkbox,
  InputGroup,
  Icon,
  InputLeftElement,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "./Spacer";
import BeenhereOutlined from "@mui/icons-material/BeenhereOutlined";
import { AttestationInstance } from "../types";
import "@fontsource/birthstone";
import { AttestationContent } from "src/types/form.types";
import { AttestationDate } from "./AttestationDate";
import React from "react";

interface AttestationRowProps {
  index: number;
  attestation: AttestationInstance;
  onUpdateAttestation?: (
    index: number,
    field: string,
    newValue: AttestationInstance[keyof AttestationInstance]
  ) => void;
  elementIsEditable: boolean;
  value: AttestationContent;
}

export const AttestationRow = ({
  index,
  attestation,
  onUpdateAttestation,
  elementIsEditable,
  value,
}: AttestationRowProps) => {
  const handleUpdateAttestation = React.useCallback(
    (
      index: number,
      field: string,
      newValue: AttestationInstance[keyof AttestationInstance]
    ) => {
      if (onUpdateAttestation) {
        onUpdateAttestation(index, field, newValue);
      }
    },
    [onUpdateAttestation]
  );

  const handleOnChange = React.useCallback(
    (field: string) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUpdateAttestation(index, field, e.target.value);
      };
    },
    [handleUpdateAttestation, index]
  );

  const handleOnCheck = React.useCallback(
    (field: string) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUpdateAttestation(index, field, e.target.checked);
      };
    },
    [handleUpdateAttestation, index]
  );

  return (
    <div key={index}>
      {value.attestationType === "eSignature" ? (
        <HStack>
          <Flex mb={2} gap={0}>
            <InputGroup>
              <InputLeftElement>
                <Icon as={BeenhereOutlined} color={"red.600"} />
              </InputLeftElement>
              <Input
                readOnly={!elementIsEditable}
                placeholder="Signature"
                onChange={handleOnChange("signature")}
                variant="flushed"
                style={{ fontFamily: "birthstone", fontSize: 24 }}
                value={attestation.signature}
              />
            </InputGroup>
          </Flex>
          <AttestationDate
            index={index}
            attestation={attestation}
            elementIsEditable={elementIsEditable}
            updateAttestation={handleUpdateAttestation}
          />
        </HStack>
      ) : (
        <HStack alignItems={"center"} justifyContent={"space-between"}>
          <HStack mb={2}>
            <Icon as={BeenhereOutlined} color={"red.600"} />
            <Checkbox
              colorScheme="brightblue"
              defaultChecked={attestation.signed}
              onChange={handleOnCheck("signed")}
            />
            <Text>I attest to the above statement.</Text>
          </HStack>
          <AttestationDate
            index={index}
            attestation={attestation}
            elementIsEditable={elementIsEditable}
            updateAttestation={handleUpdateAttestation}
          />
        </HStack>
      )}
      <HStack>
        {value.collectOrganization ? (
          <VStack gap={0} alignItems={"flex-start"}>
            <Text>Organization</Text>
            <Input
              _placeholder={{ textColor: "gray.600" }}
              placeholder={"Organization Name"}
              value={attestation.organization}
              onChange={handleOnChange("organization")}
            />
          </VStack>
        ) : null}
        {value.collectTitle ? (
          <VStack gap={0} alignItems={"flex-start"}>
            <Text>Title</Text>
            <Input
              _placeholder={{ textColor: "gray.600" }}
              placeholder={"Your Title"}
              value={attestation.title}
              onChange={handleOnChange("title")}
            />
          </VStack>
        ) : null}
      </HStack>
      <Spacer y={SpacerSizes.md} />
    </div>
  );
};
