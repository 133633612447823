import { SideNavItem } from "./SideNavItems";
import { FiInbox, FiEdit } from "react-icons/fi";
import { LuClipboardList } from "react-icons/lu";

export const navItems: SideNavItem[] = [
  // hide this until we want to re-enable
  // { icon: FiInbox, label: "Todo", to: "/" },
  { icon: FiEdit, label: "Forms", to: "/forms" },
  { icon: LuClipboardList, label: "Submissions", to: "/submissions" },
];
