import {
  Center,
  Heading,
  Flex,
  Card,
  CardBody,
  Button,
  Spacer as FlexSpacer,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { useNavigate } from "react-router-dom";
import React from "react";
import { ActiveFormsSection } from "src/components/ActiveFormsSection";
import { EmptyFormsSection } from "./EmptyFormsSection";
import { Colors } from "src/theme/colors";
import { getEmptySection } from "src/util/element.utils";
import { useAppSelector } from "src/store";
import { selectCreateOrCopyForms } from "src/store/selectors/users.selector";
import {
  useCreateFormMutation,
  useGetFormsQuery,
} from "src/services/forms.service";
import { ArchivedFormsSection } from "./ArchivedFormsSection";
import { Header } from "src/lib/components/Header";

export const Forms = () => {
  const navigate = useNavigate();
  const [createForm] = useCreateFormMutation();
  const createOrCopyForms = useAppSelector(selectCreateOrCopyForms);
  const { data: activeForms } = useGetFormsQuery({
    archived: false,
  });
  const { data: archivedForms } = useGetFormsQuery({
    archived: true,
  });

  const createFormAndGo = React.useCallback(async () => {
    const formContent: any = {
      meta: {
        title: "Untitled Form",
        description: "",
        privileged: false,
      },
      elements: [
        {
          ...getEmptySection(null),
          titleNumber: "1.",
          pathIndex: [0],
        },
      ],
      commentThreadsDictionary: {},
    };
    const newForm = await createForm(formContent).unwrap();
    navigate(`/form-builder/${newForm.id}`);
  }, [createForm, navigate]);

  return (
    <div style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "5%" }}>
      <Flex>
        <Center>
          <Header data-test-id="forms-page-header-text">Manage Forms</Header>
        </Center>
        <FlexSpacer />
        {createOrCopyForms && (
          <Button data-test-id="new-form-btn" onClick={createFormAndGo}>
            New Form
          </Button>
        )}
      </Flex>
      <Spacer y={SpacerSizes.sm} />
      <Tabs>
        <TabList>
          <Tab data-test-id="forms-active-tab">Active</Tab>
          <Tab data-test-id="forms-archived-tab">Archived</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {activeForms ? (
              <ActiveFormsSection
                tableDataTestId={"active-forms-table"}
                forms={activeForms}
              />
            ) : (
              <EmptyFormsSection />
            )}
          </TabPanel>
          <TabPanel>
            {archivedForms ? (
              <ArchivedFormsSection
                tableDataTestId={"archived-forms-table"}
                forms={archivedForms}
              />
            ) : (
              <Card style={{ cursor: "pointer" }}>
                <CardBody
                  style={{
                    backgroundColor: "white",
                    border: "dashed",
                    borderWidth: "1px",
                    borderColor: Colors.brand[500],
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Heading variant="h2" color="brand.500" mt="6px" mb="6px">
                    You have no archived Forms.
                  </Heading>
                  <Spacer x={SpacerSizes.md} />
                </CardBody>
              </Card>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Spacer y={SpacerSizes.xl} />
    </div>
  );
};
