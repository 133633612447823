import React from "react";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";

import { Colors } from "../theme/colors";

const PrivilegeEnabledIcon = VerifiedUserOutlinedIcon;
const PrivilegeWarningIcon = GppMaybeOutlinedIcon;
const PrivilegeDisabledIcon = GppBadOutlinedIcon;

interface PrivilegeIconButtonProps {
  currentDocumentIsPrivileged: boolean;
  currentDocumentPrivilegeWarningsIgnored: boolean;
  onClick: () => void;
}

export const PrivilegeIconButton = ({
  currentDocumentIsPrivileged,
  currentDocumentPrivilegeWarningsIgnored,
  onClick,
}: PrivilegeIconButtonProps) => {
  const { Icon, color } = React.useMemo(() => {
    let Icon = PrivilegeDisabledIcon;
    let color = Colors.white[500];

    if (currentDocumentIsPrivileged) {
      Icon = PrivilegeEnabledIcon;
    } else if (currentDocumentPrivilegeWarningsIgnored) {
      Icon = PrivilegeDisabledIcon;
    } else {
      Icon = PrivilegeWarningIcon;
      color = Colors.red[500];
    }

    return { Icon, color };
  }, [currentDocumentIsPrivileged, currentDocumentPrivilegeWarningsIgnored]);

  return (
    <Icon
      data-test-id="privilege-icon-button"
      sx={{ color }}
      onClick={onClick}
    />
  );
};
