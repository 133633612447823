import { useNavigate } from "react-router-dom";
import {
  Center,
  HStack,
  Text,
  Spinner,
  Heading,
  Button,
} from "@chakra-ui/react";
import { AuthenticatedPage } from "src/components/AuthenticatedPage";
import { HEADER_HEIGHT } from "src/components/LuminosNavBar";
import { useGetFormsQuery } from "src/services/forms.service";
import { CreateSubmission } from "src/components/CreateSubmission";

export const CreateSubmissionDataPage = ({ id }: { id: string }) => {
  const navigate = useNavigate();

  const { data, isLoading, error } = useGetFormsQuery({});
  const form = data?.find((form) => form._id === id);

  return (
    <AuthenticatedPage>
      <div style={{ marginTop: HEADER_HEIGHT }}>
        <Center>
          <div style={{ marginTop: "5%", maxWidth: "800px" }}>
            {isLoading ? (
              <HStack>
                <Text variant="bold">Loading form...</Text>
                <Spinner />
              </HStack>
            ) : error || !form?.readOnly.actions?.submit ? (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Heading size="md">Form Not Found</Heading>
                <Button
                  style={{ marginTop: 10 }}
                  onClick={() => navigate("/forms")}
                >
                  Cancel
                </Button>
              </div>
            ) : data && id ? (
              <CreateSubmission form={form} id={id} />
            ) : null}
          </div>
        </Center>
      </div>
    </AuthenticatedPage>
  );
};
