import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormBuilderDatePage } from "src/components/FormBuilderDataPage";
import { routes } from "src/routes";
import { formsApi } from "src/services/forms.service";
import { useAppDispatch } from "src/store";
import { resetFormState } from "src/store/slices/form.slice";

export const FormBuilderRoutePage = () => {
  const navigate = useNavigate();
  const formId = useParams<{ id: string }>().id;
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    return () => {
      // reset form state when user navigates away
      dispatch(resetFormState(undefined));
      dispatch(formsApi.util.resetApiState());
    };
  }, [dispatch]);

  if (!formId) {
    navigate(routes.Forms.path);
    return null;
  }

  return <FormBuilderDatePage formId={formId} />;
};
