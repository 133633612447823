import { Flex, Icon, Text } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "./Spacer";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { RichTextReadOnly } from "./RichTextReadOnly";
import { RichTextDelta } from "src/types/form.types";

export const SurveyTakerNotes = ({ text }: { text: RichTextDelta }) => {
  return (
    <Flex
      alignContent="flex-start"
      w={"100%"}
      borderLeftColor={"brand.500"}
      borderLeftWidth={7}
      borderRadius={2}
      pt={3}
      pl={5}
      flexDirection="column"
    >
      <Flex flexDirection="row" alignItems={"flex-end"}>
        <Icon as={ErrorOutlineIcon} />
        <Spacer x={SpacerSizes.sm} />
        <Text variant="bold">Notes and Instructions</Text>
      </Flex>
      <RichTextReadOnly value={text} />
    </Flex>
  );
};
