import { Box } from "@mui/material";
import { DeltaStatic, Sources } from "quill";
import React from "react";
import ReactQuill, { ReactQuillProps, UnprivilegedEditor } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RichTextDelta } from "src/types/form.types";

export interface RichTextEditorProps {
  dataTestId?: string;
  placeholder?: ReactQuillProps["placeholder"];
  value: RichTextDelta;
  readOnly?: boolean;
  renderToolbar?: boolean;
  onChange?: (contents: RichTextDelta, source: Sources) => void;
}

export const RichTextEditor = ({
  dataTestId,
  placeholder,
  value,
  readOnly,
  renderToolbar,
  onChange,
}: RichTextEditorProps) => {
  const reactQuillRef = React.useRef<ReactQuill | null>(null);

  React.useEffect(() => {
    if (reactQuillRef.current) {
      const editor = reactQuillRef.current.getEditor();
      editor.setSelection(editor.getLength(), 0);
    }
  }, []);

  const handleChange = React.useCallback(
    (
      html: string,
      delta: DeltaStatic,
      source: Sources,
      editor: UnprivilegedEditor
    ) => {
      if (source == "user" && onChange) {
        const contents = editor.getContents();
        onChange(contents, source);
      }
    },
    [onChange]
  );

  return (
    <Box data-test-id={dataTestId}>
      <ReactQuill
        ref={reactQuillRef}
        theme="snow"
        readOnly={readOnly}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        modules={{
          toolbar: renderToolbar,
        }}
      />
    </Box>
  );
};
