import React from "react";
import { PermissionDetail } from "./OrganizationMembershipModal";
import { Text, Tooltip, Stack } from "@chakra-ui/react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { OrganizationPermissions } from "src/types/organization.types";
import { setOrgPermissions } from "src/store/slices/organization-membership.slice";
import { selectOrganizationMemberInfo } from "src/store/selectors/organization-membership.selectors";
import { useAppDispatch, useAppSelector } from "src/store";
import { Colors } from "src/theme/colors";

export const OrganizationModalPermissionDetail = ({
  permission,
  showCustom,
}: {
  permission: PermissionDetail;
  showCustom: boolean;
}) => {
  const checkboxFontSize = "small";
  const checkboxSize = "small";
  const checkboxTooltipOpenDelay = 800;
  const checkboxTooltipCloseDelay = 50;
  const checkboxTooltipFontSize = "sm";
  const userInfo = useAppSelector(selectOrganizationMemberInfo);
  const dispatch = useAppDispatch();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        permission.variableName === "manageOrgPermissions" &&
        event.target.checked
      ) {
        dispatch(
          setOrgPermissions({
            ...userInfo.currentOrgMembership.permissions,
            manageOrgPermissions: true,
            manageOrgSettings: true,
            inviteUser: true,
            seeAllMembers: true,
            seeAllGuests: true,
          })
        );
      } else {
        dispatch(
          setOrgPermissions({
            ...userInfo.currentOrgMembership.permissions,
            [permission.variableName]: event.target.checked,
          })
        );
      }
    },
    [permission.variableName, userInfo, dispatch]
  );

  return (
    <>
      <FormControlLabel
        label={
          <Tooltip
            fontSize={checkboxTooltipFontSize}
            openDelay={checkboxTooltipOpenDelay}
            closeDelay={checkboxTooltipCloseDelay}
            label={
              <Stack mt="4px" mb="8px">
                {permission.helpText}
              </Stack>
            }
          >
            <Text fontSize={checkboxFontSize}>{permission.text}</Text>
          </Tooltip>
        }
        control={
          <Checkbox
            sx={{
              "&.Mui-checked": {
                color: Colors.brightblue[600],
              },
              "&.Mui-disabled": {
                color: Colors.gray[400],
              },
            }}
            data-test-id={`permission-${permission.variableName}`}
            style={{ padding: 3 }}
            disabled={!showCustom}
            checked={
              userInfo.currentOrgMembership.permissions[
                permission.variableName as keyof OrganizationPermissions
              ] as boolean
            }
            size={checkboxSize}
            onChange={handleChange}
          />
        }
      />
    </>
  );
};
