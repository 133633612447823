// A container where our sidenav will be rendered along with the main content.
import { ReactNode, ReactElement } from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { HideIfNoAuth } from "../HideIfNoAuth";
import { TOPNAV_Z_INDEX } from "../LuminosNavBar";

export interface SideNavContainerProps {
  children: ReactNode;
  sideNav: ReactElement;
}

export function SideNavContainer({ children, sideNav }: SideNavContainerProps) {
  return (
    <Grid templateAreas={`'sidebar main'`} templateColumns="auto 1fr">
      <HideIfNoAuth>
        <GridItem area="sidebar" as="aside" w="full">
          <Box
            pos="fixed"
            top={"0px"}
            w={{ base: 0, md: "52px" }}
            borderRight="1px solid"
            borderColor="gray.100"
            height={"100%"}
            paddingTop={"60px"}
            bg="gray.800"
            zIndex={`calc(${TOPNAV_Z_INDEX} - 1)`}
          >
            {sideNav}
          </Box>
        </GridItem>
      </HideIfNoAuth>

      {children}
    </Grid>
  );
}

export default SideNavContainer;
