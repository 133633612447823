import { Card, CardBody, Heading, Spinner } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../../components/Spacer";
import { Colors } from "../../theme/colors";

export const LoadingSubmissions = () => {
  return (
    <Card>
      <CardBody
        style={{
          backgroundColor: "white",
          border: "dashed",
          borderWidth: "1px",
          borderColor: Colors.brand[500],
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner mr={2} />
        <Heading variant="h2" color="brand.500">
          Loading submissions...
        </Heading>
        <Spacer x={SpacerSizes.md} />
      </CardBody>
    </Card>
  );
};
