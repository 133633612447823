import React from "react";

import { PrivilegeIconButton } from "./PrivilegeIconButton";
import { FormPrivilegeConfigModal } from "./FormPrivilegeConfigModal";

interface FormPrivilegeActionProps {
  formId: string;
  displayPrivilegeBanner: boolean;
  setDisplayPrivilegeBanner: (x: boolean) => void;
  privilegeWarningDismissed: boolean;
  setDisplayPrivilegeConfirmation: (x: boolean) => void;
}

export function FormPrivilegeAction({
  formId,
  displayPrivilegeBanner,
  setDisplayPrivilegeBanner,
  privilegeWarningDismissed,
  setDisplayPrivilegeConfirmation,
}: FormPrivilegeActionProps) {
  const [currentDocumentIsPrivileged, setCurrentDocumentIsPrivileged] =
    React.useState(displayPrivilegeBanner);
  const [
    currentDocumentPrivilegeWarningsIgnored,
    setCurrentDocumentIgnorePrivilegeWarnings,
  ] = React.useState(privilegeWarningDismissed);
  const [displayPrivilegeConfig, setDisplayPrivilegeConfig] =
    React.useState(false);

  const handlePrivilegeIconClick = React.useCallback(() => {
    setDisplayPrivilegeConfig(true);
  }, []);

  return (
    <React.Fragment>
      <PrivilegeIconButton
        currentDocumentIsPrivileged={currentDocumentIsPrivileged}
        currentDocumentPrivilegeWarningsIgnored={
          currentDocumentPrivilegeWarningsIgnored
        }
        onClick={handlePrivilegeIconClick}
      />
      <FormPrivilegeConfigModal
        formId={formId}
        setCurrentDocumentIsPrivileged={setCurrentDocumentIsPrivileged}
        setDisplayPrivilegeBanner={setDisplayPrivilegeBanner}
        isOpen={displayPrivilegeConfig}
        onClose={() => setDisplayPrivilegeConfig(false)}
        setDisplayPrivilegeConfirmation={setDisplayPrivilegeConfirmation}
        setCurrentDocumentIgnorePrivilegeWarnings={
          setCurrentDocumentIgnorePrivilegeWarnings
        }
        currentDocumentPrivilegeWarningsIgnored={
          currentDocumentPrivilegeWarningsIgnored
        }
        currentDocumentIsPrivileged={currentDocumentIsPrivileged}
      />
    </React.Fragment>
  );
}
