import React from "react";
import { useGetUserQuery } from "../services/user.service";
import { useAppDispatch } from "../store";
import { setCurrentOrganizationId } from "../store/slices/user.slice";
import { useGetOrganizationMembersQuery } from "src/services/organization.service";
import { setMentionableOrganizationMembers } from "src/store/slices/organization.slice";

interface UseAppInitParams {
  targetOrganizationId: string | null;
}

export const useAppInit = ({ targetOrganizationId }: UseAppInitParams) => {
  const dispatch = useAppDispatch();
  const {
    data: userContext,
    error: userQueryError,
    isLoading: userQueryIsLoading,
  } = useGetUserQuery({ targetOrganizationId });

  // org members call is non blocking to the app
  const { data: organizationMembers, error: organizationMembersQueryError } =
    useGetOrganizationMembersQuery({ targetOrganizationId });

  React.useEffect(() => {
    if (userContext) {
      if (userContext.currentOrgMembership.organization?._id) {
        dispatch(
          setCurrentOrganizationId(
            userContext.currentOrgMembership.organization._id
          )
        );
      }
    }
  }, [userContext, dispatch]);

  React.useEffect(() => {
    if (organizationMembers) {
      dispatch(setMentionableOrganizationMembers(organizationMembers));
    }
  }, [organizationMembers, dispatch]);

  return {
    userContext,
    error: userQueryError || organizationMembersQueryError,
    isLoading: userQueryIsLoading,
  };
};
