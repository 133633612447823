import { MentionableUser } from "../../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OrganizationState {
  mentionableMembers: MentionableUser[];
}

const initialState: OrganizationState = {
  mentionableMembers: [],
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setMentionableOrganizationMembers: (
      state,
      action: PayloadAction<MentionableUser[]>
    ) => {
      state.mentionableMembers = action.payload;
    },
    resetOrganizationState: (state) => {
      state.mentionableMembers = [];
    },
  },
});

export const { setMentionableOrganizationMembers, resetOrganizationState } =
  organizationSlice.actions;
