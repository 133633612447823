import React from "react";
import { Fonts } from "../theme/fonts";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "../theme";

export const BasePage = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <ChakraProvider cssVarsRoot="body" theme={theme}>
      <Fonts />
      {children}
    </ChakraProvider>
  );
};
