import React from "react";
import { useAppSelector } from "../store";

export const useAPIRequest = () => {
  const { userContext, currentOrganizationId } = useAppSelector(
    (state) => state.user
  );

  const sendAPIRequest = React.useCallback(
    async (
      route: string,
      method: "GET" | "POST" | "COPY" | "PUT" | "PATCH" | "DELETE" = "GET",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      body?: { [key: string]: any },
      organizationIdOverride?: string
    ) => {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Organization: organizationIdOverride ?? currentOrganizationId ?? "",
        User: userContext?.user?._id ?? "",
      };

      const options: RequestInit = {
        method,
        headers,
      };

      if (body) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL!}/${route}`,
        options
      );
      const data = await response.json();
      return data;
    },
    [userContext?.user?._id, currentOrganizationId]
  );

  return sendAPIRequest;
};
