import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import React from "react";
import { nanoid } from "nanoid";

interface SelectOption<
  V extends string | number | readonly string[] | undefined,
> {
  value: V;
  label?: string | React.ReactNode;
  menuItemProps?: Omit<MenuItemProps, "value">;
}

export interface SelectInputProps<
  V extends string | number | readonly string[] | undefined,
> {
  label?: string;
  name: string;
  value: V;
  onChange?: (event: SelectChangeEvent<V>, child: React.ReactNode) => void;
  options: SelectOption<V>[];
  fullWidth?: boolean;
  disabled?: boolean;
  helperText?: string | React.ReactNode;
  menuProps?: SelectProps["MenuProps"];
  formControlProps?: Omit<FormControlProps, "size">;
  dataTestId?: string;
}

export const SelectInput = <
  V extends string | number | readonly string[] | undefined,
>({
  label,
  name,
  value,
  options,
  fullWidth,
  disabled,
  helperText,
  onChange,
  menuProps,
  formControlProps,
  dataTestId,
}: SelectInputProps<V>) => {
  const uuid = React.useMemo(() => nanoid(8), []);
  return (
    <FormControl
      data-test-id={dataTestId}
      fullWidth={fullWidth}
      disabled={disabled}
      {...formControlProps}
    >
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Select
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        SelectDisplayProps={{
          style: {
            height: "1rem",
            padding: "4px 26px 4px 10px",
          },
        }}
        MenuProps={menuProps}
      >
        {options.map((o) => {
          return (
            <MenuItem
              key={`${o.value}-${uuid}`}
              value={o.value}
              {...o.menuItemProps}
            >
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {o.label}
              </div>
            </MenuItem>
          );
        })}
      </Select>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};
