import React from "react";
import { Text } from "@chakra-ui/react";
import { isSubmissionElementEditable } from "../util/isSubmissionElementEditable";
import { useAppDispatch, useAppSelector } from "src/store";
import { SubmissionElementEditViewProps } from "src/types/submission.types";
import {
  selectSubmissionMeta,
  selectUserRoleOnSubmission,
  lookupSubmissionElementById,
} from "src/store/selectors/submission.selectors";
import { updateSubmissionElement } from "src/store/slices/submission.slice";
import { SubmissionViewHeader } from "./SubmissionViewHeader";
import { RichTextReadOnly } from "./RichTextReadOnly";
import { RichTextEditor } from "./RichTextEditor";
import { RichTextDelta } from "src/types/form.types";
import { Delta } from "src/util/quill.utils";

export const TextResponseSubmissionView = ({
  id,
}: React.PropsWithChildren<SubmissionElementEditViewProps>) => {
  const element = useAppSelector(lookupSubmissionElementById(id));
  const globalUserId = useAppSelector(
    (state) => state.user.userContext?.user._id
  );
  const role = useAppSelector((state) =>
    selectUserRoleOnSubmission(state, globalUserId)
  );
  const meta = useAppSelector(selectSubmissionMeta);
  const dispatch = useAppDispatch();

  const isEditable = isSubmissionElementEditable(element?.content, role, meta);

  const handleOnChange = React.useCallback(
    (e: RichTextDelta) => {
      dispatch(
        updateSubmissionElement({
          elementValue: { ops: e.ops },
          elementId: element?.id,
        })
      );
    },
    [dispatch, element?.id]
  );

  if (element && element.type !== "textResponse") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  if (!element) {
    return null;
  }

  return (
    <div>
      <SubmissionViewHeader
        titleDataTestId={`text-response-title-text-${element?.pathIndex.join("-")}`}
        rules={element.rules}
        title={element.content.title}
        titleNumber={element.titleNumber ?? ""}
        role={role}
        adjudicatorNotes={element.content.adjudicatorNotes}
        surveyTakerNotes={element.content.surveyTakerNotes}
        audience={element.content.audience}
        questionPlaceholder="Enter the question"
        question={element.content.question}
      />
      <Text>Response</Text>
      {isEditable ? (
        <RichTextEditor
          dataTestId="text-response-submission-editor"
          placeholder="Please enter a response"
          value={element.elementValue || new Delta()}
          onChange={handleOnChange}
        />
      ) : (
        <RichTextReadOnly value={element.elementValue} />
      )}
    </div>
  );
};
