import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import {
  CommentThread,
  UserQuery,
  Comment,
  SlateObject,
  MentionableUser,
} from "src/types";
import _ from "lodash";
import { CommentsState } from "../slices/comments.slice";

export const updateConversationReducer: CaseReducer<
  CommentsState,
  PayloadAction<{
    elementId: string;
    commentThreads: CommentThread[];
    commentThread: CommentThread;
    comment: SlateObject;
    resolved: boolean;
    globalUserInfo: UserQuery | null;
    mentionableMembers: MentionableUser[];
    assignedTo?: string[];
    mentioned?: string[];
  }>
> = (state, action) => {
  const {
    elementId,
    commentThreads,
    comment,
    commentThread,
    resolved,
    assignedTo,
    mentioned,
    globalUserInfo,
  } = action.payload;
  if (!globalUserInfo) {
    throw new Error("Global user info is not set");
  }

  const newComment: Comment = {
    user: globalUserInfo.user._id,
    content: comment,
    createdAt: new Date().toISOString(),
    assignedTo,
    mentioned,
    firstName: globalUserInfo.user.firstName,
    lastName: globalUserInfo.user.lastName,
    userEmail: globalUserInfo.user.email,
    pending: false,
  };

  const updatedThreads: CommentThread[] = commentThreads.map(
    (thread: CommentThread) => {
      if (_.isEqual(thread, commentThread)) {
        return {
          ...thread,
          resolved,
          comments: [...thread.comments, newComment],
        };
      } else {
        return thread;
      }
    }
  );

  state.commentThreadsDictionary[elementId] = updatedThreads;
};
