import React from "react";
import {
  Button,
  Heading,
  HStack,
  Input,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  TableContainer,
  Table,
  Tbody,
  Thead,
  Td,
  Th,
  Tr,
  Text,
  Textarea,
  Tooltip,
  Circle,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../components/Spacer";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { UserQuery } from "../types";
import { OrganizationMembership } from "src/types/organization.types";
import { useAppDispatch } from "src/store";
import { setUserContext } from "src/store/slices/user.slice";
import { useUserInitials } from "src/hooks/useUserInitials";

interface UserProfileProps {
  userContext: UserQuery;
}

export const UserProfile = ({ userContext }: UserProfileProps) => {
  const [userInfo, setUserInfo] = React.useState<UserQuery>(userContext);
  const dispatch = useAppDispatch();
  const initials = useUserInitials();

  const setFirstName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!userInfo) return;
      setUserInfo({
        ...userInfo,
        user: {
          ...userInfo.user,
          firstName: event.target.value,
        },
      });
    },
    [userInfo, setUserInfo]
  );

  const setLastName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!userInfo) return;
      const newUserInfo = {
        ...userInfo,
        user: {
          ...userInfo.user,
          lastName: event.target.value,
        },
      };
      setUserInfo(newUserInfo);
    },
    [userInfo, setUserInfo]
  );

  const updateInitials = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!userInfo) return;
      const newUserInfo = {
        ...userInfo,
        user: {
          ...userInfo.user,
          initials: event.target.value,
        },
      };
      setUserInfo(newUserInfo);
    },
    [userInfo, setUserInfo]
  );

  const getRenderedPronouns = React.useCallback(() => {
    if (!userInfo?.user.pronouns) {
      return "-";
    } else {
      return userInfo?.user.pronouns;
    }
  }, [userInfo]);

  const setPronouns = React.useCallback(
    (newValue: string) => {
      if (!userInfo) return;
      if (newValue === "-") {
        setUserInfo({
          ...userInfo,
          user: {
            ...userInfo.user,
            pronouns: null,
          },
        });
      } else {
        setUserInfo({
          ...userInfo,
          user: {
            ...userInfo.user,
            pronouns: newValue,
          },
        });
      }
    },
    [userInfo, setUserInfo]
  );

  const setTitle = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!userInfo) return;
      setUserInfo({
        ...userInfo,
        user: {
          ...userInfo.user,
          title: event.target.value,
        },
      });
    },
    [userInfo, setUserInfo]
  );

  const setOtherContactInformation = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!userInfo) return;
      setUserInfo({
        ...userInfo,
        user: {
          ...userInfo.user,
          otherContactInformation: event.target.value,
        },
      });
    },
    [userInfo, setUserInfo]
  );

  React.useEffect(() => {
    dispatch(setUserContext(userInfo));
  }, [userInfo, userContext.currentOrgMembership.organizationId, dispatch]);

  return (
    <div style={{ justifyContent: "center", width: "100%", display: "flex" }}>
      <div
        style={{
          paddingLeft: "2.5%",
          maxWidth: "900px",
          marginTop: "4%",
          marginBottom: "4%",
        }}
      >
        {
          <>
            <Heading variant="h2">Account Settings</Heading>
            <Spacer y={SpacerSizes.lg} />

            {/* Avatar (initials) Circle */}
            <Grid
              templateRows="repeat(8, 1fr)"
              templateColumns="repeat(5, 1fr)"
              gap="3"
              alignItems="center"
            >
              <GridItem rowSpan={10} colSpan={1} alignSelf="start">
                <HStack>
                  <Circle
                    size="84px"
                    bg="brightblue.200"
                    marginLeft="auto"
                    mt="10px"
                  >
                    <Text fontWeight="bold">
                      {userInfo.user.initials || initials}
                    </Text>
                  </Circle>
                </HStack>
              </GridItem>

              {/* Row for First Name Entry */}
              <GridItem colSpan={1} rowSpan={1}>
                <Text align="right">First Name</Text>
              </GridItem>
              <GridItem colSpan={3} rowSpan={1}>
                <Input
                  placeholder="First Name"
                  size="md"
                  value={userInfo.user.firstName}
                  onChange={setFirstName}
                ></Input>
              </GridItem>

              {/* Row for Last Name Entry */}
              <GridItem colSpan={1} rowSpan={1}>
                <Text align="right">Last Name</Text>
              </GridItem>
              <GridItem colSpan={3} rowSpan={1}>
                <Input
                  placeholder="Last Name"
                  size="md"
                  value={userInfo.user.lastName}
                  onChange={setLastName}
                ></Input>
              </GridItem>

              {/* Row for Initials Entry */}
              <GridItem colSpan={1} rowSpan={1}>
                <Text align="right">Initials</Text>
              </GridItem>
              <GridItem colSpan={1} rowSpan={1}>
                <Tooltip label="Three character maximum." fontSize="md">
                  <Input
                    placeholder="Initials"
                    size="md"
                    value={userInfo.user.initials}
                    onChange={updateInitials}
                  ></Input>
                </Tooltip>
              </GridItem>
              <GridItem colSpan={2} rowSpan={1}></GridItem>

              {/* Row for Pronouns Entry */}
              <GridItem colSpan={1} rowSpan={1}>
                <Text align="right">Pronouns</Text>
              </GridItem>
              <GridItem colSpan={1} rowSpan={1}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ExpandMoreOutlinedIcon />}
                    variant="gray_dropdown"
                    value={
                      userInfo?.user.pronouns ? userInfo.user.pronouns : ""
                    }
                    justifyItems={"flex-start"}
                  >
                    {getRenderedPronouns()}
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => setPronouns("-")}>-</MenuItem>
                    <MenuItem onClick={() => setPronouns("She/Her")}>
                      She/Her
                    </MenuItem>
                    <MenuItem onClick={() => setPronouns("He/Him")}>
                      He/Him
                    </MenuItem>
                    <MenuItem onClick={() => setPronouns("They/Them")}>
                      They/Them
                    </MenuItem>
                  </MenuList>
                </Menu>
              </GridItem>
              <GridItem colSpan={2} rowSpan={1}></GridItem>

              {/* Row for Initials Entry */}
              <GridItem colSpan={1} rowSpan={1}>
                <Text align="right">Title</Text>
              </GridItem>
              <GridItem rowSpan={1} colSpan={3}>
                <Input
                  placeholder="Title"
                  size="md"
                  value={userInfo?.user?.title}
                  onChange={setTitle}
                ></Input>
              </GridItem>

              {/* Row for Email Address Entry */}
              <GridItem colSpan={1} rowSpan={1}>
                <Text align="right">Email Address</Text>
              </GridItem>
              <GridItem colSpan={3} rowSpan={1}>
                <Tooltip
                  label="Email Address changes require assistance from Luminos.AI support and may cause you to lose access to certain organizations."
                  fontSize="md"
                >
                  <Input
                    placeholder="Email Address"
                    size="md"
                    isDisabled={true}
                    value={userInfo?.user?.email}
                  ></Input>
                </Tooltip>
              </GridItem>

              {/* Row for User ID Display */}
              <GridItem colSpan={1} rowSpan={1}>
                <Tooltip
                  label="Your User ID is not editable, but may be useful should you need to communicate with Luminos.AI support."
                  fontSize="md"
                >
                  <Text align="right">User ID</Text>
                </Tooltip>
              </GridItem>
              <GridItem colSpan={3} rowSpan={1}>
                <Text color="gray.500">{userInfo?.user._id}</Text>
              </GridItem>

              {/* TODO allow for rich text here so that we can support hyperlinks */}
              {/* Row for Other Contact Information */}
              <GridItem colSpan={1} rowSpan={1}>
                <Text align="right">Other Contact Information</Text>
              </GridItem>
              <GridItem colSpan={3} rowSpan={3}>
                <Textarea
                  onChange={setOtherContactInformation}
                  value={userInfo.user.otherContactInformation}
                />
              </GridItem>
            </Grid>
          </>
        }

        <Spacer y={SpacerSizes.xl} />
        {
          <>
            <Heading variant="h2">Organization Memberships</Heading>
            <Spacer y={SpacerSizes.md} />

            {/* Header Row */}
            <TableContainer>
              <Table variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Membership</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userInfo?.orgMemberships.map(
                    (organization: OrganizationMembership, index: number) => (
                      <Tr key={index}>
                        <Td>
                          <Text>{organization.name}</Text>
                        </Td>

                        <Td>
                          <Text>{organization.membershipType}</Text>
                        </Td>
                        <Td></Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        }
      </div>
    </div>
  );
};
