import React from "react";
import { Text, HStack, Icon, InputGroup, Tooltip } from "@chakra-ui/react";
import { AudienceSelection } from "./AudienceSelection";
import AutoWidthInput from "./AutoWidthInput";
import RuleFolderOutlinedIcon from "@mui/icons-material/RuleFolderOutlined";
import { ServerRule } from "../types/rule.types";
import { useAppDispatch } from "src/store";
import { toggleIsRulesDrawerOpen } from "src/store/slices/form.slice";

interface BuildHeaderProps {
  title: string;
  titleNumber: string;
  audience: string;
  questionPrompt: string;
  titlePlaceholder: string;
  fixedAudience?: boolean;
  rules: ServerRule[];
  id: string;
  titleDataTestId?: string;
  onTitleChange?: (title: string) => void;
  onAudienceChange?: (audience: string) => void;
}

export const BuildViewHeader = ({
  title,
  titlePlaceholder,
  titleNumber,
  audience,
  questionPrompt,
  titleDataTestId,
  rules,
  onAudienceChange,
  onTitleChange,
}: BuildHeaderProps) => {
  const dispatch = useAppDispatch();

  const handleRulesIconClick = React.useCallback(() => {
    dispatch(toggleIsRulesDrawerOpen(null));
  }, [dispatch]);

  return (
    <>
      <HStack justifyContent={"space-between"} gap={1}>
        <HStack style={{ width: "100%" }}>
          <Text>{titleNumber}</Text>
          <InputGroup width="fit-content" style={{ width: "100%" }}>
            <AutoWidthInput
              dataTestId={titleDataTestId}
              value={title}
              placeholder={titlePlaceholder}
              onChange={onTitleChange}
            />
          </InputGroup>
        </HStack>
        <HStack>
          {rules && rules.length ? (
            <Tooltip label="A rule is applied to this element. Click to view details.">
              <Icon
                onClick={handleRulesIconClick}
                boxSize={5}
                as={RuleFolderOutlinedIcon}
              />
            </Tooltip>
          ) : null}
          <AudienceSelection
            audience={audience}
            changeAudience={onAudienceChange}
          />
        </HStack>
      </HStack>
      <Text>{questionPrompt}</Text>
    </>
  );
};
