import { Grid } from "@mui/material";
import { Text, Heading, VStack, Button } from "@chakra-ui/react";
import { EnrichedFormContentless, MembershipType } from "src/types";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store";
import { submissionsApi } from "src/services/submission.service";
import { getEmailsNewToOrganization } from "src/util/form.utils";
import { selectFormReadOnly } from "src/store/selectors/form.selectors";
import { organizationApi } from "src/services/organization.service";

export const CreateSubmission = ({
  form,
  id,
}: {
  form: EnrichedFormContentless;
  id: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mentionableMembers = useAppSelector(
    (state) => state.organization.mentionableMembers
  );
  const usersInfo = useAppSelector(selectFormReadOnly)?.usersInfo;

  async function createSubmission() {
    const userIds = mentionableMembers?.map((member) => member.key) ?? [];
    const emailAddresses = usersInfo?.map((user) => user.email);
    const newEmails = getEmailsNewToOrganization(
      mentionableMembers,
      emailAddresses
    );
    if (newEmails) {
      const newUserIds = await Promise.all(
        newEmails.map(async (address: string | undefined) => {
          if (!address) {
            return;
          }
          const userInfo = {
            firstName: "",
            lastName: "",
            email: address.trim(),
            orgMembership: {
              membershipType: "guest" as MembershipType,
              permissions: {
                inviteUser: true,
                manageOrgPermissions: false,
                manageOrgSettings: false,
                manageOrgTags: false,
                manageLegalPrivilege: false,
                manageAllGroups: false,
                seeAllGroups: false,
                createOrCopyForms: false,
                createGroups: false,
                seeAllMembers: false,
                seeAllGuests: false,
              },
            },
          };
          const res = await dispatch(
            organizationApi.endpoints.inviteUserToOrganization.initiate({
              userInfo,
            })
          );
          if (res.data?.userId) {
            return res.data.userId;
          }
        })
      );

      newUserIds.forEach((userId) => {
        if (userId) {
          userIds.push(userId);
        }
      });
    }

    const body = {
      formId: id,
      submitters: [],
    };
    const response = await dispatch(
      submissionsApi.endpoints.createSubmission.initiate(body)
    );
    if (response.error) {
      return;
    }

    navigate(`/submission-editor/${response.data.id}`);
  }

  return (
    <VStack alignItems={"flex-start"} gap={1}>
      <Heading size="md">Create Submission</Heading>
      <Text size="md">Create submission of &quot;{form?.meta.title}&quot;</Text>
      {form?.meta.description ? (
        <Text variant="caption" size="md">
          Description: {form.meta.description}
        </Text>
      ) : null}
      <Grid flexDirection={"row"} style={{ marginTop: 45, marginBottom: 5 }}>
        <Button variant="secondary" onClick={() => navigate("/forms")}>
          Cancel
        </Button>
        <Button onClick={createSubmission}>Create Submission</Button>
      </Grid>

      <Text variant="caption">
        You&apos;ll be able to add collaborators after creating the submission.
      </Text>
    </VStack>
  );
};
