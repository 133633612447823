import { RichTextDelta } from "src/types/form.types";
import { Delta } from "src/util/quill.utils";
import ReactQuill from "react-quill";
import { Box } from "@mui/material";

interface RichTextPreviewProps {
  placeholder?: string;
  value: RichTextDelta | null;
}

export const RichTextReadOnly = ({
  placeholder,
  value,
}: RichTextPreviewProps) => {
  return (
    <Box
      sx={{
        ".ql-container.ql-snow": { border: "none" },
        ".ql-editor": { paddingLeft: 0, paddingTop: 0 },
        ".ql-editor.ql-blank:before": {
          left: 0,
        },
      }}
    >
      <ReactQuill
        theme="snow"
        readOnly={true}
        placeholder={placeholder}
        value={value || new Delta()}
        modules={{
          toolbar: false,
        }}
      />
    </Box>
  );
};
