import { Add } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Container,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Rule } from "./RuleEntry";
import { EmptyRuleEntry } from "./EmptyRuleEntry";
import {
  getAllAvailableSubjectElements,
  getAllElementsWithRules,
  transformRuleToServerRule,
} from "src/util/rule.utils";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  selectFormElements,
  selectFormElementsById,
} from "src/store/selectors/form.selectors";
import {
  negativeRuleOperatorMapping,
  RuleOperator,
} from "src/types/rule.types";
import { EditableRuleEntry, EditableRuleEntryProps } from "./EditableRuleEntry";
import { updateFormElement } from "src/store/slices/form.slice";
import { Colors } from "src/theme/colors";

export const RulesBuilder = () => {
  const theme = useTheme();
  const [paperScrolled, setPaperScrolled] = React.useState(false);
  const isMediumAndUpScreen = useMediaQuery(theme.breakpoints.up("md"));

  const elements = useAppSelector(selectFormElements);
  const elementsById = useAppSelector(selectFormElementsById);
  const [pendingRule, setPendingRule] = React.useState<Rule | null>(null);
  const paperRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const paperEl = paperRef.current;
    const handleScroll = () => {
      const scroll = paperRef.current?.scrollTop;
      if (!scroll) {
        return;
      }

      if (scroll > 60) {
        setPaperScrolled(true);
      } else if (paperScrolled === true && scroll <= 60) {
        setPaperScrolled(false);
      }
    };

    paperEl?.addEventListener("scroll", handleScroll);

    return () => paperEl?.removeEventListener("scroll", handleScroll);
  }, [paperScrolled]);

  const allRules = React.useMemo(() => {
    if (elements && elementsById) {
      let rules: Rule[] = [];
      const allElementsWithRules = getAllElementsWithRules({
        elements,
        elementsById,
      });

      allElementsWithRules.forEach((e) => {
        const transformedElementRules: Rule[] = e.rules.map((r) => {
          const predicateVerb =
            typeof r.secondOperand === "boolean"
              ? r.secondOperand === false
                ? negativeRuleOperatorMapping[RuleOperator[r.operator]]
                : RuleOperator[r.operator]
              : RuleOperator[r.operator];
          return {
            // temp solution to identify rules
            subjectElementId: e.id,
            audience: e.content.audience,
            conditionalSubjectElementId: r.firstOperand,
            predicateVerb,
            predicateAdjective:
              typeof r.secondOperand === "string" ? r.secondOperand : null,
          };
        });
        rules = [...rules, ...transformedElementRules];
      });
      return rules;
    }
  }, [elements, elementsById]);

  const { availableElements, availableSubjectElements } = React.useMemo(() => {
    if (elements && elementsById) {
      const availableElements = getAllAvailableSubjectElements({
        currentLevelElements: elements,
        elementsById,
        allowElementsWithRules: true,
        excludeRootElements: false,
        firstEditableElement: { value: null },
      });

      const availableSubjectElements = getAllAvailableSubjectElements({
        currentLevelElements: elements,
        elementsById,
        allowElementsWithRules: false,
        excludeRootElements: true,
        firstEditableElement: { value: null },
      });

      return { availableElements, availableSubjectElements };
    }

    return { availableElements: null, availableSubjectElements: null };
  }, [elements, elementsById]);

  const handleAddRuleClick = React.useCallback(() => {
    setPendingRule({
      subjectElementId: null,
      audience: null,
      conditionalSubjectElementId: null,
      predicateVerb: null,
      predicateAdjective: null,
    });
  }, []);

  const handleSaveRule: EditableRuleEntryProps["onSaveRule"] =
    React.useCallback(
      ({ element, rule }) => {
        const updatedServerRule = transformRuleToServerRule(rule);

        dispatch(
          updateFormElement({
            ...element,
            // @TODO support multiple rules per element
            // this works for now since we only allow one rule per subject
            rules: [updatedServerRule],
          })
        );
      },
      [dispatch]
    );

  const handleRuleCreate = React.useCallback(() => {
    setPendingRule(null);
  }, []);

  const handleDeletePendingRule = React.useCallback(() => {
    setPendingRule(null);
  }, []);

  const handleAudienceChange: EditableRuleEntryProps["onAudienceChange"] =
    React.useCallback(
      ({ element, audience }) => {
        dispatch(
          updateFormElement({
            ...element,
            content: {
              ...element.content,
              audience,
            },
          })
        );
      },
      [dispatch]
    );

  const handleDeleteRule: EditableRuleEntryProps["onDeleteRule"] =
    React.useCallback(
      ({ element }) => {
        dispatch(
          updateFormElement({
            ...element,
            // @TODO support multiple rules per element
            // this works for now since we only allow one rule per subject
            rules: [],
          })
        );
      },
      [dispatch]
    );

  return (
    <Paper
      ref={paperRef}
      elevation={6}
      sx={{
        height: "350px",
        width: "100%",
        backgroundColor: "white",
        padding: "30px",
        marginBottom: "100px",
        overflow: "scroll",
        borderRadius: 0,
        paddingTop: paperScrolled ? 0 : "30px",
        position: "relative",
        zIndex: 100,
      }}
    >
      <Container maxWidth={isMediumAndUpScreen ? "lg" : "xl"}>
        <Typography component="h3" sx={{ fontWeight: "bold" }}>
          Rules
        </Typography>
        <Box
          sx={{
            background: "white",
            padding: paperScrolled ? "50px 0 0 0" : "20px 0 0 0",
            marginBottom: "20px",
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              textAlign: pendingRule ? "left" : "center",
              border: `1px dashed ${Colors.gray[400]}`,
              borderRadius: "4px",
              padding: "10px",
            }}
          >
            {pendingRule ? (
              <EmptyRuleEntry
                onRuleCreate={handleRuleCreate}
                onDeletePendingRule={handleDeletePendingRule}
              />
            ) : (
              <ButtonBase
                data-test-id="add-rule-btn"
                disableRipple
                sx={{ color: Colors.gray[400], fontSize: "0.8rem" }}
                onClick={handleAddRuleClick}
              >
                <Add sx={{ fontSize: "1rem" }} />
                <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                  Add Rule
                </Typography>
              </ButtonBase>
            )}
          </Box>
        </Box>

        <Stack spacing={3}>
          {availableElements
            ? allRules?.map((r, index) => {
                return (
                  <React.Fragment
                    key={`${r.subjectElementId}-${r.conditionalSubjectElementId}`}
                  >
                    <EditableRuleEntry
                      displayNumber={`${index + 1}.`}
                      rule={r}
                      availableElements={availableElements}
                      availableSubjectElements={availableSubjectElements}
                      onAudienceChange={handleAudienceChange}
                      onSaveRule={handleSaveRule}
                      onDeleteRule={handleDeleteRule}
                    />
                  </React.Fragment>
                );
              })
            : null}

          {allRules?.length === 0 ? (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="caption" color={Colors.gray[400]}>
                No rules are set
              </Typography>
            </Box>
          ) : null}
        </Stack>
      </Container>
    </Paper>
  );
};
