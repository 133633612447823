import { Collapse } from "@mui/material";
import { RulesBuilder } from "./RulesBuilder";

interface RulesDrawerProps {
  open: boolean;
}

export const RulesDrawer = ({ open }: RulesDrawerProps) => {
  return <Collapse in={open}>{open && <RulesBuilder />}</Collapse>;
};
