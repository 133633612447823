import {
  Box,
  Checkbox,
  Text,
  Flex,
  HStack,
  VStack,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  ModalBody,
  Spacer,
  Icon,
  Heading,
  Textarea,
} from "@chakra-ui/react";
import {
  EnrichedFormContentless,
  EnrichedSubmissionContentless,
  SlateObject,
  EnrichedForm,
} from "../types";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import RichText from "./RichText";
import { ROLE_1 } from "../roles";
import React from "react";
import { EnrichedSubmission } from "src/types/submission.types";

interface IAddUserModalContent {
  setDisplayAddUserDialog: (x: boolean) => void;
  setEmailAddresses: (x: string[]) => void;
  loadingDocumentInfo: boolean;
  addUsersRole: string;
  type: string;

  setAddUsersRole: (x: string) => void;
  notifyOnShare: boolean;
  setNotifyOnShare: (x: boolean) => void;
  setNotifyOnShareWrapper: (e: React.ChangeEvent<HTMLInputElement>) => void;
  notificationMessage: string;
  setNotificationMessageWrapper: (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleOnChange: (e: SlateObject) => void;
  textInputInitialValue: SlateObject;
  document:
    | EnrichedForm
    | EnrichedFormContentless
    | EnrichedSubmission
    | EnrichedSubmissionContentless;
}

export const SharingModalConfirmAddUser = ({
  setDisplayAddUserDialog,
  setEmailAddresses,
  loadingDocumentInfo,
  addUsersRole,
  type,
  setAddUsersRole,
  notifyOnShare,
  setNotifyOnShareWrapper,
  notificationMessage,
  setNotificationMessageWrapper,
  handleOnChange,
  textInputInitialValue,
  document,
}: IAddUserModalContent) => {
  return (
    <ModalBody bg="white">
      <HStack w="100%">
        <Box>
          <KeyboardBackspaceIcon
            onClick={() => {
              setDisplayAddUserDialog(false);
              setEmailAddresses([]);
            }}
          />
        </Box>
        <Heading size="md" w="100%">
          Share &quot;{document?.meta.title}&quot;
        </Heading>
      </HStack>
      {loadingDocumentInfo ? (
        <Text>loading...</Text>
      ) : (
        <>
          <Flex mt="12px" mb="8px">
            <Box w="100%">
              <div style={{ width: "100%" }}>
                <RichText
                  showToolbar={false}
                  placeholder="type @ to add users"
                  size="fit"
                  value={textInputInitialValue}
                  onChange={(e: SlateObject) => handleOnChange(e)}
                  includeMentions={true}
                />
              </div>
            </Box>
            <Spacer />
            <Box>
              <Menu>
                <MenuButton
                  h="38px"
                  border="1px"
                  ml="12px"
                  mr="12px"
                  mt="4px"
                  pl="8px"
                  borderColor="gray.500"
                  whiteSpace="nowrap"
                >
                  <HStack spacing="2px">
                    <Text>
                      {addUsersRole === "adjudicator"
                        ? "reviewer"
                        : addUsersRole}
                    </Text>
                    <Icon as={ArrowDropDownOutlinedIcon} />
                  </HStack>
                </MenuButton>

                <MenuList>
                  <>
                    {type === "form" &&
                    (document as EnrichedFormContentless).readOnly.actions
                      .becomeAdjudicator ? (
                      <MenuItem onClick={() => setAddUsersRole("owner")}>
                        <Flex w="100%">
                          {addUsersRole === "owner" ? (
                            <Icon as={CheckOutlinedIcon} />
                          ) : null}
                          <Spacer />
                          <Text>owner</Text>
                        </Flex>
                      </MenuItem>
                    ) : null}
                  </>
                  <>
                    {type === "form" &&
                    (document as EnrichedFormContentless).readOnly.actions
                      .edit ? (
                      <MenuItem onClick={() => setAddUsersRole("editor")}>
                        <Flex w="100%">
                          {addUsersRole === "editor" ? (
                            <Icon as={CheckOutlinedIcon} />
                          ) : null}
                          <Spacer />
                          <Text>editor</Text>
                        </Flex>
                      </MenuItem>
                    ) : null}
                  </>
                  <>
                    {type === "submission" &&
                    (document as EnrichedSubmissionContentless).readOnly.actions
                      .assignAdjudicators ? (
                      <MenuItem onClick={() => setAddUsersRole("adjudicator")}>
                        <Flex w="100%">
                          {addUsersRole === "adjudicator" ||
                          addUsersRole === "reviewer" ? (
                            <Icon as={CheckOutlinedIcon} />
                          ) : null}
                          <Spacer />
                          <Text>reviewer</Text>
                        </Flex>
                      </MenuItem>
                    ) : null}
                  </>
                  <MenuItem onClick={() => setAddUsersRole("submitter")}>
                    <Flex w="100%">
                      {addUsersRole === "submitter" ? (
                        <Icon as={CheckOutlinedIcon} />
                      ) : null}
                      <Spacer />
                      <Text>{ROLE_1}</Text>
                    </Flex>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Flex>

          <VStack w="100%">
            <Checkbox
              w="96%"
              isChecked={notifyOnShare}
              onChange={setNotifyOnShareWrapper}
            >
              Notify people
            </Checkbox>
            {notifyOnShare ? (
              <Textarea
                size="lg"
                placeholder="Message for new users"
                value={notificationMessage}
                onChange={(e) => setNotificationMessageWrapper(e)}
              />
            ) : null}
          </VStack>
        </>
      )}
    </ModalBody>
  );
};
