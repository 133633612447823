import { Box, HStack, Icon, Tooltip } from "@chakra-ui/react";
import SyncIcon from "@mui/icons-material/Sync";
import CheckIcon from "@mui/icons-material/Check";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import { SaveStatus } from "src/constants/status.constants";

export const SaveIndicator = ({
  saveStatus,
  savedOnce,
}: {
  saveStatus?: SaveStatus;
  savedOnce?: string;
}) => {
  const iconWH = "22px";
  const iconWHsm = "12px";
  return saveStatus == SaveStatus.inProgress ? (
    <HStack>
      <Tooltip label="Saving..." fontSize="md">
        <div style={{ position: "relative", width: iconWH, height: "36px" }}>
          <div style={{ zIndex: 1, position: "absolute" }}>
            <Icon w={iconWH} h="36px" as={CloudQueueIcon} />
          </div>
          <div style={{ zIndex: 2, position: "absolute" }}>
            <Box w={iconWH} h={iconWH}>
              <Icon
                w={iconWHsm}
                h={iconWHsm}
                ml="4px"
                mt="12px"
                as={SyncIcon}
                sx={{
                  animation: "spin 1s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }}
              />
            </Box>
          </div>
        </div>
      </Tooltip>
    </HStack>
  ) : saveStatus == SaveStatus.saved && savedOnce ? (
    <HStack>
      <Tooltip label="All changes saved." fontSize="md">
        <div style={{ position: "relative", width: iconWH, height: "36px" }}>
          <div style={{ zIndex: 1, position: "absolute" }}>
            <Icon w={iconWH} h="36px" as={CloudQueueIcon} />
          </div>
          <div style={{ zIndex: 2, position: "absolute" }}>
            <Box w={iconWH} h={iconWH}>
              <Icon
                w={iconWHsm}
                h={iconWHsm}
                ml="4px"
                mt="12px"
                as={CheckIcon}
              />
            </Box>
          </div>
        </div>
      </Tooltip>
    </HStack>
  ) : (
    <HStack>
      <Tooltip label="Unsaved. Changes will save soon." fontSize="md">
        <div style={{ position: "relative", width: iconWH, height: "36px" }}>
          <div style={{ zIndex: 1, position: "absolute" }}>
            <Icon w={iconWH} h="36px" as={CloudQueueIcon} />
          </div>
          <div style={{ zIndex: 2, position: "absolute" }}>
            <Box w={iconWH} h={iconWH}>
              <Icon
                w={iconWHsm}
                h={iconWHsm}
                ml="17px"
                mt="0px"
                as={HourglassTopIcon}
              />
            </Box>
          </div>
        </div>
      </Tooltip>
    </HStack>
  );
};
