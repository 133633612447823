import { HStack, Tooltip, useDisclosure } from "@chakra-ui/react";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";

import { CommentFilter } from "src/pages/Comments/CommentFilter";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  selectSubmissionId,
  selectSubmissionMeta,
  selectSubmissionReadOnly,
  selectDisplayPrivilegeBanner,
  selectSubmission,
  selectSubmissionStatus,
} from "src/store/selectors/submission.selectors";
import React from "react";
import {
  setDisplayPrivilegeBanner,
  setDisplayPrivilegeConfirmation,
} from "src/store/slices/submission.slice";
import { FinalizeSubmission } from "./TopNavBar/FinalizeSubmission";
import { SharingModal } from "./Modal/SharingModal";
import { SubmissionPrivilegeAction } from "./SubmissionPrivilegeAction";
import { SaveIndicator } from "./TopNavBar/SaveIndicator";
import { SaveStatus } from "src/constants/status.constants";

export const SubmissionActions = () => {
  const dispatch = useAppDispatch();
  const submissionId = useAppSelector(selectSubmissionId);
  const submission = useAppSelector(selectSubmission);
  const submissionMeta = useAppSelector(selectSubmissionMeta);
  const readOnly = useAppSelector(selectSubmissionReadOnly);
  const submissionStatus = useAppSelector(selectSubmissionStatus);
  const displayPrivilegeBanner = useAppSelector(selectDisplayPrivilegeBanner);
  const {
    isOpen: sharingModalIsOpen,
    onOpen: openSharingModal,
    onClose: closeSharingModal,
  } = useDisclosure();

  const handleSetDisplayPrivilegeBanner = React.useCallback(
    (displayPrivilegeBanner: boolean) => {
      dispatch(setDisplayPrivilegeBanner(displayPrivilegeBanner));
    },
    [dispatch]
  );

  const handleSetDisplayPrivilegeConfirmation = React.useCallback(
    (displayPrivilegeConfirmation: boolean) => {
      dispatch(setDisplayPrivilegeConfirmation(displayPrivilegeConfirmation));
    },
    [dispatch]
  );

  return (
    <HStack m="12px" color="white.500">
      <SaveIndicator
        saveStatus={
          submissionStatus === "saving"
            ? SaveStatus.inProgress
            : submissionStatus === "success"
              ? SaveStatus.saved
              : SaveStatus.unsavedChanges
        }
        savedOnce={submissionId || ""}
      />
      <HStack>
        <Tooltip label="Share Submission" aria-label="Share Submission">
          <PersonAddAlt1OutlinedIcon onClick={openSharingModal} />
        </Tooltip>
        <Tooltip
          label="View and Filter Comments"
          aria-label="View and filter comments"
        >
          {/* div is necessary for the tooltip to display */}
          <div>
            <CommentFilter />
          </div>
        </Tooltip>
        {readOnly?.actions.privilegeApprovals ? (
          <Tooltip label="Manage Privilege Assertion">
            {/* div is necessary for the tooltip to display */}
            <div>
              {submissionId ? (
                <SubmissionPrivilegeAction
                  submissionId={submissionId}
                  displayPrivilegeBanner={displayPrivilegeBanner ?? false}
                  setDisplayPrivilegeBanner={handleSetDisplayPrivilegeBanner}
                  privilegeWarningDismissed={
                    submissionMeta?.privilegeWarningdismissed ?? false
                  }
                  setDisplayPrivilegeConfirmation={
                    handleSetDisplayPrivilegeConfirmation
                  }
                />
              ) : null}
            </div>
          </Tooltip>
        ) : null}

        {["denied", "approved"].includes(submissionMeta?.status ?? "") &&
        submissionId ? (
          <FinalizeSubmission
            unfinalizePermission={readOnly?.actions.finalize ?? false}
            submissionId={submissionId}
            finalized={submissionMeta?.finalized ?? false}
          />
        ) : null}
      </HStack>

      {submission ? (
        <SharingModal
          document={submission}
          type="submission"
          isOpen={sharingModalIsOpen}
          onClose={closeSharingModal}
        />
      ) : null}
    </HStack>
  );
};
