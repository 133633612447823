import { TextResponseBuildView } from "src/components/TextResponseBuildView";
import { TextResponseSubmissionView } from "src/components/TextResponseSubmissionView";
import { ApprovedBuildView } from "src/components/ApprovedBuildView";
import { ApprovedSubmissionView } from "src/components/ApprovedSubmissionView";
import { FormElement } from "src/types/form.types";

import { AttestationBuildView } from "src/components/AttestationBuildView";
import { AttestationSubmissionView } from "src/components/AttestationSubmissionView";
import { BiasAnalysisBuildView } from "src/components/BiasAnalysisBuildView";
import { BiasAnalysisSubmissionView } from "src/components/BiasAnalysisSubmissionView";
import { DeniedBuildView } from "src/components/DeniedBuildView";
import { DeniedSubmissionView } from "src/components/DeniedSubmissionView";
import { FileUploadBuildView } from "src/components/FileUploadBuildView";
import { FileUploadSubmissionView } from "src/components/FileUploadSubmissionView";
import { MultiSelectBuildView } from "src/components/MultiSelectBuildView";
import { MultiSelectSubmissionView } from "src/components/MultiSelectSubmissionView";
import { SectionBuildView } from "src/components/SectionBuildView";
import { SectionSubmissionView } from "src/components/SectionSubmissionView";
import { SingleSelectBuildView } from "src/components/SingleSelectBuildView";
import { SingleSelectSubmissionView } from "src/components/SingleSelectSubmissionView";
import { TextBlockBuildView } from "src/components/TextBlockBuildView";
import { TextBlockSubmissionView } from "src/components/TextBlockSubmissionView";

export const elementsByType: {
  [elementType in FormElement["type"]]: {
    build: React.ElementType;
    submission: React.ElementType;
  };
} = {
  section: {
    build: SectionBuildView,
    submission: SectionSubmissionView,
  },
  textBlock: {
    build: TextBlockBuildView,
    submission: TextBlockSubmissionView,
  },
  textResponse: {
    build: TextResponseBuildView,
    submission: TextResponseSubmissionView,
  },
  singleSelect: {
    build: SingleSelectBuildView,
    submission: SingleSelectSubmissionView,
  },
  multiSelect: {
    build: MultiSelectBuildView,
    submission: MultiSelectSubmissionView,
  },
  attestation: {
    build: AttestationBuildView,
    submission: AttestationSubmissionView,
  },
  biasAnalysis: {
    build: BiasAnalysisBuildView,
    submission: BiasAnalysisSubmissionView,
  },
  fileUpload: {
    build: FileUploadBuildView,
    submission: FileUploadSubmissionView,
  },
  approved: {
    build: ApprovedBuildView,
    submission: ApprovedSubmissionView,
  },
  denied: {
    build: DeniedBuildView,
    submission: DeniedSubmissionView,
  },
};
