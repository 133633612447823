export enum SpacerSizes {
  "xl" = 50,
  "lg" = 38,
  "md" = 25,
  "sm" = 10,
  "xs" = 3,
}

export const Spacer = ({ x, y }: { x?: number; y?: number }) => {
  return (
    <div
      style={{
        marginTop: y ?? 0,
        marginLeft: x ?? 0,
      }}
    />
  );
};
