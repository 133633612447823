import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Session } from "@supabase/supabase-js";

interface AuthState {
  token: string | null;
  session: Session | null;
}

const initialState: AuthState = {
  token: null,
  session: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSessionAndToken: (
      state,
      action: PayloadAction<Pick<AuthState, "token" | "session">>
    ) => {
      state.token = action.payload.token;
      state.session = action.payload.session;
    },
    resetAuthState: (state) => {
      state.token = null;
      state.session = null;
    },
  },
});

export const { setSessionAndToken, resetAuthState } = authSlice.actions;
