import { Box } from "@chakra-ui/react";
import { useAppSelector } from "../store";
import { AuthenticatedPage } from "../components/AuthenticatedPage";
import { TOPNAV_Z_INDEX } from "../components/LuminosNavBar";
import { UserProfile } from "../components/UserProfile";

export const UserProfilePage = () => {
  const userContext = useAppSelector((state) => state.user.userContext);

  return (
    <AuthenticatedPage title="User Profile">
      {userContext ? (
        <UserProfile userContext={userContext} />
      ) : (
        <Box mt={TOPNAV_Z_INDEX} ml={100}>
          Loading...
        </Box>
      )}
    </AuthenticatedPage>
  );
};
