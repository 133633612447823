import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  createListenerMiddleware,
} from "@reduxjs/toolkit";

import "../listeners/handle-update-submission-title.listener";
import {
  deleteSubmissionUser,
  setDisplayPrivilegeBanner,
  setDisplayPrivilegeConfirmation,
  updateSubmissionElement,
  updateSubmissionTitle,
} from "../slices/submission.slice";
import { handleUpdateSubmissionTitle } from "../listeners/handle-update-submission-title.listener";
import { handleDeleteSubmissionUser } from "../listeners/handle-delete-submission-user.listener";
import { handleDisplayPrivilegeBanner } from "../listeners/handle-set-display-privilege-banner.listener";
import { handleDisplayPrivilegeConfirmation } from "../listeners/handle-set-display-privilege-confirmation.listener";
import { handleUpdateSubmissionElement } from "../listeners/update-submission-element.listener";

export const submissionMiddleware = createListenerMiddleware();

submissionMiddleware.startListening({
  actionCreator: updateSubmissionTitle as ActionCreatorWithoutPayload,
  effect: handleUpdateSubmissionTitle,
});

submissionMiddleware.startListening({
  actionCreator: deleteSubmissionUser as ActionCreatorWithPayload<string>,
  effect: handleDeleteSubmissionUser,
});

submissionMiddleware.startListening({
  actionCreator: setDisplayPrivilegeBanner as ActionCreatorWithPayload<boolean>,
  effect: handleDisplayPrivilegeBanner,
});

submissionMiddleware.startListening({
  actionCreator:
    setDisplayPrivilegeConfirmation as ActionCreatorWithPayload<boolean>,
  effect: handleDisplayPrivilegeConfirmation,
});

submissionMiddleware.startListening({
  actionCreator: updateSubmissionElement as ActionCreatorWithPayload<{
    elementId: string;
    elementValue: any;
  }>,
  effect: handleUpdateSubmissionElement,
});
