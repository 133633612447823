import {
  EText,
  getNode,
  getNodeString,
  PlateEditor,
  replaceNode,
  Value,
  withoutNormalizing,
} from "@udecode/plate-common";
import { Path } from "slate";
import { Editor, Transforms, Location } from "slate";

export const removeMentionInput = <V extends Value>(
  editor: PlateEditor<V>,
  path: Path
) =>
  withoutNormalizing(editor, () => {
    const node = getNode(editor, path);
    if (!node) return;

    const { trigger } = node;

    const text = getNodeString(node);

    replaceNode(editor, {
      at: path,
      nodes: { text: `${trigger}${text}` } as EText<V>,
    });
  });

export const removeTriggeringText = (editor: any, selection: Location) => {
  const beforeText = Editor.before(editor, selection, { unit: "character" });
  const beforeRange = beforeText && Editor.range(editor, beforeText, selection);

  if (beforeRange) {
    // Extend the range to cover the trigger character
    const triggerRange = {
      ...beforeRange,
      focus: { ...beforeRange.focus, offset: beforeRange.focus.offset - 1 },
    };
    Transforms.delete(editor, { at: triggerRange });
  }
};
