import {
  Box,
  Button,
  Center,
  Circle,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  useDisclosure,
  Spacer,
  Icon,
  Tooltip,
  useClipboard,
  useToast,
  Progress,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import {
  FormUser,
  EnrichedFormContentless,
  EnrichedSubmissionContentless,
  OtherUserInfo,
  FormAvailability,
  MembershipType,
  SlateObject,
  EnrichedForm,
  MentionableUser,
} from "../../types";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { LuminosModalHeader } from "./LuminosModalHeader";
import { DestructiveErrorModal } from "./DestructiveErrorModal";
import { RootState, useAppSelector, useAppDispatch } from "../../store";
import { useOrganizationAPI } from "../../endpoints/organization";
import { useAPIRequest } from "../../hooks/useAPI";
import { SharingModalUserSelection } from "../SharingModalUserSelection";
import { SharingModalConfirmAddUser } from "../SharingModalConfirmAddUser";
import { deleteFormUser, updateFormUsers } from "src/store/slices/form.slice";
import {
  updateSubmissionUsers,
  deleteSubmissionUser,
} from "src/store/slices/submission.slice";
import { organizationApi } from "src/services/organization.service";
import { formsApi } from "src/services/forms.service";
import { setMentionableOrganizationMembers } from "src/store/slices/organization.slice";
import { submissionsApi } from "src/services/submission.service";
import { EnrichedSubmission, SubmissionUser } from "src/types/submission.types";

export function urlPathPrefix(type: string) {
  if (type === "form") {
    return "forms";
  } else if (type === "submission") {
    return "submissions";
  }
  return "";
}

export interface DocUser {
  userInfo: OtherUserInfo;
  userDocInfo: FormUser | SubmissionUser;
}

interface SharingModalInterface {
  document:
    | EnrichedForm
    | EnrichedFormContentless
    | EnrichedSubmission
    | EnrichedSubmissionContentless;
  type: "form" | "submission";
  isOpen: boolean;
  onClose: () => void;
}

export const SharingModal = ({
  document, // NOTE: document must be passed in as a prop or this sharing wont work from forms or submissions tables
  type,
  isOpen,
  onClose,
}: SharingModalInterface) => {
  const globalUserInfo = useAppSelector((state) => state.user.userContext);
  const [loadingDocumentInfo, setLoadingDocumentInfo] = useState(true);
  const [addUsersRole, setAddUsersRole] = useState("submitter");
  const [docUsers, setDocUsers] = useState<Map<string, DocUser>>();
  const [saving, setSaving] = useState(false);
  const {
    isOpen: errorModalIsOpen,
    onOpen: openErrorModal,
    onClose: closeErrorModal,
  } = useDisclosure();
  const { onCopy: onUrlCopy, setValue: setUrl } = useClipboard("");
  const toast = useToast();
  const mentionableMembers = useAppSelector(
    (state: RootState) => state.organization.mentionableMembers
  );
  const dispatch = useAppDispatch();
  const generalAccessRestrictedText = "Only those granted access above";
  const generalAccessMembersText = `All ${globalUserInfo?.currentOrgMembership?.name} members`;
  const generalAccessMembersAndGuestsText = `All ${globalUserInfo?.currentOrgMembership?.name} members and guests`;
  const [generalAccessText, setGeneralAccessText] = useState(
    generalAccessRestrictedText
  );
  const [generalAccess, setGeneralAccess] = useState<
    "restricted" | "members" | "membersAndGuests"
  >("restricted");
  const [discoverable, setDiscoverable] = useState(false);
  const [notifyOnShare, setNotifyOnShare] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [emailAddresses, setEmailAddresses] = useState<string[] | null>(null);
  const [displayAddUserDialog, setDisplayAddUserDialog] = useState(false);
  const [validInput, setValidInput] = useState(false);
  const { inviteNewUser } = useOrganizationAPI();
  const textInputRef = React.useRef<SlateObject>();
  const sendAPIRequest = useAPIRequest();

  const mergeUsers = React.useCallback(
    (
      docUsersInfo: FormUser[] | SubmissionUser[],
      usersInfo: OtherUserInfo[]
    ) => {
      const usersInfoMap: { [key: string]: OtherUserInfo } = {};
      const docUserMap = new Map();

      usersInfo.forEach((userInfo) => {
        usersInfoMap[userInfo.userId] = userInfo;
      });

      docUsersInfo.forEach(async (userDocInfo) => {
        const userInfo = usersInfoMap[userDocInfo.userId];
        if (!userInfo) {
          // userInfo will be undefined in the case that the user has just been invited
          const res = await sendAPIRequest(`user/${userDocInfo.userId}`, "GET");

          usersInfoMap[userDocInfo.userId] = {
            ...res.user,
            userId: res.user._id,
          };
        }
        docUserMap.set(userDocInfo.userId, {
          userInfo: usersInfoMap[userDocInfo.userId],
          userDocInfo: userDocInfo,
        });
      });

      return docUserMap;
    },
    [sendAPIRequest]
  );

  const onDocUpdate = React.useCallback(() => {
    if (!document) return;
    const combinedUsersAndUserUnfo = mergeUsers(
      document.users,
      document.readOnly.usersInfo
    );

    setDocUsers(combinedUsersAndUserUnfo);
    if (type === "form") {
      setUrl(
        `${window.location.protocol}//${window.location.host}/form-builder/${document._id}`
      );
      setDiscoverable(
        (document as EnrichedFormContentless).availability.discoverable
      );
      if (
        (document as EnrichedFormContentless).availability.audience?.includes(
          "guest"
        ) &&
        (document as EnrichedFormContentless).availability.audience?.includes(
          "member"
        )
      ) {
        setGeneralAccess("membersAndGuests");
        setGeneralAccessText(generalAccessMembersAndGuestsText);
      } else if (
        (document as EnrichedFormContentless).availability.audience?.includes(
          "member"
        )
      ) {
        setGeneralAccess("members");
        setGeneralAccessText(generalAccessMembersText);
      } else {
        setGeneralAccess("restricted");
        setGeneralAccessText(generalAccessRestrictedText);
      }
      if (
        (document as EnrichedFormContentless).readOnly.actions.becomeAdjudicator
      ) {
        setAddUsersRole("owner");
      } else if ((document as EnrichedFormContentless).readOnly.actions.edit) {
        setAddUsersRole("editor");
      }
    } else if (type == "submission") {
      setUrl(
        `${window.location.protocol}//${window.location.host}/submission-editor/${document._id}`
      );
      if (
        (document as EnrichedSubmissionContentless).readOnly.actions
          .assignAdjudicators
      ) {
        setAddUsersRole("adjudicator");
      }
    }
    setLoadingDocumentInfo(false);
  }, [
    generalAccessMembersAndGuestsText,
    document,
    generalAccessMembersText,
    generalAccessRestrictedText,
    type,
    setUrl,
    mergeUsers,
  ]);

  useEffect(() => {
    onDocUpdate();
  }, [document, onDocUpdate]);

  function copyLinkToClipboard() {
    onUrlCopy();
    toast({
      title: "Copied",
      description: "The form link was copied to your clipboard.",
      status: "success",
      duration: 3000,
      isClosable: true,
      containerStyle: {
        textColor: "white",
      },
    });
  }

  async function shareAndSend() {
    if (!document) {
      return;
    }
    setSaving(true);

    try {
      const knownEmails = mentionableMembers.map(
        (member: MentionableUser) => member.data.email
      );
      const knownNames = mentionableMembers.map(
        (member: MentionableUser) => member.text
      );
      const knownUsers = knownEmails.concat(knownNames);
      const knownUserIds = mentionableMembers
        .filter(
          (member: MentionableUser) =>
            emailAddresses?.includes(member.data.email) ||
            emailAddresses?.includes(member.text)
        )
        .map((member: MentionableUser) => member.key);
      const newEmails = emailAddresses?.filter(
        (email: string) => !knownUsers.includes(email.trim())
      );

      let userIds = [...knownUserIds];
      userIds = userIds.filter((id: string) => id !== globalUserInfo?.user._id);

      if (newEmails && newEmails.length > 0) {
        const newUserIds: (string | null)[] = await Promise.all(
          newEmails.map(async (address: string) => {
            const res: { userId?: string } = await inviteNewUser({
              email: address.trim(),
              firstName: "",
              lastName: "",
              orgMembership: {
                membershipType: "guest",
                permissions: {
                  inviteUser: true,
                  manageOrgPermissions: false,
                  manageOrgSettings: false,
                  manageOrgTags: false,
                  manageLegalPrivilege: false,
                  manageAllGroups: false,
                  seeAllGroups: false,
                  createOrCopyForms: false,
                  createGroups: false,
                  seeAllMembers: false,
                  seeAllGuests: false,
                },
              },
            });
            if (res.userId) {
              return res.userId;
            }
            return null;
          })
        );

        newUserIds.forEach((id: string | null) => {
          if (id !== null) {
            userIds.push(id);
          }
        });

        if (globalUserInfo?.currentOrgMembership.organization) {
          const res = await dispatch(
            organizationApi.endpoints.getOrganizationMembers.initiate({
              targetOrganizationId:
                globalUserInfo?.currentOrgMembership.organization._id,
            })
          );
          if (res.data) {
            dispatch(setMentionableOrganizationMembers(res.data));
          }
        }

        toast({
          title: "Invitations Sent",
          description: `${newEmails.join(", ")} invited to join ${globalUserInfo?.currentOrgMembership.organization!.name}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
          containerStyle: {
            textColor: "white",
          },
        });
      }

      if (userIds.length > 0) {
        const newDocumentUsers = userIds.map((userId) => {
          return {
            userId: userId,
            role: addUsersRole,
            isApproved: true,
          };
        });

        if (type === "form") {
          dispatch(
            updateFormUsers({
              documentUsers: [...document.users, ...newDocumentUsers],
              userIds,
            })
          );

          // NOTE: This cannot be moved to form.middleware or sharing breaks from the forms table
          const formUpdateUsersPayload: {
            formId: string;
            users: FormUser[];
            notifyUsers?: boolean;
            sharingMessage?: string;
          } = {
            formId: document._id,
            users: [...document.users, ...newDocumentUsers],
            notifyUsers: notifyOnShare,
          };

          if (notifyOnShare && notificationMessage !== "") {
            formUpdateUsersPayload.sharingMessage = notificationMessage;
          }

          dispatch(
            formsApi.endpoints.addUsersToForm.initiate(formUpdateUsersPayload)
          );
        } else {
          dispatch(
            updateSubmissionUsers({
              documentUsers: [...document.users, ...newDocumentUsers],
              userIds,
            })
          );

          // NOTE: This cannot be moved to submission.middleware or sharing breaks from the submissions table
          const updateSubmissionUserPayload: {
            submissionId: string;
            users: SubmissionUser[];
            notifyUsers: boolean;
            sharingMessage?: string;
          } = {
            submissionId: document._id,
            users: [...document.users, ...newDocumentUsers] as SubmissionUser[],
            notifyUsers: notifyOnShare,
          };

          if (notifyOnShare && notificationMessage !== "") {
            updateSubmissionUserPayload.sharingMessage = notificationMessage;
          }

          dispatch(
            submissionsApi.endpoints.addUsersToSubmission.initiate(
              updateSubmissionUserPayload
            )
          );
        }
      }
      cleanupAndClose();
    } catch (error) {
      openErrorModal();
    }
  }

  function cleanupAndClose() {
    setEmailAddresses([]);
    setSaving(false);
    setDisplayAddUserDialog(false);
    setNotifyOnShare(true);
    setNotificationMessage("");
    onClose();
  }

  function closeErrorModalWrapper() {
    // reset everything derived from the input document
    cleanupAndClose();
    closeErrorModal();
  }

  async function saveAvailability(availability: FormAvailability) {
    setSaving(true);
    try {
      if (type === "form") {
        // this functionality is only available for forms
        dispatch(
          formsApi.endpoints.updateFormAvailability.initiate({
            formId: document?._id,
            availability,
          })
        );
      }
      setSaving(false);
    } catch (error) {
      openErrorModal();
    }
  }

  const updateGeneralAccess = (
    accessType: "restricted" | "members" | "membersAndGuests"
  ) => {
    switch (accessType) {
      case "restricted":
        setGeneralAccessText(generalAccessRestrictedText);
        setGeneralAccess("restricted");
        saveAvailability({
          discoverable: false,
          audience: [],
        });
        break;
      case "members":
        setGeneralAccessText(generalAccessMembersText);
        setGeneralAccess("members");
        saveAvailability({
          discoverable: discoverable,
          audience: ["member"],
        });
        break;
      case "membersAndGuests":
        setGeneralAccessText(generalAccessMembersAndGuestsText);
        setGeneralAccess("membersAndGuests");
        saveAvailability({
          discoverable: discoverable,
          audience: ["member", "guest"],
        });
        break;
      default:
        break;
    }
  };

  function getMembershipType() {
    if (generalAccess === "membersAndGuests") {
      return ["member", "guest"] as MembershipType[];
    } else if (generalAccess === "members") {
      return ["member"] as MembershipType[];
    } else {
      return [] as MembershipType[];
    }
  }

  function setDiscoverableWrapper(discoverable: boolean) {
    setDiscoverable(discoverable);
    saveAvailability({
      discoverable: discoverable,
      audience: getMembershipType(),
    });
  }

  const deleteUser = React.useCallback(
    async (userId: string) => {
      setSaving(true);

      try {
        if (type === "form") {
          dispatch(deleteFormUser(userId));
        } else {
          dispatch(deleteSubmissionUser(userId));
        }
      } catch {
        openErrorModal();
      }

      setSaving(false);
    },
    [type, openErrorModal, dispatch]
  );

  const updateDocumentUserRole = React.useCallback(
    async (role: string, userId: string) => {
      setSaving(true);
      try {
        if (!document) {
          return null;
        }

        if (type === "form") {
          dispatch(
            formsApi.endpoints.updateFormUserRole.initiate({
              formId: document._id,
              userId: userId,
              role: role,
            })
          );
        } else {
          dispatch(
            submissionsApi.endpoints.updateSubmissionUserRole.initiate({
              submissionId: document._id,
              userId: userId,
              role: role,
            })
          );
        }

        const newDocUsers = new Map(docUsers);
        const currentUser = newDocUsers.get(userId);

        if (!currentUser) {
          throw new Error("User not found updating role on document");
        }

        newDocUsers.set(userId, {
          userDocInfo: {
            ...currentUser.userDocInfo,
            role: role,
          },
          userInfo: currentUser.userInfo,
        });

        setDocUsers(newDocUsers);
      } catch (error) {
        console.log(error);
        onClose();
        openErrorModal();
      } finally {
        setSaving(false);
      }
    },
    [docUsers, document, type, dispatch, openErrorModal, onClose]
  );

  useEffect(() => {
    if (emailAddresses !== null && emailAddresses.length > 0) {
      setValidInput(true);
      setDisplayAddUserDialog(true);
    } else {
      setValidInput(false);
      setDisplayAddUserDialog(false);
    }
  }, [emailAddresses]);

  const handleOnChange = (event: SlateObject) => {
    textInputRef.current = event;
    if (event[0].children) {
      const mentionedUsers = event[0].children.filter(
        (textNode: SlateObject) => textNode.type == "@"
      );
      if (mentionedUsers) {
        setEmailAddresses(
          mentionedUsers.map((textNode: SlateObject) => textNode.value)
        );
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={cleanupAndClose}>
        <ModalOverlay />
        <ModalContent minWidth="600px">
          <LuminosModalHeader
            title={`${type === "form" ? "Form" : "Submission"} Sharing`}
            closeButton={false}
          />
          {saving ? (
            <Progress w="100%" size="xs" isIndeterminate />
          ) : (
            <Box h="4px" bg="white"></Box>
          )}
          {displayAddUserDialog ? (
            <SharingModalConfirmAddUser
              document={document}
              handleOnChange={handleOnChange}
              setDisplayAddUserDialog={setDisplayAddUserDialog}
              setEmailAddresses={setEmailAddresses}
              loadingDocumentInfo={loadingDocumentInfo}
              addUsersRole={addUsersRole}
              type={type}
              setAddUsersRole={setAddUsersRole}
              notifyOnShare={notifyOnShare}
              setNotifyOnShare={setNotifyOnShare}
              setNotifyOnShareWrapper={(
                e: React.ChangeEvent<HTMLInputElement>
              ) => setNotifyOnShare(e.target.checked)}
              notificationMessage={notificationMessage}
              setNotificationMessageWrapper={(
                e: React.ChangeEvent<HTMLTextAreaElement>
              ) => setNotificationMessage(e.target.value)}
              textInputInitialValue={textInputRef.current}
            />
          ) : (
            <SharingModalUserSelection
              document={document}
              updateDocumentUserRole={updateDocumentUserRole}
              loadingDocumentInfo={loadingDocumentInfo}
              type={type}
              handleOnChange={handleOnChange}
              docUsers={docUsers}
              setDocUsers={setDocUsers}
              openErrorModal={openErrorModal}
              setSaving={setSaving}
              generalAccessRestrictedText={generalAccessRestrictedText}
              generalAccessMembersText={generalAccessMembersText}
              generalAccessMembersAndGuestsText={
                generalAccessMembersAndGuestsText
              }
              generalAccessText={generalAccessText}
              updateGeneralAccess={updateGeneralAccess}
              generalAccess={generalAccess}
              discoverable={discoverable}
              setDiscoverableWrapper={setDiscoverableWrapper}
              deleteUser={deleteUser}
            />
          )}
          <ModalFooter bg="white">
            <Flex w="100%">
              <Center>
                <Tooltip label="Copy Link to Form" fontSize="md">
                  <Circle
                    size="32px"
                    border="1px"
                    borderColor="brightblue.500"
                    onClick={copyLinkToClipboard}
                    boxShadow="lg"
                  >
                    <Icon as={LinkOutlinedIcon} w="22px" h="22px" />
                  </Circle>
                </Tooltip>
              </Center>

              <Spacer />
              {displayAddUserDialog ? (
                <>
                  {saving ? (
                    <Button isDisabled={true}>Saving...</Button>
                  ) : (
                    <>
                      <Button
                        variant="secondary"
                        mr={3}
                        onClick={cleanupAndClose}
                      >
                        Cancel
                      </Button>
                      <Button isDisabled={!validInput} onClick={shareAndSend}>
                        Send
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {saving ? (
                    <Button isDisabled={true} onClick={cleanupAndClose}>
                      Saving...
                    </Button>
                  ) : (
                    <Button isDisabled={false} onClick={cleanupAndClose}>
                      Done
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DestructiveErrorModal
        errorModalIsOpen={errorModalIsOpen}
        title={`${type === "form" ? "Form" : "Submission"} Sharing Error`}
        closeErrorModalWrapper={closeErrorModalWrapper}
        closeParent={cleanupAndClose}
      />
    </>
  );
};
