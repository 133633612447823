import { useAppSelector } from "../store";
import React from "react";

export const HideIfNoAuth = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const session = useAppSelector((state) => state.auth.session);
  return session ? children : <></>;
};
