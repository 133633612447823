import React from "react";
import { AdvancedSectionWrapper } from "./AdvancedSectionWrapper";
import {
  Text,
  HStack,
  StackDivider,
  NumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  Switch,
  VStack,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "./Spacer";
import { AdvancedNotes } from "./AdvancedNotes";
import "@fontsource/birthstone";
import { useAppDispatch, useAppSelector } from "src/store";
import { FormElementBuildViewProps, RichTextDelta } from "src/types/form.types";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { updateFormElement } from "src/store/slices/form.slice";
import { selectSelectedElement } from "../store/selectors/selected-element.selectors";
import { AttestationPreview } from "./AttestationPreview";
import { getDefaultAttestationInstance } from "src/util/element.utils";
import { BuildViewHeader } from "./BuildViewHeader";
import { RichTextEditor } from "./RichTextEditor";

export const AttestationBuildView = ({ id }: FormElementBuildViewProps) => {
  const selectedElementId = useAppSelector(selectSelectedElement);
  const element = useAppSelector(lookupFormElementById(id));
  const dispatch = useAppDispatch();
  const isSelectedElement = selectedElementId === id;

  if (element && element.type !== "attestation") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const handleRequireNumberChange = React.useCallback(
    (newRequiredNumberValue: string) => {
      if (element) {
        const newRequiredNumber = Number(newRequiredNumberValue);
        const attestations = [];
        for (let i = 0; i < Number(newRequiredNumber); i++) {
          attestations.push(getDefaultAttestationInstance());
        }

        dispatch(
          updateFormElement({
            ...element,
            content: {
              ...element.content,
              attestations,
              requiredNumber: newRequiredNumber,
            },
          })
        );
      }
    },
    [dispatch, element]
  );

  const handleHeaderFieldChange = React.useCallback(
    (field: "title" | "audience") => {
      return (value: string) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: value,
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  const handleInputChange = React.useCallback(
    (field: "collectTitle" | "collectOrganization") => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: e.target.checked,
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  const handleRichTextChange = React.useCallback(
    (field: "question" | "surveyTakerNotes" | "adjudicatorNotes") => {
      return (value: RichTextDelta) => {
        if (element) {
          dispatch(
            updateFormElement({
              ...element,
              content: {
                ...element.content,
                [field]: { ops: value.ops },
              },
            })
          );
        }
      };
    },
    [dispatch, element]
  );

  if (!element) {
    return null;
  }

  return isSelectedElement ? (
    <>
      <BuildViewHeader
        id={id}
        titleDataTestId={`attestation-title-input-${element.pathIndex.join("-")}`}
        rules={element?.rules || []}
        questionPrompt="Attestation Statement"
        title={element.content.title}
        titlePlaceholder="Untitled Attestation"
        titleNumber={element?.titleNumber ?? ""}
        audience={element.content.audience}
        onAudienceChange={handleHeaderFieldChange("audience")}
        onTitleChange={handleHeaderFieldChange("title")}
      />
      <RichTextEditor
        placeholder="Enter the attestation statement"
        value={element.content.question}
        onChange={handleRichTextChange("question")}
      />
      <Spacer y={SpacerSizes.sm} />
      <Text>Options</Text>
      <HStack>
        <VStack w={"50%"} alignItems={"flex-start"}>
          <HStack>
            <Switch
              colorScheme="brightblue"
              size="sm"
              isChecked={element.content.collectTitle}
              onChange={handleInputChange("collectTitle")}
            />
            <Text>Collect Title</Text>
          </HStack>
          <HStack>
            <Switch
              colorScheme="brightblue"
              size="sm"
              isChecked={element.content.collectOrganization}
              onChange={handleInputChange("collectOrganization")}
            />
            <Text>Collect Organization Name</Text>
          </HStack>
        </VStack>
      </HStack>
      <AdvancedSectionWrapper>
        <HStack
          gap={1}
          w={"100%"}
          divider={<StackDivider borderColor="gray.600" />}
        >
          <div style={{ width: "30%" }}>
            <Text># of Attestations to Collect</Text>
            <NumberInput
              onChange={handleRequireNumberChange}
              size="sm"
              maxW={20}
              min={1}
              value={element.content.requiredNumber}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </div>
          <div style={{ width: "70%" }}>
            <AdvancedNotes
              updateReviewerNotes={handleRichTextChange("adjudicatorNotes")}
              updateSubmitterNotes={handleRichTextChange("surveyTakerNotes")}
              adjudicatorNotes={element.content.adjudicatorNotes}
              surveyTakerNotes={element.content.surveyTakerNotes}
            />
          </div>
        </HStack>
      </AdvancedSectionWrapper>
    </>
  ) : (
    <AttestationPreview id={id} />
  );
};
