import { Box } from "@mui/material";
import React from "react";
import { initializeQuillTranslator } from "src/util/quill.utils";

/**
 * create a single Quill Instance that is used to
 * access quill's instance API.
 *
 * See src/util/quill.utils.ts for util functions
 * leveraging the Quill instance
 */
export const QuillTranslator = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (ref.current) {
      initializeQuillTranslator(ref.current);
    }
  }, []);

  return (
    <Box
      sx={{
        height: 0,
        padding: 0,
        "& > .ql-editor": {
          height: 0,
          padding: 0,
        },
      }}
      ref={ref}
    >
      {children}
    </Box>
  );
};
