import { Box, Stack } from "@mui/material";
import { Rule, RuleEntry, RuleEntryProps, ValidRule } from "./RuleEntry";
import { Delete } from "@mui/icons-material";
import React from "react";
import { getIsPredicateAdjectiveRequired } from "src/util/rule.utils";
import { RuleSaveButton } from "./RuleSaveButton";
import { useAppSelector } from "src/store";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { FormElement } from "src/types/form.types";
import { RuleIconButton } from "./RuleIconButton";

export interface EditableRuleEntryProps
  extends Omit<RuleEntryProps, "onRuleChange"> {
  onAudienceChange: ({
    element,
    audience,
  }: {
    element: FormElement;
    audience: string;
  }) => void;
  onSaveRule: ({
    element,
    rule,
  }: {
    element: FormElement;
    rule: ValidRule;
  }) => void;
  onDeleteRule: ({
    element,
    rule,
  }: {
    element: FormElement;
    rule: Rule;
  }) => void;
}

interface RuleState {
  isValid: boolean;
  isDirty: boolean;
  rule: Rule;
}

/**
 * wrapper component for RuleEntry to
 * create a copy of rule prop to handle
 * pending changes before sending to the server
 */
export const EditableRuleEntry = ({
  rule,
  onSaveRule,
  onDeleteRule,
  onAudienceChange,
  ...props
}: EditableRuleEntryProps) => {
  const subjectElement = useAppSelector(
    lookupFormElementById(rule.subjectElementId || "")
  );
  const [ruleState, setRuleState] = React.useState<RuleState>({
    isValid: false,
    isDirty: false,
    rule,
  });

  const handleRuleChange: RuleEntryProps["onRuleChange"] = React.useCallback(
    ({ fieldName, value }) => {
      // handle conditional subject change
      // reset predicate fields
      if (fieldName === "conditionalSubjectElementId") {
        setRuleState((prevRuleState) => {
          return {
            ...prevRuleState,
            isValid: false,
            isDirty: true,
            rule: {
              ...prevRuleState.rule,
              [fieldName]: value,
              predicateVerb: null,
              predicateAdjective: null,
            },
          };
        });
      } else if (fieldName === "audience") {
        // handle audience changes which will update the element
        // immediately
        if (subjectElement) {
          onAudienceChange({ element: subjectElement, audience: value });
        }
      } else {
        setRuleState((prevRuleState) => {
          const pendingState = {
            ...prevRuleState,
            isDirty: true,
            rule: {
              ...prevRuleState.rule,
              [fieldName]: value,
            },
          };
          const isValid = Boolean(
            pendingState.rule.subjectElementId &&
              pendingState.rule.audience &&
              pendingState.rule.conditionalSubjectElementId &&
              pendingState.rule.predicateVerb &&
              (getIsPredicateAdjectiveRequired(pendingState.rule.predicateVerb)
                ? pendingState.rule.predicateAdjective
                : true)
          );

          return {
            ...pendingState,
            isValid,
          };
        });
      }
    },
    [onAudienceChange, subjectElement]
  );

  const handleSaveRule = React.useCallback(() => {
    if (
      subjectElement &&
      ruleState.rule.subjectElementId &&
      ruleState.rule.audience &&
      ruleState.rule.conditionalSubjectElementId &&
      ruleState.rule.predicateVerb
    ) {
      onSaveRule({
        element: subjectElement,
        rule: {
          subjectElementId: ruleState.rule.subjectElementId,
          audience: ruleState.rule.audience,
          conditionalSubjectElementId:
            ruleState.rule.conditionalSubjectElementId,
          predicateVerb: ruleState.rule.predicateVerb,
          predicateAdjective: ruleState.rule.predicateAdjective,
        },
      });
    }
  }, [ruleState, subjectElement, onSaveRule]);

  const handleDeleteRule = React.useCallback(() => {
    if (subjectElement) {
      onDeleteRule({ element: subjectElement, rule: ruleState.rule });
    }
  }, [ruleState.rule, subjectElement, onDeleteRule]);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <RuleEntry
        rule={ruleState.rule}
        onRuleChange={handleRuleChange}
        {...props}
      />

      <Box sx={{ height: "100%", display: "flex" }}>
        <Box sx={{ width: 28, height: 28 }}>
          {ruleState.isDirty && ruleState.isValid ? (
            <RuleSaveButton onClick={handleSaveRule} />
          ) : null}
        </Box>

        <Box sx={{ width: 28, height: 28 }}>
          <RuleIconButton onClick={handleDeleteRule}>
            <Delete fontSize="inherit" />
          </RuleIconButton>
        </Box>
      </Box>
    </Stack>
  );
};
