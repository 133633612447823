import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import {
  findElementById,
  getSiblingAbove,
  getSiblingBelow,
  recursiveUpdateStateTitleNumbers,
  recursiveUpdateTitleNumbers,
} from "src/util/form.utils";
import { Position } from "src/types";

import { FormElementState, FormState } from "../slices/form.slice";

export const swapElementReducer: CaseReducer<
  FormState,
  PayloadAction<{
    elementId: string;
    position: Position;
    elementState: FormElementState;
  }>
> = (state, action) => {
  if (state.status === "success") {
    const { elementId, position, elementState } = action.payload;
    const targetElement = state.elementsById[elementId];

    const siblingState =
      position === Position.Above
        ? getSiblingAbove(targetElement.pathIndex, state.elements)
        : getSiblingBelow(targetElement.pathIndex, state.elements);

    const parentState = elementState.parentElementId
      ? findElementById(elementState.parentElementId, state.elements)
      : null;

    const parentElement = state.elementsById[elementState.parentElementId!];

    if (parentState && siblingState) {
      const siblingElement = state.elementsById[siblingState.id];
      const siblingIndex = parentState.children.findIndex(
        (child) => child.id === siblingState.id
      );

      const targetIndex = parentState.children.findIndex(
        (child) => child.id === elementId
      );

      const newTargetPathIndex = siblingElement.pathIndex;
      const newSiblingPathIndex = targetElement.pathIndex;

      parentState.children[siblingIndex] = {
        ...elementState,
        pathIndex: newTargetPathIndex,
      };
      parentState.children[targetIndex] = {
        ...siblingState,
        pathIndex: newSiblingPathIndex,
      };

      const tempChildren = state.elementsById[parentState.id].children;
      tempChildren[siblingIndex] =
        state.elementsById[siblingState.id].children[targetIndex];
      tempChildren[targetIndex] =
        state.elementsById[elementState.id].children[siblingIndex];

      state.elementsById[parentState.id] = {
        ...state.elementsById[parentState.id],
        children: tempChildren,
      };

      const newTargetElement = {
        ...targetElement,
        pathIndex: newTargetPathIndex,
      };

      const newSiblingElement = {
        ...siblingElement,
        pathIndex: newSiblingPathIndex,
      };

      parentElement.children[siblingIndex] = newTargetElement;
      parentElement.children[targetIndex] = newSiblingElement;

      recursiveUpdateStateTitleNumbers(state.elements, null, state);

      recursiveUpdateTitleNumbers(state.elements, state);
    } else if (siblingState) {
      const siblingIndex = state.elements.findIndex(
        (element) => element.id === siblingState.id
      );

      const targetIndex = state.elements.findIndex(
        (element) => element.id === elementId
      );

      // swap elements in state.elements
      state.elements[siblingIndex] = {
        ...elementState,
        pathIndex: [targetIndex],
      };
      state.elements[targetIndex] = {
        ...siblingState,
        pathIndex: [siblingIndex],
      };

      state.elementsById[siblingState.id] = {
        ...state.elementsById[siblingState.id],
        pathIndex: siblingState.pathIndex,
      };

      recursiveUpdateStateTitleNumbers(state.elements, null, state);
      recursiveUpdateTitleNumbers(state.elements, state);
    } else {
      throw new Error("Sibling element not found");
    }
  }
};
