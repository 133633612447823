import React from "react";
import { ElementProvider } from "src/context/ElementContext";
import { DocumentSectionWrapper } from "./DocumentSectionWrapper";
import { useAppSelector } from "src/store";
import {
  selectFormElements,
  selectFormUsers,
} from "src/store/selectors/form.selectors";
import { FormElementState } from "src/store/slices/form.slice";
import { FormUser } from "src/types";
import { elementsByType } from "src/util/elements-by-type.utils";
import { Box } from "@mui/material";

export const FormBuilderElements = () => {
  const userId = useAppSelector((state) => state.user.userContext?.user._id);
  const formElements = useAppSelector(selectFormElements);
  const formUsers = useAppSelector(selectFormUsers);

  const renderFormElements = React.useCallback(
    (elements: FormElementState[], level: number = 0): JSX.Element[] => {
      if (!formElements) {
        return [];
      }

      return elements?.map((element, i) => {
        const ElementComponent = elementsByType[element.type].build;
        // Construct children elements recursively if they exist
        const children = element.children
          ? renderFormElements(element.children, level + 1)
          : undefined;

        // @TODO fix this - initialize an element with null id
        return (
          <ElementProvider key={`${level}-${i}-wrapper`} element={element}>
            <DocumentSectionWrapper
              elementId={element.id!}
              userRoleOnDocument={
                formUsers?.find((user: FormUser) => user.userId === userId)
                  ?.role ?? "submitter"
              }
              isSection={element.type === "section"}
            >
              <Box
                paddingLeft={`${(element.indentationLevel - 1 ?? 0) * 15}px`}
              >
                <ElementComponent id={element.id!} key={`${level}-${i}`}>
                  {children}
                </ElementComponent>
              </Box>
            </DocumentSectionWrapper>
          </ElementProvider>
        );
      });
    },
    [formElements, formUsers, userId]
  );

  return <div>{formElements ? renderFormElements(formElements) : null}</div>;
};
