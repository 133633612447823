import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  addFormElement,
  updateFormElement,
  dedentFormElement,
  deleteFormElement,
  swapFormElement,
  indentFormElement,
  updateFormDescription,
  updateFormTitle,
  setEditingElementId,
  deleteFormUser,
} from "../slices/form.slice";
import { RootState } from "src/store";
import { formsApi } from "src/services/forms.service";
import { FormElement } from "src/types/form.types";
import { findElementById } from "src/util/form.utils";
import { setSelectedElementId } from "../slices/selected-element.slice";

export const formMiddleware = createListenerMiddleware();

let lastUpdateFormElementRequestAbort: () => void;

formMiddleware.startListening({
  actionCreator: updateFormElement,

  effect: async (action, listener) => {
    if (lastUpdateFormElementRequestAbort) {
      lastUpdateFormElementRequestAbort();
    }
    const state = listener.getState() as RootState;

    const formState = state.form;

    if (formState.status !== "success" && formState.status !== "saving") {
      throw new Error("Attempting to update a form when form is not set");
    }
    const { formId } = formState;
    const updatedElement = action.payload;
    const { abort } = listener.dispatch(
      formsApi.endpoints.updateFormElement.initiate({
        formId,
        updatedElementsById: {
          [updatedElement.id]: updatedElement,
        },
      })
    );

    listener.dispatch(setEditingElementId(updatedElement.id));
    lastUpdateFormElementRequestAbort = abort;
  },
});

let lastAppendFormElementRequestAbort: () => void;

formMiddleware.startListening({
  actionCreator: addFormElement,
  effect: async (action, listener) => {
    if (lastAppendFormElementRequestAbort) {
      lastAppendFormElementRequestAbort();
    }
    const state = listener.getState() as RootState;
    const formState = state.form;

    if (formState.status !== "success" && formState.status !== "saving") {
      throw new Error("Attempting to update a form when form is not set");
    }

    const { formId } = formState;
    const { referenceElementId } = action.payload as {
      referenceElementId: string;
      element: FormElement;
    };
    // set new element as selected so that user can edit
    listener.dispatch(setSelectedElementId(action.payload.element.id));
    const referenceElement = formState.elementsById[referenceElementId];

    if (
      referenceElement.type === "section" &&
      referenceElement.pathIndex.length < 4
    ) {
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElement.initiate({
          formId,
          updatedElementsById: {
            [referenceElement.id]: referenceElement,
          },
        })
      );
      lastAppendFormElementRequestAbort = abort;
    } else if (referenceElement.parentElementId) {
      const parentElement =
        formState.elementsById[referenceElement.parentElementId];

      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElement.initiate({
          formId,
          updatedElementsById: {
            [parentElement.id]: parentElement,
          },
        })
      );
      lastAppendFormElementRequestAbort = abort;
    } else {
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElements.initiate({
          formId,
          formElements: formState.elements.map(
            (element) => formState.elementsById[element.id]
          ),
          commentThreadsDictionary: formState.form.commentThreadsDictionary,
        })
      );
      lastAppendFormElementRequestAbort = abort;
    }
    listener.dispatch(setEditingElementId(action.payload.element.id));
  },
});

formMiddleware.startListening({
  actionCreator: swapFormElement,
  effect: async (action, listener) => {
    if (lastSwapFormElementRequestAbort) {
      lastSwapFormElementRequestAbort();
    }
    const state = listener.getState() as RootState;

    const formState = state.form;

    if (formState.status !== "success" && formState.status !== "saving") {
      throw new Error("Attempting to update a form when form is not set");
    }
    const { formId } = formState;
    const { elementState } = action.payload;
    const parentElement = formState.elementsById[elementState.parentElementId];

    const body: { [key: string]: FormElement } = {};
    if (parentElement) {
      body[parentElement.id] = parentElement;
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElement.initiate({
          formId,
          updatedElementsById: body,
        })
      );
      lastUpdateFormElementRequestAbort = abort;
    } else {
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElements.initiate({
          formId,
          formElements: formState.elements.map(
            (element) => formState.elementsById[element.id]
          ),
          commentThreadsDictionary: formState.form.commentThreadsDictionary,
        })
      );
      lastAppendFormElementRequestAbort = abort;
    }
  },
});

let lastIndentFormElementRequestAbort: () => void;

formMiddleware.startListening({
  actionCreator: indentFormElement,
  effect: async (action, listener) => {
    if (lastIndentFormElementRequestAbort) {
      lastIndentFormElementRequestAbort();
    }
    const state = listener.getState() as RootState;

    const formState = state.form;

    if (formState.status !== "success") {
      throw new Error("Attempting to update a form when form is not set");
    }
    const { formId } = formState;
    const { parentElementId } = action.payload;
    if (parentElementId) {
      const parentElement = formState.elementsById[parentElementId];

      const siblingElements = parentElement.children;

      const body: { [key: string]: FormElement } = {};

      siblingElements.forEach((element) => {
        body[element.id] = formState.elementsById[element.id];
      });

      body[parentElement.id] = parentElement;

      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElement.initiate({
          formId,
          updatedElementsById: body,
        })
      );
      lastAppendFormElementRequestAbort = abort;
    } else {
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElements.initiate({
          formId,
          formElements: formState.elements.map(
            (element) => formState.elementsById[element.id]
          ),
          commentThreadsDictionary: formState.form.commentThreadsDictionary,
        })
      );
      lastAppendFormElementRequestAbort = abort;
    }
  },
});

let lastDeleteFormElementRequestAbort: () => void;

formMiddleware.startListening({
  actionCreator: deleteFormElement,

  effect: async (action, listener) => {
    if (lastDeleteFormElementRequestAbort) {
      lastDeleteFormElementRequestAbort();
    }

    const state = listener.getState() as RootState;

    const formState = state.form;

    if (formState.status !== "success") {
      throw new Error("Attempting to update a form when form is not set");
    }
    const { formId } = formState;
    const { parentElementId } = action.payload;
    const parentElement = formState.elementsById[parentElementId];

    if (parentElement) {
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElement.initiate({
          formId,
          updatedElementsById: {
            [parentElement.id]: parentElement,
          },
        })
      );
      lastDeleteFormElementRequestAbort = abort;
    } else {
      // for deleting top level elements only
      const elements: FormElement[] = formState.elements.map(
        (element) => formState.elementsById[element.id]
      );
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElements.initiate({
          formId,
          formElements: elements,
          commentThreadsDictionary: formState.form.commentThreadsDictionary,
        })
      );

      lastDeleteFormElementRequestAbort = abort;
    }
  },
});

let lastUpdateFormTitleRequestAbort: () => void;

formMiddleware.startListening({
  actionCreator: updateFormTitle,
  effect: async (action, listener) => {
    if (lastUpdateFormTitleRequestAbort) {
      lastUpdateFormTitleRequestAbort();
    }

    const state = listener.getState() as RootState;
    const formState = state.form;

    if (formState.status !== "success" && formState.status !== "saving") {
      throw new Error("Attempting to update a form when form is not set");
    }

    const { formId } = formState;
    const { abort } = listener.dispatch(
      formsApi.endpoints.updateFormTitle.initiate({
        formId,
        formTitle: formState.meta.title,
      })
    );
    lastUpdateFormTitleRequestAbort = abort;
  },
});

let lastUpdateFormDescriptionRequestAbort: () => void;

formMiddleware.startListening({
  actionCreator: updateFormDescription,
  effect: async (action, listener) => {
    if (lastUpdateFormDescriptionRequestAbort) {
      lastUpdateFormDescriptionRequestAbort();
    }

    const state = listener.getState() as RootState;
    const formState = state.form;

    if (formState.status !== "success" && formState.status !== "saving") {
      throw new Error("Attempting to update a form when form is not set");
    }

    const { formId } = formState;
    const { abort } = listener.dispatch(
      formsApi.endpoints.updateFormDescription.initiate({
        formId,
        formDescription: formState.meta.description,
      })
    );
    lastUpdateFormDescriptionRequestAbort = abort;
  },
});

let lastSwapFormElementRequestAbort: () => void;

formMiddleware.startListening({
  actionCreator: swapFormElement,
  effect: async (action, listener) => {
    if (lastSwapFormElementRequestAbort) {
      lastSwapFormElementRequestAbort();
    }
    const state = listener.getState() as RootState;

    const formState = state.form;

    if (formState.status !== "success" && formState.status !== "saving") {
      throw new Error("Attempting to update a form when form is not set");
    }
    const { formId } = formState;
    const updatedElement = action.payload;
    const { abort } = listener.dispatch(
      formsApi.endpoints.updateFormElement.initiate({
        formId,
        updatedElementsById: {
          [updatedElement.id]: updatedElement,
        },
      })
    );
    lastUpdateFormElementRequestAbort = abort;
  },
});

let lastDedentFormElementRequestAbort: () => void;
formMiddleware.startListening({
  actionCreator: dedentFormElement,
  effect: async (action, listener) => {
    if (lastDedentFormElementRequestAbort) {
      lastDedentFormElementRequestAbort();
    }

    const state = listener.getState() as RootState;
    const formState = state.form;

    if (formState.status !== "success") {
      throw new Error("Attempting to update a form when form is not set");
    }
    const { formId } = formState;
    const { elementState, parentElementId } = action.payload;
    const parentElement = formState.elementsById[parentElementId!];
    const parentElementState = findElementById(
      parentElementId,
      formState.elements
    );
    const grandParentElement = parentElementState?.parentElementId
      ? formState.elementsById[parentElementState!.parentElementId!]
      : null;

    if (grandParentElement) {
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElement.initiate({
          formId,
          updatedElementsById: {
            [elementState.parentElementId]: parentElement,
            [grandParentElement.id]: grandParentElement,
          },
        })
      );
      lastDedentFormElementRequestAbort = abort;
    } else {
      const elements: FormElement[] = formState.elements.map(
        (element) => formState.elementsById[element.id]
      );
      const { abort } = listener.dispatch(
        formsApi.endpoints.updateFormElements.initiate({
          formId,
          formElements: elements,
          commentThreadsDictionary: formState.form.commentThreadsDictionary,
        })
      );

      lastDedentFormElementRequestAbort = abort;
    }
  },
});

let lastDeleteFormUserRequestAbort: () => void;
formMiddleware.startListening({
  actionCreator: deleteFormUser,
  effect: async (action, listener) => {
    if (lastDeleteFormUserRequestAbort) {
      lastDeleteFormUserRequestAbort();
    }

    const state = listener.getState() as RootState;
    const formState = state.form;

    if (formState.status !== "success" && formState.status !== "saving") {
      throw new Error("Attempting to update a form when form is not set");
    }

    const { abort } = listener.dispatch(
      formsApi.endpoints.deleteFormUser.initiate({
        formId: formState.formId,
        userId: action.payload,
      })
    );

    lastDeleteFormUserRequestAbort = abort;
  },
});
