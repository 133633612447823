import { Typography } from "@mui/material";
import React from "react";

export const Header = ({
  children,
  ...props
}: React.PropsWithChildren<unknown>) => {
  return (
    <Typography
      variant="h2"
      fontSize={"1.25rem"}
      fontWeight={"bold"}
      {...props}
    >
      {children}
    </Typography>
  );
};
