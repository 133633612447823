import React from "react";
import { orgRoleTemplateNames } from "src/store/slices/organization-membership.slice";
import {
  Icon,
  ModalFooter,
  ModalBody,
  Button,
  VStack,
  Box,
} from "@chakra-ui/react";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { ErrorSnackbar } from "src/lib/components/ErrorSnackbar";
import { Spacer, SpacerSizes } from "../Spacer";
import { OrganizationModalInformationInput } from "./OrganizationModalInformationInput";
import { OrganizationModalNewUserRole } from "./OrganizationModalNewUserRole";
import { OrganizationModalUserExpiration } from "./OrganizationModalUserExpiration";
import { LuminosModalHeader } from "./LuminosModalHeader";
import { determinePermissionsFromOrgRole } from "../../util/organization.utils";
import { useInviteUserToOrganizationMutation } from "src/services/organization.service";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "src/store";
import { selectUserState } from "src/store/selectors/users.selector";
import {
  selectOrganizationMemberInfo,
  selectOrganizationMembershipExpiration,
  selectOrganizationMembershipEmailValid,
  selectOrganizationMembershipShowCustom,
  selectCurrentOrgMembership,
  selectOrgRoleName,
} from "src/store/selectors/organization-membership.selectors";
import {
  setUserInfoField,
  setCurrentOrgMembershipField,
  resetOrganizationMembershipState,
  setOrgPermissions,
  setShowCustom,
  setEmailValid,
  setOrgRole,
} from "src/store/slices/organization-membership.slice";
import { SuccessSnackbar } from "src/lib/components/SuccessSnackbar";

export const AddNewOrganizationUser = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState<string | null>(
    null
  );
  const userInfo = useAppSelector(selectOrganizationMemberInfo);
  const dispatch = useAppDispatch();
  const organizationName =
    useAppSelector(selectUserState).userContext?.currentOrgMembership.name;
  const expiration = useAppSelector(selectOrganizationMembershipExpiration);
  const [inviteUserToOrganization] = useInviteUserToOrganizationMutation();
  const emailValid = useAppSelector(selectOrganizationMembershipEmailValid);
  const showCustom = useAppSelector(selectOrganizationMembershipShowCustom);
  const currentOrgMembership = useAppSelector(selectCurrentOrgMembership);
  const orgRole = useAppSelector(selectOrgRoleName);

  async function addUser() {
    const orgMembership = { ...currentOrgMembership };
    if (expiration.expirationEnabled) {
      if (expiration.nowVsScheduled === "immediately") {
        orgMembership.membershipExpiration = moment().toISOString();
      } else {
        orgMembership.membershipExpiration = expiration.expirationDateTime;
      }
    }

    const requestBody = {
      firstName: userInfo.user.firstName ?? "",
      lastName: userInfo.user.lastName ?? "",
      email: userInfo.user.email,
      orgMembership,
    };

    try {
      await inviteUserToOrganization({ userInfo: requestBody }).unwrap();

      setSuccessMessage("The user was successfully invited.");
      dispatch(resetOrganizationMembershipState());
    } catch (e: any) {
      setSuccessMessage(null);
      if (e.data.code === "UserHasMembership") {
        setErrorMessage("This user already has a membership.");
      } else {
        setErrorMessage(
          "An error occured while trying to edit the membership."
        );
      }
    }
  }

  const changeRoleTemplate = React.useCallback(
    ({
      userType,
      isMember,
      templateName,
    }: {
      userType: string;
      isMember: boolean;
      templateName?: string;
    }) => {
      const permissions = determinePermissionsFromOrgRole(
        templateName ?? orgRole,
        userInfo,
        isMember
      );

      dispatch(setOrgPermissions(permissions));
      dispatch(
        setCurrentOrgMembershipField({
          value: userType,
          field: "membershipType",
        })
      );

      if (templateName) {
        dispatch(setOrgRole(templateName));
      }
      if (orgRoleTemplateNames.custom === templateName) {
        dispatch(setShowCustom(true));
      } else {
        dispatch(setShowCustom(false));
      }
    },
    [userInfo, dispatch, orgRole]
  );

  const handleRelationshipChangetoMember = React.useCallback(() => {
    changeRoleTemplate({ userType: "member", isMember: true });
  }, [changeRoleTemplate]);

  const handleRelationshipChangetoGuest = React.useCallback(() => {
    changeRoleTemplate({ userType: "guest", isMember: false });
  }, [changeRoleTemplate]);

  const setEmailAddress: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (event) => {
        let emailValid = false;
        const emailParts = (event.target.value as string).split("@");
        if (
          emailParts.length == 2 &&
          emailParts[0].length > 0 &&
          emailParts[1].length > 0
        ) {
          emailValid = true;
        }
        dispatch(
          setUserInfoField({
            value: event.target.value,
            field: "email",
          })
        );
        dispatch(setEmailValid(emailValid));
      },
      [dispatch]
    );

  const handleNameChange = React.useCallback(
    (field: "firstName" | "lastName") => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
          setUserInfoField({
            value: event.target.value,
            field,
          })
        );
      };
    },
    [dispatch]
  );

  const changeRoleTemplateWrapper = React.useCallback(
    (newRole: string) => {
      changeRoleTemplate({
        userType: userInfo.currentOrgMembership.membershipType,
        isMember: userInfo.currentOrgMembership.membershipType == "member",
        templateName: newRole,
      });
    },
    [changeRoleTemplate, userInfo]
  );

  return (
    <>
      <LuminosModalHeader
        iconWrapper={<Icon as={PersonAddAltOutlinedIcon} ml="4px"></Icon>}
        title={"Invite User to Organization"}
        closeButton={false}
      />
      <ModalBody bg="white">
        <VStack spacing="0px" mt="8px" align="left">
          <OrganizationModalNewUserRole
            userInfo={userInfo}
            membershipToEdit={null}
            handleRelationshipChangetoMember={handleRelationshipChangetoMember}
            handleRelationshipChangetoGuest={handleRelationshipChangetoGuest}
            changeRoleTemplateWrapper={changeRoleTemplateWrapper}
            organizationName={organizationName ?? ""}
          />

          <Spacer y={SpacerSizes.md} />
          <Box h="1px" w="auto" bg="gray.500"></Box>
          <Spacer y={SpacerSizes.md} />
          <OrganizationModalInformationInput
            showCustom={showCustom}
            handleNameChange={handleNameChange}
            setEmailAddress={setEmailAddress}
          />
          <OrganizationModalUserExpiration membershipToEdit={null} />
        </VStack>
      </ModalBody>
      <ModalFooter bg="white">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <>
          {emailValid ? (
            <Button data-test-id="send-invite-button" onClick={addUser}>
              Add User
            </Button>
          ) : (
            <Button isDisabled variant="disabled" onClick={addUser}>
              Add User
            </Button>
          )}
        </>
      </ModalFooter>

      <SuccessSnackbar title={"User Invited"} message={successMessage} />
      <ErrorSnackbar title={"Error Inviting User"} message={errorMessage} />
    </>
  );
};
