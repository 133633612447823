import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import {
  findElementById,
  recursiveUpdateStateTitleNumbers,
  recursiveUpdateTitleNumbers,
} from "src/util/form.utils";

import { FormState } from "../slices/form.slice";

export const deleteElementReducer: CaseReducer<
  FormState,
  PayloadAction<{
    parentElementId: string;
    deletedElementId: string;
  }>
> = (state, action) => {
  if (state.status === "success" || state.status === "saving") {
    const { parentElementId, deletedElementId } = action.payload;

    const parentElement = state.elementsById[parentElementId];
    const parentElementState = findElementById(parentElementId, state.elements);

    if (parentElement && parentElementState) {
      // remove element from parent element state children
      const filteredParentChildrenState = parentElementState.children.filter(
        (child) => child.id !== deletedElementId
      );
      parentElementState.children = [...filteredParentChildrenState];

      delete state.elementsById[deletedElementId];

      state.elementsById[parentElement.id] = {
        ...parentElement,
        children: [
          ...state.elementsById[parentElement.id].children.filter(
            (e) => e.id !== deletedElementId
          ),
        ],
      };

      const grandparentElementId = parentElementState.parentElementId;
      const grandparentElementState = grandparentElementId
        ? findElementById(grandparentElementId, state.elements)
        : null;

      recursiveUpdateStateTitleNumbers(
        grandparentElementState
          ? grandparentElementState.children
          : state.elements,
        grandparentElementState,
        state
      );

      recursiveUpdateTitleNumbers(
        grandparentElementState
          ? grandparentElementState.children
          : state.elements,
        state
      );
    } else {
      // remove element from root state elements
      const newElements = state.elements.filter(
        (element) => element.id !== deletedElementId
      );
      state.elements = newElements;

      recursiveUpdateStateTitleNumbers(newElements, null, state);

      delete state.elementsById[deletedElementId];

      recursiveUpdateTitleNumbers(newElements, state);
    }
  }
};
