import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { PathIndex } from "../../types";
import { FormElement } from "../../types/form.types";
import {
  findElementById,
  getTitleNumber,
  recursiveUpdateStateTitleNumbers,
  recursiveUpdateTitleNumbers,
  setTitleNumberPathIndex,
} from "src/util/form.utils";
import { FormElementState, FormState } from "../slices/form.slice";

export const addElementReducer: CaseReducer<
  FormState,
  PayloadAction<{ referenceElementId: string; element: FormElement }>
> = (state, action) => {
  if (state.status === "success" || state.status === "saving") {
    const { referenceElementId, element } = action.payload;
    const referenceElementState = findElementById(
      referenceElementId,
      state.elements
    );
    const referenceElement = state.elementsById[referenceElementId];

    if (
      referenceElement &&
      referenceElementState &&
      referenceElement.type === "section" &&
      referenceElementState.pathIndex.length < 4
    ) {
      // adds new element as the reference element's last child
      const childElementIndentationLevel =
        referenceElementState.indentationLevel + 1;
      const pathIndex = [
        ...referenceElement.pathIndex,
        referenceElement.children.length,
      ];

      const childElementState = {
        id: element.id,
        parentElementId: referenceElementState.id,
        type: element.type,
        pathIndex,
        titleNumberPathIndex: [] as PathIndex,
        children: [],
        indentationLevel: childElementIndentationLevel,
      };

      referenceElementState.children.push(childElementState);
      const updatedParentChildrenState = setTitleNumberPathIndex({
        elements: referenceElementState.children,
        parentTitleNumberPathIndex: referenceElement.titleNumberPathIndex,
      });

      referenceElementState.children = updatedParentChildrenState;
      const updatedChildElementState = updatedParentChildrenState.find(
        (e: FormElementState) => e.id === element.id
      );

      const childElement: FormElement = {
        ...element,
        pathIndex: updatedChildElementState?.pathIndex || [],
        titleNumber: getTitleNumber(
          updatedChildElementState?.titleNumberPathIndex || []
        ),
        titleNumberPathIndex:
          updatedChildElementState?.titleNumberPathIndex || [],
      };

      // update parent element
      state.elementsById[referenceElementId] = {
        ...referenceElement,
        children: [...referenceElement.children, childElement],
      };

      // add new element
      state.elementsById[childElement.id] = {
        ...childElement,
        pathIndex,
        titleNumber: getTitleNumber(childElement.titleNumberPathIndex),
      };
    } else if (referenceElement && referenceElementState) {
      // add the new element as the reference element's sibling
      const newElement: FormElementState = {
        id: element.id,
        parentElementId: referenceElement.parentElementId,
        type: element.type,
        pathIndex: [],
        titleNumberPathIndex: [],
        children: [],
        indentationLevel: referenceElementState.indentationLevel || 0,
      };

      if (referenceElement.parentElementId) {
        // case where the reference element IS NOT at the root level
        const parentElementState: FormElementState | null = findElementById(
          referenceElement.parentElementId,
          state.elements
        );

        if (parentElementState) {
          const index = parentElementState.children.findIndex(
            (element) => element.id === referenceElementId
          );

          // insert new element after reference element
          parentElementState.children.splice(index + 1, 0, {
            ...newElement,
          });

          state.elementsById[element.id] = {
            ...element,
            parentElementId: referenceElement.parentElementId,
          };

          state.elementsById[parentElementState.id] = {
            ...state.elementsById[parentElementState.id],
            children: state.elementsById[parentElementState.id].children.splice(
              index + 1,
              0,
              {
                ...element,
                parentElementId: referenceElement.parentElementId,
              }
            ),
          };

          recursiveUpdateStateTitleNumbers(state.elements, null, state);
          recursiveUpdateTitleNumbers(state.elements, state);
        }
      } else {
        // case where the reference element IS at the root level
        const index = state.elements.findIndex(
          (element) => element.id === referenceElementId
        );

        state.elementsById[element.id] = {
          ...element,
          parentElementId: referenceElement.parentElementId,
        };
        // insert new element after reference element
        state.elements.splice(index + 1, 0, newElement);
        recursiveUpdateStateTitleNumbers(state.elements, null, state);
        recursiveUpdateTitleNumbers(state.elements, state);
      }
    }
  }
};
