export enum RuleOperator {
  isCompleted = "is completed",
  isEqualTo = "equal to",
  hasValue = "filled out",
  includes = "includes",
  filenameIsEqualTo = "filename is equal to",
  didPass = "passing",
  isNotEqualTo = "not equal to",
  isNotCompleted = "is not completed",
  isNotFilledOut = "is not filled out",
  doesNotInclude = "does not include",
}

export const negativeRuleOperatorMapping = {
  [RuleOperator.isCompleted]: RuleOperator.isNotCompleted,
  [RuleOperator.isEqualTo]: RuleOperator.isEqualTo,
  [RuleOperator.hasValue]: RuleOperator.isNotFilledOut,
  [RuleOperator.includes]: RuleOperator.doesNotInclude,

  // rules that are already negative/falsey
  [RuleOperator.isNotEqualTo]: RuleOperator.isNotEqualTo,
  [RuleOperator.isNotCompleted]: RuleOperator.isNotCompleted,
  [RuleOperator.isNotFilledOut]: RuleOperator.isNotFilledOut,
  [RuleOperator.doesNotInclude]: RuleOperator.doesNotInclude,

  // rules without boolean inverse values
  [RuleOperator.filenameIsEqualTo]: RuleOperator.filenameIsEqualTo,
  [RuleOperator.didPass]: RuleOperator.didPass,
};

export type RuleOperatorKey = keyof typeof RuleOperator;

export interface ServerRule {
  firstOperand: string;
  operator: RuleOperatorKey;
  secondOperand: string | boolean;
  continuation?: "AND" | "OR";
}
