import React, { useState, useEffect } from "react";
import {
  Text,
  Tooltip,
  Icon,
  HStack,
  VStack,
  Divider,
  CircularProgress,
} from "@chakra-ui/react";
import { UploadedFile } from "../types";
import { ROLE_2, ROLE_1 } from "../roles";
import { useFileAPI } from "../endpoints/files";
import { handleFileUpload } from "../util/handleFileUpload";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Colors } from "../theme/colors";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { isSubmissionElementEditable } from "../util/isSubmissionElementEditable";
import { useAppDispatch, useAppSelector, RootState } from "src/store";
import {
  lookupSubmissionElementById,
  selectUserRoleOnSubmission,
  selectSubmissionMeta,
  selectSubmissionId,
} from "src/store/selectors/submission.selectors";
import { SubmissionElementEditViewProps } from "src/types/submission.types";
import { updateSubmissionElement } from "src/store/slices/submission.slice";
import { SubmissionViewHeader } from "./SubmissionViewHeader";

export const FileUploadSubmissionView = ({
  id,
}: SubmissionElementEditViewProps) => {
  const element = useAppSelector(lookupSubmissionElementById(id));
  const { postFiles, getFile, deleteFile } = useFileAPI();
  const [disableUpload, setDisableUpload] = useState(false);
  const [allowDelete, setAllowDelete] = useState(false);

  if (element && element.type !== "fileUpload") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(
    element?.elementValue || []
  );
  const [uploading, setUploading] = useState(false);
  const globalUserInfo = useAppSelector((state) => state.user.userContext);
  const role = useAppSelector((state: RootState) =>
    selectUserRoleOnSubmission(state, globalUserInfo?.user._id)
  );
  const meta = useAppSelector(selectSubmissionMeta);
  const dispatch = useAppDispatch();
  const submissionId = useAppSelector(selectSubmissionId);

  const [isEditable, setIsEditable] = useState(
    isSubmissionElementEditable(element?.content, role, meta)
  );

  useEffect(() => {
    const updatedIsEditable = isSubmissionElementEditable(
      element?.content,
      role,
      meta
    );
    setIsEditable(updatedIsEditable);

    if (
      (!element?.content.allowMultiple &&
        (element?.content.uploadedFiles || []).length > 0) ||
      !updatedIsEditable
    ) {
      setDisableUpload(true);
    } else {
      setDisableUpload(false);
    }
  }, [element?.content, role, meta]);

  useEffect(() => {
    if (role === ROLE_2) {
      setAllowDelete(true);
    } else if (role === ROLE_1 && element?.content.restrictDeletion) {
      setAllowDelete(false);
    }
  }, [role, element?.content.restrictDeletion]);

  const upload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEditable || !submissionId) return;
    setUploading(true);
    const [fileId, url] = await handleFileUpload(
      event,
      submissionId,
      id,
      postFiles
    );

    if (!fileId || !url) {
      setUploading(false);
      return;
    }

    const newFile = { fileId, url };
    const allFiles =
      element?.content.allowMultiple && uploadedFiles
        ? [...uploadedFiles, newFile]
        : [newFile];
    setUploadedFiles(allFiles);

    dispatch(
      updateSubmissionElement({
        elementValue: allFiles,
        elementId: element?.id,
      })
    );

    setUploading(false);
  };

  const download = async (fileId: string, fileName: string) => {
    // download file to user's local machine
    const fileResponse = await getFile(fileId);
    if (!fileResponse.url) {
      return;
    }

    const res = await fetch(fileResponse.url);

    // let type = res.headers.get("content-type");

    // type = type!.concat(";charset=utf-8");

    const blob = await res.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName || "downloaded-file";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const deleteFileById = async (fileId: string) => {
    // delete file from the server
    try {
      await deleteFile(fileId);
    } catch (e) {
      console.log(e);
    }
    const newFiles = uploadedFiles?.filter((file) => file.fileId !== fileId);
    setUploadedFiles(newFiles);
    dispatch(
      updateSubmissionElement({
        elementValue: newFiles,
        elementId: element?.id,
      })
    );
  };

  const FileRow = ({ file }: { file: { fileId: string; url: string } }) => {
    return (
      <div style={{ width: "100%" }}>
        <HStack justifyContent={"space-between"}>
          <Tooltip label="Download file">
            <HStack
              onClick={() => download(file.fileId, file.url)}
              pb={2}
              style={{ cursor: "pointer" }}
            >
              <Icon
                boxSize={5}
                color="gray.500"
                as={InsertDriveFileOutlinedIcon}
              />
              <Text style={{ textDecoration: "underline", color: "#0077db" }}>
                {file.url}
              </Text>
            </HStack>
          </Tooltip>
          {allowDelete ? (
            <Tooltip label="Delete file">
              <Icon
                style={{ cursor: "pointer" }}
                onClick={() => deleteFileById(file.fileId)}
                as={ClearOutlinedIcon}
                boxSize={5}
                color="gray.500"
              />
            </Tooltip>
          ) : null}
        </HStack>
        <Divider />
      </div>
    );
  };

  const UploadingLoader = () => {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <HStack pt={2} style={{ width: "88%" }}>
          <CircularProgress
            size="20px"
            isIndeterminate
            color="brightblue.500"
          />
          <Text>Uploading...</Text>
        </HStack>
      </div>
    );
  };

  if (!element) {
    return null;
  }

  return (
    <div style={{ width: "100%" }}>
      <SubmissionViewHeader
        rules={element?.rules}
        title={element.content.title}
        titleNumber={element.titleNumber ?? ""}
        role={role}
        adjudicatorNotes={element.content.adjudicatorNotes}
        surveyTakerNotes={element.content.surveyTakerNotes}
        audience={element.content.audience}
        questionPlaceholder="Enter the file upload instructions"
        question={element.content.question}
      />
      {uploadedFiles ? (
        <VStack style={{ width: "100%", display: "flex" }}>
          {uploadedFiles.map((file: { fileId: string; url: string }, index) => (
            <FileRow key={index} file={file} />
          ))}
        </VStack>
      ) : null}
      {uploading ? <UploadingLoader /> : null}
      <input
        style={{ ...styles.input }}
        id={`file-upload-${id}`}
        type="file"
        onChange={upload}
      />
      <label
        style={{ display: "flex", alignContent: "flex-start" }}
        htmlFor={disableUpload ? "" : `file-upload-${id}`}
      >
        <div
          style={{
            ...styles.label,
            marginBottom: 8,
            backgroundColor: disableUpload
              ? Colors.gray[300]
              : Colors.brightblue[500],
          }}
        >
          <Icon as={FileUploadOutlinedIcon} />
          <Text>Choose a file</Text>
        </div>
      </label>
    </div>
  );
};

const styles = {
  input: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    zIndex: -1,
  },
  label: {
    width: "150px",
    margin: 1,
    padding: 5,
    borderRadius: "2px",
    fontSize: "12px",
    boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",
    color: "white",
    cursor: "pointer",
    justifyContent: "center",
    display: "flex",
  },
};
