//  Our sidenav which is visible by default on the large screen and convert into the drawer in mobile devices.

import React from "react";
import { VStack } from "@chakra-ui/react";
import SideNavItems from "./SideNavItems";

export function SideNav() {
  return (
    <React.Fragment>
      <VStack position={"sticky"} spacing="5" as="nav">
        <SideNavItems />
      </VStack>
    </React.Fragment>
  );
}
