import {
  Box,
  Grid,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { AudienceSelection } from "./AudienceSelection";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import {
  allPredicateVerbOptions,
  getIsPredicateAdjectiveRequired,
  getPredicateAdjectivesOptionsFromConditionalSubjectElement,
  getPredicateVerbOptionsFromElementType,
} from "src/util/rule.utils";
import { RulesSelectInput } from "./RulesSelectInput";
import { useAppSelector } from "src/store";
import { FormElement } from "src/types/form.types";
import { RuleOperator } from "src/types/rule.types";
import { RuleTypography } from "./RuleTypography";
import { staticElementTypes } from "src/util/form.utils";
import { translateDeltaToText } from "src/util/quill.utils";

export interface Rule {
  subjectElementId: string | null;
  audience: string | null;
  conditionalSubjectElementId: string | null;
  predicateVerb: RuleOperator | null;
  predicateAdjective: string | null;
}

export interface ValidRule {
  subjectElementId: string;
  audience: string;
  conditionalSubjectElementId: string;
  predicateVerb: RuleOperator;
  predicateAdjective: string | null;
}

export interface RuleEntryProps {
  displayNumber?: string;
  rule: Rule;
  availableElements: FormElement[];
  availableSubjectElements: FormElement[];
  availableConditionSubjectElements?: FormElement[];
  onRuleChange: ({
    element,
    fieldName,
    value,
  }: {
    element?: FormElement;
    rule: Rule;
    fieldName: string;
    value: string;
  }) => void;
}

export const RuleEntry = ({
  displayNumber,
  rule,
  availableElements,
  availableSubjectElements,
  availableConditionSubjectElements,
  onRuleChange,
}: RuleEntryProps) => {
  const subjectElement = useAppSelector(
    lookupFormElementById(rule.subjectElementId || "")
  );
  const conditionalSubjectElement = useAppSelector(
    lookupFormElementById(rule.conditionalSubjectElementId || "")
  );

  const getTitle = React.useCallback((element: FormElement) => {
    if (element) {
      if (element.type === "textBlock") {
        return `Text Block: ${translateDeltaToText(element.content.value)}`;
      }
      return `${element.titleNumber} ${element.content.title || (element.type === "section" ? "Untitled Section" : "Untitled Element")}`;
    }
  }, []);

  const subjectOptions = React.useMemo(() => {
    if (availableSubjectElements) {
      return availableSubjectElements.map((e) => {
        const label = getTitle(e);
        return {
          value: e.id,
          label,
        };
      });
    }
  }, [availableSubjectElements, getTitle]);

  const conditionalSubjectOptions = React.useMemo(() => {
    const conditionalSubjectElements =
      availableConditionSubjectElements || availableElements;
    if (subjectElement && conditionalSubjectElements) {
      const subjectIndex = conditionalSubjectElements?.findIndex((e) => {
        return e.id === subjectElement.id;
      });

      if (subjectIndex < 0) {
        throw new Error(
          `subject element was not found in available elements: ${subjectElement.id}`
        );
      }
      return conditionalSubjectElements
        .slice(0, subjectIndex)
        .filter((e) => !staticElementTypes.includes(e.type))
        .map((e) => {
          if (!("title" in e.content)) {
            throw new Error("title is not in element content");
          }
          return {
            value: e.id,
            label: getTitle(e),
          };
        });
    }
  }, [
    subjectElement,
    availableElements,
    availableConditionSubjectElements,
    getTitle,
  ]);

  const subjectAudience = React.useMemo(() => {
    return subjectElement?.type === "section"
      ? undefined
      : subjectElement?.content.audience;
  }, [subjectElement]);

  const predicateVerbOptions = React.useMemo(() => {
    if (conditionalSubjectElement) {
      const verbOptionsForElementType = getPredicateVerbOptionsFromElementType(
        conditionalSubjectElement.type
      );

      return allPredicateVerbOptions.filter((o) =>
        verbOptionsForElementType.includes(o.value)
      );
    }
  }, [conditionalSubjectElement]);

  const predicateAdjectiveOptions = React.useMemo(() => {
    if (conditionalSubjectElement && rule.predicateVerb) {
      const isPredicateAdjectiveRequired = getIsPredicateAdjectiveRequired(
        rule.predicateVerb
      );

      if (!isPredicateAdjectiveRequired) {
        return undefined;
      }

      const optionLabels =
        getPredicateAdjectivesOptionsFromConditionalSubjectElement(
          conditionalSubjectElement
        );
      return optionLabels
        ? optionLabels.map((l) => {
            return {
              value: l,
              label: l,
            };
          })
        : undefined;
    }
  }, [conditionalSubjectElement, rule.predicateVerb]);

  const handleSelectChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      const { name, value } = event.target;
      onRuleChange({ element: subjectElement, rule, fieldName: name, value });
    },
    [subjectElement, rule, onRuleChange]
  );

  const handleAudienceChange = React.useCallback(
    (audience: string) => {
      if (subjectElement) {
        onRuleChange({
          element: subjectElement,
          rule,
          fieldName: "audience",
          value: audience,
        });
      }
    },
    [subjectElement, rule, onRuleChange]
  );

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={1} lg={0.25}>
        <RuleTypography>{displayNumber}</RuleTypography>
      </Grid>

      <Grid item container xs={11} spacing={1}>
        <Grid item xs={1.75} md={1} lg={0.6}>
          <RuleTypography>Task</RuleTypography>
        </Grid>
        <Grid data-test-id="subject-dropdown" item xs={5} md={3.5} lg={3}>
          {subjectElement?.id ? (
            <Tooltip title={getTitle(subjectElement)}>
              <Typography
                variant="body1"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: {
                    xs: 15,
                    sm: 150,
                    md: 200,
                    lg: 230,
                  },
                }}
              >
                {getTitle(subjectElement)}
              </Typography>
            </Tooltip>
          ) : (
            <RulesSelectInput
              name="subjectElementId"
              value={subjectElement?.id || ""}
              disabled={Boolean(subjectElement?.id)}
              options={
                subjectOptions
                  ? [
                      { value: "", label: "Select a Question" },
                      ...subjectOptions,
                    ]
                  : []
              }
              formControlProps={{ sx: { maxWidth: "200px" } }}
              onChange={handleSelectChange}
            />
          )}
        </Grid>
        <Grid
          item
          xs={1.75}
          md={1}
          lg={0.3}
          sx={{
            textAlign: {
              sm: "center",
              md: "left",
              lg: "left",
            },
          }}
        >
          <RuleTypography>to</RuleTypography>
        </Grid>
        <Grid item xs={3.5} md={6} lg={1.4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: 115,
              height: 25,
            }}
          >
            {subjectAudience ? (
              <AudienceSelection
                isNameDisplayed={true}
                audience={subjectAudience}
                changeAudience={handleAudienceChange}
              />
            ) : (
              <Typography color="#ccc">--</Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={1.75} md={1} lg={0.7}>
          <RuleTypography>When</RuleTypography>
        </Grid>
        <Grid data-test-id="conditional-dropdown" item xs={3.5} lg={2}>
          <RulesSelectInput
            name="conditionalSubjectElementId"
            value={rule.conditionalSubjectElementId || ""}
            options={conditionalSubjectOptions || []}
            onChange={handleSelectChange}
          />
        </Grid>
        <Grid data-test-id="predicate-verb-dropdown" item xs={3.5} lg={2}>
          <RulesSelectInput
            name="predicateVerb"
            value={rule.predicateVerb || ""}
            options={predicateVerbOptions || []}
            onChange={handleSelectChange}
          />
        </Grid>
        <Grid data-test-id="predicate-adjective-dropdown" item xs={3.25} lg={2}>
          {predicateAdjectiveOptions ? (
            <RulesSelectInput
              name="predicateAdjective"
              value={rule.predicateAdjective || ""}
              options={predicateAdjectiveOptions}
              onChange={handleSelectChange}
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
