import { DocumentSectionWrapper } from "./DocumentSectionWrapper";
import { useAppSelector } from "src/store";
import {
  selectSubmissionElements,
  selectUserRoleOnSubmission,
} from "src/store/selectors/submission.selectors";
import { SubmissionElementState } from "src/store/slices/submission.slice";
import { ElementProvider } from "src/context/ElementContext";
export const LEFT_MARGIN = 20;
import { elementsByType } from "src/util/elements-by-type.utils";

export const SubmissionEditorElements = () => {
  const submissionElements = useAppSelector(selectSubmissionElements);
  const currentRole = useAppSelector(selectUserRoleOnSubmission);

  const renderElements = (
    elements: SubmissionElementState[],
    level: number = 0
  ): JSX.Element[] => {
    return elements?.map((element, i) => {
      const ElementComponent = elementsByType[element.type].submission;

      // Construct children elements recursively if they exist
      const children = element.children
        ? renderElements(element.children, level + 1)
        : undefined;

      return (
        <ElementProvider key={`${level}-${i}-wrapper`} element={element}>
          <DocumentSectionWrapper
            elementId={element.id!}
            userRoleOnDocument={currentRole}
            key={`${level}-${i}-wrapper`}
            readOnly={true}
            isSection={element.type === "section"}
          >
            <div style={{ marginLeft: LEFT_MARGIN }}>
              <ElementComponent id={element.id!} key={`${level}-${i}`}>
                {children}
              </ElementComponent>
            </div>
          </DocumentSectionWrapper>
        </ElementProvider>
      );
    });
  };

  return (
    <div>{submissionElements ? renderElements(submissionElements) : null}</div>
  );
};
