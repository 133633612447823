import { FormElementState } from "src/store/slices/form.slice";
import { FormElement } from "src/types/form.types";
import { SelectItem } from "src/types";
import { RuleOperator, ServerRule } from "src/types/rule.types";
import { Rule, ValidRule } from "src/components/RuleEntry";
import { staticElementTypes } from "./form.utils";

export const allPredicateVerbOptions = [
  { value: RuleOperator.isEqualTo, label: "is equal to" },
  { value: RuleOperator.isNotEqualTo, label: "is not equal to" },
  { value: RuleOperator.hasValue, label: "is filled out" },
  { value: RuleOperator.isNotFilledOut, label: "is not filled out" },
  { value: RuleOperator.includes, label: "includes" },
  { value: RuleOperator.doesNotInclude, label: "does not include" },
];

interface GetAllAvailableSubjectElementsParams {
  currentLevelElements: FormElementState[];
  elementsById: { [elementId: string]: FormElement };
  allowElementsWithRules: boolean;
  excludeRootElements: boolean;
  firstEditableElement: { value: FormElement | null };
}

/**
 *
 * @param param0
 * @returns
 */
export const getAllAvailableSubjectElements = ({
  currentLevelElements,
  elementsById,
  allowElementsWithRules,
  excludeRootElements,
  firstEditableElement = { value: null },
}: GetAllAvailableSubjectElementsParams) => {
  let availableSubjectElements: FormElement[] = [];
  currentLevelElements.forEach((e) => {
    const element = elementsById[e.id];

    if (!element) {
      throw new Error("could not find element in lookup");
    }

    if (excludeRootElements && !firstEditableElement.value) {
      if (!staticElementTypes.includes(element.type)) {
        firstEditableElement.value = element;
      }
    }

    const hasRules = element.rules.length > 0;
    const isNotRootElement =
      excludeRootElements &&
      firstEditableElement.value &&
      element !== firstEditableElement.value;
    if (
      (isNotRootElement || !excludeRootElements) &&
      (allowElementsWithRules === true ||
        (allowElementsWithRules === false && !hasRules))
    ) {
      availableSubjectElements.push(element);
    }
    const childrenSubjectElements = getAllAvailableSubjectElements({
      currentLevelElements: e.children,
      elementsById,
      allowElementsWithRules,
      excludeRootElements,
      firstEditableElement,
    });

    availableSubjectElements = [
      ...availableSubjectElements,
      ...childrenSubjectElements,
    ];
  });

  return availableSubjectElements;
};

interface GetAllElementsWithRulesParams {
  elements: FormElementState[];
  elementsById: { [elementId: string]: FormElement };
}

export const getAllElementsWithRules = ({
  elements,
  elementsById,
}: GetAllElementsWithRulesParams) => {
  let elementsWithRules: FormElement[] = [];
  elements.forEach((e) => {
    const element = elementsById[e.id];

    if (!element) {
      throw new Error("could not find element in lookup");
    }

    if (element.rules.length > 0) {
      elementsWithRules.push(element);
    }

    const childrenWithRules = getAllElementsWithRules({
      elements: e.children,
      elementsById,
    });

    elementsWithRules = [...elementsWithRules, ...childrenWithRules];
  });

  return elementsWithRules;
};

export const getIsPredicateAdjectiveRequired = (
  predicateVerb: RuleOperator
) => {
  return [
    RuleOperator.isEqualTo,
    RuleOperator.isNotEqualTo,
    RuleOperator.includes,
    RuleOperator.doesNotInclude,
  ].includes(predicateVerb);
};

export const getPredicateAdjectivesOptionsFromConditionalSubjectElement = (
  conditionalSubjectElement: FormElement
) => {
  switch (conditionalSubjectElement.type) {
    case "textResponse":
      return null;
    case "singleSelect": {
      const otherIncluded = conditionalSubjectElement.content.other.allowed;
      const noneIncluded = conditionalSubjectElement.content.none.allowed;
      return conditionalSubjectElement.content.items
        .map((item: SelectItem) => item.label)
        .concat(otherIncluded ? ["Other"] : [])
        .concat(noneIncluded ? ["None"] : []);
    }
    case "multiSelect": {
      const allIncluded = conditionalSubjectElement.content.all.allowed;
      const otherIncluded = conditionalSubjectElement.content.other.allowed;
      const noneIncluded = conditionalSubjectElement.content.none.allowed;
      return conditionalSubjectElement.content.items
        .map((item: SelectItem) => item.label)
        .concat(allIncluded ? ["All"] : [])
        .concat(otherIncluded ? ["Other"] : [])
        .concat(noneIncluded ? ["None"] : []);
    }
    case "fileUpload":
      return null;
    case "biasAnalysis":
      return null;
    case "attestation":
      return null;
    case "approved":
      return null;
    case "denied":
      return null;
    case "section":
      return null;
    case "textBlock":
      return null;
    default:
      return null;
  }
};

export const elementTypeToPredicateVerbOptions: {
  [key: string]: RuleOperator[];
} = {
  textResponse: [RuleOperator.hasValue, RuleOperator.isNotFilledOut],
  singleSelect: [
    RuleOperator.hasValue,
    RuleOperator.isNotFilledOut,

    RuleOperator.isEqualTo,
    RuleOperator.isNotEqualTo,
  ],
  multiSelect: [
    RuleOperator.hasValue,
    RuleOperator.isNotFilledOut,

    RuleOperator.includes,
    RuleOperator.doesNotInclude,
  ],
  fileUpload: [RuleOperator.hasValue, RuleOperator.isNotFilledOut],
  biasAnalysis: [
    RuleOperator.hasValue,
    RuleOperator.isNotFilledOut,

    RuleOperator.didPass,
  ],
  attestation: [RuleOperator.hasValue, RuleOperator.isNotFilledOut],
  approved: [],
  denied: [],
  section: [],
  textBlock: [],
};

export const getPredicateVerbOptionsFromElementType = (elementType: string) => {
  if (!(elementType in elementTypeToPredicateVerbOptions)) {
    throw new Error(
      "element type provided is not in elementTypeToPredicateVerbOptions"
    );
  }
  return elementTypeToPredicateVerbOptions[elementType];
};

interface TranslatePredicateVerbToServerFieldParams {
  predicateVerb: RuleOperator;
  predicateAdjective: string | null;
}

export const translatePredicateVerbToServerField = ({
  predicateVerb,
  predicateAdjective,
}: TranslatePredicateVerbToServerFieldParams) => {
  switch (predicateVerb) {
    case RuleOperator.hasValue:
      return { secondOperand: true, operator: "hasValue" as const };
    case RuleOperator.isNotFilledOut:
      return { secondOperand: false, operator: "hasValue" as const };

    case RuleOperator.isEqualTo:
      return {
        secondOperand: predicateAdjective,
        operator: "isEqualTo" as const,
      };
    case RuleOperator.isNotEqualTo:
      return {
        secondOperand: predicateAdjective,
        operator: "isNotEqualTo" as const,
      };

    case RuleOperator.includes:
      return {
        secondOperand: predicateAdjective,
        operator: "includes" as const,
      };
    case RuleOperator.doesNotInclude:
      return {
        secondOperand: predicateAdjective,
        operator: "doesNotInclude" as const,
      };

    case RuleOperator.isCompleted:
      return { secondOperand: true, operator: "isCompleted" as const };

    case RuleOperator.isNotCompleted:
      return { secondOperand: false, operator: "isCompleted" as const };

    case RuleOperator.didPass:
      return { secondOperand: true, operator: "didPass" as const };

    case RuleOperator.filenameIsEqualTo:
      return {
        secondOperand: predicateAdjective,
        operator: "filenameIsEqualTo" as const,
      };

    default:
      throw new Error(`unknown predicate verb provided: ${predicateVerb}`);
  }
};

export const transformRuleToServerRule = (rule: ValidRule): ServerRule => {
  const { operator, secondOperand } = translatePredicateVerbToServerField({
    predicateVerb: rule.predicateVerb,
    predicateAdjective: rule.predicateAdjective,
  });

  if (secondOperand === null) {
    throw new Error("second operand should have a value");
  }

  return {
    firstOperand: rule.conditionalSubjectElementId,
    operator,
    secondOperand,
  };
};

export const getEmptyRule = () => {
  const emptyRule: Rule = {
    subjectElementId: null,
    audience: null,
    conditionalSubjectElementId: null,
    predicateVerb: null,
    predicateAdjective: null,
  };
  return emptyRule;
};
