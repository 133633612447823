import { Spacer, SpacerSizes } from "./Spacer";
import { Flex, Text, HStack, Icon, Tooltip } from "@chakra-ui/react";
import { Colors } from "../theme/colors";
import RuleFolderOutlinedIcon from "@mui/icons-material/RuleFolderOutlined";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { useElementContext } from "src/hooks/useElementContext";
import { useAppSelector } from "src/store";
import { FormElementBuildViewProps } from "src/types/form.types";
import { RichTextReadOnly } from "./RichTextReadOnly";

export const ApprovedPreview = ({ id }: FormElementBuildViewProps) => {
  const element = useAppSelector(lookupFormElementById(id));
  const { element: elementState } = useElementContext();

  if (element && element.type !== "approved") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  if (!element) {
    return null;
  }

  return (
    <Flex
      paddingLeft={5}
      alignContent="flex-start"
      w={"100%"}
      borderLeftColor={"green.500"}
      borderLeftWidth={7}
      borderRadius={2}
      flexDirection="column"
    >
      <Flex flexDirection="row">
        <HStack justifyContent={"space-between"}>
          <HStack gap={0}>
            <span
              style={{ color: Colors.gray[800] }}
              className="material-symbols-outlined"
            >
              new_releases
            </span>
            <Spacer x={SpacerSizes.sm} />

            <Text variant="bold">{element?.content.title}</Text>
          </HStack>
          <HStack>
            {element?.rules && element?.rules.length > 0 ? (
              <Tooltip label="A rule is applied to this element?. Click to view details.">
                <Icon boxSize={5} as={RuleFolderOutlinedIcon} />
              </Tooltip>
            ) : null}
          </HStack>
        </HStack>
      </Flex>
      <RichTextReadOnly value={element.content.description} />
    </Flex>
  );
};
