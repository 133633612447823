import React from "react";
import { HStack, Flex, VStack, Checkbox } from "@chakra-ui/react";
import { isSubmissionElementEditable } from "../util/isSubmissionElementEditable";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  lookupSubmissionElementById,
  selectSubmissionMeta,
  selectUserRoleOnSubmission,
} from "src/store/selectors/submission.selectors";
import { SubmissionElementEditViewProps } from "src/types/submission.types";
import { updateSubmissionElement } from "src/store/slices/submission.slice";
import { SubmissionViewHeader } from "./SubmissionViewHeader";
import { Typography } from "@mui/material";

export const MultiSelectSubmissionView = ({
  id,
}: SubmissionElementEditViewProps) => {
  const element = useAppSelector(lookupSubmissionElementById(id));
  if (element && element.type !== "multiSelect") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }
  const [selectedItems, setSelectedItems] = React.useState<string[]>(
    element?.elementValue || []
  );
  const globalUserId = useAppSelector(
    (state) => state.user.userContext?.user._id
  );
  const meta = useAppSelector(selectSubmissionMeta);
  const role = useAppSelector((state) =>
    selectUserRoleOnSubmission(state, globalUserId)
  );
  const dispatch = useAppDispatch();

  const isEditable = isSubmissionElementEditable(element?.content, role, meta);

  React.useEffect(() => {
    if (
      selectedItems.filter((item) => item !== "None" && item !== "Other")
        .length > 0
    ) {
      setSelectedItems(
        selectedItems.filter((item) => item !== "None" && item !== "Other")
      );
    }
  }, [selectedItems]);

  const updateCheckbox = React.useCallback(
    (label: string) => {
      if (!isEditable) return;

      let newSelectedItems = [...selectedItems];

      const selectedIndex = newSelectedItems.indexOf(label);
      const isSelected = selectedIndex !== -1;

      if (isSelected) {
        newSelectedItems.splice(selectedIndex, 1);
        newSelectedItems = newSelectedItems.filter((item) => item !== "All");
      } else {
        newSelectedItems.push(label);
      }

      setSelectedItems(newSelectedItems);
      dispatch(
        updateSubmissionElement({
          elementValue: newSelectedItems,
          elementId: id,
        })
      );
    },
    [dispatch, id, isEditable, selectedItems]
  );

  const selectNone = React.useCallback(() => {
    if (!isEditable) return;
    if (selectedItems.includes("None")) {
      setSelectedItems([]);
      dispatch(updateSubmissionElement({ elementValue: [], elementId: id }));
    } else {
      setSelectedItems(["None"]);
      dispatch(
        updateSubmissionElement({ elementValue: ["None"], elementId: id })
      );
    }
  }, [dispatch, id, isEditable, selectedItems]);

  const selectOther = React.useCallback(() => {
    if (!isEditable) return;
    if (selectedItems.includes("Other")) {
      setSelectedItems([]);
      dispatch(updateSubmissionElement({ elementValue: [], elementId: id }));
    } else {
      setSelectedItems(["Other"]);
      dispatch(
        updateSubmissionElement({ elementValue: ["Other"], elementId: id })
      );
    }
  }, [dispatch, id, isEditable, selectedItems]);

  const selectAll = React.useCallback(() => {
    if (element && isEditable) {
      if (selectedItems.includes("All")) {
        setSelectedItems([]);
        dispatch(updateSubmissionElement({ elementValue: [], elementId: id }));
      } else {
        const allItems = element.content.items.map((item) => item.label);
        setSelectedItems([...allItems, "All"]);
        dispatch(
          updateSubmissionElement({ elementValue: allItems, elementId: id })
        );
      }
    }
  }, [dispatch, element, id, isEditable, selectedItems]);

  if (!element) {
    return null;
  }

  return (
    <Flex w={"100%"} direction="column">
      <div style={{ width: "100%" }}>
        <SubmissionViewHeader
          title={element.content.title}
          titleNumber={element.titleNumber ?? ""}
          role={role}
          adjudicatorNotes={element.content.adjudicatorNotes}
          surveyTakerNotes={element.content.surveyTakerNotes}
          audience={element.content.audience}
          questionPlaceholder="Enter the question"
          question={element.content.question}
          rules={element.rules}
        />
      </div>
      <HStack justifyContent={"flex-start"}>
        <VStack gap={1} alignItems={"flex-start"} justifyContent={"flex-start"}>
          {element.content.items.map((item, i) => (
            <Checkbox
              isFocusable={isEditable}
              onChange={() => updateCheckbox(item.label)}
              colorScheme="brightblue"
              key={i}
              isChecked={selectedItems.includes(item.label)}
              value={item.label}
              alignItems="flex-start"
            >
              <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
                {item.label}
              </Typography>
            </Checkbox>
          ))}
          {element.content.other.allowed && (
            <Checkbox
              isFocusable={isEditable}
              onChange={selectOther}
              colorScheme="brightblue"
              isChecked={selectedItems.includes(element.content.other.label)}
              value={element.content.other.label}
              alignItems="flex-start"
            >
              <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
                {element.content.other.label}
              </Typography>
            </Checkbox>
          )}
          {element.content.none.allowed && (
            <Checkbox
              isFocusable={isEditable}
              onChange={selectNone}
              colorScheme="brightblue"
              isChecked={selectedItems.includes(element.content.none.label)}
              value={element.content.none.label}
              alignItems="flex-start"
            >
              <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
                {element.content.none.label}
              </Typography>
            </Checkbox>
          )}
          {element.content.all.allowed && (
            <Checkbox
              isFocusable={isEditable}
              onChange={selectAll}
              colorScheme="brightblue"
              isChecked={selectedItems.includes(element.content.all.label)}
              value={element.content.all.label}
              alignItems={"flex-start"}
            >
              <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
                {element.content.all.label}
              </Typography>
            </Checkbox>
          )}
        </VStack>
      </HStack>
    </Flex>
  );
};
