import { SubmissionElementEditViewProps } from "src/types/submission.types";
import { Spacer, SpacerSizes } from "./Spacer";
import { Flex, Text, HStack } from "@chakra-ui/react";
import { Colors } from "../theme/colors";
import { useAppSelector } from "src/store";
import { lookupSubmissionElementById } from "src/store/selectors/submission.selectors";
import { RichTextReadOnly } from "./RichTextReadOnly";

export const ApprovedSubmissionView = ({
  id,
}: SubmissionElementEditViewProps) => {
  const element = useAppSelector(lookupSubmissionElementById(id));

  if (element && element.type !== "approved") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  if (!element) {
    return null;
  }

  return (
    <Flex
      alignContent="flex-start"
      w={"100%"}
      borderLeftColor={"green.500"}
      borderLeftWidth={7}
      borderRadius={2}
      flexDirection="column"
      paddingLeft={5}
    >
      <Flex flexDirection="row" alignItems={"flex-end"}>
        <HStack w={"96%"} justifyContent={"space-between"}>
          <HStack gap={0}>
            <span
              style={{ color: Colors.gray[800] }}
              className="material-symbols-outlined"
            >
              new_releases
            </span>
            <Spacer x={SpacerSizes.sm} />

            <Text variant="bold">{element.content.title}</Text>
          </HStack>
        </HStack>
      </Flex>
      <RichTextReadOnly value={element.content.description} />
    </Flex>
  );
};
