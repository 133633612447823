import React from "react";
import {
  PRIVILEGE_BANNER_HEIGHT,
  PrivilegedBanner,
} from "../components/PrivilegedBanner";
import { Colors } from "../theme/colors";

import { useAppSelector } from "../store";
import {
  SUBMISSION_STATUS_HEIGHT,
  SubmissionStatusDisplay,
} from "../components/SubmissionStatusDisplay";
import { PrivilegeConfirmationModal } from "../components/Modal/PrivilegeConfirmationModal";
import { HEADER_HEIGHT } from "../components/LuminosNavBar";
import { setDisplayPrivilegeConfirmation } from "src/store/slices/submission.slice";
import {
  selectDisplayPrivilegeBanner,
  selectSubmissionMeta,
  selectDisplayPrivilegeConfirmation,
} from "src/store/selectors/submission.selectors";
import { SubmissionEditorElements } from "./SubmissionEditorElements";
import { AuthenticatedPage } from "../components/AuthenticatedPage";
import { useAppDispatch } from "../store";
import { setSelectedElementId } from "src/store/slices/selected-element.slice";
import { SubmissionEditorTitle } from "./SubmissionEditorTitle";
import { SubmissionActions } from "./SubmissionActions";

export const SubmissionEditor = () => {
  const submissionMeta = useAppSelector(selectSubmissionMeta);
  const displayPrivilegeBanner = useAppSelector(selectDisplayPrivilegeBanner);
  const displayPrivilegeConfirmation = useAppSelector(
    selectDisplayPrivilegeConfirmation
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const closestParent = (event.target as HTMLElement).closest(
        ".element-wrapper"
      )?.id;
      const isClickInside = (event.target as HTMLElement).closest(
        ".document-container"
      );
      const isClickInsideComment = (event.target as HTMLElement).id;

      if (closestParent) {
        dispatch(setSelectedElementId(closestParent));
      } else if (isClickInsideComment.includes("downshift")) {
        return;
      } else if (!isClickInside) {
        dispatch(setSelectedElementId(null));
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [dispatch]);

  return (
    <AuthenticatedPage
      navBarLeftAlignedContent={<SubmissionEditorTitle />}
      navBarRightAlignedContent={<SubmissionActions />}
    >
      <div
        style={{
          display: "flex",
          marginTop: HEADER_HEIGHT,
          height: `100%`,
          minHeight: `100vh`,
          backgroundColor: Colors.gray[50],
        }}
      >
        <PrivilegeConfirmationModal
          open={displayPrivilegeConfirmation ?? false}
          setOpen={(x) => dispatch(setDisplayPrivilegeConfirmation(x))}
        />
        <div style={{ minWidth: "68px", marginRight: "auto" }} />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "50px 1fr",
          }}
        >
          <div style={{ backgroundColor: Colors.gray[50] }}></div>
          <div
            style={{
              position: "relative",
              width: "800px",
              marginTop: PRIVILEGE_BANNER_HEIGHT,
              backgroundColor: Colors.gray[50],
            }}
          >
            {displayPrivilegeBanner ? <PrivilegedBanner /> : null}
            <div
              className="document-container"
              style={{
                borderRadius: "3px",
                backgroundColor: Colors.white[100],
                padding: 40,
                paddingTop: SUBMISSION_STATUS_HEIGHT,
                border: `1px solid ${Colors.gray[600]}`,
                minHeight: `calc(100vh - ${HEADER_HEIGHT} * 3)`,
              }}
            >
              <SubmissionEditorElements />
            </div>
          </div>
        </div>
        <div style={{ width: 50 }} />
        <div
          style={{
            width: "400px",
            marginTop: PRIVILEGE_BANNER_HEIGHT,
          }}
        >
          {submissionMeta ? (
            <SubmissionStatusDisplay submissionStatus={submissionMeta.status} />
          ) : null}
        </div>
        <div style={{ minWidth: "4px", marginRight: "auto" }} />
      </div>
    </AuthenticatedPage>
  );
};
