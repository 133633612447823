import {
  Alert,
  Button,
  HStack,
  ListItem,
  ModalBody,
  ModalFooter,
  Switch,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";

interface SubmissionPrivilegeEnabledBodyProps {
  onClose: () => void;
  savePrivilegeState: (x: boolean) => Promise<boolean>;
}

export const SubmissionPrivilegeEnabledBody = ({
  onClose,
  savePrivilegeState,
}: SubmissionPrivilegeEnabledBodyProps) => {
  const [disablingPrivilege, setDisablingPrivilege] = React.useState(false);

  const disablePrivilege = React.useCallback(() => {
    setDisablingPrivilege(!disablingPrivilege);
  }, [disablingPrivilege]);

  const clearAndSavePrivilegeassertion = React.useCallback(async () => {
    await savePrivilegeState(false);
    onClose();
  }, [onClose, savePrivilegeState]);

  return (
    <>
      <ModalBody bg="white">
        <VStack spacing="12px" mt="8px">
          <Alert
            colorScheme={disablingPrivilege ? "gray" : "green"}
            variant="left-accent"
          >
            Legal privilege being asserted.
          </Alert>
          <HStack w="full">
            <Switch
              size="sm"
              isChecked={!disablingPrivilege}
              onChange={disablePrivilege}
              colorScheme={"brightblue"}
            />
            <Text mt="20px" mb="20px">
              Assert Legal Privilege
            </Text>
          </HStack>
          {disablingPrivilege ? (
            <>
              <Alert status="error" variant="left-accent">
                You are about to stop asserting legal privilege.
              </Alert>
              <Text w="full">When asserting privilege...</Text>
              <UnorderedList w="full" ml="160px" mr="100px">
                <ListItem>
                  Form and submission collaborators must be approved by a person
                  with the Manage Legal Privilege permission.
                </ListItem>
                <ListItem>
                  All collaborators must agree to your communications and
                  confidentiality statement.
                </ListItem>
                <ListItem>
                  A privilege assertion banner will be displayed.
                </ListItem>
              </UnorderedList>
            </>
          ) : (
            <></>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter bg="white">
        {!disablingPrivilege ? (
          <Button onClick={onClose}>Close</Button>
        ) : (
          <>
            <Button onClick={onClose}>Close</Button>
            <Button
              variant="secondary"
              onClick={clearAndSavePrivilegeassertion}
            >
              Remove Privilege
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};
