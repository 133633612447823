import {
  isHotkey,
  moveSelection,
  PlateEditor,
  Value,
} from "@udecode/plate-common";
import { Range } from "slate";
import { KeyboardEventHandler } from "./create-mention-plugin";
import { MoveSelectionByOffsetOptions } from "./mention-types";

export const moveSelectionByOffset: <V extends Value>(
  editor: PlateEditor<V>,
  options?: MoveSelectionByOffsetOptions<V>
) => KeyboardEventHandler =
  (editor, { query = () => true } = {}) =>
  (event) => {
    const { selection } = editor;

    if (!selection || Range.isExpanded(selection) || !query(editor)) {
      return false;
    }

    if (isHotkey("left", event)) {
      event.preventDefault();
      moveSelection(editor, { unit: "offset", reverse: true });
      return true;
    }

    if (isHotkey("right", event)) {
      event.preventDefault();
      moveSelection(editor, { unit: "offset" });
      return true;
    }
  };
