import { createSelector } from "@reduxjs/toolkit";
import { ROLE_1 } from "src/roles";
import { RootState, Selector } from "src/store";
import { SubmissionElement } from "src/types/submission.types";

export const selectSubmissionState = (state: RootState) => {
  return state.submission;
};

export const selectSubmissionStatus = createSelector(
  selectSubmissionState,
  (submissionState) => {
    return submissionState.status;
  }
);

export const selectSubmissionErrorMessage = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (submissionState.status === "error") {
      return submissionState.message;
    }
  }
);

export const selectSubmissionId = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.submissionId;
    }
  }
);

export const selectSubmissionMeta = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.meta;
    }
  }
);

export const selectSubmissionUsers = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.submission.users;
    }
  }
);

export const selectSubmissionReadOnly = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.submission.readOnly;
    }
  }
);

export const selectDisplayPrivilegeBanner = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.displayPrivilegeBanner;
    }
  }
);

export const selectDisplayPrivilegeConfirmation = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.displayPrivilegeConfirmation;
    }
  }
);

export const selectSubmissionEditingElementId = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.editingElementId;
    }
  }
);

export const selectSubmissionElements = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.elements;
    }
  }
);

export const selectSubmissionElementsById = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.elementsById;
    }
  }
);

export const lookupSubmissionElementById = (
  elementId: string
): Selector<SubmissionElement | undefined> =>
  createSelector(selectSubmissionState, (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.elementsById[elementId];
    }
  });

export const selectSubmission = createSelector(
  selectSubmissionState,
  (submissionState) => {
    if (
      submissionState.status === "success" ||
      submissionState.status === "saving"
    ) {
      return submissionState.submission;
    }
  }
);

export const selectUserRoleOnSubmission = createSelector(
  [
    (state: RootState) => selectSubmission(state),
    (state: RootState, userId?: string) => userId,
  ],
  (submission, userId) => {
    return (
      submission?.users?.find((user) => user.userId === userId)?.role ?? ROLE_1
    );
  }
);
