import { CommentThread, CommentThreadDictionary } from "../../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { newConversationReducer } from "../reducers/new-conversation.reducer";
import { updateConversationReducer } from "../reducers/update-conversation.reducer";
import _ from "lodash";
import { formsApi } from "src/services/forms.service";
import { submissionsApi } from "src/services/submission.service";

export type CommentsState = {
  commentThreadsDictionary: CommentThreadDictionary;
  primaryCommentFilter: string;
  secondaryCommentFilter: string;
};

const initialState: CommentsState = {
  commentThreadsDictionary: {},
  primaryCommentFilter: "all conversations",
  secondaryCommentFilter: "open",
};

export const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    newConversation: newConversationReducer,
    updateConversation: updateConversationReducer,

    resolveConversation: (
      state,
      action: PayloadAction<{
        commentThread: CommentThread;
        commentThreads: CommentThread[];
        resolved: boolean;
        elementId: string;
      }>
    ) => {
      const { commentThread, resolved, elementId } = action.payload;
      const updatedCommentThreads: CommentThread[] =
        state.commentThreadsDictionary[elementId].map((thread) => {
          if (_.isEqual(thread, commentThread)) {
            return {
              ...thread,
              resolved,
            };
          }
          return thread;
        });

      state.commentThreadsDictionary[elementId] = updatedCommentThreads;
    },

    updatePrimaryCommentFilter: (state, action: PayloadAction<string>) => {
      state.primaryCommentFilter = action.payload;
    },

    updateSecondaryCommentFilter: (state, action: PayloadAction<string>) => {
      state.secondaryCommentFilter = action.payload;
    },

    updateCommentThreadsDictionary: (
      state,
      action: PayloadAction<CommentThreadDictionary>
    ) => {
      state.commentThreadsDictionary = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      formsApi.endpoints.getFormById.matchFulfilled,
      (state, action) => {
        const form = action.payload;
        state.commentThreadsDictionary = form.commentThreadsDictionary;
      }
    );

    builder.addMatcher(
      submissionsApi.endpoints.getSubmissionById.matchFulfilled,
      (state, action) => {
        const submission = action.payload;
        state.commentThreadsDictionary = submission.commentThreadsDictionary;
      }
    );
  },
});

export const {
  newConversation,
  updateConversation,
  updatePrimaryCommentFilter,
  updateSecondaryCommentFilter,
  updateCommentThreadsDictionary,
  resolveConversation,
} = commentsSlice.actions;
