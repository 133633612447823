import { cn, withRef } from "@udecode/cn";
import { getHandler, PlateElement, useElement } from "@udecode/plate-common";
import { TMentionElement } from "@udecode/plate-mention";
import { useFocused, useSelected } from "slate-react";
import { RootState, useAppSelector } from "../../../store";

export const MentionElement = withRef<
  typeof PlateElement,
  {
    prefix?: string;
    onClick?: (mentionNode: any) => void;
    renderLabel?: (mentionable: TMentionElement) => string;
  }
>(({ children, prefix, renderLabel, className, onClick, ...props }, ref) => {
  const element = useElement<TMentionElement>();
  const selected = useSelected();
  const focused = useFocused();
  const userQuery = useAppSelector(
    (state: RootState) => state.user.userContext
  );
  const name = userQuery?.user.firstName + " " + userQuery?.user.lastName;
  const mentionIsCurrentUser =
    element.value === name || element.value === userQuery?.user.email;

  return (
    <PlateElement
      ref={ref}
      className={cn(
        "inline-block cursor-pointer rounded-md px-1.5 py-0.5 align-baseline text-sm font-bold",
        selected && focused && "ring-2 ring-ring",
        element.children[0].bold === true && "font-bold",
        element.children[0].italic === true && "italic",
        element.children[0].underline === true && "underline",
        mentionIsCurrentUser === true && "bg-blue-100 text-blue-600",
        className
      )}
      data-slate-value={element.value}
      contentEditable={false}
      onClick={getHandler(onClick, element)}
      {...props}
    >
      {prefix}
      {renderLabel ? renderLabel(element) : element.value}
      {children}
    </PlateElement>
  );
});
