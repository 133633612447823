import React from "react";
import { FormElementState } from "src/store/slices/form.slice";

interface ElementContextConfig {
  element: FormElementState | null;
}

export const ElementContext = React.createContext<ElementContextConfig>({
  element: null,
});

export const ElementProvider = ({
  children,
  element,
}: React.PropsWithChildren<{ element: FormElementState }>) => {
  return (
    <ElementContext.Provider value={{ element }}>
      {children}
    </ElementContext.Provider>
  );
};
