import { AuthenticatedPage } from "../components/AuthenticatedPage";
import { useGetFormByIdQuery } from "src/services/forms.service";
import { PageLoader } from "src/lib/components/PageLoader";
import { FormBuilderTitle } from "src/components/FormBuilderTitle";
import { useAppSelector } from "src/store";
import { ErrorSnackbar } from "src/lib/components/ErrorSnackbar";
import { FormBuilder } from "./FormBuilder";
import { FormActions } from "./FormActions";
import {
  selectFormErrorMessage,
  selectFormsEditingElementId,
  selectFormStatus,
} from "src/store/selectors/form.selectors";

interface FormBuilderDataPageProps {
  formId: string;
}

export const FormBuilderDatePage = ({ formId }: FormBuilderDataPageProps) => {
  const userId = useAppSelector((state) => state.user.userContext?.user?._id);
  const formErrorMessage = useAppSelector(selectFormErrorMessage);
  const formStatus = useAppSelector(selectFormStatus);
  const editingElementId = useAppSelector(selectFormsEditingElementId);

  const { data, error, isLoading } = useGetFormByIdQuery(
    { formId },
    {
      pollingInterval: 5000,
      skip: Boolean(editingElementId) || formStatus === "saving",
    }
  );

  return (
    <AuthenticatedPage
      navBarLeftAlignedContent={<FormBuilderTitle />}
      navBarRightAlignedContent={<FormActions />}
    >
      {!error && isLoading ? <PageLoader /> : null}

      {!isLoading && data && userId ? <FormBuilder /> : null}
      <ErrorSnackbar
        message={
          error || formErrorMessage
            ? formErrorMessage || "Failed to get form"
            : undefined
        }
      />
    </AuthenticatedPage>
  );
};
