import React from "react";
import { HEADER_HEIGHT } from "./LuminosNavBar";
import { Colors } from "src/theme/colors";
import { PRIVILEGE_BANNER_HEIGHT, PrivilegedBanner } from "./PrivilegedBanner";
import { SUBMISSION_STATUS_HEIGHT } from "./SubmissionStatusDisplay";
import { useAppDispatch, useAppSelector } from "src/store";
import { setSelectedElementId } from "src/store/slices/selected-element.slice";
import { FormBuilderElements } from "./FormBuilderElements";
import {
  selectDisplayPrivilegeBanner,
  selectFormMeta,
  selectIsRulesDrawerOpen,
  selectDisplayPrivilegeConfirmation,
} from "src/store/selectors/form.selectors";
import { setDisplayPrivilegeConfirmation } from "src/store/slices/form.slice";
import { Box } from "@mui/material";
import { RulesDrawer } from "./RulesDrawer";
import { PrivilegeConfirmationModal } from "./Modal/PrivilegeConfirmationModal";

export const FormBuilder = () => {
  const dispatch = useAppDispatch();
  const displayPrivilegeBanner = useAppSelector(selectDisplayPrivilegeBanner);
  const isRulesDrawerOpen = useAppSelector(selectIsRulesDrawerOpen);
  const formMeta = useAppSelector(selectFormMeta);
  const displayPrivilegeConfirmation = useAppSelector(
    selectDisplayPrivilegeConfirmation
  );

  React.useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const closestParentId = (event.target as HTMLElement).closest(
        ".element-wrapper"
      )?.id;
      const isClickInside = (event.target as HTMLElement).closest(
        ".document-container"
      );
      const isClickInsideComment = (event.target as HTMLElement).id;

      if (closestParentId) {
        dispatch(setSelectedElementId(closestParentId));
      } else if (isClickInsideComment.includes("downshift")) {
        //handles case of click inside mention dropdown
        return;
      } else if (!isClickInside) {
        dispatch(setSelectedElementId(null));
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [dispatch]);

  const showPrivilegeBanner = React.useMemo(() => {
    return displayPrivilegeBanner || formMeta?.privileged;
  }, [displayPrivilegeBanner, formMeta]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: HEADER_HEIGHT,
        marginLeft: "52px",
        backgroundColor: Colors.gray[50],
        height: `calc(100vh - ${HEADER_HEIGHT})`,
      }}
    >
      <RulesDrawer open={isRulesDrawerOpen || false} />
      <PrivilegeConfirmationModal
        open={displayPrivilegeConfirmation ?? false}
        setOpen={(x) => dispatch(setDisplayPrivilegeConfirmation(x))}
      />

      {showPrivilegeBanner ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: isRulesDrawerOpen ? "150px" : 0,
          }}
        >
          <Box
            style={{
              width: "800px",
              paddingTop: PRIVILEGE_BANNER_HEIGHT,
              marginBottom: PRIVILEGE_BANNER_HEIGHT,
            }}
          >
            <PrivilegedBanner />
          </Box>
        </Box>
      ) : null}

      <Box
        sx={{
          display: "flex",
          marginTop: isRulesDrawerOpen
            ? showPrivilegeBanner
              ? "20px"
              : "120px"
            : showPrivilegeBanner
              ? 0
              : "40px",
          justifyContent: "center",
          height: `calc(100vh - ${HEADER_HEIGHT})`,
          overflow: "scroll",
        }}
      >
        <Box
          style={{
            width: "800px",
            position: "relative",
            minHeight: "100vh",
            backgroundColor: Colors.gray[50],
          }}
        >
          <Box
            className="document-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              backgroundColor: Colors.white[100],
              border: `1px solid ${Colors.gray[600]}`,
              padding: 40,
              paddingTop: SUBMISSION_STATUS_HEIGHT,
              minHeight: `calc(100vh - ${HEADER_HEIGHT} * 3)`,
              borderRadius: "3px",
            }}
          >
            <FormBuilderElements />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
