import React from "react";
import { useAppSelector } from "src/store";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { FormElementBuildViewProps } from "src/types/form.types";
import { PreviewHeader } from "./PreviewHeader";
import { RichTextReadOnly } from "./RichTextReadOnly";
import { Delta } from "src/util/quill.utils";
import { Typography } from "@mui/material";

export const TextResponsePreview = ({
  id,
}: React.PropsWithChildren<FormElementBuildViewProps>) => {
  const element = useAppSelector(lookupFormElementById(id));

  if (element && element.type !== "textResponse") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  if (!element) {
    return null;
  }

  return (
    <div>
      <PreviewHeader
        titleDataTestId={`text-response-title-text-${element?.pathIndex.join("-")}`}
        rules={element.rules}
        title={element.content.title}
        titleNumber={element.titleNumber ?? ""}
        adjudicatorNotes={element.content.adjudicatorNotes}
        surveyTakerNotes={element.content.surveyTakerNotes}
        audience={element.content.audience}
        questionPlaceholder="Enter the question"
        question={element.content.question}
      />
      <Typography>Response</Typography>
      <RichTextReadOnly
        placeholder="Please enter a response"
        value={new Delta()}
      />
    </div>
  );
};
