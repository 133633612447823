import { RootState } from "src/store";
import { submissionsApi } from "src/services/submission.service";
import {
  Action,
  ListenerEffect,
  PayloadAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";

let lastUpdateSubmissionPrivilegeConfirmationRequestAbort: () => void;

export const handleDisplayPrivilegeConfirmation: ListenerEffect<
  PayloadAction<boolean>,
  unknown,
  ThunkDispatch<unknown, unknown, Action>,
  unknown
> = async (action, listener) => {
  if (lastUpdateSubmissionPrivilegeConfirmationRequestAbort) {
    lastUpdateSubmissionPrivilegeConfirmationRequestAbort();
  }
  const state = listener.getState() as RootState;
  const submissionState = state.submission;

  if (
    submissionState.status !== "success" &&
    submissionState.status !== "saving"
  ) {
    throw new Error(
      "Attempting to update a submission when submission is not set"
    );
  }

  const { submissionId } = submissionState;
  const { abort } = listener.dispatch(
    submissionsApi.endpoints.updateSubmissionPrivilegeWarning.initiate({
      submissionId,
      privilegeWarningDismissed: action.payload,
    })
  );
  lastUpdateSubmissionPrivilegeConfirmationRequestAbort = abort;
};
