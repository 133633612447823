import {
  findNode,
  FindNodeOptions,
  getPluginType,
  PlateEditor,
  Value,
} from "@udecode/plate-common";
import { ELEMENT_MENTION_INPUT } from "./create-mention-plugin";

export const findMentionInput = <V extends Value>(
  editor: PlateEditor<V>,
  options?: Omit<FindNodeOptions<V>, "match">
) =>
  findNode(editor, {
    ...options,
    match: { type: getPluginType(editor, ELEMENT_MENTION_INPUT) },
  });
