import React, { ReactNode } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  VStack,
} from "@chakra-ui/react";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import { MembershipDetail } from "../../types";

import { EditOrganizationMembership } from "./EditOrganizationMembership";
import { AddNewOrganizationUser } from "./AddNewOrganizationUser";
import { useAppDispatch } from "src/store";
import { resetOrganizationMembershipState } from "src/store/slices/organization-membership.slice";
import { SuccessSnackbar } from "src/lib/components/SuccessSnackbar";

export const orgRoleTemplateNames = {
  governor: "Governor",
  standardMember: "Standard Member",
  standardGuest: "Standard Guest",
  custom: "Custom",
};

export type PermissionDetail = {
  variableName: string;
  text: string;
  helpText: ReactNode;
};

export function OrganizationMembershipModal({
  isOpen,
  onClose,
  membershipToEdit,
}: {
  isOpen: boolean;
  onClose: () => void;
  membershipToEdit: MembershipDetail | null;
}) {
  const dispatch = useAppDispatch();
  const [successModalIsOpen, setSuccessModalIsOpen] = React.useState(false);

  const closeModal = React.useCallback(() => {
    onClose();
    dispatch(resetOrganizationMembershipState());
  }, [dispatch, onClose]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent
          data-test-id="organization-membership-modal"
          minW="fit-content"
        >
          {membershipToEdit ? (
            <EditOrganizationMembership
              openSuccessModal={setSuccessModalIsOpen}
              membershipToEdit={membershipToEdit}
              onClose={closeModal}
            />
          ) : (
            <AddNewOrganizationUser onClose={closeModal} />
          )}
        </ModalContent>
      </Modal>
      <SuccessSnackbar
        title={"Membership Edited"}
        message={
          successModalIsOpen
            ? "The user's membership was successfully edited"
            : null
        }
      />
    </>
  );
}

const checkboxTooltipFontSize = "sm";
export const permissionDetails: PermissionDetail[] = [
  {
    variableName: "manageOrgPermissions",
    text: "Manage Permissions for All Organization Users",
    helpText: (
      <VStack align="start" spacing="0px">
        <Text fontSize={checkboxTooltipFontSize}>
          Essentially an administrator role. A user with this permission can:
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Add users to the organization{" "}
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Manage the permissions below for all users
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Manage organization settings except
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          those related to legal privilege
        </Text>
      </VStack>
    ),
  },
  {
    variableName: "manageOrgSettings",
    text: "Manage All Settings of the Organization",
    helpText: (
      <VStack align="start" spacing="0px">
        <Text fontSize={checkboxTooltipFontSize}>
          A user with this role can manage all organization settings except
          those involving legal privilege.
        </Text>
      </VStack>
    ),
  },
  {
    variableName: "inviteUser",
    text: "Invite Users to the Organization",
    helpText: (
      <VStack align="start" spacing="0px">
        <Text fontSize={checkboxTooltipFontSize}>
          A user with this permission can invite other users via comments in
          Forms and Form Submissions.
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Luminos.AI recommends this permission be
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          checked for nearly all users.
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Luminos.AI strongly advises against removing
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          this permission for members of
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          your organization.
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - In some sensitive situations, it may
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          be advisable to prevent external / guest
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          users from sending invitations.
        </Text>
      </VStack>
    ),
  },
  {
    variableName: "manageLegalPrivilege",
    text: "Manage Legal Privilege",
    helpText: (
      <VStack align="start" spacing="0px">
        <Text fontSize={checkboxTooltipFontSize}>
          A user with this permission can
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Mark a Form (and all of its submissions)
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          as subject to legal privilege.
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Approve or reject the addition of users
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          to Forms and Form Submissions
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          that are subject to legal privilege.
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Modify the organizations legal privilege
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          language.
        </Text>
      </VStack>
    ),
  },
  {
    variableName: "createOrCopyForms",
    text: "Create or Copy Forms",
    helpText: (
      <VStack align="start" spacing="0px">
        <Text fontSize={checkboxTooltipFontSize}>
          A user with this privilege can create new forms from scratch or by
          copying existing forms.
        </Text>
      </VStack>
    ),
  },
  {
    variableName: "seeAllMembers",
    text: "See All Organization Members",
    helpText: (
      <VStack align="start" spacing="0px">
        <Text fontSize={checkboxTooltipFontSize}>
          This permission allows a user to see all
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          members of the organization even if they
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          haven&apos;t interacted with them before.
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - All organization members will have
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          this permission.
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Luminos.AI recommends very limited
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          use of this permission with organization guests.
        </Text>
      </VStack>
    ),
  },
  {
    variableName: "seeAllGuests",
    text: "See All Organization Guests (External Users)",
    helpText: (
      <VStack align="start" spacing="0px">
        <Text fontSize={checkboxTooltipFontSize}>
          This permission allows a user to see all
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          guests of the organization even if they
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          haven&apos;t interacted with them before.
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Luminos.AI recommends caution when
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          granting access to this permission
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          to organization members.
        </Text>
        <Text fontSize={checkboxTooltipFontSize}>
          {" "}
          - Luminos.AI recommends very limited
        </Text>
        <Text fontSize={checkboxTooltipFontSize} ml="8px">
          {" "}
          use of this permission with organization guests.
        </Text>
      </VStack>
    ),
  },
];
