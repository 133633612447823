import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";

export const selectUserState = (state: RootState) => {
  return state.user;
};

export const selectCreateOrCopyForms = createSelector(
  selectUserState,
  (userState) => {
    return userState.userContext?.currentOrgMembership.permissions
      ?.createOrCopyForms;
  }
);
