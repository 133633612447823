export const Colors = {
  brand: {
    100: "#F8E88B",
    200: "#F7E478",
    300: "#F6E065",
    400: "#F5DC51",
    500: "#F3D532",
    600: "#F3D52B",
    700: "#F2D118",
    800: "#E7C70D",
    900: "#D4B60C",
  },
  red: {
    100: "#FFAC99",
    200: "#FF9B85",
    300: "#FF8A70",
    400: "#FF7A5C",
    500: "#FF5B36",
    600: "#FF481F",
    700: "#FF370A",
    800: "#F52D00",
    900: "#E02900",
  },
  brightblue: {
    100: "#A4ABF4",
    200: "#929AF2",
    300: "#8089EF",
    400: "#6E78ED",
    500: "#5965EB",
    600: "#4957E9",
    700: "#3746E6",
    800: "#2535E4",
    900: "#1B2BDA",
  },

  green: {
    500: "#66B926",
  },
  gray: {
    50: "#F5F5F5",
    100: "#EBEBEB",
    200: "#D6D6D6",
    300: "#C2C2C2",
    400: "#ADADAD",
    500: "#8F8F8F",
    600: "#7A7A7A",
    700: "#5C5C5C",
    800: "#3C3C3C",
    900: "#1A1A1A",
  },
  white: {
    100: "#FFFFFF",
    500: "#F8F8F4",
  },
};
