import {
  isHotkey,
  moveSelection,
  PlateEditor,
  Value,
  getNodeString,
} from "@udecode/plate-common";
import { removeMentionInput } from "./remove-mention-input";
import { moveSelectionByOffset } from "./move-selection-by-offset";
import { comboboxSelectors } from "@udecode/plate-combobox";
import { findMentionInput } from "./find-mention-input";
import { KeyboardEventHandler } from "./create-mention-plugin";
import { MoveSelectionByOffsetOptions } from "./mention-types";

export const mentionOnKeyDownHandler: <V extends Value>(
  options?: MoveSelectionByOffsetOptions<V>
) => (editor: PlateEditor<V>) => KeyboardEventHandler =
  (options) => (editor) => (event: any) => {
    if (isHotkey("escape", event)) {
      const currentMentionInput = findMentionInput(editor)!;
      if (currentMentionInput) {
        event.preventDefault();
        removeMentionInput(editor, currentMentionInput[1]);
        moveSelection(editor, { unit: "word" });
        return true;
      }
      return false;
    } else if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      const currentMentionInput = findMentionInput(editor);

      let mentionableMembers: any = comboboxSelectors
        .items()
        .map((item: any) => [item.text, item.data.email].flat());
      mentionableMembers = mentionableMembers.flat() as string[];

      if (currentMentionInput) {
        const query = getNodeString(currentMentionInput[0]);
        const isEmail = /\S+@\S+\.\S+/.test(query as string);

        if (isEmail) {
          event.preventDefault();

          editor.delete({ at: currentMentionInput[1] });

          editor.insertNode({
            type: "@",
            value: query,
            children: [{ text: "" }],
          });

          moveSelection(editor, { unit: "word" });
        } else if (
          mentionableMembers.filter((member: string) => member.includes(query))
            .length === 0
        ) {
          window.alert(
            `${query} is not a valid email address. Mention organization members or valid email addresses to invite new members.`
          );
        }
      }
    } else if (event.metaKey && event.key === "a") {
      event.preventDefault();
    }
    return moveSelectionByOffset(editor, options)(event);
  };
