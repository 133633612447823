import { IconButtonProps } from "@mui/material";
import { RuleIconButton } from "./RuleIconButton";
import { Save } from "@mui/icons-material";

interface RuleSaveButtonProps {
  onClick: IconButtonProps["onClick"];
}

export const RuleSaveButton = ({ onClick }: RuleSaveButtonProps) => {
  return (
    <RuleIconButton data-test-id="save-rule-btn" onClick={onClick}>
      <Save fontSize="inherit" />
    </RuleIconButton>
  );
};
