import { useAPIRequest } from "../hooks/useAPI";

export const useFileAPI = () => {
  const sendAPIRequest = useAPIRequest();
  const postFiles = async (files: any) => {
    return await sendAPIRequest(`files`, "POST", files);
  };

  const getFile = async (fileId: string) => {
    return await sendAPIRequest(`file/${fileId}`, "GET");
  };

  const deleteFile = async (fileId: string) => {
    return await sendAPIRequest(`file/${fileId}`, "DELETE");
  };

  return { postFiles, getFile, deleteFile };
};
