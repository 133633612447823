import { Form, FormElement } from "./types/form.types";
import { ServerRule } from "./types/rule.types";
import { OrganizationMembership } from "./types/organization.types";
import { SubmissionEnrichment, SubmissionMeta } from "./types/submission.types";

export interface FormMeta {
  title: string;
  description: string;
  version: number;
  publishedVersion: number;
  privileged: boolean;
  createdAt?: string;
  createdByUserId: string;
  organizationId: string;
  privilegeWarningDismissed: boolean;
  archived: boolean;
  updatedAt: Date | null;
}

export type MembershipType = "member" | "guest";

export interface FormAvailability {
  discoverable: boolean;
  audience: MembershipType[];
}

export interface OtherUserInfo {
  userId: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  initials?: string;
  title?: string;
  pronouns?: string;
  otherContactInformation?: string;
  accountStatus?: AccountStatus;
}

export interface FormEnrichment {
  actions: FormActions;
  creator: OtherUserInfo;
  attentionItems: AttentionItems;
  usersInfo: OtherUserInfo[];
}

/* what's returned by the GET /forms/:id endpoint */
export interface EnrichedForm extends Form {
  readOnly: FormEnrichment;
}

/* an array of these is what is returned by the GET /forms endpoint */
export interface EnrichedFormContentless {
  _id: string;
  meta: FormMeta;
  availability: FormAvailability;
  readOnly: FormEnrichment;
  users: FormUser[];
}

export interface Comment {
  user: string;
  userEmail: string;
  content: SlateObject;
  mentioned?: string[];
  assignedTo?: string[];
  firstName?: string;
  lastName?: string;
  createdAt: string;
  pending: boolean;
}

export interface CommentThread {
  _id?: string;
  resolved: boolean;
  type: string;
  comments: Comment[];
}

export interface CommentThreadDictionary {
  [key: string]: CommentThread[];
}

/* an array of these is what is returned by the GET /forms endpoint */
export interface EnrichedSubmissionContentless {
  _id: string;
  meta: SubmissionMeta;
  readOnly: SubmissionEnrichment;
  users: FormUser[];
}

export interface UploadedFile {
  fileId: string;
  url: string;
}

export type PathIndex = number[];

export enum Position {
  Above = "above",
  Below = "below",
  In = "in",
}

export interface SelectItem {
  label: string;
  selected: boolean;
  allowed?: boolean;
}

export interface AttestationInstance {
  person: User | null;
  title: string;
  organization: string;
  signed: boolean;
  signedTimestamp: string;
  signature: string;
  date: string;
}

export interface ElementEditViewProps<T> {
  id: string;
  elementValue?: any;
  value: T;
  titleNumber: string;
  onChange?: (e: any) => void;
  indentationLevel: number;
  role: string;
  buildView?: boolean;
  rules: ServerRule[];
  meta?: SubmissionMeta;
}

export type AccountStatus = "pending" | "active" | "disabled";
export interface User {
  _id: string;
  supabaseId: string;
  email: string;
  firstName?: string;
  lastName?: string;
  initials?: string;
  title?: string;
  pronouns?: string | null;
  otherContactInformation?: string;
  accountStatus: AccountStatus;
}

export interface MentionableUser {
  key: string;
  text: string;
  data: {
    email: string;
  };
}

export interface OrganizationPrivilegeSettings {
  privilegeTerms: string;
  privilegeAgreement: string;
  privilegeBanner: string;
}

export interface UserQuery {
  user: User;
  currentOrgMembership: OrganizationMembership;
  orgMemberships: OrganizationMembership[];
}

export interface MembershipDetail extends OrganizationMembership {
  user: User;
}

export type FormUserRole = "owner" | "editor" | "submitter";

export interface FormUser {
  userId: string;
  isApproved: boolean;
  role: string;
}

/* the below are used for sharing and should be used for assignment, but are not yet */
export interface AttentionItems {
  privilegeApprovalsNeeded: boolean;
}

export interface FormActions {
  share: boolean;
  comment: boolean;
  edit: boolean;
  submit: boolean;
  copy: boolean;
  archive: boolean;
  privilegeApprovals: boolean;
  becomeAdjudicator: boolean;
}

export interface FormInfoForSharing {
  readOnly: {
    actions: FormActions;
    creator: User;
    attentionItems: AttentionItems;
    usersInfo: OtherUserInfo[];
  };
}

export interface NewForm {
  meta: {
    title: string;
    description: string;
    privileged: boolean;
  };
  elements: FormElement[];
}

export interface MembershipDetailUpdate {
  firstName: string;
  lastName: string;
  email: string;
  orgMembership: OrganizationMembership;
}

export interface FileInfo {
  _id?: string;
  userId: string;
  organizationId: string;
  submissionId: string;
  elementId: string;
  key: string;
  url: string;
}

export interface Job {
  _id?: string;
  userId: string;
  organizationId: string;
  submissionId: string;
  elementId: string;
  inputFileId: string;
  outputFileId?: string;
  type: string;
  parameters: BiasTestParams;
  output?: Record<string, string | number | object>;
  status: "processing" | "completed" | "cancelled";
  timeStarted?: Date;
  timeCompleted?: Date;
  errors?: {
    error: boolean;
    message: string;
    stackTrace: string;
  };
}

export type BiasTestParams = Record<string, string | number | object>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SlateObject = any;
