import { createApi } from "@reduxjs/toolkit/query/react";
import {
  CommentThreadDictionary,
  EnrichedForm,
  EnrichedFormContentless,
  NewForm,
  FormUser,
  FormAvailability,
} from "src/types";
import { FormElement } from "src/types/form.types";
import { getAuthenticatedBaseQuery } from "src/util/base-query.utils";
import { booleanToString } from "src/util/boolean.utils";

export const formsApi = createApi({
  reducerPath: "@formsApi",
  baseQuery: getAuthenticatedBaseQuery(`${process.env.REACT_APP_API_URL}`),
  endpoints: (builder) => ({
    getForms: builder.query<EnrichedFormContentless[], { archived?: boolean }>({
      query: ({ archived }) => {
        if (archived === undefined) {
          return {
            url: `/forms`,
          };
        }
        const queryParams = new URLSearchParams({
          archived: booleanToString(archived),
        });
        return {
          url: `/forms?${queryParams.toString()}`,
        };
      },
    }),

    getFormById: builder.query<EnrichedForm, { formId: string }>({
      query: ({ formId }) => ({
        url: `/forms/${formId}`,
      }),
    }),

    createForm: builder.mutation<{ id: string }, NewForm>({
      query: (body) => ({
        url: `/forms`,
        method: "POST",
        body,
      }),
    }),

    copyForm: builder.mutation<
      { id: string; message: string },
      { formId: string }
    >({
      query: ({ formId }) => ({
        url: `/forms/${formId}`,
        method: "COPY",
      }),
    }),

    archiveForm: builder.mutation<{ message: string }, { formId: string }>({
      query: ({ formId }) => ({
        url: `/forms/${formId}/archive`,
        method: "PUT",
      }),
    }),

    unarchiveForm: builder.mutation<{ message: string }, { formId: string }>({
      query: ({ formId }) => ({
        url: `/forms/${formId}/unarchive`,
        method: "PUT",
      }),
    }),

    updateFormElements: builder.mutation<
      FormElement[],
      {
        formId: string;
        formElements: FormElement[];
        commentThreadsDictionary: CommentThreadDictionary;
      }
    >({
      query: ({ formId, formElements, commentThreadsDictionary }) => ({
        url: `/forms/${formId}/elements`,
        method: "PUT",
        body: {
          elements: formElements,
          commentThreadsDictionary,
        },
      }),
    }),

    updateFormElement: builder.mutation<
      EnrichedForm,
      {
        formId: string;
        updatedElementsById: { [elementId: string]: FormElement };
      }
    >({
      query: ({ formId, updatedElementsById }) => ({
        url: `/forms/${formId}/elements`,
        method: "PATCH",
        body: updatedElementsById,
      }),
    }),

    updateFormTitle: builder.mutation<
      { message: string },
      { formId: string; formTitle: string }
    >({
      query: ({ formId, formTitle }) => ({
        url: `/forms/${formId}/title`,
        method: "PUT",
        body: { title: formTitle },
      }),
    }),

    updateFormDescription: builder.mutation<
      { message: string },
      { formId: string; formDescription: string }
    >({
      query: ({ formId, formDescription }) => ({
        url: `/forms/${formId}/description`,
        method: "PUT",
        body: { description: formDescription },
      }),
    }),

    updateFormPrivilege: builder.mutation<
      { message: string },
      { formId: string; privileged: boolean }
    >({
      query: ({ formId, privileged }) => ({
        url: `/forms/${formId}/privileged`,
        method: "PUT",
        body: { privileged },
      }),
    }),

    updateFormPrivilegeWarning: builder.mutation<
      { message: string },
      { formId: string; privilegeWarningDismissed: boolean }
    >({
      query: ({ formId, privilegeWarningDismissed }) => ({
        url: `/forms/${formId}/privileged-warning-dismissed`,
        method: "PUT",
        body: { privilegeWarningDismissed },
      }),
    }),

    updateFormAvailability: builder.mutation<
      { message: string },
      { formId: string; availability: FormAvailability }
    >({
      query: ({ formId, availability }) => ({
        url: `/forms/${formId}/availability`,
        method: "PUT",
        body: { availability },
      }),
    }),

    addUsersToForm: builder.mutation<
      { message: string },
      {
        formId: string;
        users: FormUser[];
        notifyUsers?: boolean;
        sharingMessage?: string;
      }
    >({
      query: ({ formId, users, notifyUsers, sharingMessage }) => ({
        url: `/forms/${formId}/users`,
        method: "POST",
        body: { users, notifyUsers, sharingMessage },
      }),
    }),

    updateFormUserApproved: builder.mutation<
      { message: string },
      { formId: string; userId: string }
    >({
      query: ({ formId, userId }) => ({
        url: `/forms/${formId}/user-approval/${userId}`,
        method: "PUT",
      }),
    }),

    updateFormUserRole: builder.mutation<
      { message: string },
      { formId: string; userId: string; role: string }
    >({
      query: ({ formId, userId, role }) => ({
        url: `/forms/${formId}/user-role/${userId}`,
        method: "PUT",
        body: { role },
      }),
    }),

    deleteFormUser: builder.mutation<
      { message: string },
      { formId: string; userId: string }
    >({
      query: ({ formId, userId }) => ({
        url: `/forms/${formId}/user/${userId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetFormsQuery,
  useLazyGetFormsQuery,
  useGetFormByIdQuery,
  useUpdateFormElementsMutation,
  useUpdateFormTitleMutation,
  useUpdateFormDescriptionMutation,
  useUpdateFormPrivilegeMutation,
  useUpdateFormPrivilegeWarningMutation,
  useUpdateFormAvailabilityMutation,
  useAddUsersToFormMutation,
  useUpdateFormUserApprovedMutation,
  useUpdateFormUserRoleMutation,
  useDeleteFormUserMutation,
  useCreateFormMutation,
  useCopyFormMutation,
  useArchiveFormMutation,
  useUnarchiveFormMutation,
  useUpdateFormElementMutation,
} = formsApi;
