import { RootState } from "src/store";
import { submissionsApi } from "src/services/submission.service";
import {
  Action,
  ListenerEffect,
  PayloadAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { setSubmissionEditingElementId } from "../slices/submission.slice";

let lastUpdateSubmissionElementRequestAbort: () => void;

export const handleUpdateSubmissionElement: ListenerEffect<
  PayloadAction<{ elementValue: any; elementId: string }>,
  unknown,
  ThunkDispatch<unknown, unknown, Action>,
  unknown
> = async (action, listener) => {
  if (lastUpdateSubmissionElementRequestAbort) {
    lastUpdateSubmissionElementRequestAbort();
  }
  const state = listener.getState() as RootState;

  const submissionState = state.submission;

  if (
    submissionState.status !== "success" &&
    submissionState.status !== "saving"
  ) {
    throw new Error(
      "Attempting to update a submission when submission is not set"
    );
  }
  const { submissionId } = submissionState;

  const { abort } = listener.dispatch(
    submissionsApi.endpoints.updateSubmissionElements.initiate({
      submissionId,
      elements: [submissionState.elementsById[action.payload.elementId]],
    })
  );
  listener.dispatch(setSubmissionEditingElementId(action.payload.elementId));
  lastUpdateSubmissionElementRequestAbort = abort;
};
