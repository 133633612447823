import { useAppSelector } from "../store";
import { UserQuery } from "../types";
import React from "react";

export const useUserInitials = () => {
  const globalUserInfo: UserQuery | null = useAppSelector(
    (state) => state.user.userContext
  );
  return React.useMemo(() => {
    const user = globalUserInfo?.user;
    if (user?.initials) {
      return user.initials;
    } else if (user?.firstName && user?.lastName) {
      return user?.firstName[0] + user?.lastName[0];
    } else if (user?.lastName) {
      return user?.lastName[0];
    } else if (user?.firstName) {
      return user?.firstName[0];
    } else {
      return (
        (user?.email?.split("@")[0][0] ?? "") +
        (user?.email.split("@")[1][0] ?? "")
      );
    }
  }, [globalUserInfo?.user]);
};
