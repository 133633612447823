import { ComboboxProps } from "@udecode/plate-combobox";
import { getPluginOptions, useEditorRef } from "@udecode/plate-common";
import {
  ELEMENT_MENTION,
  getMentionOnSelectItem,
  MentionPlugin,
} from "@udecode/plate-mention";
import { MentionableUser } from "src/types";

import { Combobox } from "./combobox";

export function MentionCombobox({
  pluginKey = ELEMENT_MENTION,
  id = pluginKey,
  items,
  ...props
}: Partial<ComboboxProps> & {
  pluginKey?: string;
  items: MentionableUser[];
}) {
  const editor = useEditorRef();
  const { trigger } = getPluginOptions<MentionPlugin>(editor, pluginKey);

  return (
    <div onMouseDown={(e) => e.preventDefault()}>
      <Combobox
        maxSuggestions={10}
        id={id}
        trigger={trigger ?? "@"}
        controlled
        items={items}
        onSelectItem={getMentionOnSelectItem({
          key: pluginKey,
        })}
        {...props}
      />
    </div>
  );
}
