import { ComponentStyleConfig, defineStyle } from "@chakra-ui/react";

export const TextStyle: ComponentStyleConfig = defineStyle({
  variants: {
    caption: {
      fontSize: "12px",
      color: "gray.800",
    },
    bold: {
      fontFamily: "Lato Heavy",
    },
  },
  baseStyle: {
    fontSize: "16px",
  },
});
