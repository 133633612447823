import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserQuery } from "../../types";
import {
  OrganizationPermissions,
  OrganizationMembership,
} from "src/types/organization.types";
import moment from "moment";

const locales = ["en-us", "en-gb", "zh-cn", "de"];
type LocaleKey = (typeof locales)[number];

interface OrganizationMembershipState {
  userInfo: UserQuery;
  edited: boolean;
  emailValid: boolean;
  showCustom: boolean;
  roleName: string;
  expiration: {
    nowVsScheduled: string;
    expirationEnabled: boolean;
    expirationDateTime: string;
    expirationLocale: LocaleKey;
  };
}
const organization = {
  membershipType: "member",
  membershipExpiration: null,
  permissions: {
    manageOrgPermissions: false,
    manageOrgSettings: false,
    inviteUser: true,
    manageLegalPrivilege: false,
    createOrCopyForms: true,
    seeAllMembers: true,
    seeAllGuests: false,
  } as OrganizationPermissions,
} as OrganizationMembership;

const defaultUserInfoState: UserQuery = {
  user: {
    _id: "",
    supabaseId: "",
    email: "",
    firstName: "",
    lastName: "",
    accountStatus: "pending",
  },
  currentOrgMembership: organization,
  orgMemberships: [organization],
};

const defaultExpirationState = {
  nowVsScheduled: "immediately",
  expirationEnabled: false,
  expirationDateTime: new Date().toISOString(),
  expirationLocale: "en-us",
};
export const orgRoleTemplateNames = {
  governor: "Governor",
  standardMember: "Standard Member",
  standardGuest: "Standard Guest",
  custom: "Custom",
};

const initialState: OrganizationMembershipState = {
  userInfo: defaultUserInfoState,
  edited: false,
  roleName: orgRoleTemplateNames.standardMember,
  expiration: defaultExpirationState,
  emailValid: false,
  showCustom: false,
};

export const organizationMembershipSlice = createSlice({
  name: "organizationMembership",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserQuery>) => {
      state.userInfo = action.payload;
    },

    setUserInfoField: (
      state,
      action: PayloadAction<{ value: any; field: string }>
    ) => {
      const { field, value } = action.payload;
      state.userInfo.user = {
        ...state.userInfo.user,
        [field]: value,
      };
      state.edited = true;
    },

    setCurrentOrgMembershipField: (
      state,
      action: PayloadAction<{
        value: any;
        field: string;
      }>
    ) => {
      const { field, value } = action.payload;
      state.userInfo.currentOrgMembership = {
        ...state.userInfo.currentOrgMembership,
        [field]: value,
      };
      state.edited = true;
    },

    setOrgPermissions: (
      state,
      action: PayloadAction<OrganizationPermissions>
    ) => {
      state.userInfo.currentOrgMembership.permissions = action.payload;
      state.edited = true;
    },

    resetOrganizationMembershipState: (state) => {
      state.userInfo = defaultUserInfoState;
      state.expiration = defaultExpirationState;
      state.edited = false;
      state.emailValid = false;
      state.showCustom = false;
      state.roleName = orgRoleTemplateNames.standardMember;
    },

    setExpirationDate: (
      state,
      action: PayloadAction<{ e: string | undefined }>
    ) => {
      const { e } = action.payload;
      if (e) {
        const newExpiration = moment();
        const currentExpiration = moment(state.expiration.expirationDateTime);
        newExpiration.set("hour", currentExpiration.hour());
        newExpiration.set("minute", currentExpiration.minute());
        newExpiration.set("year", moment(e).year());
        newExpiration.set("month", moment(e).month());
        newExpiration.set("date", moment(e).date());
        newExpiration.set("seconds", moment(e).seconds());
        state.expiration.expirationDateTime = newExpiration.toISOString();
        state.userInfo.currentOrgMembership.membershipExpiration =
          newExpiration.toISOString();
        state.edited = true;
      }
    },

    setExpirationTime: (
      state,
      action: PayloadAction<{ e: string | undefined }>
    ) => {
      const { e } = action.payload;
      if (e) {
        const currentExpiration = moment(state.expiration.expirationDateTime);
        const newExpiration = moment();
        newExpiration.set("hour", moment(e).hour());
        newExpiration.set("minute", moment(e).minute());
        newExpiration.set("year", currentExpiration.year());
        newExpiration.set("month", currentExpiration.month());
        newExpiration.set("date", currentExpiration.date());
        newExpiration.set("seconds", moment(e).seconds());
        state.expiration.expirationDateTime = newExpiration.toISOString();
        state.userInfo.currentOrgMembership.membershipExpiration =
          newExpiration.toISOString();
        state.edited = true;
      }
    },

    setExpirationLocale: (state, action: PayloadAction<string>) => {
      state.expiration.expirationLocale = action.payload;
      state.edited = true;
    },

    setExpirationNowVsScheduled: (state, action: PayloadAction<string>) => {
      state.expiration.nowVsScheduled = action.payload;
      state.edited = true;
    },

    setExpirationEnabled: (
      state,
      action: PayloadAction<{ enabled: boolean }>
    ) => {
      const { enabled } = action.payload;
      state.expiration.expirationEnabled = enabled;

      if (!enabled) {
        state.userInfo.currentOrgMembership.membershipExpiration = null;
      }

      state.edited = true;
    },

    setShowCustom: (state, action: PayloadAction<boolean>) => {
      state.showCustom = action.payload;
    },

    setEmailValid: (state, action: PayloadAction<boolean>) => {
      state.emailValid = action.payload;
    },

    setOrgRole: (state, action: PayloadAction<string>) => {
      state.roleName = action.payload;
    },
  },
});

export const {
  setUserInfo,
  setUserInfoField,
  setCurrentOrgMembershipField,
  setOrgPermissions,
  resetOrganizationMembershipState,
  setExpirationDate,
  setExpirationTime,
  setExpirationLocale,
  setExpirationNowVsScheduled,
  setExpirationEnabled,
  setShowCustom,
  setEmailValid,
  setOrgRole,
} = organizationMembershipSlice.actions;
