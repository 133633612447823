import React from "react";
import { Box, Text, Button, Flex } from "@chakra-ui/react";
import { Chip, ListItem, Paper, Stack, TextField } from "@mui/material";
import { UserQuery } from "../../types";
import { useAppDispatch, useAppSelector } from "../../store";
import { setUserContext } from "../../store/slices/user.slice";
import { useLazyGetUserQuery } from "../../services/user.service";
import { useUpdateOrganizationAllowedDomainsMutation } from "src/services/organization.service";

export default function AllowedDomainsTabPanel() {
  const dispatch = useAppDispatch();
  const { currentOrganizationId, userContext } = useAppSelector(
    (state) => state.user
  );
  const [userInfo, setUserInfo] = React.useState<UserQuery | null>(userContext);

  const [newDomain, setNewDomain] = React.useState("");
  const [saveDisabled, setSaveDisabled] = React.useState(true);
  const [getUser] = useLazyGetUserQuery();
  const [updateOrganizationAllowedDomains] =
    useUpdateOrganizationAllowedDomainsMutation();

  const updateNewDomain = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLocaleLowerCase();
      setNewDomain(value);
      const splitValue = value.split(".");
      if (
        splitValue.length > 1 &&
        splitValue[0].length > 0 &&
        splitValue[1].length > 0 &&
        (!userInfo?.currentOrgMembership?.organization?.allowedDomains ||
          (userInfo?.currentOrgMembership?.organization?.allowedDomains &&
            !userInfo.currentOrgMembership.organization.allowedDomains.includes(
              value
            )))
      ) {
        setSaveDisabled(false);
      } else {
        setSaveDisabled(true);
      }
    },
    [userInfo]
  );

  const handleAddDomain = React.useCallback(async () => {
    if (userInfo && userInfo.currentOrgMembership.organization) {
      let domains: string[] = [];
      if (userInfo?.currentOrgMembership?.organization?.allowedDomains) {
        domains = domains.concat(
          userInfo.currentOrgMembership.organization.allowedDomains
        );
      }
      domains.push(newDomain);

      setUserInfo((prevUserInfo) => {
        return prevUserInfo && prevUserInfo.currentOrgMembership.organization
          ? {
              ...prevUserInfo,
              currentOrgMembership: {
                ...prevUserInfo.currentOrgMembership,
                organization: {
                  ...prevUserInfo.currentOrgMembership.organization,
                  allowedDomains: domains,
                },
              },
            }
          : prevUserInfo;
      });
      setNewDomain("");
      setSaveDisabled(true);
      await updateOrganizationAllowedDomains(domains);
      getUser({
        targetOrganizationId: currentOrganizationId,
      });
    }
  }, [
    userInfo,
    newDomain,
    updateOrganizationAllowedDomains,
    getUser,
    currentOrganizationId,
  ]);

  const handleSubmitAddDomain: React.FormEventHandler<HTMLFormElement> =
    React.useCallback(
      (e) => {
        e.preventDefault();
        if (!saveDisabled) {
          handleAddDomain();
        }
      },
      [saveDisabled, handleAddDomain]
    );

  const handleDelete = React.useCallback(
    async (deleteDomain: string) => {
      if (userInfo && userInfo.currentOrgMembership.organization) {
        const domains: string[] =
          userInfo!.currentOrgMembership!.organization!.allowedDomains!;

        setUserInfo((prevUserInfo) => {
          return prevUserInfo && prevUserInfo.currentOrgMembership.organization
            ? {
                ...prevUserInfo,
                currentOrgMembership: {
                  ...prevUserInfo.currentOrgMembership,
                  organization: {
                    ...prevUserInfo.currentOrgMembership.organization,
                    allowedDomains: domains.filter((x) => x !== deleteDomain),
                  },
                },
              }
            : prevUserInfo;
        });
        await updateOrganizationAllowedDomains(
          domains.filter((x) => x !== deleteDomain)
        );
        const updatedUserContext = await getUser({
          targetOrganizationId: currentOrganizationId,
        }).unwrap();
        dispatch(setUserContext(updatedUserContext));
      }
    },
    [
      currentOrganizationId,
      userInfo,
      dispatch,
      getUser,
      updateOrganizationAllowedDomains,
    ]
  );

  return (
    <>
      <Flex alignContent="">
        <Box w="800px">
          <Stack>
            <form onSubmit={handleSubmitAddDomain}>
              <Stack direction="row" width="100%">
                <TextField
                  id="outlined-basic"
                  label="New Domain"
                  variant="outlined"
                  fullWidth={true}
                  value={newDomain}
                  onChange={updateNewDomain}
                />
                <Button
                  ml="12px"
                  isDisabled={saveDisabled}
                  onClick={handleAddDomain}
                >
                  Add Domain
                </Button>
              </Stack>
            </form>

            {userInfo?.currentOrgMembership?.organization?.allowedDomains
              ?.length &&
            userInfo.currentOrgMembership.organization.allowedDomains.length >
              0 ? (
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  mt: 1,
                  mb: 1,
                }}
                component="ul"
              >
                <Stack direction="row" component="ul">
                  {userInfo.currentOrgMembership.organization.allowedDomains.map(
                    (chipDomain: string) => {
                      return (
                        <ListItem key={chipDomain}>
                          <Chip
                            label={chipDomain}
                            onDelete={() => {
                              handleDelete(chipDomain);
                            }}
                          />
                        </ListItem>
                      );
                    }
                  )}
                </Stack>
              </Paper>
            ) : (
              <Text mt="18px" mb="18px" align="center" fontWeight="bold">
                No Allowed Domains Configured
              </Text>
            )}

            <Text align="left">
              Anyone with an email address at the above domains may sign into
              Luminos.AI and automatically become a member of your organization.
              Upon authenticating, such members will be able to create and edit
              submissions, create or copy forms, invite other users to the
              organization, and see all members of the organization. Their
              permissions can always be managed by any organization member with
              the permission Manage All Permissions.
            </Text>
          </Stack>
        </Box>
      </Flex>
    </>
  );
}
