import React from "react";
import { AttestationInstance } from "../types";
import "@fontsource/birthstone";
import { isSubmissionElementEditable } from "../util/isSubmissionElementEditable";
import { useAppDispatch, useAppSelector } from "src/store";
import { SubmissionElementEditViewProps } from "src/types/submission.types";
import {
  lookupSubmissionElementById,
  selectSubmissionMeta,
  selectUserRoleOnSubmission,
} from "src/store/selectors/submission.selectors";
import { updateSubmissionElement } from "src/store/slices/submission.slice";
import { AttestationRow } from "./AttestationRow";
import { SubmissionViewHeader } from "./SubmissionViewHeader";
import { getDefaultAttestationInstance } from "src/util/element.utils";

export const AttestationSubmissionView = ({
  id,
}: SubmissionElementEditViewProps) => {
  const element = useAppSelector(lookupSubmissionElementById(id));
  const globalUserId = useAppSelector(
    (state) => state.user.userContext?.user._id
  );
  const role = useAppSelector((state) =>
    selectUserRoleOnSubmission(state, globalUserId)
  );
  const meta = useAppSelector(selectSubmissionMeta);
  const dispatch = useAppDispatch();

  if (element && element.type !== "attestation") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const elementIsEditable = isSubmissionElementEditable(
    element?.content,
    role,
    meta
  );

  const attestations: AttestationInstance[] = React.useMemo(() => {
    return element?.elementValue
      ? element.elementValue
      : element?.content.requiredNumber
        ? Array(element.content.requiredNumber)
            .fill(null)
            .map(getDefaultAttestationInstance)
        : [];
  }, [element]);

  const handleAttestationUpdate = React.useCallback(
    (
      index: number,
      field: string,
      newValue: AttestationInstance[keyof AttestationInstance]
    ) => {
      if (!elementIsEditable) {
        return;
      }

      const newAttestations = attestations.map((attestation, i) => {
        if (i === index) {
          return { ...attestation, [field]: newValue };
        }
        return attestation;
      });

      dispatch(
        updateSubmissionElement({
          elementValue: newAttestations,
          elementId: element?.id,
        })
      );
    },
    [attestations, dispatch, element?.id, elementIsEditable]
  );

  if (!element) {
    return null;
  }

  return (
    <div>
      <SubmissionViewHeader
        rules={element.rules}
        title={element.content.title}
        titleNumber={element.titleNumber ?? ""}
        role={role}
        adjudicatorNotes={element.content.adjudicatorNotes}
        surveyTakerNotes={element.content.surveyTakerNotes}
        audience={element.content.audience}
        questionPlaceholder="Enter the attestation statement"
        question={element.content.question}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {attestations?.map(
          (attestation: AttestationInstance, index: number) => (
            <AttestationRow
              value={element.content}
              elementIsEditable={elementIsEditable}
              onUpdateAttestation={handleAttestationUpdate}
              key={index}
              index={index}
              attestation={attestation}
            />
          )
        )}
      </div>
    </div>
  );
};
