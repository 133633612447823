import { SelectInput, SelectInputProps } from "src/lib/components/SelectInput";

export const RulesSelectInput = <
  V extends string | number | readonly string[] | undefined,
>(
  props: SelectInputProps<V>
) => {
  return (
    <SelectInput
      {...props}
      fullWidth
      menuProps={{
        MenuListProps: {
          sx: {
            maxWidth: "50vw",
          },
        },
        PaperProps: {
          "data-test-id": "rules-select-input",
        },
      }}
      formControlProps={{
        ...props.formControlProps,
        sx: {
          ...props.formControlProps?.sx,
        },
      }}
    />
  );
};
