import "./styles/app.css";

import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import { PlateController } from "@udecode/plate-common";
import { Auth } from "./components/Auth";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "react-quill/dist/quill.snow.css";

export default function App() {
  return (
    <Auth>
      <PlateController>
        <Routes>
          <Route path={routes.Login.path} element={routes.Login.Page} />
          <Route path={routes.PostLogin.path} element={routes.PostLogin.Page} />
          <Route path={routes.Home.path} element={routes.Home.Page} />
          <Route path={routes.Forms.path} element={routes.Forms.Page} />
          <Route
            path={routes.Submissions.path}
            element={routes.Submissions.Page}
          />
          <Route
            path={routes.FormBuilder.path}
            element={routes.FormBuilder.Page}
          />
          <Route
            path={routes.SubmissionEditor.path}
            element={routes.SubmissionEditor.Page}
          />
          <Route
            path={routes.UserProfile.path}
            element={routes.UserProfile.Page}
          />
          <Route
            path={routes.Organization.path}
            element={routes.Organization.Page}
          />
          <Route path={routes.NotFound.path} element={routes.NotFound.Page} />
          <Route
            path={routes.CreateSubmission.path}
            element={routes.CreateSubmission.page}
          />
        </Routes>
      </PlateController>
    </Auth>
  );
}
