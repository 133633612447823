import React from "react";
import {
  Button,
  Center,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Textarea,
} from "@chakra-ui/react";
import { LuminosModalHeader } from "./LuminosModalHeader";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import { useAppDispatch } from "src/store";
import { updateFormDescription } from "src/store/slices/form.slice";

interface FormDescriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  formId: string | undefined;
  initialFormDescription?: string;
}

export const FormDescriptionModal = ({
  isOpen,
  onClose,
  initialFormDescription,
}: FormDescriptionModalProps) => {
  const dispatch = useAppDispatch();
  const [updatedDescription, setUpdatedDescription] = React.useState(
    initialFormDescription
  );

  const handleFormDescriptionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setUpdatedDescription(e.target.value);
    },
    []
  );

  const handleSaveFormDescription = React.useCallback(() => {
    dispatch(updateFormDescription(updatedDescription));
    onClose();
  }, [dispatch, onClose, updatedDescription]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth="500px">
          <LuminosModalHeader
            title={"Form Description"}
            closeButton={false}
            iconWrapper={<Icon as={AssignmentIndOutlinedIcon} ml="4px" />}
          />
          <ModalBody bg="white">
            <Center>
              <Textarea
                placeholder="a description of the usage of this form"
                size="sm"
                w="460px"
                value={updatedDescription}
                onChange={handleFormDescriptionChange}
              ></Textarea>
            </Center>
          </ModalBody>
          <ModalFooter bg="white">
            <Button variant="secondary" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSaveFormDescription}>
              Save Description
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
