import { HStack, Flex, VStack, RadioGroup, Radio } from "@chakra-ui/react";
import { isSubmissionElementEditable } from "../util/isSubmissionElementEditable";
import { useAppDispatch, useAppSelector } from "src/store";
import { SubmissionElementEditViewProps } from "src/types/submission.types";
import {
  lookupSubmissionElementById,
  selectSubmissionMeta,
  selectUserRoleOnSubmission,
} from "src/store/selectors/submission.selectors";
import { updateSubmissionElement } from "src/store/slices/submission.slice";
import { SubmissionViewHeader } from "./SubmissionViewHeader";
import { Typography } from "@mui/material";

export const SingleSelectSubmissionView = ({
  id,
}: SubmissionElementEditViewProps) => {
  const element = useAppSelector(lookupSubmissionElementById(id));
  const globalUserId = useAppSelector(
    (state) => state.user.userContext?.user._id
  );
  const role = useAppSelector((state) =>
    selectUserRoleOnSubmission(state, globalUserId)
  );
  const meta = useAppSelector(selectSubmissionMeta);
  const dispatch = useAppDispatch();

  if (element && element.type !== "singleSelect") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  const isEditable = isSubmissionElementEditable(element?.content, role, meta);

  const updateSelectedRadio = (e: string) => {
    if (!isEditable) return;

    dispatch(
      updateSubmissionElement({ elementValue: e, elementId: element?.id })
    );
  };

  if (!element) {
    return null;
  }

  return (
    <Flex w={"100%"} direction="column">
      <div style={{ width: "100%" }}>
        <SubmissionViewHeader
          rules={element.rules}
          title={element.content.title}
          titleNumber={element.titleNumber ?? ""}
          role={role}
          adjudicatorNotes={element.content.adjudicatorNotes}
          surveyTakerNotes={element.content.surveyTakerNotes}
          audience={element.content.audience}
          questionPlaceholder="Enter the question"
          question={element.content.question}
        />
      </div>
      <HStack justifyContent={"flex-start"}>
        <RadioGroup
          _disabled={{ opacity: "100%" }}
          isDisabled={!isEditable}
          isFocusable={isEditable}
          onChange={updateSelectedRadio}
          value={element.elementValue || undefined}
        >
          <VStack
            gap={1}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            {element.content.items
              .filter((item) => item.allowed)
              .map((item, i) => (
                <Radio
                  _disabled={{ opacity: "100%" }}
                  isFocusable={isEditable}
                  colorScheme="brightblue"
                  key={i}
                  value={item.label}
                  alignItems={"flex-start"}
                >
                  <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
                    {item.label}
                  </Typography>
                </Radio>
              ))}
            {element.content.other.allowed && (
              <Radio
                _disabled={{ opacity: "100%" }}
                isFocusable={isEditable}
                colorScheme="brightblue"
                value={element.content.other.label}
                alignItems={"flex-start"}
              >
                <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
                  {element.content.other.label}
                </Typography>
              </Radio>
            )}
            {element.content.none.allowed && (
              <Radio
                _disabled={{ opacity: "100%" }}
                isFocusable={isEditable}
                colorScheme="brightblue"
                value={element.content.none.label}
                alignItems={"flex-start"}
              >
                <Typography sx={{ wordBreak: "break-word" }} mt={"-4px"}>
                  {element.content.none.label}
                </Typography>
              </Radio>
            )}
          </VStack>
        </RadioGroup>
      </HStack>
    </Flex>
  );
};
