import React from "react";
import * as Sentry from "@sentry/react";
import { resetAuthState, setSessionAndToken } from "../store/slices/auth.slice";
import { supabaseClient } from "../clients/supabase.client";
import { useAppDispatch } from "../store";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { resetOrganizationState } from "src/store/slices/organization.slice";

export const useSession = () => {
  const [sessionLoaded, setSessionLoaded] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    const getSupabaseSession = async () => {
      /**
       * Since `supbaseClient.auth.getSession()` is async
       * we don't want to allow rendering until that has returned
       * otherwise, a user will get pushed to /login and then routed back
       * to the dashboard once the session has been loaded.
       *
       * We're doing this by blocking any children rendering
       * with `sessionLoaded` flag in local state.
       **/
      const {
        data: { session },
      } = await supabaseClient.auth.getSession();

      dispatch(
        setSessionAndToken({
          token: session?.access_token ?? null,
          session,
        })
      );

      if (session?.access_token) {
        localStorage.setItem("token", session?.access_token);
      }
      setSessionLoaded(true);
    };

    getSupabaseSession();
  }, [dispatch]);

  React.useEffect(() => {
    /**
     * Handle any supabase session changes
     */
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange((event, session) => {
      // event includes `TOKEN_REFRESHED` which refreshes the session/token
      if (event === "INITIAL_SESSION" || event === "SIGNED_IN") {
        return;
      }

      if (session && session.access_token) {
        localStorage.setItem("token", session.access_token);
        dispatch(setSessionAndToken({ token: session.access_token, session }));
      } else if (!session) {
        localStorage.removeItem("token");
        dispatch(resetAuthState());
        dispatch(resetOrganizationState());
        navigate(routes.Login.path);
      } else {
        Sentry.captureMessage("Unexpected session response", {
          extra: {
            event,
            session,
          },
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [dispatch, navigate]);

  return {
    sessionLoaded,
  };
};
