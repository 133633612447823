import { useAPIRequest } from "../hooks/useAPI";
import React from "react";

export const useUserAPI = () => {
  const sendAPIRequest = useAPIRequest();

  const getUserById = React.useCallback(
    async (userId: string) => {
      return await sendAPIRequest(`user/${userId}`, "GET");
    },
    [sendAPIRequest]
  );

  return { getUserById };
};
