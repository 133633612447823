import React from "react";
import { LuminosNavBar } from "./LuminosNavBar";
import { Box, GridItem, HStack } from "@chakra-ui/react";
import { BasePage } from "./BasePage";
import { UserMenu } from "./UserMenu";
import { ErrorSnackbar } from "../lib/components/ErrorSnackbar";
import { PageLoader } from "../lib/components/PageLoader";
import { useAppInit } from "../hooks/useAppInit";
import SideNavContainer from "./SideNavBar/SideNavContainer";
import { SideNav } from "./SideNavBar/SideNav";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "src/store";
import { getSlugFromOrgId } from "src/util/org-slugs.utils";

interface AuthenticatedPageProps {
  title?: string;
  navBarLeftAlignedContent?: React.ReactNode;
  navBarRightAlignedContent?: React.ReactNode;
  navBarMenuContent?: React.ReactNode;
}

export const AuthenticatedPage = ({
  title,
  navBarLeftAlignedContent,
  navBarRightAlignedContent,
  navBarMenuContent = (
    <HStack>
      <Box w="1px" bg="brand.500" h="24px" />
      <UserMenu />
    </HStack>
  ),
  children,
}: React.PropsWithChildren<AuthenticatedPageProps>) => {
  const { error, isLoading } = useAppInit({ targetOrganizationId: null });

  const location = useLocation();
  const navigate = useNavigate();
  const organizationId = useAppSelector(
    (state) => state.user.currentOrganizationId
  );

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    const orgSlug = query.get("org");

    if (!orgSlug && organizationId) {
      const params = new URLSearchParams(window.location.search);
      params.set("org", getSlugFromOrgId(organizationId));
      navigate(`${window.location.pathname}?${params}`, { replace: true });
    }
  }, [navigate, location, organizationId]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <BasePage>
      <LuminosNavBar
        title={title}
        leftAlignedContent={navBarLeftAlignedContent}
        rightAlignedContent={navBarRightAlignedContent}
        menuContent={navBarMenuContent}
      />
      <SideNavContainer sideNav={<SideNav />}>
        <GridItem as="main">{children}</GridItem>
      </SideNavContainer>
      <ErrorSnackbar message={error ? "Error getting user data" : undefined} />
    </BasePage>
  );
};
