import {
  Center,
  Tooltip,
  Circle,
  Text,
  Flex,
  HStack,
  VStack,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  MenuDivider,
  Spacer,
  Icon,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import {
  EnrichedForm,
  FormUser,
  EnrichedFormContentless,
  EnrichedSubmissionContentless,
  OtherUserInfo,
} from "../../types";
import { useAPIRequest } from "../../hooks/useAPI";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { urlPathPrefix } from "./SharingModal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDeleteFormUserMutation } from "src/services/forms.service";
import { EnrichedSubmission, SubmissionUser } from "src/types/submission.types";

interface DocUser {
  userInfo: OtherUserInfo;
  userDocInfo: FormUser | SubmissionUser;
}

interface IUserAccessCard {
  userId: string;
  docUsers: Map<string, DocUser>;
  docId: string;
  docType: "form" | "submission";
  doc:
    | EnrichedForm
    | EnrichedFormContentless
    | EnrichedSubmission
    | EnrichedSubmissionContentless;
  openErrorModal: () => void;
  setSaving: (arg0: boolean) => void;
  setDocUsers: (du: Map<string, DocUser>) => void;
  deleteUser: (userId: string) => void;
  updateDocumentUserRole: (role: string, userId: string) => void;
}

export const SharingModalUserAccessCard = ({
  userId,
  docUsers,
  docId,
  docType,
  doc,
  openErrorModal,
  setSaving,
  setDocUsers,
  deleteUser: onDeleteUser,
  updateDocumentUserRole,
}: IUserAccessCard) => {
  const [savingThis, setSavingThis] = React.useState(false);
  const sendAPIRequest = useAPIRequest();
  let roleColor = "black";
  let showRoleSelection = true;
  let ownerCount = 0;
  let adjudicatorCount = 0;
  let pendingApproval = false;

  const currentUser = docUsers.get(userId)!;
  const role = currentUser.userDocInfo.role;
  const [deleteUser, { isLoading }] = useDeleteFormUserMutation();

  docUsers.forEach((value: DocUser) => {
    if (value.userDocInfo.role === "owner" && value.userDocInfo.isApproved) {
      ownerCount = ownerCount + 1;
    } else if (
      value.userDocInfo.role === "adjudicator" &&
      value.userDocInfo.isApproved
    ) {
      adjudicatorCount = adjudicatorCount + 1;
    }
  });

  const renderName = React.useCallback((user: OtherUserInfo) => {
    if (user.firstName && user.lastName) {
      return (
        <Heading size="sm">{`${user.firstName} ${user.lastName}`}</Heading>
      );
    }
    if (user.lastName) {
      return <Heading size="sm">{`${user.lastName}`}</Heading>;
    }
    if (user.firstName) {
      return <Heading size="sm">{`${user.firstName}`}</Heading>;
    }
    return <></>;
  }, []);

  const getInitials = React.useCallback((user: OtherUserInfo) => {
    if (user.initials) {
      return user.initials.toUpperCase();
    }
    if (user.firstName && user.lastName) {
      return (
        user.firstName.substring(0, 1).toUpperCase() +
        user.lastName.substring(0, 0).toUpperCase()
      );
    }
    if (user.firstName) {
      return user.firstName.substring(0, 1).toUpperCase();
    }
    if (user.lastName) {
      return user.lastName.substring(0, 1).toUpperCase();
    }
    return user.email!.substring(0, 1).toUpperCase();
  }, []);

  const approveUser = React.useCallback(async () => {
    setSaving(true);
    setSavingThis(true);
    try {
      const res: any = await sendAPIRequest(
        `${urlPathPrefix(docType)}/${docId}/user-approval/${docUsers.get(userId)!.userInfo.userId}`,
        "PUT"
      );
      if (!res.message) {
        openErrorModal();
      } else {
        setDocUsers(
          docUsers.set(userId, {
            userDocInfo: {
              ...currentUser.userDocInfo,
              isApproved: true,
            },
            userInfo: currentUser!.userInfo,
          })
        );
      }
    } catch (error) {
      openErrorModal();
    } finally {
      setSaving(false);
      setSavingThis(false);
    }
  }, [
    currentUser,
    docId,
    docType,
    docUsers,
    openErrorModal,
    sendAPIRequest,
    setDocUsers,
    setSaving,
    userId,
  ]);

  const handleUpdateUserRole = React.useCallback(
    (newRole: string) => {
      updateDocumentUserRole(newRole, userId);
    },
    [updateDocumentUserRole, userId]
  );

  const handleDeleteUser = React.useCallback(async () => {
    setSaving(true);
    setSavingThis(true);
    try {
      await deleteUser({ formId: docId, userId });
      onDeleteUser(userId);
    } catch (error) {
      openErrorModal();
    } finally {
      setSaving(false);
      setSavingThis(false);
    }
  }, [deleteUser, docId, onDeleteUser, openErrorModal, setSaving, userId]);

  if (docType === "form") {
    if (role === "owner" && ownerCount === 1) {
      showRoleSelection = false;
      roleColor = "gray.500";
    }
  } else if (docType === "submission") {
    if (role === "adjudicator" && adjudicatorCount === 1) {
      showRoleSelection = false;
      roleColor = "gray.500";
    }
  }
  if (!currentUser.userDocInfo.isApproved) {
    showRoleSelection = false;
    pendingApproval = true;
    roleColor = "gray.500";
  }

  return (
    <Flex w="100%" mt="8px">
      <Circle bg="brightblue.500" size="42px" mr="6px">
        <Text textColor="white" fontWeight="bold" fontSize="14px">
          <>{getInitials(currentUser.userInfo)}</>
        </Text>
      </Circle>
      <Center>
        <VStack spacing="0px" align="start">
          {currentUser!.userInfo.firstName || currentUser!.userInfo.lastName ? (
            <>{renderName(currentUser!.userInfo)}</>
          ) : (
            <></>
          )}
          <Text>{currentUser!.userInfo.email}</Text>
        </VStack>
      </Center>
      <Spacer />
      <Center mr="12px">
        {savingThis ? (
          <Text textColor={roleColor}>saving...</Text>
        ) : (
          <>
            {!showRoleSelection ? (
              <>
                {pendingApproval ? (
                  <HStack w="100%">
                    <Text
                      fontSize={"12px"}
                    >{`${role}, pending legal approval`}</Text>
                    {doc!.readOnly.actions.privilegeApprovals ? (
                      <>
                        <Tooltip label={`Approve user access`}>
                          <Icon
                            onClick={approveUser}
                            as={CheckCircleOutlineIcon}
                            color="green.500"
                          />
                        </Tooltip>
                        <Tooltip label={`Reject user access`}>
                          <Icon
                            onClick={handleDeleteUser}
                            as={HighlightOffIcon}
                            color="red.500"
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <></>
                    )}
                  </HStack>
                ) : (
                  <Text textColor={roleColor} w="100%">
                    {role === "adjudicator" ? "reviewer" : role}
                  </Text>
                )}
              </>
            ) : (
              <Menu>
                <MenuButton>
                  <Center>
                    <HStack spacing="2px">
                      <Text>{role === "adjudicator" ? "reviewer" : role}</Text>
                      <Icon as={ArrowDropDownOutlinedIcon} />
                    </HStack>
                  </Center>
                </MenuButton>
                <MenuList>
                  {docType === "form" &&
                  (doc as EnrichedFormContentless).readOnly.actions
                    .becomeAdjudicator ? (
                    <MenuItem onClick={() => handleUpdateUserRole("owner")}>
                      <Flex w="100%">
                        {role === "owner" ? (
                          <Icon as={CheckOutlinedIcon} />
                        ) : (
                          <></>
                        )}
                        <Spacer />
                        <Text>owner</Text>
                      </Flex>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  {docType === "form" ? (
                    <MenuItem onClick={() => handleUpdateUserRole("editor")}>
                      <Flex w="100%">
                        {role === "editor" ? (
                          <Icon as={CheckOutlinedIcon} />
                        ) : (
                          <></>
                        )}
                        <Spacer />
                        <Text>editor</Text>
                      </Flex>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  {docType === "submission" &&
                  (doc as EnrichedSubmissionContentless).readOnly.actions
                    .assignAdjudicators ? (
                    <MenuItem
                      onClick={() => handleUpdateUserRole("adjudicator")}
                    >
                      <Flex w="100%">
                        {role === "adjudicator" ? (
                          <Icon as={CheckOutlinedIcon} />
                        ) : (
                          <></>
                        )}
                        <Spacer />
                        <Text>reviewer</Text>
                      </Flex>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  <MenuItem onClick={() => handleUpdateUserRole("submitter")}>
                    <Flex w="100%">
                      {role === "submitter" ? (
                        <Icon as={CheckOutlinedIcon} />
                      ) : (
                        <></>
                      )}
                      <Spacer />
                      <Text>submitter</Text>
                    </Flex>
                  </MenuItem>
                  <MenuDivider></MenuDivider>
                  <MenuItem onClick={handleDeleteUser}>Remove Access</MenuItem>
                </MenuList>
              </Menu>
            )}
          </>
        )}
      </Center>
    </Flex>
  );
};
