import { AttestationInstance } from "../types";
import "@fontsource/birthstone";
import { useAppSelector } from "src/store";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { AttestationRow } from "./AttestationRow";
import { FormElementBuildViewProps } from "src/types/form.types";
import { PreviewHeader } from "./PreviewHeader";

export const AttestationPreview = ({ id }: FormElementBuildViewProps) => {
  const element = useAppSelector(lookupFormElementById(id));

  if (element && element.type !== "attestation") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  if (!element) {
    return null;
  }

  return (
    <div>
      <PreviewHeader
        rules={element.rules}
        title={element.content.title}
        titleNumber={element.titleNumber ?? ""}
        adjudicatorNotes={element.content.adjudicatorNotes}
        surveyTakerNotes={element.content.surveyTakerNotes}
        audience={element.content.audience}
        questionPlaceholder="Enter the attestation statement"
        question={element.content.question}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {element.content.attestations?.map(
          (attestation: AttestationInstance, index: number) => (
            <AttestationRow
              value={element.content}
              elementIsEditable={false}
              key={index}
              index={index}
              attestation={attestation}
            />
          )
        )}
      </div>
    </div>
  );
};
