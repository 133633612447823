import { Flex, Icon, Tooltip } from "@chakra-ui/react";
import { Colors } from "../theme/colors";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { MdMoveUp } from "react-icons/md";
import { MdMoveDown } from "react-icons/md";
import { MdFormatIndentIncrease } from "react-icons/md";
import { MdFormatIndentDecrease } from "react-icons/md";
import { FiTrash2 } from "react-icons/fi";
import { Position } from "../types";
import { useAppSelector, useAppDispatch } from "src/store";
import {
  lookupFormElementById,
  selectFormElements,
} from "src/store/selectors/form.selectors";
import {
  deleteFormElement,
  swapFormElement,
  FormElementState,
  indentFormElement,
  dedentFormElement,
} from "src/store/slices/form.slice";
import {
  getSiblingAbove,
  getElementPathIndexes,
  getSiblingBelow,
  findRelativeTreeHeight,
} from "src/util/form.utils";
import { useElementContext } from "src/hooks/useElementContext";

export const DocumentSectionToolbar = ({
  addSectionVisible,
  setAddSectionVisible,
  id,
  isSelected,
}: {
  addSectionVisible: boolean;
  setAddSectionVisible: (x: boolean) => void;
  id: string;
  isSelected: boolean;
}) => {
  const dispatch = useAppDispatch();

  const element = useAppSelector(lookupFormElementById(id));
  const { element: elementState } = useElementContext();
  const formElements: FormElementState[] | undefined =
    useAppSelector(selectFormElements);
  const indentationLevel = element?.pathIndex?.length ?? 0;
  const currentPath = getElementPathIndexes([id], formElements)[0];

  const deleteItem = () => {
    dispatch(
      deleteFormElement({
        parentElementId: elementState?.parentElementId,
        deletedElementId: elementState?.id,
      })
    );
  };

  const moveUp = () => {
    dispatch(
      swapFormElement({
        elementId: id,
        position: "above" as Position,
        elementState,
      })
    );
  };

  const indent = () => {
    dispatch(
      indentFormElement({
        elementState,
        parentElementId: elementState?.parentElementId,
      })
    );
  };

  const dedent = () => {
    dispatch(
      dedentFormElement({
        elementState,
        parentElementId: elementState?.parentElementId,
      })
    );
  };

  const moveDown = () => {
    dispatch(
      swapFormElement({
        elementId: id,
        position: "below" as Position,
        elementState,
      })
    );
  };

  const canBeDeleted = () => {
    const siblingAbove = getSiblingAbove(currentPath, formElements);
    const siblingBelow = getSiblingBelow(currentPath, formElements);

    return (
      indentationLevel > 1 || siblingAbove !== null || siblingBelow !== null
    );
  };

  const canBeIndented = () => {
    // maximum levels of nesting is 4, and only sections may have nested components within them
    if (!element || !elementState) {
      return false;
    }

    const treeDepth = findRelativeTreeHeight(element);

    return (
      treeDepth + elementState.indentationLevel < 5 &&
      getSiblingAbove(currentPath, formElements) !== null &&
      getSiblingAbove(currentPath, formElements)!.type === "section"
    );
  };

  const buttons = [
    {
      icon: MdMoveUp,
      ariaLabel: "move up",
      onClick: () => moveUp(),
      visible: getSiblingAbove(currentPath, formElements) !== null,
      tooltipText: "Move up",
    },
    {
      icon: MdFormatIndentIncrease,
      ariaLabel: "tab in",
      onClick: () => indent(),
      visible: canBeIndented(),
      tooltipText: "Tab in",
    },
    {
      icon: FiTrash2,
      ariaLabel: "delete",
      onClick: () => deleteItem(),
      visible: canBeDeleted(),
      tooltipText: "Delete",
    },
    {
      icon: MdFormatIndentDecrease,
      ariaLabel: "tab out",
      onClick: () => dedent(),
      visible: indentationLevel > 1,
      tooltipText: "Tab out",
    },
    {
      icon: MdMoveDown,
      ariaLabel: "move down",
      onClick: () => moveDown(),
      visible: getSiblingBelow(currentPath, formElements) !== null,
      tooltipText: "Move down",
    },
    {
      dataTestId: "add-element-btn",
      icon: MdOutlineAddCircleOutline,
      ariaLabel: "add section below",
      onClick: () => {
        setAddSectionVisible(!addSectionVisible);
      },
      visible: true,
      tooltipText: "Add element within section",
    },
  ];

  return (
    <Flex
      direction="column"
      gap={1}
      p={1}
      style={{
        marginTop: -2,
        borderColor: Colors.brightblue[500],
        backgroundColor: "white",
        borderWidth: "2px",
        position: "absolute",
        width: "fit-content",
        left: -62,
        opacity: isSelected ? 1 : 0,
        transition: "opacity 0.3s ease-in-out",
        pointerEvents: isSelected ? "auto" : "none",
      }}
    >
      {buttons.map((button) => {
        if (!button.visible) return null;
        return (
          <Tooltip
            key={`${button.ariaLabel}-${id}`}
            position="relative"
            placement="bottom-start"
            label={button.tooltipText}
          >
            <span>
              <Icon
                data-test-id={`${button.dataTestId}-${elementState?.pathIndex.join("-")}`}
                _hover={{ cursor: "pointer" }}
                boxSize={5}
                onClick={(event) => {
                  event.stopPropagation();
                  isSelected && button.onClick();
                }}
                aria-label={button.ariaLabel}
                as={button.icon}
              />
            </span>
          </Tooltip>
        );
      })}
    </Flex>
  );
};
