import React from "react";
import { Rule, RuleEntry } from "./RuleEntry";
import { Box, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  lookupFormElementById,
  selectFormElements,
  selectFormElementsById,
} from "src/store/selectors/form.selectors";
import {
  getAllAvailableSubjectElements,
  getEmptyRule,
  getIsPredicateAdjectiveRequired,
  transformRuleToServerRule,
} from "src/util/rule.utils";
import { updateFormElement } from "src/store/slices/form.slice";
import { FormElement } from "src/types/form.types";
import { RuleSaveButton } from "./RuleSaveButton";
import { RuleIconButton } from "./RuleIconButton";
import { Delete } from "@mui/icons-material";

interface EmptyRuleEntryProps {
  onRuleCreate: () => void;
  onDeletePendingRule: () => void;
}

export const EmptyRuleEntry = ({
  onRuleCreate,
  onDeletePendingRule,
}: EmptyRuleEntryProps) => {
  const elements = useAppSelector(selectFormElements);
  const elementsById = useAppSelector(selectFormElementsById);
  const [pendingRule, setPendingRule] = React.useState<Rule>(getEmptyRule());
  const [isValidRule, setIsValidRule] = React.useState(false);
  const subjectElement = useAppSelector(
    lookupFormElementById(pendingRule.subjectElementId || "")
  );
  const dispatch = useAppDispatch();

  const {
    allAvailableElements,
    availableSubjectElements,
    availableElementsWithoutRules,
  } = React.useMemo(() => {
    if (elements && elementsById) {
      const allAvailableElements = getAllAvailableSubjectElements({
        currentLevelElements: elements,
        elementsById,
        allowElementsWithRules: true,
        excludeRootElements: false,
        firstEditableElement: { value: null },
      });

      const availableSubjectElements = getAllAvailableSubjectElements({
        currentLevelElements: elements,
        elementsById,
        allowElementsWithRules: false,
        excludeRootElements: true,
        firstEditableElement: { value: null },
      });

      const availableElementsWithoutRules = allAvailableElements.filter((e) => {
        return e.rules.length === 0;
      });

      return {
        allAvailableElements,
        availableSubjectElements,
        availableElementsWithoutRules,
      };
    }

    return { availableElementsWithoutRules: null };
  }, [elements, elementsById]);

  React.useEffect(() => {
    /**
     * handle when subject element has change and we need to match
     * pending audience with the subject element
     */
    if (
      subjectElement &&
      subjectElement.content.audience !== pendingRule.audience
    ) {
      setPendingRule((prevPendingRule) => {
        return {
          ...prevPendingRule,
          audience: subjectElement.content.audience,
        };
      });
    }
  }, [pendingRule.audience, subjectElement]);

  React.useEffect(() => {
    if (
      pendingRule.subjectElementId &&
      pendingRule.audience &&
      pendingRule.conditionalSubjectElementId &&
      pendingRule.predicateVerb &&
      (getIsPredicateAdjectiveRequired(pendingRule.predicateVerb)
        ? pendingRule.predicateAdjective
        : true)
    ) {
      setIsValidRule(true);
    }
  }, [pendingRule]);

  const handleRuleChange = React.useCallback(
    ({
      element,
      fieldName,
      value,
    }: {
      element?: FormElement;
      fieldName: string;
      value: string;
    }) => {
      if (element && fieldName === "audience") {
        dispatch(
          updateFormElement({
            ...element,
            content: {
              ...element.content,
              [fieldName]: value,
            },
          })
        );
      }

      setPendingRule((prevPendingRule) => {
        return {
          ...prevPendingRule,
          [fieldName]: value,
        };
      });
    },
    [dispatch]
  );

  const handleCreateRule = React.useCallback(() => {
    if (!isValidRule) {
      return;
    }

    if (subjectElement) {
      if (
        pendingRule.subjectElementId &&
        pendingRule.audience &&
        pendingRule.predicateVerb &&
        pendingRule.conditionalSubjectElementId
      ) {
        const pendingServerRule = transformRuleToServerRule({
          subjectElementId: pendingRule.subjectElementId,
          audience: pendingRule.audience,
          conditionalSubjectElementId: pendingRule.conditionalSubjectElementId,
          predicateVerb: pendingRule.predicateVerb,
          predicateAdjective: pendingRule.predicateAdjective,
        });
        dispatch(
          updateFormElement({
            ...subjectElement,
            rules: [...subjectElement.rules, pendingServerRule],
          })
        );

        onRuleCreate();
      }
    }
  }, [subjectElement, pendingRule, isValidRule, dispatch, onRuleCreate]);

  return (
    <form onSubmit={handleCreateRule}>
      <Stack direction="row" spacing={2}>
        {allAvailableElements && availableElementsWithoutRules ? (
          <RuleEntry
            rule={pendingRule}
            availableElements={availableElementsWithoutRules}
            availableSubjectElements={availableSubjectElements}
            availableConditionSubjectElements={allAvailableElements}
            onRuleChange={handleRuleChange}
          />
        ) : null}

        <Box sx={{ width: 28, height: 28 }}>
          {isValidRule ? <RuleSaveButton onClick={handleCreateRule} /> : null}
        </Box>

        <Box sx={{ width: 28, height: 28 }}>
          <RuleIconButton onClick={onDeletePendingRule}>
            <Delete fontSize="inherit" />
          </RuleIconButton>
        </Box>
      </Stack>
    </form>
  );
};
