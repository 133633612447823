import {
  autoformatArrow,
  autoformatLegal,
  autoformatLegalHtml,
  autoformatMath,
  autoformatPunctuation,
  autoformatSmartQuotes,
} from "@udecode/plate-autoformat";

import { autoformatBlocks } from "./autoformatBlocks";
import { autoformatIndentLists } from "./autoformatIndentLists";
// import { autoformatMarks } from "@/lib/plate/autoformatMarks";
// import { MyAutoformatRule } from "@/lib/plate/plate-types";

export const autoformatRules = [
  ...autoformatBlocks,
  ...autoformatIndentLists,
  // ...autoformatMarks,
  // ...(autoformatSmartQuotes as MyAutoformatRule[]),
  // ...(autoformatPunctuation as MyAutoformatRule[]),
  // ...(autoformatLegal as MyAutoformatRule[]),
  // ...(autoformatLegalHtml as MyAutoformatRule[]),
  // ...(autoformatArrow as MyAutoformatRule[]),
  // ...(autoformatMath as MyAutoformatRule[]),
];
