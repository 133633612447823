import { createListenerMiddleware } from "@reduxjs/toolkit";
import { setUserContext } from "../slices/user.slice";
import { userApi } from "src/services/user.service";

export const userMiddleware = createListenerMiddleware();

let lastNewUpdateUserRequestAbort: () => void;

userMiddleware.startListening({
  actionCreator: setUserContext,

  effect: async (action, listener) => {
    if (lastNewUpdateUserRequestAbort) {
      lastNewUpdateUserRequestAbort();
    }

    const userInfo = action.payload;

    const { abort } = listener.dispatch(
      userApi.endpoints.updateUser.initiate({ user: userInfo.user })
    );

    lastNewUpdateUserRequestAbort = abort;
  },
});
