import RichText from "./RichText";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { Flex, Text, Icon, Tooltip, HStack } from "@chakra-ui/react";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import RuleFolderOutlinedIcon from "@mui/icons-material/RuleFolderOutlined";
import { useElementContext } from "src/hooks/useElementContext";
import { useAppSelector } from "src/store";
import { lookupFormElementById } from "src/store/selectors/form.selectors";
import { FormElementBuildViewProps } from "src/types/form.types";
import { RichTextReadOnly } from "./RichTextReadOnly";

export const DeniedPreview = ({ id }: FormElementBuildViewProps) => {
  const element = useAppSelector(lookupFormElementById(id));
  const { element: elementState } = useElementContext();

  if (element && element.type !== "denied") {
    throw new Error(`element type is unexpected type: ${element.type}`);
  }

  if (!element) {
    return null;
  }

  return (
    <Flex
      alignContent="flex-start"
      w={"100%"}
      borderLeftColor={"red.500"}
      borderLeftWidth={7}
      borderRadius={2}
      flexDirection="column"
      paddingLeft={5}
    >
      <Flex flexDirection="row">
        <HStack w={"96%"} justifyContent={"space-between"}>
          <HStack gap={0}>
            <Icon as={RemoveCircleOutlineOutlinedIcon} />
            <Spacer x={SpacerSizes.sm} />

            <Text variant="bold">{element.content.title}</Text>
          </HStack>
          <HStack>
            {element.rules && element.rules.length > 0 ? (
              <Tooltip label="A rule is applied to this element?. Click to view details.">
                <Icon boxSize={5} as={RuleFolderOutlinedIcon} />
              </Tooltip>
            ) : null}
          </HStack>
        </HStack>
      </Flex>

      <RichTextReadOnly value={element.content.description} />
    </Flex>
  );
};
