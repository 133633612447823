import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { ROLE_1, ROLE_2 } from "src/roles";
import { CommentThread, Comment } from "src/types";

export const selectCommentsState = (state: RootState) => {
  return state.comments;
};

export const selectCommentThreadsDictionary = createSelector(
  selectCommentsState,
  (commentsState) => {
    return commentsState.commentThreadsDictionary;
  }
);

export const selectPrimaryCommentFilter = createSelector(
  selectCommentsState,
  (commentsState) => {
    return commentsState.primaryCommentFilter;
  }
);

export const selectSecondaryCommentFilter = createSelector(
  selectCommentsState,
  (commentsState) => {
    return commentsState.secondaryCommentFilter;
  }
);

export const selectOpenCount = createSelector(
  selectCommentThreadsDictionary,
  (commentThreadsDictionary) => {
    let count = 0;
    for (const key in commentThreadsDictionary) {
      count += commentThreadsDictionary[key].filter(
        (thread: CommentThread) => !thread.resolved
      ).length;
    }
    return count;
  }
);

export const selectResolvedCount = createSelector(
  selectCommentThreadsDictionary,
  (commentThreadsDictionary) => {
    if (!commentThreadsDictionary) return 0;
    let count = 0;
    for (const key in commentThreadsDictionary) {
      count += commentThreadsDictionary[key].filter(
        (thread) => thread.resolved
      ).length;
    }
    return count;
  }
);

export const selectAdjudicationCount = createSelector(
  selectCommentThreadsDictionary,
  (commentThreadsDictionary) => {
    let count = 0;
    for (const key in commentThreadsDictionary) {
      count += commentThreadsDictionary[key].filter(
        (thread) => thread.type === ROLE_2
      ).length;
    }
    return count;
  }
);

export const selectSubmitterCount = createSelector(
  selectCommentThreadsDictionary,
  (commentThreadsDictionary) => {
    let count = 0;
    for (const key in commentThreadsDictionary) {
      count += commentThreadsDictionary[key].filter(
        (thread) => thread.type === ROLE_1
      ).length;
    }
    return count;
  }
);

export const selectAssignedCount = createSelector(
  [
    (state: RootState) => selectCommentThreadsDictionary(state),
    (state: RootState, email: string) => email,
    (state: RootState, email: string, name: string) => name,
    (state: RootState, email: string, name: string, resolved: boolean) =>
      resolved,
  ],
  (commentThreadsDictionary, email, name, resolved) => {
    let count = 0;
    for (const key in commentThreadsDictionary) {
      count += commentThreadsDictionary[key].filter(
        (thread: CommentThread) =>
          thread.comments.find(
            (comment: Comment) =>
              comment.assignedTo?.includes(email) ||
              comment.assignedTo?.includes(name)
          ) && thread.resolved === resolved
      ).length;
    }
    return count;
  }
);

export const selectParticipatingCount = createSelector(
  [
    (state: RootState) => selectCommentThreadsDictionary(state),
    (state: RootState, email: string) => email,
    (state: RootState, email: string, userId: string) => userId,
    (state: RootState, email: string, name: string, resolved: boolean) =>
      resolved,
  ],
  (commentThreadsDictionary, email, userId, resolved) => {
    let count = 0;
    for (const key in commentThreadsDictionary) {
      count += commentThreadsDictionary[key].filter((thread: CommentThread) =>
        thread.comments.find(
          (comment: Comment) =>
            (comment.user === userId ||
              comment.assignedTo?.includes(email) ||
              comment.mentioned?.includes(email)) &&
            thread.resolved === resolved
        )
      ).length;
    }
    return count;
  }
);
