import { Colors } from "../theme/colors";
import { useAppSelector } from "../store";
import { Box } from "@mui/material";
export const PRIVILEGE_BANNER_HEIGHT = "30px";

export const PrivilegedBanner = () => {
  const privilegeSettings = useAppSelector(
    (state) =>
      state.user.userContext?.currentOrgMembership?.organization
        ?.privilegeSettings
  );
  return (
    <Box
      data-test-id="privilege-banner"
      style={{
        zIndex: 10,
        height: PRIVILEGE_BANNER_HEIGHT,
        backgroundColor: Colors.brand[200],
        borderRadius: "3px",
      }}
    >
      <Box
        style={{
          padding: "2px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {privilegeSettings?.privilegeBanner ?? "Privileged and Confidential"}
      </Box>
    </Box>
  );
};
