import React from "react";
import { useAppDispatch, useAppSelector } from "../store";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Circle,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useUserInitials } from "../hooks/useUserInitials";
import { useNavigate } from "react-router-dom";
import { resetAuthState } from "../store/slices/auth.slice";
import { supabaseClient } from "../clients/supabase.client";
import { resetOrganizationState } from "../store/slices/organization.slice";
import { getSlugFromOrgId } from "src/util/org-slugs.utils";

export const UserMenu = () => {
  const globalUserInfo = useAppSelector((state) => state.user.userContext);
  const initials = useUserInitials();
  const navigate = useNavigate();
  const [accordionIndex, setAccordionIndex] = React.useState(-1);
  const userAndOrgMenuMargin = "10px";
  const dispatch = useAppDispatch();

  const renderName = React.useCallback(() => {
    if (globalUserInfo?.user?.firstName || globalUserInfo?.user?.lastName) {
      return (
        (globalUserInfo?.user?.firstName ?? "") +
        " " +
        (globalUserInfo?.user?.lastName ?? "")
      );
    }
    return globalUserInfo?.user?.email;
  }, [globalUserInfo]);

  const handleOrgAccordionClick = React.useCallback(() => {
    if (accordionIndex == -1) {
      setAccordionIndex(0);
    } else {
      setAccordionIndex(-1);
    }
  }, [accordionIndex]);

  const handleOrgSelection = React.useCallback(
    async (update: string) => {
      handleOrgAccordionClick();

      let targetOrgId = "";
      globalUserInfo?.orgMemberships.forEach((membership) => {
        if (update === membership.organization?.name) {
          targetOrgId = membership.organization?._id;
        }
      });

      const params = new URLSearchParams(window.location.search);
      params.set("org", getSlugFromOrgId(targetOrgId));
      navigate(`${window.location.pathname}?${params}`);
      window.location.reload();
    },
    [handleOrgAccordionClick, globalUserInfo, navigate]
  );

  const handleLogout = React.useCallback(async () => {
    const { error } = await supabaseClient.auth.signOut();
    if (error) {
      // @TODO log to error monitoring to notify team about the error
      console.error("Error logging out:", error.message);
    }
    localStorage.removeItem("token");
    dispatch(resetAuthState());
    dispatch(resetOrganizationState());
    navigate("/login");
  }, [navigate, dispatch]);

  return (
    <Menu>
      <MenuButton data-test-id="user-menu-btn">
        <HStack border="1px" borderColor="brand.500" color="white.500" p="4px">
          <Center>
            <Box w="160px">
              <Text data-test-id="user-menu-current-org-name-text" isTruncated>
                {globalUserInfo?.currentOrgMembership?.organization?.name}
              </Text>
            </Box>
          </Center>
          <Center>
            <Icon as={AccountCircleOutlinedIcon} />
          </Center>
        </HStack>
      </MenuButton>
      <MenuList style={{ color: "gray.900", borderRadius: "2px" }}>
        <HStack m="12px">
          <VStack>
            <Circle size="64px" bg="brightblue.200">
              <Text fontWeight="bold">{initials}</Text>
            </Circle>
          </VStack>
          <VStack>
            <Text w="full" fontWeight="bold">
              {renderName()}
            </Text>
            <Text w="full" fontSize="sm">
              {globalUserInfo?.user?.email}
            </Text>
          </VStack>
        </HStack>
        <MenuItem
          w="100%"
          icon={<AccountCircleOutlinedIcon />}
          mt={userAndOrgMenuMargin}
          onClick={() => navigate("/user-profile")}
        >
          Profile
        </MenuItem>
        <MenuDivider />
        <VStack w="full">
          {globalUserInfo &&
          globalUserInfo.orgMemberships &&
          globalUserInfo.orgMemberships ? (
            <Accordion allowToggle w="full" p="8px" index={accordionIndex}>
              <AccordionItem border="none">
                <AccordionButton
                  data-test-id="user-organization-accordion-btn"
                  onClick={handleOrgAccordionClick}
                >
                  <Box as="span" flex="1" textAlign="left">
                    <Text data-test-id="user-organization-accordion-text">
                      {globalUserInfo.currentOrgMembership?.organization?.name}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <RadioGroup
                    onChange={handleOrgSelection}
                    defaultValue={globalUserInfo.currentOrgMembership?.name}
                  >
                    <VStack align="left" ml="8px">
                      {globalUserInfo?.orgMemberships.map((m) => {
                        return (
                          <Radio
                            data-test-id={`user-organization-${m.name}`}
                            key={m.name}
                            value={m.name}
                          >
                            {m.name}
                          </Radio>
                        );
                      })}
                    </VStack>
                  </RadioGroup>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ) : (
            <></>
          )}
        </VStack>
        {globalUserInfo?.currentOrgMembership?.permissions
          .manageOrgPermissions ||
        globalUserInfo?.currentOrgMembership?.permissions.manageOrgSettings ||
        globalUserInfo?.currentOrgMembership?.permissions
          .manageLegalPrivilege ? (
          <>
            <MenuItem
              data-test-id="organization-settings-btn"
              w="100%"
              icon={<GroupsOutlinedIcon />}
              mt={userAndOrgMenuMargin}
              onClick={() => navigate("/organization")}
            >
              Organization Settings
            </MenuItem>
          </>
        ) : (
          <></>
        )}
        <MenuDivider />
        <MenuItem
          w="100%"
          icon={<LogoutOutlinedIcon />}
          mt={userAndOrgMenuMargin}
          onClick={handleLogout}
        >
          Log Out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
